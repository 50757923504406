import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidationErrors, AsyncValidatorFn } from '@angular/forms';
import { SpaceType, MeasurementUnit } from 'src/app/core/services/api-clients';
import { Subscription, BehaviorSubject, Observable, of } from 'rxjs';
import { validateRequiredAreaFn } from '../../editor-spacetype-validation';
import { IntlService } from '@progress/kendo-angular-intl';
import { DataStoreFacade, UIFacade } from 'src/app/spaceplan/shared/facades';
import { take, map } from 'rxjs/operators';
import { CustomValidators } from 'src/app/spaceplan/shared/custom-validators';
import { ActiveColorClickEvent } from '@progress/kendo-angular-inputs';

@Component({
  selector: 'app-editor-spacetype-form',
  styleUrls: ['./editor-spacetype-form.component.scss'],
  templateUrl: './editor-spacetype-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorSpaceTypeFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public parentForm: FormGroup;
  @Input()
  model: SpaceType;
  @Input()
  options: {
    measurementUnit: MeasurementUnit;
    readOnly: boolean;
  };
  @Input()
  public isRequesting: boolean;
  @Output()
  public updateModelWithChanges = new EventEmitter<Partial<SpaceType>>();
  public form: FormGroup;
  public MeasurementUnit = MeasurementUnit;
  private readonly subscription: Subscription = new Subscription();
  private readonly onChanges = new BehaviorSubject<SimpleChanges>(null);

  constructor(private fb: FormBuilder, public intl: IntlService, public uiFacade: UIFacade, private dataStoreFacade: DataStoreFacade) {}

  ngOnInit() {
    this.prepareLookups();
    this.initForm();
    if (this.parentForm) {
      this.parentForm.addControl('spaceType', this.form);
    }
    this.subscription.add(
      this.onChanges.subscribe((changes: SimpleChanges) => {
        if (changes?.model) {
          const model = changes.model.currentValue as SpaceType;
          const values = this.modelToForm(model);
          this.form.patchValue(values, { emitEvent: false });
          if (values.length || values.breadth) {
          } else {
            if (values.area != null) {
            }
          }
        }
      })
    );
    this.subscription.add(
      this.form.valueChanges.pipe().subscribe((value) => {
        const values = this.form.getRawValue();
        this.model = this.formToModel(values);
        this.updateModelWithChanges.emit(this.model);
      })
    );
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.onChanges.next(changes);
  }
  prepareLookups() {}
  initForm() {
    this.form = this.fb.group({
      spaceTypeName: this.fb.control('', [Validators.required], this.validateNameDuplicateValidator()),
      spaceTypeCode: this.fb.control(''),
      length: this.fb.control(null, [CustomValidators.greaterThanNumber(0), Validators.max(10000)]), // validateRequiredLengthFn.bind(this)
      breadth: this.fb.control(null, [CustomValidators.greaterThanNumber(0), Validators.max(10000)]), // validateRequiredBreadthFn.bind(this)
      area: this.fb.control(null, [validateRequiredAreaFn.bind(this), CustomValidators.greaterThanNumber(0), Validators.max(10000)]),
      capacity: this.fb.control(null, [Validators.min(0), Validators.max(999)]),
      workPlaceFactor: this.fb.control(null, [Validators.min(0), Validators.max(1)]),
      primaryFactor: this.fb.control(null, [Validators.min(0), Validators.max(999)]),
      spaceTypeDescr: this.fb.control(''),
      imageUrl: this.fb.control(null),
      colourCode: this.fb.control(null),
    });
    if (this.options.readOnly) {
      this.form.disable({ onlySelf: true });
    }
  }
  formToModel(values: any): Partial<SpaceType> {
    const model: Partial<SpaceType> = {
      ...this.model,
      spaceTypeName: values.spaceTypeName,
      spaceTypeCode: values.spaceTypeCode,
      length: values.length ? +values.length : null,
      breadth: values.breadth ? +values.breadth : null,
      area: +values.area,
      capacity: values.capacity ? +values.capacity : null,
      workPlaceFactor: values.workPlaceFactor ? +values.workPlaceFactor : null,
      primaryFactor: values.primaryFactor ? +values.primaryFactor : null,
      spaceTypeDescr: values.spaceTypeDescr,
      imageUrl: values.imageUrl,
      colourCode: values.colourCode,
    };
    return model;
  }
  modelToForm(model: SpaceType): { [key: string]: any } {
    return (
      (model && {
        spaceTypeName: model.spaceTypeName,
        spaceTypeCode: model.spaceTypeCode,
        length: model.length,
        breadth: model.breadth,
        area: model.area,
        capacity: model.capacity,
        workPlaceFactor: model.workPlaceFactor,
        primaryFactor: model.primaryFactor,
        spaceTypeDescr: model.spaceTypeDescr,
        imageUrl: model.imageUrl,
        colourCode: model.colourCode,
      }) ||
      {}
    );
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onChangeLength(inputLength, inputBreadth, inputArea) {
    this.calcArea();
    this.controls.length.updateValueAndValidity();
    this.controls.breadth.updateValueAndValidity();
  }

  onChangeBreath(inputLength, inputBreadth, inputArea) {
    this.calcArea();
    this.controls.length.updateValueAndValidity();
    this.controls.breadth.updateValueAndValidity();
  }

  onChangeArea(inputLength, inputBreadth, inputArea) {
    this.controls.area.updateValueAndValidity();
    this.calcLengthBreadth();
  }

  calcArea() {
    if (this.isNumber(this.controls.length.value) && this.isNumber(this.controls.breadth.value)) {
      const value = Math.round(+this.controls.length.value * +this.controls.breadth.value * 100) / 100;

      this.controls.area.setValue(+value, { onlySelf: true });
      this.controls.area.updateValueAndValidity();
    }
  }

  calcLengthBreadth() {
    if (this.isNumber(this.controls.area.value) && +this.controls.area.value > 0) {
      // has area
      if (this.isNumber(this.controls.length.value) || this.isNumber(this.controls.breadth.value)) {
        if (this.isNumber(this.controls.length.value)) {
          if (+this.controls.length.value > 0) {
            this.controls.breadth.setValue(+this.controls.area.value / +this.controls.length.value);
            this.controls.breadth.updateValueAndValidity();
          }
        }
        if (this.isNumber(this.controls.breadth.value)) {
          if (+this.controls.breadth.value > 0) {
            this.controls.length.setValue(+this.controls.area.value / +this.controls.breadth.value);
            this.controls.length.updateValueAndValidity();
          }
        }
      }
    }
  }

  isNumber(value: string | number): boolean {
    return value != null && value !== '' && !isNaN(Number(value.toString()));
  }

  validateNameDuplicateValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.dataStoreFacade.spaceTypes$.pipe(
        map((spaceTypes) => {
          const duplicate = !!spaceTypes.find((f) => f.spaceTypeID !== this.model.spaceTypeID && f.spaceTypeName === control.value);
          if (duplicate) {
            return {
              duplicate: true,
            };
          }
          return null;
        }),
        take(1)
      );
    };
  }

  get controls() {
    return {
      area: this.form.get('area'),
      length: this.form.get('length'),
      breadth: this.form.get('breadth'),
    };
  }
}
