import { Component, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, AbstractControl, Validators } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { EditorWorkPlaceTypeFacade, UIFacade } from '../../../../facades';

@Component({
  selector: 'app-editor-workplacetype',
  templateUrl: './editor-workplacetype.component.html',
  styleUrls: ['./editor-workplacetype.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorWorkPlaceTypeComponent {
  public form: FormGroup;
  public isRequesting = false;
  public options$ = combineLatest([this.editorFacade.isReadOnly$]).pipe(
    map(([isReadOnly]) => {
      return {
        readOnly: isReadOnly,
      };
    })
  );

  constructor(public editorFacade: EditorWorkPlaceTypeFacade, private fb: FormBuilder, public uiFacade: UIFacade) {
    this.form = this.fb.group({});
  }

  onSubmit() {
    this.isRequesting = true;
    if (!this.form.invalid) {
      this.uiFacade.showLoading();
      this.editorFacade.save().subscribe(
        (result) => {
          if (result.success) {
            // SAVE SUCESS
            this.form.markAsUntouched();
            this.form.markAsPristine();
            this.isRequesting = false;
            this.uiFacade.hideLoading();
          }
        },
        (err) => {
          this.uiFacade.hideLoading();
        }
      );
    }
  }
  onClose() {
    this.editorFacade.close();
  }
}
