import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modal-error',
  styleUrls: ['./modal-error.component.scss'],
  template: `
    <div class="modal-header">
      <h4 class="modal-title">{{ title }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="onCancel()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div [innerHTML]="message"></div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="onCancel()">{{ cancelButtonText }}</button>
    </div>
  `,
})
export class ModalErrorComponent implements OnInit {
  errors: string;
  public onClose: Subject<boolean> = new Subject();

  title = 'Error';
  message: string;
  cancelButtonText = 'Ok';

  constructor(public activeModal: BsModalRef) {}

  public ngOnInit(): void {}

  public onCancel(): void {
    this.onClose.next(false);
    this.activeModal.hide();
  }
}
