import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators, AsyncValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
import { Folder, Partner, FolderUsageType } from 'src/app/core/services/api-clients';
import { Subscription, BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { FoldersDataStoreFacade } from 'src/app/folders/facades';

/**
 * t(spaceplan.folderUsageType.All)
 * t(spaceplan.folderUsageType.SpaceProgram)
 * t(spaceplan.folderUsageType.Template)
 */

@Component({
  selector: 'app-editor-folder-form',
  styleUrls: ['./editor-folder-form.component.scss'],
  templateUrl: './editor-folder-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorFolderFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public parentForm: FormGroup;
  @Input()
  model: Folder;
  @Input()
  options: {
    partners: Partner[];
    readOnly: false;
  };
  @Input()
  public isRequesting: boolean;
  @Output()
  public updateModelWithChanges = new EventEmitter<Partial<Folder>>();
  public form: FormGroup;
  private readonly subscription: Subscription = new Subscription();
  private readonly onChanges = new BehaviorSubject<SimpleChanges>(null);

  public partners: Partner[];
  public folderUsageTypes: { text: string; value?: string; type: number }[];

  constructor(private fb: FormBuilder, private dataStoreFacade: FoldersDataStoreFacade) {}

  ngOnInit() {
    this.prepareLookups();
    this.initForm();
    if (this.parentForm) {
      this.parentForm.addControl('folder', this.form);
    }
    this.subscription.add(
      this.onChanges.subscribe((changes: SimpleChanges) => {
        if (changes?.model) {
          const model = changes.model.currentValue as Folder;
          this.prepareLookupsUsageType();
          const values = this.modelToForm(model);
          this.form.patchValue(values, { emitEvent: false });
        }
      })
    );
    this.subscription.add(
      this.form.valueChanges.pipe().subscribe((value) => {
        const values = this.form.getRawValue();
        this.model = this.formToModel(values);
        this.updateModelWithChanges.emit(this.model);
      })
    );
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.onChanges.next(changes);
  }
  prepareLookups() {
    // this.prepareLookupsUsageType();
    this.partners = this.options.partners.sort((a, b) => a.partnerDescr.localeCompare(b.partnerDescr));
  }
  prepareLookupsUsageType() {
    combineLatest([this.dataStoreFacade.spacePrograms$, this.dataStoreFacade.templates$]).subscribe(([spacePrograms, templates]) => {
      let hasSpacePrograms = false;
      let hasTemplates = false;

      if (this.model.folderID) {
        this.folderUsageTypes = [];
        hasSpacePrograms = !!spacePrograms.filter((s) => s.folderID === this.model.folderID).length;
        hasTemplates = !!templates.filter((s) => s.folderID === this.model.folderID).length;

        if (this.model.usageType === FolderUsageType.All) {
          this.folderUsageTypes.push({
            text: FolderUsageType[FolderUsageType.All],
            type: FolderUsageType.All,
          });
          if (hasSpacePrograms) {
            this.folderUsageTypes.push({
              text: FolderUsageType[FolderUsageType.SpaceProgram],
              type: FolderUsageType.SpaceProgram,
            });
          }
          if (hasTemplates) {
            this.folderUsageTypes.push({
              text: FolderUsageType[FolderUsageType.Template],
              type: FolderUsageType.Template,
            });
          }
          if (!hasSpacePrograms && !hasTemplates) {
            this.folderUsageTypes.push({
              text: FolderUsageType[FolderUsageType.SpaceProgram],
              type: FolderUsageType.SpaceProgram,
            });
            this.folderUsageTypes.push({
              text: FolderUsageType[FolderUsageType.Template],
              type: FolderUsageType.Template,
            });
          }
        }

        if (this.model.usageType === FolderUsageType.SpaceProgram) {
          this.folderUsageTypes.push({
            text: FolderUsageType[FolderUsageType.All],
            type: FolderUsageType.All,
          });
          this.folderUsageTypes.push({
            text: FolderUsageType[FolderUsageType.SpaceProgram],
            type: FolderUsageType.SpaceProgram,
          });
          if (!hasSpacePrograms) {
            this.folderUsageTypes.push({
              text: FolderUsageType[FolderUsageType.Template],
              type: FolderUsageType.Template,
            });
          }
        }

        if (this.model.usageType === FolderUsageType.Template) {
          this.folderUsageTypes.push({
            text: FolderUsageType[FolderUsageType.All],
            type: FolderUsageType.All,
          });
          if (!hasTemplates) {
            this.folderUsageTypes.push({
              text: FolderUsageType[FolderUsageType.SpaceProgram],
              type: FolderUsageType.SpaceProgram,
            });
          }
          this.folderUsageTypes.push({
            text: FolderUsageType[FolderUsageType.Template],
            type: FolderUsageType.Template,
          });
        }
      } else {
        this.folderUsageTypes = [
          {
            text: FolderUsageType[FolderUsageType.All],
            type: FolderUsageType.All,
          },
          {
            text: FolderUsageType[FolderUsageType.SpaceProgram],
            type: FolderUsageType.SpaceProgram,
          },
          {
            text: FolderUsageType[FolderUsageType.Template],
            type: FolderUsageType.Template,
          },
        ];
      }
    });
  }

  initForm() {
    this.form = this.fb.group({
      folderName: this.fb.control('', [Validators.required] /*, this.validateNameDuplicateValidator()*/),
      partnerID: this.fb.control('', [Validators.required]),
      usageType: this.fb.control(FolderUsageType.All),
      // suitableFor: this.fb.control(''),
      // inFlexFactorCalcInd: this.fb.control(false),
      // imageUrl: this.fb.control(null),
    });
    if (this.options.readOnly) {
      this.form.disable({ onlySelf: true });
    }
  }
  formToModel(values: any): Partial<Folder> {
    const model: Partial<Folder> = {
      ...this.model,
      folderName: values.folderName,
      partnerID: values.partnerID,
      usageType: +values.usageType,
    };
    return model;
  }
  modelToForm(model: Folder): { [key: string]: any } {
    return (
      (model && {
        folderName: model.folderName,
        partnerID: model.partnerID,
        usageType: +model.usageType,
      }) ||
      {}
    );
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  // validateNameDuplicateValidator(): AsyncValidatorFn {
  //   return (control: AbstractControl): Observable<ValidationErrors | null> => {
  //     return this.dataStoreFacade.folders$.pipe(
  //       map((folders) => {
  //         const duplicate = !!folders.find((f) => f.folderID !== this.model.folderID && f.folderName === control.value);
  //         if (duplicate) {
  //           return {
  //             duplicate: true,
  //           };
  //         }
  //         return null;
  //       }),
  //       take(1)
  //     );
  //   };
  // }
}
