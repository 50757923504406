import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError, EMPTY, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AccountRepository } from './state/account.repository';
import { Router } from '@angular/router';

@Injectable()
export class AuthErrorInterceptor implements HttpInterceptor {
  public static IsRefreshing = false;

  constructor(private router: Router, private accountRepo: AccountRepository) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next);
        } else {
          return throwError(error);
        }
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!AuthErrorInterceptor.IsRefreshing) {
      AuthErrorInterceptor.IsRefreshing = true;
      this.accountRepo.redirectToLogin(this.router.routerState.snapshot.url);
      return throwError(new Error('Authentication failed. User session expired.'));
    } else {
      return EMPTY;
    }
  }
}
