import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthRouting } from './auth.routing';
import { PopupModule } from '@progress/kendo-angular-popup';
import { SharedModule } from '../spaceplan/shared/module/shared.module';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { InlineLoaderFactory } from '../transloco-root.module';
import { AuthErrorInterceptor } from './account/auth-error.interceptor';

const translocoProviders = [
  {
    provide: TRANSLOCO_SCOPE,
    useValue: {
      loader: InlineLoaderFactory((lang: string) => import(`./i18n/${lang}.json`)),
      scope: 'auth',
    },
    multi: true,
  },
];

@NgModule({
  imports: [CommonModule, FormsModule, SharedModule, ReactiveFormsModule, HttpClientModule, PopupModule, AuthRouting],
  declarations: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthErrorInterceptor,
      multi: true,
    },
    ...translocoProviders,
  ],
})
export class AuthModule {
  constructor() {}
}
