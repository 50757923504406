<ng-container *transloco="let t; read: 'root'">
  <div class="detail">
    <div class="header">
      <div class="title">{{ title }}</div>
      <div class="actions">
        <button (click)="onClose()" type="button" class="btn btn-primary btn-sm d-flex">
          <i class="k-icon k-i-close"></i>
        </button>
      </div>
    </div>
    <div class="body">
      <div class="p-1">
        <ng-template [ngIf]="isMultiple">
          <div class="item-actions">
            <button class="btn btn-light border" (click)="selectAll()"><i
                class="k-icon k-i-checkbox-checked"></i></button>
            <button class="btn btn-light border ml-1" (click)="selectNone()"><i
                class="k-icon k-i-checkbox"></i></button>
          </div>
        </ng-template>
        <div class="item-wrapper" *ngFor="let item of items" (click)="singleClick(item)" (dblclick)="doubleClick(item)"
          [ngClass]="{ selected: (item === selectedItem || hasSelectedItems(item)) }">
          <template
            [ngTemplateOutletContext]="{ item: item, isSelected: (item === selectedItem || hasSelectedItems(item))}"
            [ngTemplateOutlet]="templateVariable"></template>
        </div>
      </div>
    </div>
    <div class="footer" *ngIf="showFooter">
      <div class="buttons-wrapper">
        <!-- <button
          [disabled]="!(editorFacade.isDirty$ | async)"
          [ngClass]="{ disabled: !(editorFacade.isDirty$ | async) }"
          class="btn btn-primary"
          type="submit"
          style="width: 150px;"
        >
          {{ t('Select') }}
        </button> -->
        <button [disabled]="!(selectedItem || selectedItems?.length)"
          [ngClass]="{ disabled: !(selectedItem || selectedItems?.length) }" class="btn btn-primary" type="button"
          style="min-width: 150px;" (click)="onSelect()">
          {{ t('lookup.selectButton.caption') }}
        </button>
      </div>
    </div>
  </div>
</ng-container>