import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, forkJoin, of, throwError, combineLatest } from 'rxjs';
import {
  GeneratorClient,
  GeneratorOutputClient,
  GroupClient,
  PartnerClient,
  ProjectClient,
  ScenarioClient,
  SpaceProgramClient,
  SpaceTypeClient,
  TagClient,
  TagGroupClient,
  TemplateClient,
  SpaceProgram,
  Generator,
  GeneratorOutput,
  Group,
  Partner,
  SpaceType,
  Tag,
  TagGroup,
  Template,
  WorkPlaceTypeClient,
  WorkPlaceTypeGroupClient,
  WorkPlaceType,
  WorkPlaceTypeGroup,
  ActivityClient,
  ActivityToWorkPlaceTypeClient,
  Activity,
  ActivityToWorkPlaceType,
  UpdateActivityToWorkPlaceTypeDto,
  GeneratorInputType,
  CreateSpaceProgramDto,
  CreateTemplateDto,
  ActivityToWorkPlaceTypeDto,
  UpdateSpaceProgramSettingsDto,
  BehaviourActivityClient,
  BehaviourFractionSummaryDto,
  UpdateTagsSequenceDto,
  SummaryContent,
  CustomLabel,
  BehaviourWorkPlaceType,
  BehaviourWorkPlaceTypeClient,
  SpaceProgramSummary,
  UpdateSpaceProgramPrefaceDto,
  DuplicateTemplateDto,
  UserClient,
  User,
  InviteUsersDto,
  FolderClient,
  Folder,
  ColourEntityType,
  UpdateSpaceProgramColoursDto,
} from '../../../core/services/api-clients';
import {
  map,
  take,
  switchMap,
  catchError,
  filter,
  withLatestFrom,
  pluck,
  distinctUntilChanged,
  shareReplay,
  debounceTime,
  delay,
  tap,
} from 'rxjs/operators';
import { UIFacade } from './ui.facade';
import { getBrowserLang, TranslocoService } from '@ngneat/transloco';
import { getCustomLabels } from '../functions/get-custom-labels';
import keyBy from 'lodash-es/keyBy';
import { GeneratorOutputSummary } from '../models/generator-output-summary';
import { summarizeGeneratorOutputs } from '../functions/summarize-generator-outputs';

@Injectable({
  providedIn: 'root',
})
export class DataStoreFacade {
  private readonly dataStoreStateSubject = new BehaviorSubject<DataStoreState>({ ...DataStoreStateDefault });
  state$ = this.dataStoreStateSubject.asObservable();
  spacePrograms$ = this.state$.pipe(map((m) => m.spacePrograms));
  spaceProgramSummaries$ = this.state$.pipe(map((m) => m.spaceProgramSummaries));
  spaceProgram$ = this.state$.pipe(map((m) => m.spaceProgram));
  activities$ = this.state$.pipe(map((m) => m.activities));
  activityToWorkPlaceTypes$ = this.state$.pipe(map((m) => m.activityToWorkPlaceTypes));
  behaviourWorkPlaceTypes$ = this.state$.pipe(map((m) => m.behaviourWorkPlaceTypes));
  behaviourActivitySummaries$ = this.state$.pipe(map((m) => m.behaviourActivitySummaries));
  generators$ = this.state$.pipe(map((m) => m.generators));
  generatorOutputs$ = this.state$.pipe(map((m) => m.generatorOutputs));
  groups$ = this.state$.pipe(map((m) => m.groups));
  partners$ = this.state$.pipe(map((m) => m.partners));
  spaceTypes$ = this.state$.pipe(map((m) => m.spaceTypes));
  tags$ = this.state$.pipe(map((m) => m.tags));
  tagGroups$ = this.state$.pipe(map((m) => m.tagGroups));
  templates$ = this.state$.pipe(map((m) => m.templates));
  folders$ = this.state$.pipe(map((m) => m.folders));
  workPlaceTypes$ = this.state$.pipe(map((m) => m.workPlaceTypes));
  workPlaceTypeGroups$ = this.state$.pipe(map((m) => m.workPlaceTypeGroups));
  users$ = this.state$.pipe(map((m) => m.users));
  allocations$ = of([]);

  spaceTypesDict$ = this.spaceTypes$.pipe(
    map((m) => keyBy(m, 'spaceTypeID')),
    distinctUntilChanged(),
    shareReplay(1)
  );
  generatorsDict$ = this.generators$.pipe(
    map((m) => keyBy(m, 'generatorID')),
    distinctUntilChanged(),
    shareReplay(1)
  );
  groupsDict$ = this.groups$.pipe(
    map((m) => keyBy(m, 'groupID')),
    distinctUntilChanged(),
    shareReplay(1)
  );
  tagsDict$ = this.tags$.pipe(
    map((m) => keyBy(m, 'tagID')),
    distinctUntilChanged(),
    shareReplay(1)
  );

  public readonly generatorOutputSummary$ = combineLatest([
    this.spaceProgram$,
    this.generatorOutputs$,
    this.generators$,
    this.spaceTypes$,
    this.groups$,
  ]).pipe(
    delay(0),
    debounceTime(400),
    map(([spaceProgram, generatorOutputs, generators, spaceTypes, groups]) => ({
      spaceProgram,
      generatorOutputs,
      generators,
      spaceTypes,
      groups,
    })),
    map(({ spaceProgram, generatorOutputs, generators, spaceTypes, groups }) => {
      const totals = groups.reduce(
        (v: any, i) => {
          // const fte = (item.behaviorFraction ?? 0) * (item.growthFactor ?? 0);
          // const people = fte * (item.fteToPeopleFactor ?? 0);
          v.behaviorFraction += i.behaviorFraction ?? 0;
          if (spaceProgram.behaviorFractionAdjustmentMethod === 1) {
            v.adjustedInOffice += i.sourceInOfficeFraction * 100 * i.behaviorFractionAdjustmentFactor * i.behaviorFraction;
          }
          const fte = (i.behaviorFraction ?? 0) * (i.growthFactor ?? 0);
          v.fte += fte;
          v.people += fte * (spaceProgram.fteToPeopleFactor ?? 0);
          return v;
        },
        { behaviorFraction: 0, adjustedInOffice: 0, fte: 0, people: 0 }
      );

      const results = generatorOutputs?.map((go) => {
        const g = generators.find((f) => f.generatorID === go.generatorID);
        const st = spaceTypes.find((f) => f.spaceTypeID === g.spaceTypeID);
        const grp = groups.find((f) => f.groupID === go.groupID);
        return {
          ...go,
          capacity: st.capacity,
          area: st.area,
          workPlaceFactor: st.workPlaceFactor,
          areaNetToGrossFactor: spaceProgram?.areaNetToGrossFactor || 1,
          primaryFactor: st.primaryFactor,
          behaviorFraction: grp?.behaviorFraction,
          growthFactor: grp?.growthFactor,
          fteToPeopleFactor: spaceProgram?.fteToPeopleFactor,
        };
      });

      return {
        results,
        totals: {
          ...totals,
          adjustedInOffice: totals.adjustedInOffice / totals.behaviorFraction / 100,
        },
      };
    }),
    map(({ results, totals }) => {
      const summary: GeneratorOutputSummary = summarizeGeneratorOutputs(results, totals);
      return summary;
    }),
    shareReplay(1)
  );

  private generatorTagsDict: { [generatorID: string]: Observable<Tag[]> } = {};

  constructor(
    private spaceProgramClient: SpaceProgramClient,
    private activityClient: ActivityClient,
    private activityToWorkPlaceTypeClient: ActivityToWorkPlaceTypeClient,
    private generatorClient: GeneratorClient,
    private generatorOutputClient: GeneratorOutputClient,
    private groupClient: GroupClient,
    private partnerClient: PartnerClient,
    private projectClient: ProjectClient,
    private scenarioClient: ScenarioClient,
    private spaceTypeClient: SpaceTypeClient,
    private tagClient: TagClient,
    private tagGroupClient: TagGroupClient,
    private templateClient: TemplateClient,
    private folderClient: FolderClient,
    private workPlaceTypeClient: WorkPlaceTypeClient,
    private workPlaceTypeGroupClient: WorkPlaceTypeGroupClient,
    private behaviourActivityClient: BehaviourActivityClient,
    private behaviourWorkPlaceTypeClient: BehaviourWorkPlaceTypeClient,
    private userClient: UserClient,
    private uiFacade: UIFacade,
    private translocoService: TranslocoService
  ) {}

  get state(): DataStoreState {
    return this.dataStoreStateSubject.getValue();
  }

  generatorTags$(generatorID): Observable<Tag[]> {
    if (!this.generatorTagsDict[generatorID]) {
      this.generatorTagsDict[generatorID] = combineLatest([this.generatorsDict$, this.tagsDict$]).pipe(
        map(([generatorsDict, tagsDict]) => {
          return generatorsDict[generatorID]?.tags?.map((m) => tagsDict[m]);
        })
      );
    }
    return this.generatorTagsDict[generatorID];
  }

  loadSpaceProgramsList(includeFTEInd?: number | null | undefined) {
    return forkJoin([
      this.spaceProgramClient.getListSummary(includeFTEInd),
      this.partnerClient.getList(),
      this.folderClient.getList(),
    ]).pipe(
      take(1),
      // tap(t => this.uiFacade.showLoading()),
      map(([spaceProgramSummaries, partners, folders]) => {
        const tempSpaceProgramSummaries = spaceProgramSummaries?.sort((a, b) => a.spaceProgramName.localeCompare(b.spaceProgramName));

        const newstate: DataStoreState = {
          ...this.dataStoreStateSubject.value,
          spaceProgramSummaries: tempSpaceProgramSummaries,
          partners,
          folders: folders?.sort((a, b) => a.folderName.localeCompare(b.folderName)),
        };
        this.dataStoreStateSubject.next(newstate);
        return {
          spaceProgramSummaries: tempSpaceProgramSummaries,
          partners,
          folders,
        };
      }),
      catchError(this.handleError)
    );
  }

  loadTemplatesList() {
    return forkJoin([this.templateClient.getList(), this.partnerClient.getList()]).pipe(
      take(1),
      map(([templates, partners]) => {
        return {
          templates: templates?.sort((a, b) => a.templateName.localeCompare(b.templateName)),
          partners,
        };
      }),
      map(({ templates, partners }) => {
        const newstate: DataStoreState = {
          ...this.dataStoreStateSubject.value,
          templates,
          partners,
        };
        this.dataStoreStateSubject.next(newstate);
        return {
          templates,
          partners,
        };
      }),
      catchError(this.handleError)
    );
  }

  load() {
    return forkJoin([
      this.spaceProgramClient.getList(),
      this.partnerClient.getList(),
      this.templateClient.getList(),
      this.folderClient.getList(),
    ]).pipe(
      take(1),
      map(([spacePrograms, partners, templates, folders]) => {
        const newstate: DataStoreState = {
          ...this.dataStoreStateSubject.value,
          spacePrograms: spacePrograms as SpaceProgram[],
          partners: partners as Partner[],
          templates: templates as Template[],
          folders: folders as Folder[],
        };
        this.dataStoreStateSubject.next(newstate);
        return {
          spacePrograms: (spacePrograms as SpaceProgram[])?.sort((a, b) => a.spaceProgramName.localeCompare(b.spaceProgramName)),
          partners: (partners as Partner[])?.sort((a, b) => a.partnerDescr.localeCompare(b.partnerDescr)),
          templates: (templates as Template[])?.sort((a, b) => a.templateName.localeCompare(b.templateName)),
          folders: (folders as Folder[])?.sort((a, b) => a.folderName.localeCompare(b.folderName)),
        };
      }),
      catchError(this.handleError)
    );
  }

  loadSpaceProgram(spaceProgramID: string) {
    return forkJoin([
      this.spaceProgramClient.getList(),
      // this.spaceProgramClient.getSummartContentList(spaceProgramID),
      // this.spaceProgramClient.getCustomLabelList(spaceProgramID),
      // this.activityClient.getList(spaceProgramID),
      // this.activityToWorkPlaceTypeClient.getList(spaceProgramID),
      // this.behaviourActivityClient.getBehaviourFractionList(spaceProgramID),
      // this.generatorClient.getList(spaceProgramID),
      // this.generatorOutputClient.getList(spaceProgramID),
      // this.groupClient.getList(spaceProgramID),
      // this.partnerClient.getList(),
      // this.templateClient.getList(),
      // this.spaceTypeClient.getList(spaceProgramID),
      // this.tagClient.getList(spaceProgramID),
      // this.tagGroupClient.getList(spaceProgramID),
      // this.workPlaceTypeClient.getList(spaceProgramID),
      // this.userClient.getList(spaceProgramID)
      this.userClient.updateSpaceProgramLastOpened(spaceProgramID),
    ]).pipe(
      take(1),
      // tap(() =>  {
      //   this.userClient.updateSpaceProgramLastOpened(spaceProgramID).subscribe();
      // }),
      map(
        ([
          spacePrograms,
          // summaryContent,
          // customLabels,
          // activities,
          // activityToWorkPlaceTypes,
          // behaviourActivitySummaries,
          // generators,
          // generatorOutputs,
          // groups,
          // partners,
          // templates,
          // spaceTypes,
          // tags,
          // tagGroups,
          // workPlaceTypes,
          // users
        ]) => {
          // update spaceProgram loaded in space programs list
          const spaceProgram = (spacePrograms as SpaceProgram[]).find((f) => f.spaceProgramID === spaceProgramID);
          // spaceProgram.summaryContent = (summaryContent as SummaryContent[])
          // .map((m) => m.contentCode).sort((a, b) => a.localeCompare(b));
          // spaceProgram.customLabels = (customLabels as CustomLabel[]).sort((a, b) => a.labelCode.localeCompare(b.labelCode));

          const newstate: DataStoreState = {
            ...this.dataStoreStateSubject.value,
            spacePrograms: spacePrograms as SpaceProgram[],
            spaceProgramID,
            loading: false,
            // loaded: true,
            spaceProgram,
            // activities: activities as Activity[],
            // activityToWorkPlaceTypes: activityToWorkPlaceTypes as ActivityToWorkPlaceType[],
            // behaviourActivitySummaries: behaviourActivitySummaries as BehaviourFractionSummaryDto[],
            // generators: generators as Generator[],
            // generatorOutputs: generatorOutputs as GeneratorOutput[],
            // groups: groups as Group[],
            // partners: (partners as Partner[]).sort((a, b) => a.partnerDescr.localeCompare(b.partnerDescr)),
            // templates: templates as Template[],
            // spaceTypes: spaceTypes as SpaceType[],
            // tags: tags as Tag[],
            // tagGroups: tagGroups as TagGroup[],
            // workPlaceTypes: workPlaceTypes as WorkPlaceType[],
            // workPlaceTypeGroups: [],
            // users: users as User[]
          };
          this.dataStoreStateSubject.next(newstate);

          return {
            spacePrograms,
            spaceProgram,
          };
        }
      ),
      catchError(this.handleError)
    );
  }

  loadSpaceProgramDetail(spaceProgramID: string) {
    return forkJoin([
      this.spaceProgramClient.getList(),
      this.spaceProgramClient.getSummartContentList(spaceProgramID),
      this.spaceProgramClient.getCustomLabelList(spaceProgramID),
      this.activityClient.getList(spaceProgramID),
      this.activityToWorkPlaceTypeClient.getList(spaceProgramID),
      this.behaviourActivityClient.getBehaviourFractionList(spaceProgramID),
      this.generatorClient.getList(spaceProgramID),
      this.generatorOutputClient.getList(spaceProgramID),
      this.groupClient.getList(spaceProgramID),
      this.partnerClient.getList(),
      this.templateClient.getList(),
      this.spaceTypeClient.getList(spaceProgramID),
      this.tagClient.getList(spaceProgramID),
      this.tagGroupClient.getList(spaceProgramID),
      this.workPlaceTypeClient.getList(spaceProgramID),
      this.userClient.getList(spaceProgramID),
      this.folderClient.getList(),
      this.userClient.updateSpaceProgramLastOpened(spaceProgramID),
    ]).pipe(
      take(1),
      // tap(() =>  {
      //   this.userClient.updateSpaceProgramLastOpened(spaceProgramID).subscribe();
      // }),
      map(
        ([
          spacePrograms,
          summaryContent,
          customLabels,
          activities,
          activityToWorkPlaceTypes,
          behaviourActivitySummaries,
          generators,
          generatorOutputs,
          groups,
          partners,
          templates,
          spaceTypes,
          tags,
          tagGroups,
          workPlaceTypes,
          users,
          folders,
        ]) => {
          // update spaceProgram loaded in space programs list
          const spaceProgram = (spacePrograms as SpaceProgram[]).find((f) => f.spaceProgramID === spaceProgramID);
          spaceProgram.summaryContent = (summaryContent as SummaryContent[]).map((m) => m.contentCode).sort((a, b) => a.localeCompare(b));
          spaceProgram.customLabels = (customLabels as CustomLabel[]).sort((a, b) => a.labelCode.localeCompare(b.labelCode));

          const newstate: DataStoreState = {
            ...this.dataStoreStateSubject.value,
            spacePrograms: spacePrograms as SpaceProgram[],
            spaceProgramID,
            loading: false,
            loaded: true,
            spaceProgram,
            activities: activities as Activity[],
            activityToWorkPlaceTypes: activityToWorkPlaceTypes as ActivityToWorkPlaceType[],
            behaviourActivitySummaries: behaviourActivitySummaries as BehaviourFractionSummaryDto[],
            generators: generators as Generator[],
            generatorOutputs: generatorOutputs as GeneratorOutput[],
            groups: groups as Group[],
            partners: (partners as Partner[]).sort((a, b) => a.partnerDescr.localeCompare(b.partnerDescr)),
            templates: templates as Template[],
            spaceTypes: spaceTypes as SpaceType[],
            tags: tags as Tag[],
            tagGroups: tagGroups as TagGroup[],
            workPlaceTypes: workPlaceTypes as WorkPlaceType[],
            workPlaceTypeGroups: [],
            users: users as User[],
            folders: folders as Folder[],
          };
          this.dataStoreStateSubject.next(newstate);
          const customLabelList = getCustomLabels();
          customLabelList.forEach((label) => {
            this.translocoService.setTranslationKey(
              'spaceplan.customLabelOverride.' + label.code,
              '{{spaceplan.customLabel.' + label.code + '}}'
            );
          });
          spaceProgram.customLabels.forEach((label) => {
            this.translocoService.setTranslationKey('spaceplan.customLabelOverride.' + label.labelCode, label.labelText);
          });

          return {
            spacePrograms,
            spaceProgram,
            activities,
            activityToWorkPlaceTypes,
            behaviourActivitySummaries,
            generators,
            generatorOutputs,
            groups,
            partners,
            spaceTypes,
            tags,
            tagGroups,
            workPlaceTypes,
            users,
            folders,
          };
        }
      ),
      catchError(this.handleError)
    );
  }

  loadBehaviourWorkPlaceTypes(spaceProgramID: string) {
    return this.behaviourWorkPlaceTypeClient.getList(spaceProgramID).pipe(
      take(1),
      map((behaviourWorkPlaceTypes) => {
        const newstate: DataStoreState = {
          ...this.dataStoreStateSubject.value,
          behaviourWorkPlaceTypes,
        };
        this.dataStoreStateSubject.next(newstate);
        return {
          behaviourWorkPlaceTypes,
        };
      }),
      catchError(this.handleError)
    );
  }

  // SPACE PROGRAM SETTINGS
  getSpaceProgramSettings(id: string): Observable<UpdateSpaceProgramSettingsDto> {
    const state = this.dataStoreStateSubject.getValue();
    const spaceProgram = state?.spacePrograms?.find((f) => f.spaceProgramID === id);
    const groups =
      state?.groups
        .sort((a, b) => a.groupName.localeCompare(b.groupName))
        .map((grp) => ({
          groupID: grp.groupID,
          groupName: grp.groupName,
          growthFactor: grp.growthFactor,
        })) || [];
    const model: UpdateSpaceProgramSettingsDto = {
      ...spaceProgram,
      groups,
    };
    return of(model);
  }
  updateSpaceProgramSettings(model: UpdateSpaceProgramSettingsDto): Observable<UpdateSpaceProgramSettingsDto> {
    return this.spaceProgramClient.updateSettings(model).pipe(
      switchMap((updateSettings: UpdateSpaceProgramSettingsDto) => {
        this.refreshGeneratorOutputs(updateSettings.spaceProgramID, null, null);
        return this.behaviourWorkPlaceTypeClient.getList(updateSettings.spaceProgramID).pipe(
          switchMap((behaviourWorkPlaceTypes) => {
            const state = this.dataStoreStateSubject.getValue();

            const { groups, ...spaceProgram } = updateSettings;
            // update space program
            const index = state.spacePrograms?.findIndex((f) => f.spaceProgramID === updateSettings.spaceProgramID);
            if (index > -1) {
              // exclude fields not on spaceProgram
              const newspaceprogram = { ...state.spacePrograms[index], ...spaceProgram };
              state.spacePrograms[index] = newspaceprogram;
              state.spaceProgram = newspaceprogram;
              // possibly update groups with new growth factor value
            }

            // update groups
            groups?.forEach((grp) => {
              const groupIndex = state.groups?.findIndex((f) => f.groupID === grp.groupID);
              if (index > -1) {
                state.groups[groupIndex] = { ...state.groups[groupIndex], ...grp };
              }
            });

            // const updateGroups = state.groups.map(group => {
            //   const updateGroup = groups?.find((f) => f.groupID === group.groupID);
            //   return {
            //     ...group,
            //     ...updateGroup
            //   }
            // });

            // public readonly customLabelList = [
            //   { code: 'Total_FTE' },
            //   { code: 'Total_People' },
            //   { code: 'Generated_Spaces' },
            //   { code: 'Planned_Spaces' },
            //   { code: 'Delta_Spaces' },
            //   { code: 'Primary_Workspaces' },
            //   { code: 'Capacity_Ratio' },
            //   { code: 'Capacity' },
            //   { code: 'Planned_Net_Surface' },
            //   { code: 'Planned_Gross_Surface' },
            //   { code: 'Delta_Surface' },
            // ];

            // state.behaviourWorkPlaceTypes = behaviourWorkPlaceTypes;

            this.dataStoreStateSubject.next({ ...state, behaviourWorkPlaceTypes });

            const customLabelList = getCustomLabels();
            customLabelList.forEach((label) => {
              this.translocoService.setTranslationKey(
                'spaceplan.customLabelOverride.' + label.code,
                '{{spaceplan.customLabel.' + label.code + '}}'
              );
            });
            spaceProgram.customLabels
              .filter((f) => !!f)
              .forEach((label) => {
                this.translocoService.setTranslationKey('spaceplan.customLabelOverride.' + label.labelCode, label.labelText);
              });

            const result: UpdateSpaceProgramSettingsDto = {
              ...spaceProgram,
              groups:
                state?.groups
                  .sort((a, b) => a.groupName.localeCompare(b.groupName))
                  .map((grp) => ({
                    groupID: grp.groupID,
                    groupName: grp.groupName,
                    growthFactor: grp.growthFactor,
                  })) || [],
            };
            return of(result);
          })
        );
      })
    );
  }

  updateSpaceProgramColours(coloursDto: UpdateSpaceProgramColoursDto) {
    return this.spaceProgramClient.updateColours(coloursDto).pipe(
      map((settings) => {
        const state = this.dataStoreStateSubject.getValue();

        switch (coloursDto.entityType) {
          case ColourEntityType.SpaceType:
            const spaceTypes = keyBy(state.spaceTypes, 'spaceTypeID');
            coloursDto.entityColours.forEach((item) => {
              spaceTypes[item.entityID] = {
                ...spaceTypes[item.entityID],
                colourCode: item.colourCode,
              };
            });
            state.spaceTypes = Object.values(spaceTypes);
            break;
          case ColourEntityType.Group:
            const groups = keyBy(state.groups, 'groupID');
            coloursDto.entityColours.forEach((item) => {
              groups[item.entityID] = {
                ...groups[item.entityID],
                colourCode: item.colourCode,
              };
            });
            state.groups = Object.values(groups);
            break;
          case ColourEntityType.WorkPlaceType:
            const workPlaceTypes = keyBy(state.workPlaceTypes, 'workPlaceTypeID');
            coloursDto.entityColours.forEach((item) => {
              workPlaceTypes[item.entityID] = {
                ...workPlaceTypes[item.entityID],
                colourCode: item.colourCode,
              };
            });
            state.workPlaceTypes = Object.values(workPlaceTypes);
            break;
          case ColourEntityType.Tag:
            const tags = keyBy(state.tags, 'tagID');
            coloursDto.entityColours.forEach((item) => {
              tags[item.entityID] = {
                ...tags[item.entityID],
                colourCode: item.colourCode,
              };
            });
            state.tags = Object.values(tags);
            break;

          default:
            break;
        }

        this.dataStoreStateSubject.next(state);
      })
    );
  }

  // SPACE PROGRAM PREFACE
  getSpaceProgramPreface(id: string): Observable<UpdateSpaceProgramPrefaceDto> {
    const state = this.dataStoreStateSubject.getValue();
    const spaceProgram = state?.spacePrograms?.find((f) => f.spaceProgramID === id);
    const model: UpdateSpaceProgramPrefaceDto = {
      spaceProgramID: spaceProgram.spaceProgramID,
      preface: spaceProgram.preface,
    };
    return of(model);
  }
  updateSpaceProgramPreface(model: UpdateSpaceProgramPrefaceDto): Observable<UpdateSpaceProgramPrefaceDto> {
    return this.spaceProgramClient.updatePreface(model).pipe(
      switchMap((updateSettings: UpdateSpaceProgramPrefaceDto) => {
        return this.behaviourWorkPlaceTypeClient.getList(updateSettings.spaceProgramID).pipe(
          switchMap((behaviourWorkPlaceTypes) => {
            const state = this.dataStoreStateSubject.getValue();
            const { ...spaceProgram } = updateSettings;

            // update space program
            const index = state.spacePrograms?.findIndex((f) => f.spaceProgramID === updateSettings.spaceProgramID);
            if (index > -1) {
              // exclude fields not on spaceProgram
              const newspaceprogram = { ...state.spacePrograms[index], ...spaceProgram };
              state.spacePrograms[index] = newspaceprogram;
              state.spaceProgram = newspaceprogram;
            }
            const result: UpdateSpaceProgramPrefaceDto = {
              spaceProgramID: updateSettings.spaceProgramID,
              preface: updateSettings.preface,
            };
            return of(result);
          })
        );
      })
    );
  }

  getSpaceProgram(id: string): Observable<SpaceProgram> {
    const model = this.dataStoreStateSubject.getValue()?.spacePrograms?.find((f) => f.spaceProgramID === id);
    return of(model);
  }
  createSpaceProgram(model: CreateSpaceProgramDto): Observable<SpaceProgram> {
    const browserLang = getBrowserLang();
    return this.spaceProgramClient.create(model, browserLang).pipe(
      switchMap((spaceProgram) => {
        const state = this.dataStoreStateSubject.getValue();
        state.spacePrograms.push(spaceProgram);
        this.dataStoreStateSubject.next(state);
        return of(spaceProgram);
      })
    );
  }
  deleteSpaceProgram(spaceProgramID: string): Observable<boolean> {
    return this.spaceProgramClient.delete(spaceProgramID).pipe(
      switchMap(() => {
        const state = this.dataStoreStateSubject.getValue();
        const spacePrograms = state.spacePrograms?.filter((f) => f.spaceProgramID !== spaceProgramID);
        const spaceProgramSummaries = state.spaceProgramSummaries?.filter((f) => f.spaceProgramID !== spaceProgramID);
        this.dataStoreStateSubject.next({ ...state, spacePrograms, spaceProgramSummaries });
        return of(true);
      })
    );
  }

  // GENERATORS
  getGenerator(id: string): Observable<Generator> {
    const model = this.dataStoreStateSubject.getValue()?.generators?.find((f) => f.generatorID === id);
    return of(model);
  }
  createGenerator(model: Generator): Observable<Generator> {
    return this.generatorClient.insert(model).pipe(
      switchMap((generator) => {
        this.refreshGeneratorOutputs(generator.spaceProgramID, generator.generatorID);
        const state = this.dataStoreStateSubject.getValue();
        state.generators.push(generator);
        this.dataStoreStateSubject.next(state);
        this.refreshGeneratorOutputs(generator.spaceProgramID, generator.generatorID);
        return of(generator);
      })
    );
    // return of(model);
  }
  updateGenerator(model: Generator): Observable<Generator> {
    return this.generatorClient.update(model).pipe(
      switchMap((generator) => {
        const state = this.dataStoreStateSubject.getValue();
        const index = state.generators?.findIndex((f) => f.generatorID === generator.generatorID);
        if (index > -1) {
          state.generators[index] = generator;
          this.dataStoreStateSubject.next(state);
        }
        this.refreshGeneratorOutputs(generator.spaceProgramID, generator.generatorID);
        return of(generator);
      })
    );
  }
  deleteGenerator(generatorID: string): Observable<boolean> {
    return this.generatorClient.delete(generatorID).pipe(
      switchMap(() => {
        const state = this.dataStoreStateSubject.getValue();
        const generators = state.generators?.filter((f) => f.generatorID !== generatorID);
        const generatorOutputs = state.generatorOutputs?.filter((f) => f.generatorID !== generatorID);
        this.dataStoreStateSubject.next({ ...state, generators, generatorOutputs });
        return of(true);
      })
    );
  }

  // GENERATOR OUTPUTS

  updateGeneratorOutputPlannedNoOfSpaceTypes(generatorOutputID: string, plannedNoOfSpaceTypes?: number) {
    return this.generatorOutputClient
      .updatePlannedNoOfSpaceTypes({
        generatorOutputID,
        plannedNoOfSpaceTypes,
      })
      .pipe(
        switchMap(() => {
          const state = this.dataStoreStateSubject.getValue();
          const index = state.generatorOutputs?.findIndex((f) => f.generatorOutputID === generatorOutputID);
          if (index > -1) {
            state.generatorOutputs[index] = {
              ...state.generatorOutputs[index],
              plannedNoOfSpaceTypes,
            };
            this.dataStoreStateSubject.next(state);
          }
          return of(null);
        })
      );
  }

  // SPACE TYPES

  getSpaceType(id: string): Observable<SpaceType> {
    const model = this.dataStoreStateSubject.getValue()?.spaceTypes?.find((f) => f.spaceTypeID === id);
    return of(model);
  }
  createSpaceType(model: SpaceType): Observable<SpaceType> {
    return this.spaceTypeClient.insert(model).pipe(
      switchMap((spaceType) => {
        const state = this.dataStoreStateSubject.getValue();
        state.spaceTypes.push(spaceType);
        this.dataStoreStateSubject.next(state);
        return of(spaceType);
      })
    );
  }
  updateSpaceType(model: SpaceType): Observable<SpaceType> {
    return this.spaceTypeClient.update(model).pipe(
      switchMap((spaceType) => {
        const state = this.dataStoreStateSubject.getValue();
        const index = state.spaceTypes?.findIndex((f) => f.spaceTypeID === spaceType.spaceTypeID);
        if (index > -1) {
          state.spaceTypes[index] = spaceType;
          this.dataStoreStateSubject.next(state);
        }
        return of(spaceType);
      })
    );
  }
  deleteSpaceType(spaceTypeID: string): Observable<boolean> {
    return this.spaceTypeClient.delete(spaceTypeID).pipe(
      switchMap(() => {
        const state = this.dataStoreStateSubject.getValue();
        const spaceTypes = state.spaceTypes?.filter((f) => f.spaceTypeID !== spaceTypeID);
        this.dataStoreStateSubject.next({ ...state, spaceTypes });
        return of(true);
      })
    );
  }

  // TAG GROUP

  createTagGroup(model: TagGroup): Observable<TagGroup> {
    return this.tagGroupClient.insert(model).pipe(
      switchMap((tag) => {
        const state = this.dataStoreStateSubject.getValue();
        state.tagGroups.push(tag);
        this.dataStoreStateSubject.next(state);
        return of(tag);
      })
    );
  }

  updateTagGroup(model: TagGroup): Observable<TagGroup> {
    return this.tagGroupClient.update(model).pipe(
      switchMap((tagGroup) => {
        const state = this.dataStoreStateSubject.getValue();
        const index = state.tagGroups?.findIndex((f) => f.tagGroupID === tagGroup.tagGroupID);
        if (index > -1) {
          state.tagGroups[index] = tagGroup;
          this.dataStoreStateSubject.next(state);
        }
        return of(tagGroup);
      })
    );
  }

  deleteTagGroup(tagGroupID: string): Observable<boolean> {
    return this.tagGroupClient.delete(tagGroupID).pipe(
      switchMap(() => {
        const state = this.dataStoreStateSubject.getValue();
        const tagGroups = state.tagGroups?.filter((f) => f.tagGroupID !== tagGroupID);
        // reset displaySequence on tags, and call update sequence method, then return
        this.dataStoreStateSubject.next({ ...state, tagGroups });
        return of(true);
      })
    );
  }

  // TAG

  createTag(model: Tag): Observable<Tag> {
    return this.tagClient.insert(model).pipe(
      switchMap((tag) => {
        const state = this.dataStoreStateSubject.getValue();
        state.tags.push(tag);
        this.dataStoreStateSubject.next(state);
        return of(tag);
      })
    );
  }

  updateTag(model: Tag): Observable<Tag> {
    return this.tagClient.update(model).pipe(
      switchMap((tag) => {
        const state = this.dataStoreStateSubject.getValue();
        const index = state.tags?.findIndex((f) => f.tagID === tag.tagID);
        if (index > -1) {
          state.tags[index] = tag;
          this.dataStoreStateSubject.next(state);
        }
        return of(tag);
      })
    );
  }

  resequenceTags(model: UpdateTagsSequenceDto): Observable<UpdateTagsSequenceDto> {
    return this.tagClient.updateTagSequence(model).pipe(
      switchMap((dto) => {
        const state = this.dataStoreStateSubject.getValue();
        // const index = state.tags?.findIndex((f) => f.tagID === dto.tagID);
        // if (index > -1) {
        //   state.tags[index] = tag;
        //   this.dataStoreStateSubject.next(state);
        // }
        return of(dto);
      })
    );
  }

  deleteTag(tagID: string): Observable<boolean> {
    return this.tagClient.delete(tagID).pipe(
      switchMap(() => {
        const state = this.dataStoreStateSubject.getValue();
        const tags = state.tags?.filter((f) => f.tagID !== tagID);
        // reset displaySequence on tags, and call update sequence method, then return
        this.dataStoreStateSubject.next({ ...state, tags });
        return of(true);
      })
    );
  }

  // WORKPLACE TYPES

  getWorkPlaceType(id: string): Observable<WorkPlaceType> {
    const model = this.dataStoreStateSubject.getValue()?.workPlaceTypes?.find((f) => f.workPlaceTypeID === id);
    return of(model);
  }
  createWorkPlaceType(model: WorkPlaceType): Observable<WorkPlaceType> {
    return this.workPlaceTypeClient.insert(model).pipe(
      switchMap((workPlaceType) => {
        const state = this.dataStoreStateSubject.getValue();
        state.workPlaceTypes.push(workPlaceType);
        this.dataStoreStateSubject.next(state);
        return of(workPlaceType);
      })
    );
  }
  updateWorkPlaceType(model: WorkPlaceType): Observable<WorkPlaceType> {
    return this.workPlaceTypeClient.update(model).pipe(
      switchMap((workPlaceType) => {
        this.refreshGeneratorOutputs(workPlaceType.spaceProgramID, null, GeneratorInputType.WorkPlace);
        const state = this.dataStoreStateSubject.getValue();
        const index = state.workPlaceTypes?.findIndex((f) => f.workPlaceTypeID === workPlaceType.workPlaceTypeID);
        if (index > -1) {
          state.workPlaceTypes[index] = workPlaceType;
          this.dataStoreStateSubject.next(state);
        }
        return of(workPlaceType);
      })
    );
  }
  deleteWorkPlaceType(workPlaceTypeID: string): Observable<boolean> {
    return this.workPlaceTypeClient.delete(workPlaceTypeID).pipe(
      switchMap(() => {
        const state = this.dataStoreStateSubject.getValue();
        const workPlaceTypes = state.workPlaceTypes?.filter((f) => f.workPlaceTypeID !== workPlaceTypeID);
        this.dataStoreStateSubject.next({ ...state, workPlaceTypes });
        return of(true);
      })
    );
  }

  // ACTIVITY

  getActvityToWorkPlaceType(activityID: string): Observable<UpdateActivityToWorkPlaceTypeDto> {
    const state = this.dataStoreStateSubject.getValue();
    const activity = state.activities.find((f) => f.activityID === activityID);
    const activityToWorkPlaceTypes = state.activityToWorkPlaceTypes
      .filter((f) => f.activityID === activityID)
      .map(
        (m) =>
          ({
            workPlaceTypeID: m.workPlaceTypeID,
            collaborationRatingID: m.collaborationRatingID,
            complexityRatingID: m.complexityRatingID,
          } as ActivityToWorkPlaceTypeDto)
      );
    const model: UpdateActivityToWorkPlaceTypeDto = {
      activityID,
      spaceProgramID: activity.spaceProgramID,
      activityToWorkPlaceTypes,
    };
    return of(model);
  }

  updateActvityToWorkPlaceType(model: UpdateActivityToWorkPlaceTypeDto): Observable<UpdateActivityToWorkPlaceTypeDto> {
    return this.activityToWorkPlaceTypeClient.update(model).pipe(
      switchMap((updateActivityToWorkPlaceTypeDto) => {
        this.refreshGeneratorOutputs(model.spaceProgramID, null, GeneratorInputType.WorkPlace);
        const state = this.dataStoreStateSubject.getValue();

        const activityToWorkPlaceTypes = state.activityToWorkPlaceTypes
          .filter((awp) => updateActivityToWorkPlaceTypeDto.activityID !== awp.activityID)
          .concat(
            updateActivityToWorkPlaceTypeDto.activityToWorkPlaceTypes.map((m) => {
              return { ...m, activityID: model.activityID };
            })
          );

        this.dataStoreStateSubject.next({ ...state, activityToWorkPlaceTypes });
        return of(updateActivityToWorkPlaceTypeDto);
      })
    );
  }

  refreshActvityToWorkPlaceType(spaceProgramID: string) {
    this.activityToWorkPlaceTypeClient
      .getList(spaceProgramID)
      .pipe(
        switchMap((activityToWorkPlaceTypes) => {
          return of(activityToWorkPlaceTypes);
        })
      )
      .subscribe((activityToWorkPlaceTypes) => {
        const state = this.dataStoreStateSubject.getValue();
        state.activityToWorkPlaceTypes = activityToWorkPlaceTypes;
        this.dataStoreStateSubject.next(state);
      });
  }

  // SPACE PROGRAM TEMPLATES

  createSpaceProgramTemplate(model: CreateTemplateDto): Observable<Template> {
    return this.templateClient.insert(model).pipe(
      switchMap((template) => {
        this.refreshTemplates();
        return of(template);
      })
    );
  }

  // TEMPLATES

  getTemplate(id: string): Observable<Template> {
    const model = this.dataStoreStateSubject.getValue()?.templates?.find((f) => f.templateID === id);
    return of(model);
  }

  createTemplate(model: CreateTemplateDto): Observable<Template> {
    return this.templateClient.insert(model).pipe(
      switchMap((template) => {
        this.refreshTemplates();
        return of(template);
      })
    );
  }

  updateTemplate(model: DuplicateTemplateDto): Observable<Template> {
    // return this.templateClient.update(model).pipe(
    //   switchMap((template) => {
    //     this.refreshTemplates();
    //     return of(template);
    //   })
    // );
    return of(null);
  }

  duplicateTemplate(model: DuplicateTemplateDto): Observable<Template> {
    return this.templateClient.duplicate(model).pipe(
      switchMap((template) => {
        this.refreshTemplates();
        return of(template);
      })
    );
  }

  archiveTemplate(id: string): Observable<Template> {
    return this.templateClient.archive(id).pipe(
      switchMap((template) => {
        this.refreshTemplates();
        return of(template);
      })
    );
  }

  unarchiveTemplate(id: string): Observable<Template> {
    return this.templateClient.unarchive(id).pipe(
      switchMap((template) => {
        this.refreshTemplates();
        return of(template);
      })
    );
  }

  // USERS

  getUser(id: string): Observable<User> {
    const model = this.dataStoreStateSubject.getValue()?.users?.find((f) => f.spaceProgramUserID === id);
    return of(model);
  }

  inviteUsers(model: InviteUsersDto): Observable<User[]> {
    return this.userClient.invite(model).pipe(
      switchMap((users) => this.userClient.getList(model.spaceProgramID)),
      switchMap((users) => {
        const state = this.dataStoreStateSubject.getValue();
        this.dataStoreStateSubject.next({ ...state, users });
        return of(users);
      })
    );
  }

  updateUser(model: User): Observable<User> {
    return this.userClient.update(model).pipe(
      switchMap((user) => {
        const state = this.dataStoreStateSubject.getValue();
        const index = state.users?.findIndex((f) => f.spaceProgramUserID === user.spaceProgramUserID);
        if (index > -1) {
          state.users[index] = user;
          this.dataStoreStateSubject.next(state);
        }
        return of(user);
      })
    );
  }

  deleteUser(spaceProgramUserID: string): Observable<boolean> {
    return this.userClient.delete(spaceProgramUserID).pipe(
      switchMap(() => {
        const state = this.dataStoreStateSubject.getValue();
        const users = state.users?.filter((f) => f.spaceProgramUserID !== spaceProgramUserID);
        this.dataStoreStateSubject.next({ ...state, users });
        return of(true);
      })
    );
  }

  clear() {
    this.dataStoreStateSubject.next({ ...DataStoreStateDefault });
  }
  refreshGeneratorOutputs(spaceProgramID: string, generatorID?: string, generatorInputTypeNo?: GeneratorInputType) {
    this.generatorOutputClient
      .getList(spaceProgramID, generatorID, generatorInputTypeNo)
      .pipe(
        switchMap((generatorOutputs) => {
          return of(generatorOutputs);
        })
      )
      .subscribe((generatorOutputs) => {
        const state = this.dataStoreStateSubject.getValue();
        const generators = state.generators.filter(
          (f) =>
            f.spaceProgramID === spaceProgramID &&
            (!generatorID || f.generatorID === generatorID) &&
            (!generatorInputTypeNo || f.generatorInputTypeNo === generatorInputTypeNo)
        );
        const tempGeneratorOutputs = state?.generatorOutputs
          ?.filter((f) => !generators.find((g) => g.generatorID === f.generatorID))
          .concat(generatorOutputs);

        state.generatorOutputs = tempGeneratorOutputs;
        this.dataStoreStateSubject.next(state);
      });
  }
  refreshTemplates() {
    this.templateClient
      .getList()
      .pipe(
        switchMap((templates) => {
          return of(templates);
        })
      )
      .subscribe((templates) => {
        const state = this.dataStoreStateSubject.getValue();
        state.templates = templates;
        this.dataStoreStateSubject.next(state);
      });
  }

  handleError(error: any): Observable<any> {
    return throwError(error);
  }
}

export const DataStoreStateDefault: DataStoreState = { loading: false, loaded: false };

export interface DataStoreState {
  spacePrograms?: SpaceProgram[];
  spaceProgramSummaries?: SpaceProgramSummary[];
  spaceProgramID?: string;
  loading: boolean;
  loaded: boolean;
  spaceProgram?: SpaceProgram;
  activities?: Activity[];
  activityToWorkPlaceTypes?: ActivityToWorkPlaceType[];
  behaviourWorkPlaceTypes?: BehaviourWorkPlaceType[];
  behaviourActivitySummaries?: BehaviourFractionSummaryDto[];
  folders?: Folder[];
  generators?: Generator[];
  generatorOutputs?: GeneratorOutput[];
  groups?: Group[];
  partners?: Partner[];
  spaceTypes?: SpaceType[];
  tags?: Tag[];
  tagGroups?: TagGroup[];
  templates?: Template[];
  workPlaceTypes?: WorkPlaceType[];
  workPlaceTypeGroups?: WorkPlaceTypeGroup[];
  users?: User[];
}
