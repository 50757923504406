import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EditorFacadeBase } from './editor-facade-base';
import { UserFacade } from './user.facade';
import { UIFacade } from './ui.facade';
import { TranslocoService } from '@ngneat/transloco';
import { bufferCount, catchError, filter, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { Building, CreateBuildingDto, UpdateBuildingDto } from 'src/app/core/services/api-clients';
import { BuildingsDataStoreFacade } from 'src/app/buildings/facades';
import { confirmArchive, confirmUnarchive } from '../functions/confirmations';

export type BuildingAddEditDto = Building & CreateBuildingDto & UpdateBuildingDto;

/**
 * t(spaceplan.WorkPlaceType)
 */
@Injectable({
  providedIn: 'root',
})
export class EditorBuildingFacade extends EditorFacadeBase<BuildingAddEditDto> {
  constructor(
    protected toastr: ToastrService,
    protected modalService: BsModalService,
    protected uiFacade: UIFacade,
    protected translocoService: TranslocoService,
    private dataStoreFacade: BuildingsDataStoreFacade,
    private userFacade: UserFacade
  ) {
    super(toastr, modalService, uiFacade, translocoService);
    // this.dataStoreFacade.spaceProgram$
    //   .pipe(
    //     bufferCount(2, 1),
    //     filter(([a, b]) => b?.spaceProgramID !== a?.spaceProgramID),
    //     map(([a, b]) => b)
    //   )
    //   .subscribe(() => {
    //     this.resetToDefault();
    //   });
  }
  public canArchive(model: Building): Observable<boolean> {
    return of(model?.archivedInd === false);
  }
  public canUnarchive(model: Building): Observable<boolean> {
    return of(model?.archivedInd === true);
  }
  archive(id: string) {
    this.getFromDataStore(id)
      .pipe(
        switchMap((model) => {
          const currentId = this.getModelId(model);
          if (id === currentId) {
            // cancel any changes is dirty because record about to be deleted
            this.resetAndClose();
          }
          return this.canArchive(model).pipe(
            mergeMap((canArchive) => {
              if (!canArchive) {
                return of(false);
              }
              return this.userCanArchive();
            }),
            mergeMap((canArchive) => {
              if (!canArchive) {
                return of(false);
              }
              return confirmArchive(this.modalService);
            }),
            mergeMap((canArchive) => {
              if (!canArchive) {
                return of(false);
              }
              return this.archiveDataStore(id).pipe(
                switchMap(() => {
                  return of(true);
                }),
                catchError((error) => this.handleDataStoreError(error))
              );
            }),
            take(1)
          );
        })
      )
      .subscribe();
  }
  unarchive(id: string) {
    this.getFromDataStore(id)
      .pipe(
        switchMap((model) => {
          const currentId = this.getModelId(model);
          if (id === currentId) {
            // cancel any changes is dirty because record about to be deleted
            this.resetAndClose();
          }
          return this.canUnarchive(model).pipe(
            mergeMap((canUnarchive) => {
              if (!canUnarchive) {
                return of(false);
              }
              return this.userCanUnarchive();
            }),
            mergeMap((canUnarchive) => {
              if (!canUnarchive) {
                return of(false);
              }
              return confirmUnarchive(this.modalService);
            }),
            mergeMap((canUnarchive) => {
              if (!canUnarchive) {
                return of(false);
              }
              return this.unarchiveDataStore(id).pipe(
                switchMap(() => {
                  return of(true);
                }),
                catchError((error) => this.handleDataStoreError(error))
              );
            }),
            take(1)
          );
        })
      )
      .subscribe();
  }
  protected userCanArchive(): Observable<boolean> {
    return this.userCanEdit();
  }
  protected userCanUnarchive(): Observable<boolean> {
    return this.userCanEdit();
  }
  protected className(): string {
    return this.translocoService.translate('home.Building');
  }
  protected getFromDataStore(id: string): Observable<BuildingAddEditDto> {
    return this.dataStoreFacade.getBuilding(id);
  }
  protected createDataStore(model: Building): Observable<CreateBuildingDto> {
    return this.dataStoreFacade.createBuilding(model);
  }
  protected updateDataStore(model: Building): Observable<UpdateBuildingDto> {
    return this.dataStoreFacade.updateBuilding(model);
  }
  protected deleteDataStore(id: string): Observable<boolean> {
    return this.dataStoreFacade.deleteBuilding(id);
  }
  protected archiveDataStore(id: string): Observable<Building> {
    return this.dataStoreFacade.archiveBuilding(id);
  }
  protected unarchiveDataStore(id: string): Observable<Building> {
    return this.dataStoreFacade.unarchiveBuilding(id);
  }
  protected getModelId(model: BuildingAddEditDto): string {
    return model?.buildingID;
  }
  protected setModelId(model: BuildingAddEditDto, id: string) {
    model.buildingID = id;
  }
  protected userCanEdit(): Observable<boolean> {
    // return this.dataStoreFacade.spaceProgram$.pipe(
    //   map(m => (m.roleNo === UserRole.Composer))
    // );
    return of(true);
  }
  public canDelete(id: string): Observable<boolean> {
    const state = this.dataStoreFacade.state;
    const canDelete = state.buildings.filter((f) => f.buildingID === id && f.noOfAllocations > 0)?.length === 0;
    return of(canDelete);
  }
  protected getNewModel(init: Partial<BuildingAddEditDto>): Observable<BuildingAddEditDto> {
    return of({
      ...init,
      buildingID: null,
      partnerID: init.partnerID || null,
      folderID: init.folderID || null,
      archivedInd: false,
      noOfAllocations: 0,
    });
  }
}
