<ng-container *transloco="let t; read: 'spaceplan'">
  <div id="editor-form-top" *ngIf="form" [formGroup]="form">
    <div class="form-group form-row mb-1">
      <label for="spaceTypeName" class="col-sm-12 col-form-label">{{ t('editorSpacetypeForm.spaceTypeName.label')
        }}:</label>
      <div class="col-sm-12">
        <div class="input-group">
          <input type="text" class="form-control" id="spaceTypeName"
            placeholder="{{ t('editorSpacetypeForm.spaceTypeName.placeholder') }}" name="spaceTypeName"
            formControlName="spaceTypeName" />
        </div>
        <div *ngIf="isRequesting && form.get('spaceTypeName').errors" class="invalid-feedback">
          <div class="form-error" *ngIf="form.controls['spaceTypeName'].errors.required">
            {{ t('editorSpacetypeForm.spaceTypeName.error.required') }}
          </div>
          <div class="form-error" *ngIf="form.controls['spaceTypeName'].errors.duplicate">
            {{ t('editorSpacetypeForm.spaceTypeName.error.duplicate') }}
          </div>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-1">
      <label for="spaceTypeCode" class="col-sm-12 col-form-label">{{ t('editorSpacetypeForm.spaceTypeCode.label')
        }}:</label>
      <div class="col-sm-12">
        <div class="input-group">
          <input type="text" class="form-control" id="spaceTypeCode"
            placeholder="{{ t('editorSpacetypeForm.spaceTypeCode.placeholder') }}" name="spaceTypeCode"
            formControlName="spaceTypeCode" />
        </div>
      </div>
    </div>


    <div class="form-group form-row mb-1">
      <label for="area" class="col-sm-12 col-form-label">{{ t('editorSpacetypeForm.area.label') }} ({{
        (options.measurementUnit === MeasurementUnit.Metric ? t('UOM_Abbrev_Metric') : t('UOM_Abbrev_Imperial'))
        }}):</label>
      <div class="col-sm-12">
        <div class="input-group mb-1" style="max-width: 200px; align-items: center; flex-wrap: nowrap;">
          <div class="input-group mr-2">
            <input type="text" class="form-control" numberOnly #length id="length"
              placeholder="{{ t('editorSpacetypeForm.length.placeholder') }}" name="length" formControlName="length"
              size="3" (keyup)="onChangeLength(length, breadth, area)" />
          </div>
          <div class="input-group">
            <input type="text" class="form-control" numberOnly #breadth id="breadth"
              placeholder="{{ t('editorSpacetypeForm.breadth.placeholder') }}" name="breadth" formControlName="breadth"
              size="3" (keyup)="onChangeBreath(length, breadth, area)" />
          </div>
          <div class="input-group ml-2">
            <input type="text" class="form-control" numberOnly #area id="area"
              placeholder="{{ t('editorSpacetypeForm.area.placeholder') }}" name="area" formControlName="area" size="5"
              (keyup)="onChangeArea(length, breadth, area)" />
          </div>
        </div>
        <div *ngIf="isRequesting && form.get('length').errors" class="invalid-feedback">
          <div class="form-error" *ngIf="form.controls['length'].errors.min">{{
            t('editorSpacetypeForm.length.error.min') }}</div>
          <div class="form-error" *ngIf="form.controls['length'].errors.max">{{
            t('editorSpacetypeForm.length.error.max') }}</div>
        </div>
        <div *ngIf="isRequesting && form.get('breadth').errors" class="invalid-feedback">
          <div class="form-error" *ngIf="form.controls['breadth'].errors.min">{{
            t('editorSpacetypeForm.breadth.error.min') }}</div>
          <div class="form-error" *ngIf="form.controls['breadth'].errors.max">{{
            t('editorSpacetypeForm.breadth.error.max') }}</div>
        </div>
        <div *ngIf="isRequesting && form.get('area').errors" class="invalid-feedback">
          <div class="form-error" *ngIf="form.controls['area'].errors.areaRequired">
            {{ t('editorSpacetypeForm.area.error.areaRequired') }}
          </div>
          <div class="form-error" *ngIf="form.controls['area'].errors.min">{{ t('editorSpacetypeForm.area.error.min') }}
          </div>
          <div class="form-error" *ngIf="form.controls['area'].errors.max">{{ t('editorSpacetypeForm.area.error.max') }}
          </div>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-1">
      <label for="capacity" class="col-sm-12 col-form-label">{{ t('editorSpacetypeForm.capacity.label') }}:</label>
      <div class="col-sm-12">
        <div class="input-group">
          <input type="text" class="form-control" id="capacity" numberOnly
            placeholder="{{ t('editorSpacetypeForm.capacity.placeholder') }}" name="capacity"
            formControlName="capacity" />
        </div>
        <div *ngIf="isRequesting && form.get('capacity').errors" class="invalid-feedback">
          <div class="form-error" *ngIf="form.controls['capacity'].errors.min">{{
            t('editorSpacetypeForm.capacity.error.min') }}</div>
          <div class="form-error" *ngIf="form.controls['capacity'].errors.max">{{
            t('editorSpacetypeForm.capacity.error.max') }}</div>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-1">
      <label for="workPlaceFactor" class="col-sm-12 col-form-label">{{ t('editorSpacetypeForm.workPlaceFactor.label')
        }}:</label>
      <div class="col-sm-12">
        <div class="input-group">
          <input type="text" class="form-control" id="name" decimalNumberOnly
            placeholder="{{ t('editorSpacetypeForm.workPlaceFactor.placeholder') }}" name="workPlaceFactor"
            formControlName="workPlaceFactor" />
        </div>

        <div *ngIf="isRequesting && form.get('workPlaceFactor').errors" class="invalid-feedback">
          <div class="form-error" *ngIf="form.controls['workPlaceFactor'].errors.min">
            {{ t('editorSpacetypeForm.workPlaceFactor.error.min') }}
          </div>
          <div class="form-error" *ngIf="form.controls['workPlaceFactor'].errors.max">
            {{ t('editorSpacetypeForm.workPlaceFactor.error.max') }}
          </div>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-1">
      <label for="primaryFactor" class="col-sm-12 col-form-label">{{ t('editorSpacetypeForm.primaryFactor.label')
        }}:</label>
      <div class="col-sm-12">
        <div class="input-group">
          <input type="text" class="form-control" id="name" numberOnlyInteger
            placeholder="{{ t('editorSpacetypeForm.primaryFactor.placeholder') }}" name="primaryFactor"
            formControlName="primaryFactor" />
        </div>

        <div *ngIf="isRequesting && form.get('primaryFactor').errors" class="invalid-feedback">
          <div class="form-error" *ngIf="form.controls['primaryFactor'].errors.required">
            {{ t('editorSpacetypeForm.primaryFactor.error.required') }}
          </div>
          <div class="form-error" *ngIf="form.controls['primaryFactor'].errors.min">
            {{ t('editorSpacetypeForm.primaryFactor.error.min') }}
          </div>
          <div class="form-error" *ngIf="form.controls['primaryFactor'].errors.max">
            {{ t('editorSpacetypeForm.primaryFactor.error.max') }}
          </div>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-1">
      <label for="spaceTypeDescr" class="col-sm-12 col-form-label">{{ t('editorSpacetypeForm.spaceTypeDescr.label')
        }}:</label>
      <div class="col-sm-12">
        <div class="input-group mb-1">
          <textarea name="spaceTypeDescr" id="spaceTypeDescr"
            placeholder="{{ t('editorSpacetypeForm.spaceTypeDescr.placeholder') }}" class="form-control"
            formControlName="spaceTypeDescr" rows="6" style="resize: none;"></textarea>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-1">
      <label for="name" class="col-sm-12 col-form-label">{{ t('editorSpacetypeForm.imageUrl.label') }}:</label>
      <div class="col-sm-12">
        <div class="input-group mb-1">
          <app-file-upload formControlName="imageUrl"></app-file-upload>
        </div>
      </div>
    </div>


    <div class="form-group form-row mb-1">
      <label for="spaceTypeCode" class="col-sm-12 col-form-label">{{ t('editorSpacetypeForm.spaceTypeColour.label')
        }}:</label>
      <div class="col-sm-12">
        <div class="input-group">
          <kendo-colorpicker formControlName="colourCode" [format]="'hex'"
            [gradientSettings]="uiFacade.colorPickerGradientSettings">
          </kendo-colorpicker>
        </div>
      </div>
    </div>


  </div>
</ng-container>