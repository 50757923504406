import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, forkJoin, of, throwError } from 'rxjs';
import { map, take, switchMap, catchError, filter, withLatestFrom, pluck, tap } from 'rxjs/operators';
import { UIFacade } from '../../spaceplan/shared/facades/ui.facade';
import { getBrowserLang, TranslocoService } from '@ngneat/transloco';
import { getCustomLabels } from '../../spaceplan/shared/functions/get-custom-labels';

import {
  BuildingClient,
  Partner,
  PartnerClient,
  Building,
  BuildingFloor,
  BuildingZone,
  BuildingFloorZone,
  BuildingFloorClient,
  BuildingZoneClient,
  BuildingFloorZoneClient,
  CreateBuildingDto,
  UpdateBuildingDto,
  FolderClient,
  Folder,
  BuildingType,
} from '../../core/services/api-clients';

@Injectable({
  providedIn: 'root',
})
export class BuildingsDataStoreFacade {
  private readonly dataStoreStateSubject = new BehaviorSubject<BuildingsDataStoreState>({ ...BuildingsDataStoreStateDefault });
  state$ = this.dataStoreStateSubject.asObservable();
  buildings$ = this.state$.pipe(map((m) => m.buildings));
  buildingsAll$ = this.state$.pipe(map((m) => m.buildingsAll));
  building$ = this.state$.pipe(map((m) => m.building));
  partners$ = this.state$.pipe(map((m) => m.partners));
  zones$ = this.state$.pipe(map((m) => m.zones));
  folders$ = this.state$.pipe(map((m) => m.folders));

  constructor(
    private buildingClient: BuildingClient,
    private buildingFloorClient: BuildingFloorClient,
    private buildingZoneClient: BuildingZoneClient,
    private buildingFloorZoneClient: BuildingFloorZoneClient,
    private partnerClient: PartnerClient,
    private folderClient: FolderClient,
    private uiFacade: UIFacade,
    private translocoService: TranslocoService
  ) {}

  get state(): BuildingsDataStoreState {
    return this.dataStoreStateSubject.getValue();
  }

  loadBuildingsList() {
    return forkJoin([
      this.buildingClient.getList(),
      this.buildingFloorClient.getList(null),
      this.buildingZoneClient.getList(null),
      this.buildingFloorZoneClient.getList(null),
      this.partnerClient.getList(),
      this.folderClient.getList(),
    ]).pipe(
      take(1),
      map(([buildings, floors, zones, floorZones, partners, folders]) => {
        return {
          buildings: buildings?.sort((a, b) => a.buildingName.localeCompare(b.buildingName)),
          floors,
          zones,
          floorZones,
          partners,
          folders,
        };
      }),
      map(({ buildings, floors, zones, floorZones, partners, folders }) => {
        const newstate: BuildingsDataStoreState = {
          ...this.dataStoreStateSubject.value,
          buildings: buildings.filter((f) => f.buildingType === BuildingType.Programmable),
          buildingsAll: buildings,
          floors,
          zones,
          floorZones,
          partners,
          folders,
        };
        this.dataStoreStateSubject.next(newstate);
        return {
          buildings,
        };
      }),
      catchError(this.handleError)
    );
  }

  getBuilding(id: string): Observable<UpdateBuildingDto> {
    const state = this.dataStoreStateSubject.getValue();
    const building = state.buildings?.find((f) => f.buildingID === id);
    const floors = state.floors?.filter((f) => f.buildingID === id).sort((a, b) => a.displaySequence - b.displaySequence);
    const zones = state.zones?.filter((f) => f.buildingID === id).sort((a, b) => a.displaySequence - b.displaySequence);
    const floorZones = state.floorZones?.filter((f) => f.buildingID === id);
    const model = {
      ...building,
      floors,
      zones,
      floorZones,
    } as UpdateBuildingDto;
    return of(model);
  }
  createBuilding(model: CreateBuildingDto): Observable<CreateBuildingDto> {
    return this.buildingClient.insert(model).pipe(
      switchMap((result) => {
        const state = { ...this.dataStoreStateSubject.getValue() };
        const temp = { ...result };
        delete temp.floors;
        delete temp.zones;
        delete temp.floorZones;
        const building: Building = temp;
        building.archivedInd = false;

        // state.buildingsAll = [...state.buildingsAll, building];
        state.floors = [...state.floors, ...result.floors.map((m) => ({ ...m, buildingID: result.buildingID } as BuildingFloor))];
        state.zones = [...state.zones, ...result.zones.map((m) => ({ ...m, buildingID: result.buildingID } as BuildingZone))];
        state.floorZones = [
          ...state.floorZones,
          ...result.floorZones.map((m) => ({ ...m, buildingID: result.buildingID } as BuildingFloorZone)),
        ];
        this.dataStoreStateSubject.next(state);
        return of(result);
      }),
      tap(() => this.refreshBuildings())
    );
  }
  updateBuilding(model: UpdateBuildingDto): Observable<UpdateBuildingDto> {
    return this.buildingClient.update(model).pipe(
      switchMap((result) => {
        const state = { ...this.dataStoreStateSubject.getValue() };
        const temp = { ...result };
        delete temp.floors;
        delete temp.zones;
        delete temp.floorZones;
        const building = { ...state.buildingsAll?.find((f) => f.buildingID === result.buildingID), ...temp };
        state.buildingsAll = [...state.buildingsAll.filter((f) => f.buildingID !== building.buildingID), building];
        state.buildings = state.buildingsAll.filter((f) => f.buildingType === BuildingType.Programmable);

        state.floors = [
          ...state.floors.filter((f) => f.buildingID !== building.buildingID),
          ...result.floors.map((m) => ({ ...m, buildingID: result.buildingID } as BuildingFloor)),
        ];
        state.zones = [
          ...state.zones.filter((f) => f.buildingID !== building.buildingID),
          ...result.zones.map((m) => ({ ...m, buildingID: result.buildingID } as BuildingZone)),
        ];
        state.floorZones = [
          ...state.floorZones.filter((f) => f.buildingID !== building.buildingID),
          ...result.floorZones.map((m) => ({ ...m, buildingID: result.buildingID } as BuildingFloorZone)),
        ];
        this.dataStoreStateSubject.next(state);
        return of(result);
      })
    );
  }

  archiveBuilding(id: string): Observable<Building> {
    return this.buildingClient.updateArchived(id, true).pipe(
      switchMap((building) => {
        this.refreshBuildings();
        return of(building);
      })
    );
  }

  unarchiveBuilding(id: string): Observable<Building> {
    return this.buildingClient.updateArchived(id, false).pipe(
      switchMap((building) => {
        this.refreshBuildings();
        return of(building);
      })
    );
  }
  deleteBuilding(buildingID: string): Observable<boolean> {
    return this.buildingClient.delete(buildingID).pipe(
      switchMap(() => {
        const state = this.dataStoreStateSubject.getValue();
        const buildingsAll = state.buildingsAll?.filter((f) => f.buildingID !== buildingID);
        const buildings = state.buildingsAll.filter((f) => f.buildingType === BuildingType.Programmable);
        this.dataStoreStateSubject.next({ ...state, buildingsAll, buildings });
        return of(true);
      })
    );
  }

  clear() {
    this.dataStoreStateSubject.next({ ...BuildingsDataStoreStateDefault });
  }

  refreshBuildings() {
    this.buildingClient
      .getList()
      .pipe(
        switchMap((buildings) => {
          return of(buildings);
        })
      )
      .subscribe((buildings) => {
        const state = this.dataStoreStateSubject.getValue();
        state.buildingsAll = buildings;
        state.buildings = buildings.filter((f) => f.buildingType === BuildingType.Programmable);
        this.dataStoreStateSubject.next(state);
      });
  }

  handleError(error: any): Observable<any> {
    return throwError(error);
  }
}

export const BuildingsDataStoreStateDefault: BuildingsDataStoreState = { loading: false, loaded: false };

export interface BuildingsDataStoreState {
  buildings?: Building[];
  buildingsAll?: Building[];
  floors?: BuildingFloor[];
  zones?: BuildingZone[];
  floorZones?: BuildingFloorZone[];
  partners?: Partner[];
  folders?: Folder[];
  buildingID?: string;
  loading: boolean;
  loaded: boolean;
  building?: any;
}
