import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import {
  UserRole,
  Allocation,
  CreateAllocationDto,
  UpdateAllocationDto,
  Allocation2,
  DuplicateAllocationDto,
} from 'src/app/core/services/api-clients';
import { EditorFacadeBase } from './editor-facade-base';
import { UserFacade } from './user.facade';
import { UIFacade } from './ui.facade';
import { TranslocoService } from '@ngneat/transloco';
import { bufferCount, filter, map, switchMap } from 'rxjs/operators';
import { AllocationDataStoreFacade } from './allocation-data-store.facade';
import { Guid } from 'guid-typescript';

export type AllocationAddEditDto = Allocation2 & CreateAllocationDto & UpdateAllocationDto;

/**
 * t(spaceplan.Allocation)
 */
@Injectable({
  providedIn: 'root',
})
export class EditorAllocationFacade extends EditorFacadeBase<AllocationAddEditDto> {
  constructor(
    protected toastr: ToastrService,
    protected modalService: BsModalService,
    protected uiFacade: UIFacade,
    protected translocoService: TranslocoService,
    private dataStoreFacade: AllocationDataStoreFacade,
    private userFacade: UserFacade
  ) {
    super(toastr, modalService, uiFacade, translocoService);
    this.dataStoreFacade.spaceProgram$
      .pipe(
        bufferCount(2, 1),
        filter(([a, b]) => b?.spaceProgramID !== a?.spaceProgramID),
        map(([a, b]) => b)
      )
      .subscribe(() => {
        this.resetToDefault();
      });
  }
  protected className(): string {
    return this.translocoService.translate('spaceplan.Allocation');
  }
  protected getFromDataStore(id: string): Observable<AllocationAddEditDto> {
    return this.dataStoreFacade.getAllocation(id);
  }
  protected createDataStore(model: AllocationAddEditDto): Observable<AllocationAddEditDto> {
    const isDuplicate = this.state.isDuplicate;
    if (!isDuplicate) {
      return this.dataStoreFacade.createAllocation(model);
    } else {
      const duplicateAllocationModel: DuplicateAllocationDto = {
        fromAllocationID: (model as any).copyFromAllocationID,
        newAllocationID: Guid.raw(),
        newAllocationName: model.allocationName,
        newAllocationDescr: model.allocationDescr,
      };
      return this.dataStoreFacade.duplicateAllocation(duplicateAllocationModel).pipe(
        map(() => {
          return model;
        })
      );
    }
  }
  protected updateDataStore(model: AllocationAddEditDto): Observable<AllocationAddEditDto> {
    return this.dataStoreFacade.updateAllocation(model);
  }
  protected deleteDataStore(id: string): Observable<boolean> {
    return this.dataStoreFacade.deleteAllocation(id);
  }
  protected getModelId(model: AllocationAddEditDto): string {
    return model?.allocationID;
  }
  protected setModelId(model: AllocationAddEditDto, id: string) {
    model.allocationID = id;
  }
  protected userCanEdit(): Observable<boolean> {
    return this.dataStoreFacade.spaceProgram$.pipe(map((m) => m.roleNo === UserRole.Composer));
  }
  public canDelete(id: string): Observable<boolean> {
    const state = this.dataStoreFacade.state;
    return of(true);
  }
  protected getNewModel(init: Partial<AllocationAddEditDto>): Observable<AllocationAddEditDto> {
    return of({
      ...init,
      allocationID: null,
    });
  }

  protected getNewModelForDuplicate(init: Partial<AllocationAddEditDto>): Observable<AllocationAddEditDto> {
    const copyinit = {
      ...init,
      copyFromAllocationID: init.allocationID,
      buildingIDs: init.buildings?.map((m) => m.buildingID) || [],
    } as AllocationAddEditDto;
    return this.getNewModel(copyinit);
  }
}
