import {} from '@ngneat/transloco';

/**
 * t(spaceplan.customLabel.Total_FTE)
 * t(spaceplan.customLabel.Total_People)
 * t(spaceplan.customLabel.Generated_Spaces)
 * t(spaceplan.customLabel.Planned_Spaces)
 * t(spaceplan.customLabel.Delta_Spaces)
 * t(spaceplan.customLabel.Primary_Workspaces)
 * t(spaceplan.customLabel.Capacity_Ratio)
 * t(spaceplan.customLabel.Capacity)
 * t(spaceplan.customLabel.Planned_Net_Surface)
 * t(spaceplan.customLabel.Planned_Gross_Surface)
 * t(spaceplan.customLabel.Delta_Surface)
 */

export function getCustomLabels() {
  return [
    { code: 'Total_FTE' },
    { code: 'Total_People' },
    { code: 'Generated_Spaces' },
    { code: 'Planned_Spaces' },
    { code: 'Delta_Spaces' },
    { code: 'Primary_Workspaces' },
    { code: 'Capacity_Ratio' },
    { code: 'Capacity' },
    { code: 'Planned_Net_Surface' },
    { code: 'Planned_Gross_Surface' },
    { code: 'Delta_Surface' },
  ];
}
