import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Subject } from 'rxjs';
import { map, groupBy, flatMap, toArray } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserFacade {
  private readonly stateSubject = new BehaviorSubject<UserFacadeState>({
    canEditGenerator: true,
    canEditSpaceType: true,
    canEditSpaceProgram: true,
    canEditWorkPlaceType: true,
  });
  private readonly onUserChangedSubject = new Subject();
  public readonly onUserChanged$ = this.onUserChangedSubject.asObservable();

  constructor() {
    if (window) {
      // tslint:disable-next-line:no-string-literal
      window['user'] = this;
    }
  }

  get canEditGenerator(): boolean {
    return this.stateSubject.getValue()?.canEditGenerator;
  }
  set canEditGenerator(value: boolean) {
    this.updateState({ canEditGenerator: value });
    this.onUserChangedSubject.next();
  }
  get canEditSpaceType(): boolean {
    return this.stateSubject.getValue()?.canEditSpaceType;
  }
  set canEditSpaceType(value: boolean) {
    this.updateState({ canEditSpaceType: value });
    this.onUserChangedSubject.next();
  }
  get canEditSpaceProgram(): boolean {
    return this.stateSubject.getValue()?.canEditSpaceProgram;
  }
  set canEditSpaceProgram(value: boolean) {
    this.updateState({ canEditSpaceProgram: value });
    this.onUserChangedSubject.next();
  }
  get canEditWorkPlaceType(): boolean {
    return this.stateSubject.getValue()?.canEditWorkPlaceType;
  }
  set canEditWorkPlaceType(value: boolean) {
    this.updateState({ canEditWorkPlaceType: value });
    this.onUserChangedSubject.next();
  }
  private updateState(updates: Partial<UserFacadeState>) {
    const state = {
      ...this.stateSubject.getValue(),
      ...updates,
    };
    this.stateSubject.next(state);
  }
}

export class UserFacadeState {
  name?: string;
  canEditGenerator: boolean;
  canEditSpaceType: boolean;
  canEditSpaceProgram: boolean;
  canEditWorkPlaceType: boolean;
}
