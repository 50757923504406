<ng-container *transloco="let t; read: 'spaceplan'">
  <div class="detail">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate [logValidationErrors]="form" appFormHandleEnterKey>
      <div class="header">
        <div class="title">{{ editorFacade.title$ | async }}</div>
        <div class="actions">
          <button (click)="onClose()" type="button" class="btn btn-primary btn-sm d-flex">
            <i class="k-icon k-i-close"></i>
          </button>
        </div>
      </div>
      <div class="body">
        <div class="p-0">
          <app-editor-spacetype-form [parentForm]="form" [model]="editorFacade.model$ | async"
            [options]="options$ | async" (updateModelWithChanges)="editorFacade.updateModelWithChanges($event)"
            [isRequesting]="isRequesting">
          </app-editor-spacetype-form>
        </div>
      </div>
      <div class="footer" *ngIf="editorFacade.saveButtonIsVisible$ | async">
        <div class="buttons-wrapper">
          <button [disabled]="!(editorFacade.isDirty$ | async)"
            [ngClass]="{ disabled: !(editorFacade.isDirty$ | async) }" class="btn btn-primary" type="submit"
            style="min-width: 150px;">
            {{ t('Save') }}
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-container>