import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DataStoreFacade } from 'src/app/spaceplan/shared/facades/data-store.facade';
import { Template } from 'src/app/core/services/api-clients';
import { EditorFacadeBase } from './editor-facade-base';
import { UserFacade } from './user.facade';
import { UIFacade } from './ui.facade';
import { TranslocoService } from '@ngneat/transloco';
import { bufferCount, catchError, filter, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { confirmArchive, confirmUnarchive } from '../functions/confirmations';
import { TemplateDataStoreFacade } from 'src/app/template/facades/template-data-store.facade';

/**
 * t(home.Template)
 */
@Injectable({
  providedIn: 'root',
})
export class EditorTemplateFacade extends EditorFacadeBase<Template> {
  constructor(
    protected toastr: ToastrService,
    protected modalService: BsModalService,
    protected uiFacade: UIFacade,
    protected translocoService: TranslocoService,
    private dataStoreFacade: TemplateDataStoreFacade,
    private userFacade: UserFacade
  ) {
    super(toastr, modalService, uiFacade, translocoService);
    this.dataStoreFacade.template$
      .pipe(
        bufferCount(2, 1),
        filter(([a, b]) => b?.templateID !== a?.templateID),
        map(([a, b]) => b)
      )
      .subscribe(() => {
        this.resetToDefault();
      });
  }
  public canArchive(model: Template): Observable<boolean> {
    return of(model?.archivedInd === false);
  }
  public canUnarchive(model: Template): Observable<boolean> {
    return of(model?.archivedInd === true);
  }
  archive(id: string) {
    this.getFromDataStore(id)
      .pipe(
        switchMap((model) => {
          const currentId = this.getModelId(model);
          if (id === currentId) {
            // cancel any changes is dirty because record about to be deleted
            this.resetAndClose();
          }
          return this.canArchive(model).pipe(
            mergeMap((canArchive) => {
              if (!canArchive) {
                return of(false);
              }
              return this.userCanArchive();
            }),
            mergeMap((canArchive) => {
              if (!canArchive) {
                return of(false);
              }
              return confirmArchive(this.modalService);
            }),
            mergeMap((canArchive) => {
              if (!canArchive) {
                return of(false);
              }
              return this.archiveDataStore(id).pipe(
                switchMap(() => {
                  return of(true);
                }),
                catchError((error) => this.handleDataStoreError(error))
              );
            }),
            take(1)
          );
        })
      )
      .subscribe();
  }
  unarchive(id: string) {
    this.getFromDataStore(id)
      .pipe(
        switchMap((model) => {
          const currentId = this.getModelId(model);
          if (id === currentId) {
            // cancel any changes is dirty because record about to be deleted
            this.resetAndClose();
          }
          return this.canUnarchive(model).pipe(
            mergeMap((canUnarchive) => {
              if (!canUnarchive) {
                return of(false);
              }
              return this.userCanUnarchive();
            }),
            mergeMap((canUnarchive) => {
              if (!canUnarchive) {
                return of(false);
              }
              return confirmUnarchive(this.modalService);
            }),
            mergeMap((canUnarchive) => {
              if (!canUnarchive) {
                return of(false);
              }
              return this.unarchiveDataStore(id).pipe(
                switchMap(() => {
                  return of(true);
                }),
                catchError((error) => this.handleDataStoreError(error))
              );
            }),
            take(1)
          );
        })
      )
      .subscribe();
  }
  protected userCanArchive(): Observable<boolean> {
    return this.userCanEdit();
  }
  protected userCanUnarchive(): Observable<boolean> {
    return this.userCanEdit();
  }
  protected className(): string {
    return this.translocoService.translate('home.Template');
  }
  protected getFromDataStore(id: string): Observable<Template> {
    return this.dataStoreFacade.getTemplate(id);
  }
  protected createDataStore(model: Template): Observable<Template> {
    const isCopy = this.state.isCopy;
    if (isCopy) {
      return this.dataStoreFacade.duplicateTemplate(model);
    }
  }
  protected updateDataStore(model: Template): Observable<Template> {
    return this.dataStoreFacade.updateTemplate(model);
  }
  protected deleteDataStore(id: string): Observable<boolean> {
    return this.dataStoreFacade.deleteGenerator(id);
  }
  protected afterSaveNew(modelWithChanges: Template) {
    this.resetAndClose();
  }
  protected archiveDataStore(id: string): Observable<Template> {
    return this.dataStoreFacade.archiveTemplate(id);
  }
  protected unarchiveDataStore(id: string): Observable<Template> {
    return this.dataStoreFacade.unarchiveTemplate(id);
  }
  protected getModelId(model: Template): string {
    return model?.templateID;
  }
  protected setModelId(model: Template, id: string) {
    model.templateID = id;
  }
  protected getNewModel(init: Partial<Template>): Observable<Template> {
    return of({
      ...init,
      templateID: null,
      folderID: null,
      partnerID: init.partnerID || null,
    });
  }
}
