import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export enum ScreenLayout {
  Layout1_1440px = 0,
  Layout2_1024px = 1,
  Layout3_720px = 2,
  Layout4_320px = 3,
}

export interface ScreenLayoutConfig {
  layout: ScreenLayout;
  query: string;
}

@Injectable({
  providedIn: 'root',
})
export class UiFacade implements OnInit, OnDestroy {
  private _destroyed = new Subject<void>();

  private readonly _homeTitle = new BehaviorSubject<string | null>(null);
  public readonly homeTitle$ = this._homeTitle.asObservable();

  private readonly _screenLayoutChanged = new BehaviorSubject<ScreenLayoutConfig | null>(null);
  public readonly screenLayoutChanged$ = this._screenLayoutChanged.asObservable();

  private screenLayoutConfigList: ScreenLayoutConfig[] = [
    // {
    //   layout: ScreenLayout.Layout1_1440px,
    //   query: '(min-width: 1025px)',
    // },
    // {
    //   layout: ScreenLayout.Layout2_1024px,
    //   query: '(min-width: 721px) and (max-width: 1024px)',
    // },
    // {
    //   layout: ScreenLayout.Layout3_720px,
    //   query: '(min-width: 321px) and (max-width: 720px)',
    // },
    // {
    //   layout: ScreenLayout.Layout4_320px,
    //   query: '(max-width: 320px)',
    // },
    {
      layout: ScreenLayout.Layout1_1440px,
      query: '(min-width: 1440px)',
    },
    {
      layout: ScreenLayout.Layout2_1024px,
      query: '(min-width: 1024px) and (max-width: 1439px)',
    },
    {
      layout: ScreenLayout.Layout3_720px,
      query: '(min-width: 720px) and (max-width: 1023px)',
    },
    {
      layout: ScreenLayout.Layout4_320px,
      query: '(max-width: 719px)',
    },
  ];

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver
      .observe(this.screenLayoutConfigList.map((m) => m.query))
      .pipe(takeUntil(this._destroyed))
      .subscribe((result) => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            const screenLayoutConfig = this.screenLayoutConfigList.find((f) => f.query === query);
            // console.log(screenLayoutConfig);
            this._screenLayoutChanged.next(screenLayoutConfig ?? null);
          }
        }
      });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  public getCurrentScreenLayout(): ScreenLayout | null {
    // return const isSmallScreen = breakpointObserver.isMatched('(max-width: 599px)');
    get: {
      return this._screenLayoutChanged.value?.layout ?? null;
    }
  }

  public setHomeTitle(title: string) {
    // console.log({ title });
    setTimeout(() => {
      this._homeTitle.next(title);
    });
  }
}
