<ng-container *transloco="let t; read: 'spaceplan'">
  <div id="editor-form-top" *ngIf="form" [formGroup]="form">
    <div class="form-group form-row mb-1">
      <label for="generatorName" class="col-sm-12 col-form-label">{{ t('editorGeneratorForm.generatorName.label') }}:</label>
      <div class="col-sm-12">
        <div class="input-group mb-1">
          <input
            type="text"
            class="form-control"
            id="generatorName"
            placeholder="{{ t('editorGeneratorForm.generatorName.placeholder') }}"
            name="generatorName"
            formControlName="generatorName"
          />
        </div>
        <div *ngIf="isRequesting && form.get('generatorName').errors" class="invalid-feedback">
          <div class="form-error" *ngIf="form.controls['generatorName'].errors.required">
            {{ t('editorGeneratorForm.generatorName.error.required') }}
          </div>
          <div class="form-error" *ngIf="form.controls['generatorName'].errors.duplicate">
            {{ t('editorGeneratorForm.generatorName.error.duplicate') }}
          </div>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-1">
      <label for="generatorInputTypeNo" class="col-sm-12 col-form-label">{{ t('editorGeneratorForm.generatorInputTypeNo.label') }}:</label>
      <div class="col-sm-12">
        <div class="input-group">
          <select
            class="custom-select"
            id="generatorInputTypeNo"
            name="generatorInputTypeNo"
            formControlName="generatorInputTypeNo"
            style="border-radius: 0.25rem;"
            (change)="form.get('qualifier').updateValueAndValidity(); form.get('element').updateValueAndValidity()"
          >
            <option [ngValue]="null">{{ t('editorGeneratorForm.generatorInputTypeNo.option.null_For_every') }}</option>
            <option [ngValue]="6">{{ t('editorGeneratorForm.generatorInputTypeNo.option.6_Constant') }}</option>
          </select>
          <ng-template [ngIf]="form.get('generatorInputTypeNo').value != 6">
            <div class="input-group-prepend ml-3 mr-3">
              <input
                type="text"
                class="form-control"
                id="qualifier"
                numberOnly
                placeholder="{{ t('editorGeneratorForm.qualifier.placeholder') }}"
                name="qualifier"
                formControlName="qualifier"
                size="5"
              />
            </div>
            <select
              class="custom-select"
              id="element"
              name="element"
              formControlName="element"
              style="border-radius: 0.25rem;"
              [compareWith]="compareElementFn"
              (change)="form.get('element').updateValueAndValidity()"
            >
              <option [ngValue]="null">{{ t('editorGeneratorForm.element.option.null_Please_select_element') }}</option>
              <optgroup *ngIf="generatorInputTypes && generatorInputTypes.length" label="----------------------------------------">
                <ng-template ngFor let-element [ngForOf]="generatorInputTypes">
                  <option [ngValue]="element">{{ t('generatorInputType.' + element.text) }} </option>
                </ng-template>
              </optgroup>
              <optgroup *ngIf="workPlaceTypes && workPlaceTypes.length" label="----------------------------------------">
                <ng-template ngFor let-element [ngForOf]="workPlaceTypes">
                  <option [ngValue]="element">{{ element.text }} </option>
                </ng-template>
              </optgroup>
            </select>
          </ng-template>
        </div>
        <div *ngIf="isRequesting && (form.get('qualifier').errors || form.get('element').errors)" class="invalid-feedback">
          <div class="form-error" *ngIf="form.get('qualifier').errors && form.controls['qualifier'].errors.required">
            {{ t('editorGeneratorForm.qualifier.error.qualifierRequired') }}
          </div>
          <div class="form-error" *ngIf="form.get('qualifier').errors && form.controls['qualifier'].errors.qualifierRequired">
            {{ t('editorGeneratorForm.qualifier.error.qualifierRequired') }}
          </div>
          <div
            class="form-error"
            *ngIf="form.get('qualifier').errors && (form.controls['qualifier'].errors.min || form.controls['qualifier'].errors.max)"
          >
            {{ t('editorGeneratorForm.qualifier.error.min_max') }}
          </div>
          <div class="form-error" *ngIf="form.get('element').errors && form.get(['element']).errors.elementRequired">
            {{ t('editorGeneratorForm.element.error.elementRequired') }}
          </div>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-1">
      <label for="outputGroupby" class="col-sm-12 col-form-label">{{ t('editorGeneratorForm.outputGroupby.label') }}:</label>
      <div class="col-sm-12">
        <div class="input-group">
          <select
            class="custom-select"
            id="outputGroupby"
            name="outputGroupby"
            formControlName="outputGroupby"
            style="border-radius: 0.25rem;"
            (change)="onOutputGroupBy()"
          >
            <option [ngValue]="0">{{ t('editorGeneratorForm.outputGroupby.option.0_Total') }}</option>
            <option [ngValue]="1">{{ t('editorGeneratorForm.outputGroupby.option.1_All_Groups') }}</option>
            <option [ngValue]="2">{{ t('editorGeneratorForm.outputGroupby.option.2_Selected_Groups') }}</option>
          </select>
        </div>
        <div *ngIf="isRequesting && form.get('outputGroupby').errors" class="invalid-feedback">
          <div class="form-error" *ngIf="form.controls['outputGroupby'].errors.outputGroupbyRequired">
            {{ t('editorGeneratorForm.outputGroupby.error.outputGroupbyRequired') }}
          </div>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-1" *ngIf="form.get('outputGroupby').value == '2'">
      <div class="col-sm-12" formGroupName="groups">
        <div class="form-check mt-1" *ngFor="let group of groups; let i = index; let cnt = count">
          <input
            class="form-check-input k-checkbox"
            type="checkbox"
            kendoCheckBox
            [formControlName]="group.groupID"
            [id]="'chkGroup' + i"
          />
          <label class="form-check-label" [for]="'chkGroup' + i">
            {{ group.groupName }}
          </label>
        </div>
        <div *ngIf="isRequesting && form.get('groups').errors" class="invalid-feedback">
          <div class="form-error" *ngIf="form.controls['groups'].errors.groupsRequired">
            {{ t('editorGeneratorForm.groups.error.groupsRequired') }}
          </div>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-2">
      <label for="multiplier" class="col-sm-12 col-form-label">{{ t('editorGeneratorForm.multiplier.label') }}:</label>
      <div class="col-sm-12">
        <div class="input-group">
          <div class="input-group-prepend mr-3">
            <input
              type="text"
              class="form-control"
              id="multiplier"
              numberOnly
              placeholder="{{ t('editorGeneratorForm.multiplier.placeholder') }}"
              name="multiplier"
              formControlName="multiplier"
              size="5"
            />
          </div>
          <select
            class="custom-select"
            id="spacetype"
            name="spacetype"
            formControlName="spacetype"
            style="border-radius: 0.25rem;"
          >
            <option [ngValue]="null">{{ t('editorGeneratorForm.spacetype.option.null_Please_select_space_type') }}</option>
            <ng-template ngFor let-spaceType [ngForOf]="spaceTypes">
              <option [ngValue]="spaceType.spaceTypeID">{{ spaceType.spaceTypeName }}</option>
            </ng-template>
          </select>
        </div>
        <div *ngIf="isRequesting && (form.get('multiplier').errors || form.get('spacetype').errors)" class="invalid-feedback">
          <!-- <div class="form-error" *ngIf="form.get('multiplier').errors && form.controls['multiplier'].errors.required">
            {{ t('editorGeneratorForm.multiplier.error.required') }}
          </div> -->
          <div
            class="form-error"
            *ngIf="form.get('multiplier').errors && (form.controls['multiplier'].errors.min || form.controls['multiplier'].errors.max)"
          >
            {{ t('editorGeneratorForm.multiplier.error.min_max') }}
          </div>
          <div class="form-error" *ngIf="form.get('spacetype').errors && form.controls['spacetype'].errors.required">
            {{ t('editorGeneratorForm.spacetype.error.required') }}
          </div>
        </div>
      </div>
    </div>

    <div class="form-group form-row">
      <label class="col-sm-12 col-form-label" style="white-space: break-spaces;" [innerHTML]="this.model | generatorFriendlyName"></label>
    </div>

    <ng-container formGroupName="tags">
      <div class="form-group form-row mb-1" *ngFor="let tagGroup of tagGroups; let i = index; let cnt = count">
        <label [for]="'tagGroup' + tagGroup.tagGroupID" class="col-sm-12 col-form-label">{{ tagGroup.tagGroupName }}:</label>
        <div class="col-sm-12">
          <div class="input-group">
            <select
              class="custom-select"
              [id]="'tagGroup' + tagGroup.tagGroupID"
              [name]="'tagGroup' + tagGroup.tagGroupID"
              [formControlName]="tagGroup.tagGroupID"
              style="border-radius: 0.25rem;"
            >
              <option [ngValue]="''">{{ t('editorGeneratorForm.tagGroupID.option.emptyString_Please_select') }}</option>
              <ng-template ngFor let-item [ngForOf]="tagsForGroups[tagGroup.tagGroupID]">
                <option [ngValue]="item.tagID">{{ item.tagName }} </option>
              </ng-template>
            </select>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="form-group form-row mb-1">
      <label for="generatorDescr" class="col-sm-12 col-form-label">{{ t('editorGeneratorForm.generatorDescr.label') }}:</label>
      <div class="col-sm-12">
        <div class="input-group mb-1">
          <textarea
            name="generatorDescr"
            id="generatorDescr"
            placeholder="{{ t('editorGeneratorForm.generatorDescr.placeholder') }}"
            class="form-control"
            formControlName="generatorDescr"
            rows="10"
            style="resize: none;"
          ></textarea>
        </div>
      </div>
    </div></div>
  </ng-container>
