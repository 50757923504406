import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LookupSpaceTypesFacade } from '../../facades/lookup-spacetypes.facade';

@Component({
  selector: 'app-lookup-spacetypes-button',
  template: `
    <div>
      <button
        type="button"
        class="btn btn-light border"
        [disabled]="!templateID"
        ngClass="{ disabled: !templateID }"
        style="padding: 5px; border-radius: 4px; margin-left: 4px;"
        (click)="toggleLookup()"
      >
        <span class="k-icon k-i-list-bulleted mr-2"></span>
        <span>OVERRIDES</span>
      </button>
    </div>
  `,
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LookupSpaceTypeButtonComponent implements OnInit, OnChanges {
  @Input() templateID: string;

  constructor(private lookupSpaceTypesFacade: LookupSpaceTypesFacade) {}

  ngOnInit() {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes.templateID) {
      // this.lookupSpaceTypesFacade.lookup({ templateID: this.templateID }, true);
    }
  }

  toggleLookup() {
    // this.lookupSpaceTypesFacade.toggleLookup({ templateID: this.templateID });
  }
}
