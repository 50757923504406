import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, AsyncValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import {
  SpaceProgram,
  UpdateSpaceProgramSettingsDto,
  Group,
  BehaviourWorkPlaceType,
  WorkPlaceType,
  MeasurementUnit,
  Folder,
  FolderUsageType,
} from 'src/app/core/services/api-clients';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { IntlService } from '@progress/kendo-angular-intl';
import { map, take } from 'rxjs/operators';
import { DataStoreFacade, UIFacade } from 'src/app/spaceplan/shared/facades';
import { translate, TranslocoService } from '@ngneat/transloco';
import { getCustomLabels } from 'src/app/spaceplan/shared/functions/get-custom-labels';

/**
 * t(spaceplan.Chart)
 * t(spaceplan.Total_FTE)
 * t(spaceplan.Total_People)
 * t(spaceplan.Delta_Spaces)
 * t(spaceplan.Primary_Workspaces)
 * t(spaceplan.Capacity_Ratio)
 * t(spaceplan.Capacity)
 * t(spaceplan.Planned_Net_Surface)
 * t(spaceplan.Planned_Gross_Surface)
 * t(spaceplan.Delta_Surface)
 * t(spaceplan.FTE)
 * t(spaceplan.People)
 */

@Component({
  selector: 'app-editor-space-program-settings-form',
  styleUrls: ['./editor-space-program-settings-form.component.scss'],
  templateUrl: './editor-space-program-settings-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorSpaceProgramSettingsFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public parentForm: FormGroup;
  @Input()
  model: UpdateSpaceProgramSettingsDto;
  @Input()
  options: {
    folders: Folder[];
    measurementUnit: MeasurementUnit;
    groups: Group[];
    behaviourWorkPlaceTypes: BehaviourWorkPlaceType[];
    workPlaceTypes: WorkPlaceType[];
    readOnly: boolean;
  };
  @Input()
  public isRequesting: boolean;
  @Output()
  public updateModelWithChanges = new EventEmitter<Partial<UpdateSpaceProgramSettingsDto>>();
  public form: FormGroup;
  public MeasurementUnit = MeasurementUnit;
  private readonly subscription: Subscription = new Subscription();
  private readonly onChanges = new BehaviorSubject<SimpleChanges>(null);

  public groups: SettingsGroup[];
  public folders: Folder[];

  private readonly _groupsSummary = new BehaviorSubject<any>(null);
  public readonly groupsSummary$ = this._groupsSummary.asObservable().pipe(
    map((model) => {
      if (!model) {
        return null;
      }
      // console.log({ model });
      // console.log({ form: this.form.value });

      const totals: {
        sizeFTE: number;
        newSizeFTE: number;
        measuredInOffice: number;
        measuredInOfficeFTE: number;
        measuredPrimaryWorkSettingFactor: number;
        measuredPrimaryWorkSettingFactorFTE: number;
        adjustedInOffice: number;
        adjustedInOfficeFTE: number;
      } = {
        sizeFTE: 0,
        newSizeFTE: 0,
        measuredInOffice: 0,
        measuredInOfficeFTE: 0,
        measuredPrimaryWorkSettingFactor: 0,
        measuredPrimaryWorkSettingFactorFTE: 0,
        adjustedInOffice: 0,
        adjustedInOfficeFTE: 0,
      };

      for (let index = 0; index < this.groups?.length; index++) {
        const groupModel = this.model.groups[index];
        const groupForm = this.form.value?.groups[groupModel.groupID];
        const groupItem = this.groups.find((g) => g.groupID === groupModel.groupID) as any;

        totals.sizeFTE += groupItem.behaviorFraction;
        totals.newSizeFTE += this.calcNewSizeFTE(groupItem.behaviorFraction, groupModel.growthFactor);

        totals.measuredInOffice += groupItem.sourceInOfficeFraction * 100;
        totals.measuredInOfficeFTE += groupItem.sourceInOfficeFraction * 100 * groupItem.behaviorFraction;

        totals.measuredPrimaryWorkSettingFactor += groupItem.measuredPrimaryWorkSettingFactor;
        totals.measuredPrimaryWorkSettingFactorFTE += groupItem.measuredPrimaryWorkSettingFactor * groupItem.behaviorFraction;

        if (model.behaviorFractionAdjustmentMethod === 1) {
          totals.adjustedInOffice += groupItem.sourceInOfficeFraction * 100 * groupForm.behaviorFractionAdjustmentFactor;
          totals.adjustedInOfficeFTE +=
            groupItem.sourceInOfficeFraction * 100 * groupForm.behaviorFractionAdjustmentFactor * groupItem.behaviorFraction;
        }
      }

      if (this.model.groups?.length) {
        totals.measuredInOffice = totals.measuredInOfficeFTE / totals.sizeFTE;
        totals.measuredPrimaryWorkSettingFactor = totals.measuredPrimaryWorkSettingFactorFTE / totals.sizeFTE;
        totals.adjustedInOffice = totals.adjustedInOfficeFTE / totals.sizeFTE;
      }

      // console.log({ totals });

      return totals;
    })
  );

  public readonly customLabelList = getCustomLabels();

  public summaryContentList = [
    { code: 'chart', descr: 'Chart' },
    { code: 'fte', descr: 'Total_FTE' },
    { code: 'people', descr: 'Total_People' },
    { code: 'deltaSpace', descr: 'Delta_Spaces' },
    { code: 'primaryWorkspaces', descr: 'Primary_Workspaces' },
    { code: 'capacityRatio', descr: 'Capacity_Ratio' },
    { code: 'capacity', descr: 'Capacity' },
    { code: 'plannedNetSurface', descr: 'Planned_Net_Surface' },
    { code: 'plannedGrossSurface', descr: 'Planned_Gross_Surface' },
    { code: 'deltaSurface', descr: 'Delta_Surface' },
  ];
  public readonly summaryContentHiddenList = [
    { code: 'keyMetricByFTE', descr: 'FTE' },
    { code: 'keyMetricByPeople', descr: 'People' },
    { code: 'keyMetricByFTEPresent', descr: 'FTEPresent' },
  ];

  constructor(
    private fb: FormBuilder,
    public intl: IntlService,
    public uiFacade: UIFacade,
    private dataStoreFacade: DataStoreFacade,
    private translocoService: TranslocoService
  ) {}

  ngOnInit() {
    this.prepareLookups();
    this.initForm();
    if (this.parentForm) {
      this.parentForm.addControl('spaceProgram', this.form);
    }
    this.subscription.add(
      this.onChanges.subscribe((changes: SimpleChanges) => {
        if (changes?.options) {
          this.prepareLookups();
        }
        if (changes?.model) {
          const model = changes.model.currentValue as UpdateSpaceProgramSettingsDto;
          const values = this.modelToForm(model);
          this.form.patchValue(values, { emitEvent: false });
          this._groupsSummary.next(model);
        }
      })
    );
    this.subscription.add(
      this.form.valueChanges.pipe().subscribe((value) => {
        const values = this.form.getRawValue();
        this.model = this.formToModel(values);
        this.updateModelWithChanges.emit(this.model);
        this._groupsSummary.next(this.model);
      })
    );
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.onChanges.next(changes);
  }
  prepareLookups() {
    this.folders = this.options.folders.filter((f) => f.usageType !== FolderUsageType.Template);

    this.groups = this.options.groups
      .map((group) => {
        const workPlaceTypes = this.options.workPlaceTypes.filter((f) => f.inFlexFactorCalcInd === true);
        const behaviourWorkPlaceTypes = this.options.behaviourWorkPlaceTypes.filter(
          (f) => !!workPlaceTypes.find((f1) => f1.workPlaceTypeID === f.workPlaceTypeID)
        );
        const adjustedBehaviourFraction = behaviourWorkPlaceTypes
          .filter((f) => f.groupID === group.groupID)
          .reduce((v, i) => (v += i.adjustedBehaviorFraction || i.behaviorFraction), 0);
        const measuredPrimaryWorkSettingFactor = this.calcPrimaryWorkSettingFactor(
          group.behaviorFraction,
          group.growthFactor,
          adjustedBehaviourFraction
        );

        return {
          ...group,
          adjustedBehaviourFraction,
          measuredPrimaryWorkSettingFactor,
        };
      })
      .sort((a, b) => a.groupName.localeCompare(b.groupName));
  }
  initForm() {
    // const groups: FormArray = new FormArray([]);
    const groups = this.groups
      ? this.fb.group(
          this.groups.reduce((value, item) => {
            value[item.groupID] = this.fb.group({
              // groupID: this.fb.control(item.groupID),
              groupName: this.fb.control(item.groupName, [Validators.required]),
              colourCode: this.fb.control(item.colourCode),
              growthFactor: this.fb.control(item.growthFactor, [Validators.required, Validators.min(0), Validators.max(999)]),
              behaviorFractionAdjustmentFactor: this.fb.control(item.behaviorFractionAdjustmentFactor, [
                Validators.required,
                Validators.min(0),
                Validators.max(10),
              ]),
            });
            return value;
          }, {})
        )
      : this.fb.control(null);

    const customLabels = this.fb.group(
      this.customLabelList.reduce((value, item) => {
        value[item.code] = this.fb.control(null);
        return value;
      }, {})
    );

    const summaryContent = this.fb.group(
      this.summaryContentList.concat(this.summaryContentHiddenList).reduce((value, item) => {
        value[item.code] = this.fb.control(false);
        return value;
      }, {})
    );

    this.form = this.fb.group({
      spaceProgramName: this.fb.control('', [Validators.required], this.validateNameDuplicateValidator()),
      spaceProgramDescr: this.fb.control(''),
      folderID: this.fb.control(null),
      existingNetArea: this.fb.control(null, [Validators.min(1), Validators.max(999999999)]),
      proposedNetArea: this.fb.control(null, [Validators.min(1), Validators.max(999999999)]),
      fteToPeopleFactor: this.fb.control(null, [Validators.required, Validators.min(0.5), Validators.max(2)]),
      areaNetToGrossFactor: this.fb.control(null, [Validators.required, Validators.min(1), Validators.max(2)]),
      percFTEForGuest: this.fb.control(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      behaviorFractionAdjustmentMethod: this.fb.control(0, [Validators.required]),
      behaviorFractionAdjustmentPWFTo: this.fb.control(0),
      groups,
      summaryContent,
      customLabels,
      colourCodeUnallocated: this.fb.control(null),
      colourCodeOtherGroup: this.fb.control(null),
      colourCodeOtherTag: this.fb.control(null),
    });

    if (this.options.readOnly) {
      this.form.disable({ onlySelf: true });
    }
  }

  formToModel(values: any): Partial<UpdateSpaceProgramSettingsDto> {
    const groups = Object.keys(values.groups).map((groupID) => ({
      groupID,
      ...values.groups[groupID],
      growthFactor: +values.groups[groupID].growthFactor,
      behaviorFractionAdjustmentFactor: +values.groups[groupID].behaviorFractionAdjustmentFactor,
    }));

    const customLabels = Object.keys(values.customLabels)
      .filter((f) => !!values.customLabels[f])
      .map((m) => ({ labelCode: m, labelText: values.customLabels[m] }));

    const summaryContent = Object.keys(values.summaryContent)
      .filter((f) => !!values.summaryContent[f])
      .map((m) => m);

    const model: Partial<UpdateSpaceProgramSettingsDto> = {
      ...this.model,
      spaceProgramName: values.spaceProgramName,
      spaceProgramDescr: values.spaceProgramDescr,
      folderID: values.folderID,
      existingNetArea: values.existingNetArea ? +values.existingNetArea : null,
      proposedNetArea: values.proposedNetArea ? +values.proposedNetArea : null,
      fteToPeopleFactor: values.fteToPeopleFactor ? +values.fteToPeopleFactor : null,
      areaNetToGrossFactor: values.areaNetToGrossFactor ? +values.areaNetToGrossFactor : null,
      percFTEForGuest: values.percFTEForGuest || values.percFTEForGuest?.toString() === '0' ? +values.percFTEForGuest : null,
      behaviorFractionAdjustmentMethod: values.behaviorFractionAdjustmentMethod !== null ? +values.behaviorFractionAdjustmentMethod : null,
      behaviorFractionAdjustmentPWFTo: values.behaviorFractionAdjustmentPWFTo !== null ? +values.behaviorFractionAdjustmentPWFTo : null,
      groups,
      customLabels,
      summaryContent,
      colourCodeUnallocated: values.colourCodeUnallocated,
      colourCodeOtherGroup: values.colourCodeOtherGroup,
      colourCodeOtherTag: values.colourCodeOtherTag,
    };
    return model;
  }
  modelToForm(model: UpdateSpaceProgramSettingsDto): { [key: string]: any } {
    return (
      (model && {
        spaceProgramName: model.spaceProgramName,
        spaceProgramDescr: model.spaceProgramDescr,
        folderID: model.folderID,
        existingNetArea: model.existingNetArea,
        proposedNetArea: model.proposedNetArea,
        fteToPeopleFactor: model.fteToPeopleFactor,
        areaNetToGrossFactor: model.areaNetToGrossFactor,
        percFTEForGuest: model.percFTEForGuest,
        behaviorFractionAdjustmentMethod: model.behaviorFractionAdjustmentMethod,
        behaviorFractionAdjustmentPWFTo: model.behaviorFractionAdjustmentPWFTo,
        groups: this.groups.reduce((value, item) => {
          value[item.groupID] = model?.groups?.find((f) => f.groupID === item.groupID);
          return value;
        }, {}),

        customLabels: this.customLabelList.reduce((value, item) => {
          value[item.code] = model.customLabels?.find((f) => f.labelCode === item.code)?.labelText;
          return value;
        }, {}),

        summaryContent: this.summaryContentList.concat(this.summaryContentHiddenList).reduce((value, item) => {
          value[item.code] = model.summaryContent?.findIndex((f) => f === item.code) > -1;
          return value;
        }, {}),

        colourCodeUnallocated: model.colourCodeUnallocated,
        colourCodeOtherGroup: model.colourCodeOtherGroup,
        colourCodeOtherTag: model.colourCodeOtherTag,
      }) ||
      {}
    );
  }

  calcNewSizeFTE(behaviorFraction: number, growthFactor: number) {
    return behaviorFraction * growthFactor;
  }
  calcPrimaryWorkSettingFactor(behaviorFraction: number, growthFactor: number, adjustedBehaviourFraction: number) {
    const newSizeFTE = this.calcNewSizeFTE(behaviorFraction, growthFactor);
    if (newSizeFTE > 0) {
      return adjustedBehaviourFraction / newSizeFTE;
    }
    return 0;
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onGrowthFactorOverride(event: any) {
    if (event.currentTarget.value) {
      const value = +event.currentTarget.value;
      const groups = this.groups.reduce((v, i) => {
        const group = this.groups?.find((f) => f.groupID === i.groupID);
        v[i.groupID] = { growthFactor: value };
        return v;
      }, {});
      this.form.patchValue({
        groups,
      });
    }
  }

  onBehaviorFractionAdjustmentFactorOverride(event: any) {
    if (event.currentTarget.value) {
      const value = +event.currentTarget.value;
      const groups = this.groups.reduce((v, i) => {
        const group = this.groups?.find((f) => f.groupID === i.groupID);
        v[i.groupID] = { behaviorFractionAdjustmentFactor: value };
        return v;
      }, {});
      this.form.patchValue({
        groups,
      });
    }
  }

  onBehaviorFractionAdjustmentMethod(value: number) {
    const behaviorFractionAdjustmentMethod = +value;
    // primary == 0
    const primary = 0;
    let groups: any;
    if (behaviorFractionAdjustmentMethod === primary) {
      // if switch TO primary factor
      //   set desired factor (behaviorFractionAdjustmentFactor) = measured factor
      groups = this.groups.reduce((v, i) => {
        const group = this.groups?.find((f) => f.groupID === i.groupID);
        v[i.groupID] = {
          behaviorFractionAdjustmentFactor: this.intl.formatNumber(
            this.calcPrimaryWorkSettingFactor(
              group.behaviorFraction,
              this.form.get(['groups', group.groupID, 'growthFactor'])?.value,
              group.adjustedBehaviourFraction
            ),
            'N2'
          ),
        };
        return v;
      }, {});
    } else {
      // if switch to in ofice % adjust
      //   set to in offiec % adjustment factor = 1
      groups = this.groups.reduce((v, i) => {
        const group = this.groups?.find((f) => f.groupID === i.groupID);
        v[i.groupID] = { behaviorFractionAdjustmentFactor: 1 };
        return v;
      }, {});
    }

    const summaryContent = this.form.value.summaryContent;

    if (behaviorFractionAdjustmentMethod !== 1 && summaryContent['keyMetricByFTEPresent']) {
      summaryContent['keyMetricByFTE'] = false;
      summaryContent['keyMetricByPeople'] = false;
      summaryContent['keyMetricByFTEPresent'] = false;
    }

    this.form.patchValue({
      behaviorFractionAdjustmentMethod,
      behaviorFractionAdjustmentPWFTo: behaviorFractionAdjustmentMethod === primary ? 0 : null,
      summaryContent,
      groups,
    });
  }

  onBehaviorFractionAdjustmentPWFTo(value: number) {
    this.form.patchValue({
      behaviorFractionAdjustmentPWFTo: +value,
    });
  }

  onSummaryContent(code: string, value: boolean) {
    const summaryContent = this.form.value?.summaryContent || {};
    summaryContent[code] = value;
    this.form.patchValue({
      summaryContent,
    });
  }
  validateNameDuplicateValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.dataStoreFacade.spacePrograms$.pipe(
        map((spacePrograms) => {
          const duplicate = !!spacePrograms.find(
            (f) => f.spaceProgramID !== this.model.spaceProgramID && f.spaceProgramName === control.value
          );
          if (duplicate) {
            return {
              duplicate: true,
            };
          }
          return null;
        }),
        take(1)
      );
    };
  }
}

interface SettingsGroup extends Group {
  adjustedBehaviourFraction: number;
}
