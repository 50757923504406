// https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/frameworks/angular.html

import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ViewChild, ElementRef, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ChangeEvent, CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { TranslocoService } from '@ngneat/transloco';
// import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as ClassicEditor from './ckeditor/build/ckeditor';
// import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';

// import '@ckeditor/ckeditor5-build-classic/build/translations/nl';
// import '@ckeditor/ckeditor5-build-classic/build/translations/fr';
import './ckeditor/build/translations/de';
import './ckeditor/build/translations/fr';
import './ckeditor/build/translations/nl';

const CUSTOM_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RichTextEditorComponent),
  multi: true,
};

@Component({
  selector: 'app-rich-text-editor',
  providers: [CUSTOM_VALUE_ACCESSOR],
  template: `
    <ckeditor
      #editor
      [editor]="Editor"
      [data]="content"
      [config]="config"
      [disabled]="disabled"
      (ready)="onEditorReady($event)"
      (change)="onEditorChange($event)"
    ></ckeditor>
  `,
  styleUrls: ['./rich-text-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RichTextEditorComponent implements ControlValueAccessor, OnInit, OnDestroy {
  public Editor = ClassicEditor;
  public disabled: boolean;
  public config: any;

  @ViewChild('editor', { static: false }) editorComponent: CKEditorComponent;
  private hostElement;
  private onChange: (_: any) => void;
  private onTouched: () => void;
  content: string;

  constructor(private elRef: ElementRef, translactionService: TranslocoService) {
    this.hostElement = this.elRef.nativeElement;
    this.onChange = (_: any) => {};
    this.onTouched = () => {};
    const language = translactionService.getActiveLang();
    this.config = {
      extraPlugins: [MyUploadAdapterPlugin],
      // removePlugins: ['MediaEmbed'],
      language: language || 'en',
      toolbar: {
        items: [
          'heading',
          '|',
          'bold',
          'italic',
          'subscript',
          'superscript',
          'underline',
          'link',
          'alignment',
          '|',
          'bulletedList',
          'numberedList',
          'indent',
          'outdent',
          '|',
          'imageUpload',
          'blockQuote',
          'insertTable',
          'undo',
          'redo',
        ],
      },
      image: {
        // resizeUnit: 'px',

        // Configure the available styles.
        styles: ['alignLeft', 'alignCenter', 'alignRight'],
        resizeOptions: [
          {
            name: 'imageResize:original',
            label: 'Original',
            value: null,
            icon: 'original',
          },
          {
            name: 'imageResize:25',
            label: '25%',
            value: '25',
            icon: 'small',
          },
          {
            name: 'imageResize:50',
            label: '50%',
            value: '50',
            icon: 'medium',
          },
          {
            name: 'imageResize:75',
            label: '75%',
            value: '75',
            icon: 'large',
          },
        ],
        toolbar: [
          'imageStyle:alignLeft',
          'imageStyle:alignCenter',
          'imageStyle:alignRight',
          '|',
          'imageResize:25',
          'imageResize:50',
          'imageResize:75',
          'imageResize:original',
        ],
      },
    };
  }

  ngOnInit(): void {}

  ngOnDestroy() {}

  onEditorReady(editor) {
    // const width = this.hostElement.offsetWidth;
    // const height = this.hostElement.offsetHeight;
    // editor.ui.view.editable.element.style.height = `${height}px`;
  }

  onEditorChange({ editor }: ChangeEvent) {
    this.content = editor?.getData() || this.content;
    this.onChange(this.content);
    this.onTouched();
  }

  clear() {
    this.content = '';
    this.onChange(this.content);
    this.onTouched();
  }

  writeValue(obj: string): void {
    this.content = obj;
    if (this.editorComponent?.editorInstance) {
      this.editorComponent.editorInstance.setData(obj || '');
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}

export function MyUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader);
  };
}

export class MyUploadAdapter {
  private reader: any;
  constructor(private loader: any) {}

  upload() {
    return new Promise((resolve, reject) => {
      const reader = (this.reader = new window.FileReader());

      reader.addEventListener('load', () => {
        resolve({ default: reader.result });
      });

      reader.addEventListener('error', (err) => {
        reject(err);
      });

      reader.addEventListener('abort', () => {
        reject();
      });

      this.loader.file.then((file) => {
        reader.readAsDataURL(file);
      });
    });
  }

  abort() {
    this.reader.abort();
  }
}
