import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EditorGeneratorFacade, DataStoreFacade, UIFacade } from '../../../../facades';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-editor-generator',
  templateUrl: './editor-generator.component.html',
  styleUrls: ['./editor-generator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorGeneratorComponent {
  public form: FormGroup;
  public isRequesting = false;
  public options$ = combineLatest([
    this.dataStoreFacade.groups$,
    this.dataStoreFacade.tagGroups$,
    this.dataStoreFacade.tags$,
    this.dataStoreFacade.spaceTypes$,
    this.dataStoreFacade.workPlaceTypes$,
    this.editorFacade.isReadOnly$,
  ]).pipe(
    map(([groups, tagGroups, tags, spaceTypes, workPlaceTypes, isReadOnly]) => {
      return {
        groups,
        tagGroups,
        tags,
        spaceTypes,
        workPlaceTypes,
        readOnly: isReadOnly,
      };
    })
  );

  constructor(
    public editorFacade: EditorGeneratorFacade,
    private fb: FormBuilder,
    private dataStoreFacade: DataStoreFacade,
    public uiFacade: UIFacade
  ) {
    this.form = this.fb.group({});
  }
  onSubmit() {
    this.isRequesting = true;
    if (!this.form.invalid) {
      this.uiFacade.showLoading();
      this.editorFacade.save().subscribe(
        (result) => {
          if (result.success) {
            // SAVE SUCESS
            this.form.markAsUntouched();
            this.form.markAsPristine();
            this.isRequesting = false;
            this.uiFacade.hideLoading();
          }
        },
        (err) => {
          this.uiFacade.hideLoading();
        }
      );
    }
  }
  onClose() {
    this.editorFacade.close();
  }
}
