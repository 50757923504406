import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  Input,
  OnChanges,
  SimpleChanges,
  ContentChild,
  TemplateRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { deepEquals } from '../../functions/deep-equals';

@Component({
  selector: 'app-lookup',
  templateUrl: './lookup.component.html',
  styleUrls: ['./lookup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LookupComponent implements OnInit, OnDestroy {
  @Input() public title: string;
  @Input() public isMultiple: boolean = false;
  @Input() public selectedItem: any;
  @Input() public selectedItems: any;
  @Input() public items: any[];
  @Input() public showFooter: boolean = true;
  // tslint:disable-next-line:no-output-native
  @Output() select: EventEmitter<any> = new EventEmitter(null);
  // tslint:disable-next-line:no-output-native
  @Output() close: EventEmitter<any> = new EventEmitter(null);
  @Output() updateSelectedItem: EventEmitter<any> = new EventEmitter(null);
  @Output() updateSelectedItems: EventEmitter<any> = new EventEmitter(null);
  @ContentChild(TemplateRef) templateVariable: TemplateRef<any>;
  private preventSimpleClick: boolean;

  constructor() {}

  ngOnInit() {}
  ngOnDestroy() {}

  hasSelectedItems(item: any): boolean {
    return !!this.selectedItems?.find((s) => s === item);
  }

  singleClick(item: any): void {
    this.preventSimpleClick = false;
    setTimeout(() => {
      if (!this.preventSimpleClick) {
        this.onSelectItem(item);
      }
    }, 200);
  }

  doubleClick(item: any) {
    if (!this.isMultiple) {
      this.preventSimpleClick = true;
      if (!deepEquals(this.selectedItem, item)) {
        this.onSelectItem(item);
      }

      if (item) {
        setTimeout(() => {
          this.onSelect();
        }, 100);
      }
    }
  }

  selectAll() {
    this.updateSelectedItems.emit([...this.items]);
  }
  selectNone() {
    this.updateSelectedItems.emit([]);
  }

  onSelectItem(item: any) {
    if (!this.isMultiple) {
      this.updateSelectedItem.emit(item);
    } else {
      const hasItem = !!this.selectedItems?.find((s) => s === item);
      let newSelectedItems = [];
      if (hasItem) {
        newSelectedItems = this.selectedItems.filter((f) => f !== item);
      } else {
        newSelectedItems = [...(this.selectedItems || []), item];
      }
      this.updateSelectedItems.emit(newSelectedItems);
    }
  }

  onSelect() {
    this.select.emit(null);
  }

  onClose() {
    this.close.emit(null);
  }
}
