<ng-container *transloco="let t; read: 'spaceplan'">
  <div id="editor-form-top" *ngIf="form" [formGroup]="form">
    <div class="form-group form-row mb-1">
      <label for="userName" class="col-sm-12 col-form-label">{{ t('editorUserForm.userName.label') }}:</label>
      <div class="col-sm-12">
        <div class="input-group mb-1">
          <input type="text" class="form-control" id="userName" name="userName" formControlName="userName" />
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-1">
      <label for="roleNo" class="col-sm-12 col-form-label">{{ t('editorUserForm.roleNo.label') }}:</label>
      <div class="col-sm-12">
        <div class="input-group">
          <select
            class="custom-select"
            id="roleNo"
            name="roleNo"
            formControlName="roleNo"
            style="border-radius: 0.25rem"
          >
            <option *ngFor="let role of userRoles" [ngValue]="role.roleNo">{{ role.name }}</option>
          </select>
        </div>
        <div *ngIf="isRequesting && form.get('roleNo').errors" class="invalid-feedback">
          <div class="form-error" *ngIf="form.controls['roleNo'].errors.required">
            {{ t('editorUserForm.roleNo.error.required') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
