<ng-container *transloco="let t">

  <div>
    <button class="btn button-help no-shadow" (click)="onHelpClicked()">
      <span><i class="fontello icon-icon-quick-help"></i></span>
    </button>
  </div>
  <div class="mr-3">
    <kendo-menu [openOnClick]="{ toggle: 'click' }">
      <ng-container *ngIf="displayName$ | async as displayName">
        <kendo-menu-item [text]="displayName">

          <!-- <ng-container *ngIf="partnerFilterFacade.partners$ | async as partners">
            <ng-container *ngIf="partners.length > 1">
              <kendo-menu-item>
                <ng-template kendoMenuItemLinkTemplate let-item="item" let-index="index">
                  <a [kendoMenuItemLink]="index" class="nav-dropdown-item" [href]="reworcWebsiteUrl + '/switch-team'">
                    {{ t('root.Switch_Partner') }}
                  </a>
                </ng-template>
              </kendo-menu-item>
            </ng-container>
          </ng-container> -->

          <kendo-menu-item>
            <ng-template kendoMenuItemLinkTemplate let-item="item" let-index="index">
              <a [kendoMenuItemLink]="index" class="nav-dropdown-item" [href]="reworcWebsiteUrl + '/user-manager'">
                {{ t('root.User_Manager') }}
              </a>
            </ng-template>
          </kendo-menu-item>
          <kendo-menu-item>
            <ng-template kendoMenuItemLinkTemplate let-item="item" let-index="index">
              <a [kendoMenuItemLink]="index" class="nav-dropdown-item" [href]="'/signout'">
                {{ t('root.Log_Out') }}
              </a>
            </ng-template>
          </kendo-menu-item>
        </kendo-menu-item>
      </ng-container>
    </kendo-menu>
  </div>


</ng-container>
