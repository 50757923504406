import {} from '@ngneat/transloco';
import { Allocation, Building } from './api-clients.generated';

export * from './api-clients.generated';

/**
 * t(core.GeneratorInputType.FTE)
 * t(core.GeneratorInputType.Person)
 * t(core.GeneratorInputType.Workplace)
 * t(core.GeneratorInputType.FTEPresent)
 * t(core.GeneratorInputType.PersonPresent)
 * t(core.GeneratorInputType.PersonPresentGuestPresent)
 * t(core.GeneratorInputType.Number)
 * t(core.MeasurementUnit.Metric)
 * t(core.MeasurementUnit.Imperial)
 * t(core.FolderUsageType.All)
 * t(core.FolderUsageType.SpaceProgram)
 * t(core.FolderUsageType.Template)
 */

export interface Allocation2 extends Allocation {
    buildings?: Building[];
}
