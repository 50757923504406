import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of, combineLatest, BehaviorSubject, Subscription } from 'rxjs';
import {
  take,
  map,
  tap,
  distinctUntilChanged,
  shareReplay,
  delay,
  withLatestFrom,
  filter,
  bufferCount,
  debounceTime,
} from 'rxjs/operators';
import { __asyncValues } from 'tslib';
import { TranslocoService } from '@ngneat/transloco';
import { DomSanitizer } from '@angular/platform-browser';
import { DataStoreFacade } from 'src/app/spaceplan/shared/facades/data-store.facade';
import { AllocationDataStoreFacade } from '../../shared/facades/allocation-data-store.facade';
import { faXbox, faZhihu } from '@fortawesome/free-brands-svg-icons';
import { StringMapWithRename } from '@angular/compiler/src/compiler_facade_interface';
import { groupBy, groupByDeepValue } from '../../read/facades/spaceplan-read.facade';
import { webcolours } from './webcolours';
import { BuildingChartView } from '../../shared/models/building-chart-view';
import { BuildingFloorLayoutView } from '../../shared/models/building-floor-layout-view ';

@Injectable({
  providedIn: 'root',
})
export class AllocationChartFacade implements OnDestroy {
  private subscriptions: Subscription = new Subscription();
  private readonly defaultState: AllocationChartState = {
    loading: false,
    selectedGroupingLevel: 'group',
    selectedBuildingID: null,
    selectedBuildingFloorZoneID: null,
    selectedBuildingChartView: BuildingChartView.Building,
    selectedBuildingFloorLayoutView: null,
  };
  private readonly stateSubject = new BehaviorSubject<AllocationChartState>({ ...this.defaultState });
  readonly state$ = this.stateSubject.asObservable();
  readonly loading$ = this.state$.pipe(map((m) => m.loading));
  readonly selectedGroupingLevel$ = this.state$.pipe(map((m) => m.selectedGroupingLevel)).pipe(distinctUntilChanged());
  readonly selectedBuildingID$ = this.state$.pipe(map((m) => m.selectedBuildingID)).pipe(distinctUntilChanged());
  readonly selectedBuildingFloorZoneID$ = this.state$.pipe(map((m) => m.selectedBuildingFloorZoneID)).pipe(distinctUntilChanged());
  readonly selectedBuildingChartView$ = this.state$.pipe(map((m) => m.selectedBuildingChartView)).pipe(distinctUntilChanged());
  readonly selectedBuildingFloorLayoutView$ = this.state$.pipe(map((m) => m.selectedBuildingFloorLayoutView)).pipe(distinctUntilChanged());

  private emptyColourCode = '#FFD823';

  constructor(
    private dataStoreFacade: DataStoreFacade,
    private allocationDataStoreFacade: AllocationDataStoreFacade,
    private translactionService: TranslocoService,
    private sanitized: DomSanitizer
  ) {
    // this.subscriptions.add(
    //   allocationDataStoreFacade.allocation$
    //     .pipe(
    //       bufferCount(2, 1),
    //       filter(([a, b]) => b?.allocationID !== a?.allocationID),
    //       map(([a, b]) => b)
    //     )
    //     .subscribe(() => {
    //       this.resetToDefault();
    //     })
    // );
  }

  public readonly building$ = combineLatest([this.selectedBuildingID$, this.allocationDataStoreFacade.allocation$]).pipe(
    map(([selectedBuildingID, allocation]) => {
      if (selectedBuildingID) {
        return allocation.buildings.find((f) => f.buildingID === selectedBuildingID);
      } else {
        const tmpBuildingID = allocation.buildings[0].buildingID;
        this.setSelectedBuildingID(tmpBuildingID);
        return allocation.buildings.find((f) => f.buildingID === tmpBuildingID);
      }
    })
  );
  public readonly buildingFloorsZones$ = combineLatest([
    this.allocationDataStoreFacade.buildingFloorZones$,
    this.allocationDataStoreFacade.buildingFloors$,
    this.allocationDataStoreFacade.buildingZones$,
  ]).pipe(
    map(([buildingFloorZones, buildingFloors, buildingZones]) => {
      return buildingFloorZones
        .map((fz) => {
          return {
            buildingFloorZoneID: fz.buildingFloorZoneID,
            floor: buildingFloors?.find((f) => f.buildingFloorID === fz.buildingFloorID),
            zone: buildingZones?.find((f) => !!fz.buildingZoneID && f.buildingZoneID === fz.buildingZoneID),
            availableArea: fz.availableArea,
          };
        })
        .sort(
          (a, b) => b.floor.displaySequence - a.floor.displaySequence || (b.zone?.displaySequence || 0) - (a.zone?.displaySequence || 0)
        );
    })
  );

  public readonly chartAllocationData$ = this.allocationDataStoreFacade.allocationRules$.pipe(
    withLatestFrom(
      this.dataStoreFacade.spaceTypes$,
      this.dataStoreFacade.generators$,
      this.dataStoreFacade.groups$,
      this.dataStoreFacade.tags$
    ),
    map(([allocationRules, spaceTypes, generators, groups, tags]) => {
      const allocations = allocationRules.map((ar) => {
        const generator = generators.find((f) => f.generatorID === ar.generatorID);
        const spaceType = spaceTypes.find((f) => f.spaceTypeID === generator?.spaceTypeID);
        const group = groups.find((f) => ar.groupID && f.groupID === ar.groupID);
        const generatortags = generator.tags
          ?.map((gt) => tags.find((f) => f.tagID === gt))
          .filter((f) => !!f)
          .reduce((v, i) => {
            v[i.tagGroupID] = i.tagID;
            return v;
          }, {});
        const allocatedAreaFloorZone = (ar.overrideQty ?? ar.allocatedQty) * spaceType.area;
        return {
          ...ar,
          ...generatortags,
          allocatedQty: ar.overrideQty ?? ar.allocatedQty ?? 0,
          allocatedArea: allocatedAreaFloorZone,
        };
      });
      return allocations;
    })
  );

  public readonly chartData$ = combineLatest([
    this.building$,
    this.buildingFloorsZones$,
    this.chartAllocationData$,
    this.selectedGroupingLevel$,
  ]).pipe(
    withLatestFrom(
      this.dataStoreFacade.spaceProgram$,
      this.dataStoreFacade.spaceTypes$,
      this.dataStoreFacade.generators$,
      this.dataStoreFacade.groups$,
      this.dataStoreFacade.tags$
    ),
    map(
      ([
        [building, buildingFloorsZonesAll, chartAllocations, selectedGroupingLevel],
        spaceProgram,
        spaceTypes,
        generators,
        groups,
        tags,
      ]) => {
        const buildingFloorsZones = buildingFloorsZonesAll.filter((f) => building && f.floor.buildingID === building?.buildingID);

        const allocatedPlaceholder = Array.apply(null, new Array(buildingFloorsZones.length));

        const floors = buildingFloorsZones.map((fz, fzi) => {
          let allocations: AllocationChartValueData[] = [];
          const preallocations = chartAllocations.filter((f) => f.buildingFloorZoneID === fz.buildingFloorZoneID);
          if (preallocations?.length) {
            if (selectedGroupingLevel === 'group') {
              const ungroupedName = this.translactionService.translate('spaceplan.chart.NotGrouped');
              const ungroupedColourCode = spaceProgram.colourCodeOtherGroup || '#edf2f6';

              const sortedGroups = groups.sort((a, b) => a.groupName.localeCompare(b.groupName));

              allocations = Object.entries(groupBy(preallocations, 'groupID')).map(([id, items]) => {
                const groupIndex = sortedGroups.findIndex((f) => f.groupID === id);
                const group = groups.find((f) => f.groupID === id);
                const allocatedArea = (items as any[]).reduce((v, i) => {
                  v = v + i.allocatedArea;
                  return v;
                }, 0);
                const allocated = [...allocatedPlaceholder];
                allocated[fzi] = Math.round(allocatedArea);

                return {
                  id,
                  name: group ? group.groupName : ungroupedName,
                  colourCode: group ? group.colourCode || this.emptyColourCode : ungroupedColourCode,
                  displaySequence: groupIndex,
                  allocatedArea,
                  allocated,
                };
              });
            } else if (selectedGroupingLevel === 'spacetype') {
              const sortedSpaceTypes = spaceTypes.sort((a, b) => a.spaceTypeName.localeCompare(b.spaceTypeName));

              allocations = Object.entries(groupBy(preallocations, 'spaceTypeID')).map(([id, items]) => {
                const spaceTypeIndex = sortedSpaceTypes.findIndex((f) => f.spaceTypeID === id);
                const spaceType = spaceTypes.find((f) => f.spaceTypeID === id);
                const allocatedQty = (items as any[]).reduce((v, i) => {
                  v = v + i.allocatedQty;
                  return v;
                }, 0);
                const allocatedArea = (items as any[]).reduce((v, i) => {
                  v = v + i.allocatedArea;
                  return v;
                }, 0);
                const allocated = [...allocatedPlaceholder];
                allocated[fzi] = Math.round(allocatedArea);

                return {
                  id,
                  name: spaceType?.spaceTypeName,
                  colourCode: spaceType?.colourCode || this.emptyColourCode,
                  displaySequence: spaceTypeIndex,
                  area: spaceType?.area,
                  allocatedQty,
                  allocatedArea,
                  allocated,
                };
              });
            } else {
              const untaggedName = this.translactionService.translate('spaceplan.chart.NotTagged');
              const untaggedColourCode = spaceProgram.colourCodeOtherTag || '#edf2f6';

              // tag group
              allocations = Object.entries(groupBy(preallocations, selectedGroupingLevel)).map(([id, items]) => {
                const tag = tags.find((f) => f.tagID === id);
                const allocatedArea = (items as any[]).reduce((v, i) => {
                  v = v + i.allocatedArea;
                  return v;
                }, 0);
                const allocated = [...allocatedPlaceholder];
                allocated[fzi] = Math.round(allocatedArea);

                return {
                  id,
                  name: tag?.tagName || untaggedName,
                  colourCode: (tag && (tag.colourCode || this.emptyColourCode)) || untaggedColourCode,
                  displaySequence: tag?.displaySequence || -1,
                  allocatedArea,
                  allocated,
                };
              });
            }
          }

          const allocatedAreaTotal = allocations.reduce((v, i) => v + i.allocatedArea, 0);
          let remainingArea = Math.round(fz.availableArea - allocatedAreaTotal);
          if (remainingArea < 0) {
            remainingArea = 0;
          }

          return {
            id: fz.buildingFloorZoneID,
            name: !fz.zone ? fz.floor.floorName : fz.floor.floorName + ' ' + fz.zone.zoneName,
            colourCode: spaceProgram.colourCodeUnallocated || '',
            availableArea: fz.availableArea,
            allocatedArea: allocatedAreaTotal,
            remainingArea,
            allocations,
          };
        });

        const legendItems = Object.values(
          []
            .concat(
              ...floors.map((m) =>
                m.allocations.map((ma) => ({
                  id: ma.id,
                  name: ma.name,
                  colourCode: ma.colourCode,
                  displaySequence: ma.displaySequence,
                  allocatedArea: ma.allocatedArea,
                  reference: ma.id,
                }))
              )
            )
            .reduce((v: any, i: any) => {
              if (!v[i.id]) {
                v[i.id] = {
                  ...i,
                };
              } else {
                v[i.id].allocatedArea += i.allocatedArea;
              }
              return v;
            }, {})
        ).sort((a: any, b: any) => a.displaySequence - b.displaySequence);

        legendItems.forEach((item: any) => {
          item.allocatedArea = Math.round(item.allocatedArea);
        });

        const categories = floors.map((m) => m.name);
        const series = [].concat(
          ...floors.map((m) =>
            m.allocations
              .map((ma) => ({
                name: ma.name,
                colourCode: ma.colourCode,
                displaySequence: ma.displaySequence,
                data: ma.allocated.map((m) => {
                  return {
                    value: m,
                    reference: ma.id,
                  };
                }),
              }))
              .sort((a, b) => a.displaySequence - b.displaySequence)
          )
        );

        const seriesFiller = floors.map((m) => {
          return {
            value: m.remainingArea,
            reference: 'unallocated',
          };
        });
        const seriesFillerTotal = Math.round(
          seriesFiller.reduce((v, i) => {
            return v + i.value;
          }, 0)
        );

        return {
          data: floors,
          seriesPlaceholder: allocatedPlaceholder,
          seriesFiller,
          seriesFillerTotal,
          seriesFillerColourCode: spaceProgram.colourCodeUnallocated || '#EEE',
          series,
          legendItems,
          categories,
          measurementUnit: spaceProgram.measurementUnit,
        };
      }
    )
  );

  public readonly floorChartData$ = combineLatest([
    this.building$,
    this.selectedBuildingFloorZoneID$,
    this.buildingFloorsZones$,
    this.chartAllocationData$,
    this.selectedGroupingLevel$,
  ]).pipe(
    withLatestFrom(
      this.dataStoreFacade.spaceProgram$,
      this.dataStoreFacade.spaceTypes$,
      this.dataStoreFacade.generators$,
      this.dataStoreFacade.groups$,
      this.dataStoreFacade.tags$
    ),
    map(
      ([
        [building, selectedBuildingFloorZoneID, buildingFloorsZonesAll, chartAllocations, selectedGroupingLevel],
        spaceProgram,
        spaceTypes,
        generators,
        groups,
        tags,
      ]) => {
        const fz = buildingFloorsZonesAll.find(
          (f) => building && f.floor.buildingID === building?.buildingID && f.buildingFloorZoneID === selectedBuildingFloorZoneID
        );

        if (!fz) {
          return null;
        } else {
          let allocations: AllocationChartValueData[] = [];
          const preallocations = chartAllocations.filter((f) => f.buildingFloorZoneID === fz.buildingFloorZoneID);
          if (preallocations?.length) {
            if (selectedGroupingLevel === 'group') {
              const ungroupedName = this.translactionService.translate('spaceplan.chart.NotGrouped');
              const ungroupedColourCode = spaceProgram.colourCodeOtherGroup || '#edf2f6';

              const sortedGroups = groups.sort((a, b) => a.groupName.localeCompare(b.groupName));

              allocations = Object.entries(groupBy(preallocations, 'groupID')).map(([id, items]) => {
                const groupIndex = sortedGroups.findIndex((f) => f.groupID === id);
                const group = groups.find((f) => f.groupID === id);
                const allocatedArea = (items as any[]).reduce((v, i) => {
                  v = v + i.allocatedArea;
                  return v;
                }, 0);

                return {
                  id,
                  name: group ? group.groupName : ungroupedName,
                  colourCode: group ? group.colourCode || this.emptyColourCode : ungroupedColourCode,
                  displaySequence: groupIndex,
                  allocatedArea,
                };
              });
            } else if (selectedGroupingLevel === 'spacetype') {
              const sortedSpaceTypes = spaceTypes.sort((a, b) => a.spaceTypeName.localeCompare(b.spaceTypeName));

              allocations = Object.entries(groupBy(preallocations, 'spaceTypeID')).map(([id, items]) => {
                const spaceTypeIndex = sortedSpaceTypes.findIndex((f) => f.spaceTypeID === id);
                const spaceType = spaceTypes.find((f) => f.spaceTypeID === id);
                const allocatedQty = (items as any[]).reduce((v, i) => {
                  v = v + i.allocatedQty;
                  return v;
                }, 0);
                const allocatedArea = (items as any[]).reduce((v, i) => {
                  v = v + i.allocatedArea;
                  return v;
                }, 0);

                return {
                  id,
                  name: spaceType?.spaceTypeName,
                  colourCode: spaceType?.colourCode || this.emptyColourCode,
                  displaySequence: spaceTypeIndex,
                  area: spaceType?.area,
                  allocatedQty,
                  allocatedArea,
                };
              });
            } else {
              const untaggedName = this.translactionService.translate('spaceplan.chart.NotTagged');
              const untaggedColourCode = spaceProgram.colourCodeOtherTag || '#edf2f6';

              // tag group
              allocations = Object.entries(groupBy(preallocations, selectedGroupingLevel)).map(([id, items]) => {
                const tag = tags.find((f) => f.tagID === id);
                const allocatedArea = (items as any[]).reduce((v, i) => {
                  v = v + i.allocatedArea;
                  return v;
                }, 0);

                return {
                  id,
                  name: tag?.tagName || untaggedName,
                  colourCode: (tag && (tag.colourCode || this.emptyColourCode)) || untaggedColourCode,
                  displaySequence: tag?.displaySequence || -1,
                  allocatedArea,
                };
              });
            }
          }

          allocations = allocations.filter((f) => f.allocatedArea > 0).sort((a, b) => a.displaySequence - b.displaySequence);

          const allocatedAreaTotal = Math.round(allocations.reduce((v, i) => v + i.allocatedArea, 0));
          const remainingArea = Math.round(fz.availableArea - allocatedAreaTotal);

          const categories = allocations.map((m) => m.name);

          return {
            data: allocations,
            categories,
            measurementUnit: spaceProgram.measurementUnit,
            availableArea: fz.availableArea,
            allocatedArea: allocatedAreaTotal,
            remainingArea,
          };
        }
      }
    )
  );

  setSelectedGroupingLevel(selectedGroupingLevel: string) {
    this.stateSubject.next({ ...this.stateSubject.value, selectedGroupingLevel });
  }

  setSelectedBuildingID(selectedBuildingID: string) {
    this.stateSubject.next({ ...this.stateSubject.value, selectedBuildingID });
  }

  setSelectedBuildingFloorZoneID(selectedBuildingFloorZoneID: string) {
    this.stateSubject.next({ ...this.stateSubject.value, selectedBuildingFloorZoneID });
  }

  removeSelectedBuildingFloorZoneID() {
    this.stateSubject.next({ ...this.stateSubject.value, selectedBuildingFloorZoneID: null });
  }

  setSelectedBuildingChartView(selectedBuildingChartView: BuildingChartView) {
    const newState = { ...this.stateSubject.value, selectedBuildingChartView };
    if (!newState.selectedBuildingFloorLayoutView) {
      newState.selectedBuildingFloorLayoutView = BuildingFloorLayoutView.Floor;
    }

    this.stateSubject.next({ ...this.stateSubject.value, ...newState });
  }

  removeSelectedBuildingChartView() {
    this.stateSubject.next({ ...this.stateSubject.value, selectedBuildingChartView: null });
  }

  setSelectedBuildingFloorLayoutView(selectedBuildingFloorLayoutView: BuildingFloorLayoutView) {
    this.stateSubject.next({ ...this.stateSubject.value, selectedBuildingFloorLayoutView });
  }

  removeSelectedBuildingFloorLayoutView() {
    this.stateSubject.next({ ...this.stateSubject.value, selectedBuildingFloorLayoutView: null });
  }

  resetToDefault() {
    this.stateSubject.next({ ...this.defaultState });
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

export interface AllocationChartState {
  loading: boolean;
  selectedGroupingLevel: string;
  selectedBuildingID: string;
  selectedBuildingFloorZoneID: string;
  selectedBuildingChartView: BuildingChartView;
  selectedBuildingFloorLayoutView: BuildingFloorLayoutView;
}

export interface AllocationChartCategoryData {
  id: string;
  name: string;
  colourCode: string;
  availableArea: number; // total m2 size of floor   5000
  allocatedArea: number; // total allocated m2 of floor  3000
  remainingArea: number; // remaining available m2 size of floor  5000 - 3000 = 2000

  allocations: AllocationChartValueData[];
}

export interface AllocationChartValueData {
  id: string;
  name: string;
  colourCode: string;
  displaySequence?: number;
  allocatedArea: number;
  allocated?: number[];
}
