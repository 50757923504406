import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UpdateTemplateSettingsDto } from 'src/app/core/services/api-clients';

import { TranslocoService } from '@ngneat/transloco';
import { bufferCount, filter, map } from 'rxjs/operators';
import { EditorFacadeBase, UIFacade, UserFacade } from 'src/app/spaceplan/shared/facades';
import { TemplateDataStoreFacade } from './template-data-store.facade';

/**
 * t(spaceplan.SpaceProgramSettings)
 */
@Injectable({
  providedIn: 'root',
})
export class EditorTemplateSettingsFacade extends EditorFacadeBase<UpdateTemplateSettingsDto> {
  constructor(
    protected toastr: ToastrService,
    protected modalService: BsModalService,
    protected uiFacade: UIFacade,
    protected translocoService: TranslocoService,
    private dataStoreFacade: TemplateDataStoreFacade,
    private userFacade: UserFacade
  ) {
    super(toastr, modalService, uiFacade, translocoService);
    this.dataStoreFacade.template$
      .pipe(
        bufferCount(2, 1),
        filter(([a, b]) => b?.templateID !== a?.templateID),
        map(([a, b]) => b)
      )
      .subscribe(() => {
        this.resetToDefault();
      });
  }
  protected className(): string {
    return this.translocoService.translate('spaceplan.SpaceProgramSettings');
  }
  protected getFromDataStore(id: string): Observable<UpdateTemplateSettingsDto> {
    return this.dataStoreFacade.getTemplateSettings(id);
  }
  protected updateDataStore(model: UpdateTemplateSettingsDto): Observable<UpdateTemplateSettingsDto> {
    return this.dataStoreFacade.updateTemplateSettings(model);
  }
  protected getModelId(model: UpdateTemplateSettingsDto): string {
    return model?.templateID;
  }
  protected userCanEdit(): Observable<boolean> {
    // return this.dataStoreFacade.template$.pipe(map((m) => m.roleNo === UserRole.Composer || m.roleNo === UserRole.Editor));
    return of(true);
  }
}
