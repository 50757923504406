<ng-container *transloco="let t">

  <div class="navigation-button">
    <a class="btn no-shadow" [href]="reworcWebSiteUrl" (click)="onNavItem()"
      (mouseover)="onMouseOver($event, t('root.LeftNav_Portal'))" (mouseleave)="onMouseLeave($event)">
      <i class="fontello icon-icon-home"></i>
      <div class="name">{{ t('root.LeftNav_Portal') }}</div>
    </a>
  </div>


  <div class="navigation-button">
    <a class="btn no-shadow" [href]="workNavigatorWebSiteUrl" (click)="onNavItem()"
      (mouseover)="onMouseOver($event, t('root.LeftNav_WorkNavigator'))" (mouseleave)="onMouseLeave($event)">
      <i class="fontello icon-icon-wnav"></i>
      <div class="name">{{ t('root.LeftNav_WorkNavigator') }}</div>
    </a>
  </div>

  <div class="navigation-button active">
    <a class="btn no-shadow" href="/" (click)="onNavItem()"
      (mouseover)="onMouseOver($event, t('root.LeftNav_SpacePro'))" (mouseleave)="onMouseLeave($event)">
      <i class="fontello icon-icon-sp"></i>
      <div class="name">{{ t('root.LeftNav_SpacePro') }}</div>
    </a>
  </div>

  <div class="navigation-button">
    <a class="btn no-shadow" [href]="reworcWebSiteUrl + '/academy'" (click)="onNavItem()"
      (mouseover)="onMouseOver($event, t('root.LeftNav_Academy'))" (mouseleave)="onMouseLeave($event)">
      <i class="fontello icon-icon-academy"></i>
      <div class="name">{{ t('root.LeftNav_Academy') }}</div>
    </a>
  </div>

  <div #navtooltip class="nav-tooltip"></div>
</ng-container>
