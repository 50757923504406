<ng-container *transloco="let t; read: 'spaceplan'">
  <div id="editor-form-top" *ngIf="form" [formGroup]="form">
    <div class="form-group form-row mb-1">
      <label for="allocationName" class="col-sm-12 col-form-label">{{
        t('editorAllocationForm.allocationName.label') }}:</label>
      <div class="col-sm-12">
        <div class="input-group">
          <input type="text" class="form-control" id="name"
            placeholder="{{ t('editorAllocationForm.allocationName.placeholder') }}" name="allocationName"
            formControlName="allocationName" />
        </div>
        <div *ngIf="isRequesting && form.get('allocationName').errors" class="invalid-feedback">
          <div class="form-error" *ngIf="form.controls['allocationName'].errors.required">
            {{ t('editorAllocationForm.allocationName.error.required') }}
          </div>
          <div class="form-error" *ngIf="form.controls['allocationName'].errors.duplicate">
            {{ t('editorAllocationForm.allocationName.error.duplicate') }}
          </div>
        </div>
      </div>
    </div>



    <div class="form-group form-row mb-1">
      <label for="spaceTypeDescr" class="col-sm-12 col-form-label">{{ t('editorAllocationForm.allocationDescr.label')
        }}:</label>
      <div class="col-sm-12">
        <div class="input-group mb-1">
          <textarea name="allocationDescr" id="allocationDescr"
            placeholder="{{ t('editorAllocationForm.allocationDescr.placeholder') }}" class="form-control"
            formControlName="allocationDescr" rows="6" style="resize: none;"></textarea>
        </div>
      </div>
    </div>

    <ng-container formGroupName="buildings" *ngIf="options.mode==='new' || options.mode==='edit'">

      <div class="form-group form-row mb-1">
        <div class="d-flex flex-column w-100">


          <label for="buildings" class="mt-2 mb-3">{{ t('editorAllocationForm.buildings.label') }}:</label>
          <div *ngIf="isRequesting && form.get('buildings').errors" class="invalid-feedback">
            <div class="form-error mb-2">{{ t('editorAllocationForm.buildings.error.required') }}</div>
          </div>

          <div class="form-group form-row mb-1" *ngFor="let building of buildings; let i = index; let cnt = count">

            <div class="custom-control custom-checkbox mb-2" *ngIf="isVisible(building)">
              <input type="checkbox" class="custom-control-input" [id]="'building' + building.buildingID"
                [attr.disabled]="building.noOfRules > 0 ? true : null" [formControlName]="building.buildingID">
              <label class="custom-control-label pt-1" [for]="'building' + building.buildingID">{{ building.buildingName
                }}</label>
            </div>


            <!-- <label [for]="'building' + building.buildingID" class="col-sm-12 col-form-label">{{ building.buildingName }}:</label>
        <div class="col-sm-12">
          <div class="input-group">
            <select
              class="custom-select"
              [id]="'tagGroup' + tagGroup.tagGroupID"
              [name]="'tagGroup' + tagGroup.tagGroupID"
              [formControlName]="tagGroup.tagGroupID"
              style="border-radius: 0.25rem;"
            >
              <option [ngValue]="''">{{ t('editorGeneratorForm.tagGroupID.option.emptyString_Please_select') }}</option>
              <ng-template ngFor let-item [ngForOf]="tagsForGroups[tagGroup.tagGroupID]">
                <option [ngValue]="item.tagID">{{ item.tagName }} </option>
              </ng-template>
            </select>
          </div>
        </div> -->

          </div>
        </div>
      </div>
    </ng-container>


    <!-- <div class="form-group form-row mb-1">
      <div class="d-flex flex-column">
        <label for="buildings" class="mt-2 mb-3">{{ t('editorAllocationForm.buildings.label') }}:</label>
        <div *ngIf="isRequesting && form.get('buildings').errors" class="invalid-feedback">
          <div class="mb-2">{{ t('editorAllocationForm.buildings.error.required') }}</div>
        </div>
        <div formArrayName="buildings" *ngFor="let building of buildings.controls; let i = index;">
          <div class="custom-control custom-checkbox mb-2" [formGroupName]="i">
            <input type="checkbox" class="custom-control-input" [id]="'customCheck'+ i"
              [formControlName]="'isSelected'">
            <label class="custom-control-label pt-1" [for]="'customCheck'+ i">{{ building.controls.buildingName.value
              }}</label>
          </div>
        </div>
      </div>

    </div> -->


    <!-- 
    <div class="form-group form-row mb-1">
      <label for="suitableFor" class="col-sm-12 col-form-label">{{ t('editorWorkplacetypeForm.suitableFor.label')
        }}:</label>
      <div class="col-sm-12">
        <div class="input-group mb-1">
          <textarea name="suitableFor" id="suitableFor"
            placeholder="{{ t('editorWorkplacetypeForm.suitableFor.placeholder') }}" class="form-control"
            formControlName="suitableFor" rows="6" style="resize: none;"></textarea>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-1">
      <div class="col-sm-12">
        <div class="form-check mt-1">
          <input class="form-check-input k-checkbox" type="checkbox" kendoCheckBox formControlName="inFlexFactorCalcInd"
            id="inFlexFactorCalcInd" placeholder="{{ t('editorWorkplacetypeForm.inFlexFactorCalcInd.placeholder') }}" />
          <label class="form-check-label" for="inFlexFactorCalcInd">{{
            t('editorWorkplacetypeForm.inFlexFactorCalcInd.label') }}</label>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-1">
      <label for="name" class="col-sm-12 col-form-label">{{ t('editorWorkplacetypeForm.imageUrl.label') }}:</label>
      <div class="col-sm-12">
        <div class="input-group mb-1">
          <app-file-upload formControlName="imageUrl"></app-file-upload>
        </div>
      </div>
    </div>


    <div class="form-group form-row mb-1">
      <label for="spaceTypeCode" class="col-sm-12 col-form-label">{{
        t('editorWorkplacetypeForm.workPlaceTypeColour.label')
        }}:</label>
      <div class="col-sm-12">
        <div class="input-group">
          <kendo-colorpicker formControlName="colourCode" [format]="'hex'"
            [gradientSettings]="uiFacade.colorPickerGradientSettings">
          </kendo-colorpicker>
        </div>
      </div>
    </div> -->


  </div>
</ng-container>