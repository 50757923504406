import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { EditorSpaceProgramPrefaceFacade, DataStoreFacade, UIFacade } from '../../../../../facades';


@Component({
  selector: 'app-editor-space-program-preface',
  templateUrl: './editor-space-program-preface.component.html',
  styleUrls: ['./editor-space-program-preface.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorSpaceProgramPrefaceComponent {
  @Input()
  public headerVisible = true;
  @Input()
  public canClose = true;
  public form: FormGroup;
  public isRequesting = false;
  public options$ = combineLatest([this.editorFacade.isReadOnly$]).pipe(
    map(([isReadOnly]) => {
      return {
        readOnly: isReadOnly,
      };
    })
  );

  constructor(
    public editorFacade: EditorSpaceProgramPrefaceFacade,
    private fb: FormBuilder,
    private dataStoreFacade: DataStoreFacade,
    public uiFacade: UIFacade
  ) {
    this.form = this.fb.group({});
  }

  onSubmit() {
    this.isRequesting = true;
    if (!this.form.invalid) {
      this.uiFacade.showLoading();
      this.editorFacade.save().subscribe(
        (result) => {
          if (result.success) {
            // SAVE SUCESS
            this.form.markAsUntouched();
            this.form.markAsPristine();
            this.isRequesting = false;
            this.uiFacade.hideLoading();
          }
        },
        (err) => {
          this.uiFacade.hideLoading();
        }
      );
    }
  }
  onClose() {
    this.editorFacade.close();
  }
}
