import { Injectable } from "@angular/core";
import { WebSiteSettings, SystemClient } from "../../core/services/api-clients"

@Injectable({
  providedIn: 'root'
})
export class WebSiteSettingsService {
  settings: WebSiteSettings;

  constructor(private systemClient: SystemClient) {}

  loadSettings() {
    // return this.http
    //   .get<Config>('./assets/config.json')
    //   .toPromise()
    //   .then(config => {
    //     this.config = config;
    //   });
    return this.systemClient.settings().toPromise().then(settings => {
      this.settings = settings;
    });
  }
}
