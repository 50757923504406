import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { take, tap } from 'rxjs/operators';
import { AccountRepository } from './state/account.repository';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanLoad, CanActivate {
  private readonly authenticated$ = this.accountRepo.isAuthenticated$;

  public constructor(private accountRepo: AccountRepository) {}

  public canLoad(
    route: Route,
    segments: UrlSegment[]
  ) {
    return this.authenticated$.pipe(
      take(1),
      tap(auth => {
        if (!auth) {
          this.accountRepo.redirectToLogin(`/${segments.join('/')}`)
        }
      })
    );
  }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    return this.authenticated$.pipe(
      take(1),
      tap(auth => {
        if (!auth) {
          this.accountRepo.redirectToLogin(state.url);
        }
      })
    );
  }
}
