<ng-container *transloco="let t; read: 'root'">
  <div class="detail">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate [logValidationErrors]="form" appFormHandleEnterKey>
      <div class="header">
        <div class="title">{{ editorFacade.title$ | async }}</div>
        <div class="actions">
          <button (click)="onClose()" type="button" class="btn btn-primary btn-sm d-flex">
            <i class="k-icon k-i-close"></i>
          </button>
        </div>
      </div>
      <div class="body">
        <div class="p-1">
          <ng-container *ngIf="editorFacade.mode$ | async as mode">
            <app-editor-user-form
              *ngIf="mode === 'view' || mode === 'edit'"
              [parentForm]="form"
              [model]="editorFacade.model$ | async"
              [options]="options$ | async"
              (updateModelWithChanges)="editorFacade.updateModelWithChanges($event)"
              [isRequesting]="isRequesting"
            >
            </app-editor-user-form>
            <app-editor-users-invite-form
              *ngIf="mode === 'invite'"
              [parentForm]="form"
              [model]="editorFacade.model$ | async"
              [options]="options$ | async"
              (updateModelWithChanges)="editorFacade.updateModelWithChanges($event)"
              [isRequesting]="isRequesting"
            >
            </app-editor-users-invite-form>
          </ng-container>
        </div>
      </div>
      <div class="footer" *ngIf="editorFacade.saveButtonIsVisible$ | async">
        <div class="buttons-wrapper">
          <button
            [disabled]="!(editorFacade.isDirty$ | async)"
            [ngClass]="{ disabled: !(editorFacade.isDirty$ | async) }"
            class="btn btn-primary"
            type="submit"
            style="min-width: 150px"
          >
            {{ (editorFacade.saveButtonTitle$ | async) || t('editor.button.save') }}
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-container>
