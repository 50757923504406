<ng-container *transloco="let t; read: 'spaceplan'">
  <div id="editor-form-top" *ngIf="form" [formGroup]="form">
    <div class="form-group form-row mb-1">
      <label for="workPlaceTypeName" class="col-sm-12 col-form-label">{{
        t('editorWorkplacetypeForm.workPlaceTypeName.label') }}:</label>
      <div class="col-sm-12">
        <div class="input-group">
          <input type="text" class="form-control" id="name"
            placeholder="{{ t('editorWorkplacetypeForm.workPlaceTypeName.placeholder') }}" name="workPlaceTypeName"
            formControlName="workPlaceTypeName" />
        </div>
        <div *ngIf="isRequesting && form.get('workPlaceTypeName').errors" class="invalid-feedback">
          <div class="form-error" *ngIf="form.controls['workPlaceTypeName'].errors.required">
            {{ t('editorWorkplacetypeForm.workPlaceTypeName.error.required') }}
          </div>
          <div class="form-error" *ngIf="form.controls['workPlaceTypeName'].errors.duplicate">
            {{ t('editorWorkplacetypeForm.workPlaceTypeName.error.duplicate') }}
          </div>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-1">
      <label for="suitableFor" class="col-sm-12 col-form-label">{{ t('editorWorkplacetypeForm.suitableFor.label')
        }}:</label>
      <div class="col-sm-12">
        <div class="input-group mb-1">
          <textarea name="suitableFor" id="suitableFor"
            placeholder="{{ t('editorWorkplacetypeForm.suitableFor.placeholder') }}" class="form-control"
            formControlName="suitableFor" rows="6" style="resize: none;"></textarea>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-1">
      <div class="col-sm-12">
        <div class="form-check mt-1">
          <input class="form-check-input k-checkbox" type="checkbox" kendoCheckBox formControlName="inFlexFactorCalcInd"
            id="inFlexFactorCalcInd" placeholder="{{ t('editorWorkplacetypeForm.inFlexFactorCalcInd.placeholder') }}" />
          <label class="form-check-label" for="inFlexFactorCalcInd">{{
            t('editorWorkplacetypeForm.inFlexFactorCalcInd.label') }}</label>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-1">
      <label for="name" class="col-sm-12 col-form-label">{{ t('editorWorkplacetypeForm.imageUrl.label') }}:</label>
      <div class="col-sm-12">
        <div class="input-group mb-1">
          <app-file-upload formControlName="imageUrl"></app-file-upload>
        </div>
      </div>
    </div>


    <div class="form-group form-row mb-1">
      <label for="spaceTypeCode" class="col-sm-12 col-form-label">{{
        t('editorWorkplacetypeForm.workPlaceTypeColour.label')
        }}:</label>
      <div class="col-sm-12">
        <div class="input-group">
          <kendo-colorpicker formControlName="colourCode" [format]="'hex'"
            [gradientSettings]="uiFacade.colorPickerGradientSettings">
          </kendo-colorpicker>
        </div>
      </div>
    </div>


  </div>
</ng-container>