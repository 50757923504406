import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable, of, combineLatest } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UIFacade } from './ui.facade';
import { take, map, distinctUntilChanged, delay } from 'rxjs/operators';
import { deepEquals } from '../functions/deep-equals';

@Injectable({
  providedIn: 'root',
})
export class LookupFacadeManager {
  //   private readonly onOpenSubject = new Subject<any>();
  //   public readonly onOpen$ = this.onOpenSubject.asObservable();
  //   private readonly onCloseSubject = new Subject<any>();
  //   public readonly onClose$ = this.onCloseSubject.asObservable();

  private currentActiceLookups: ICancellable[] = [];

  open(instance: ICancellable) {
    this.closeAllExceptCurrent(instance);
    this.currentActiceLookups.push(instance);
  }
  close(instance: ICancellable) {
    this.currentActiceLookups = this.currentActiceLookups.filter((f) => f === instance);
  }
  closeAll() {
    this.currentActiceLookups.forEach((f) => {
      f.cancel();
    });
  }
  private closeAllExceptCurrent(instance: ICancellable) {
    const acticeLookups = this.currentActiceLookups.filter((f) => f !== instance);
    acticeLookups.forEach((f) => {
      f.cancel();
    });
  }
}

@Injectable({
  providedIn: 'root',
})
export class LookupFacadeBase<T> {
  private readonly defaultState: LookupFacadeState<T> = {
    isOpen: false,
    isBusy: false,
    items: [],
    selectedItem: null,
    selectedItems: [],
  };

  private readonly stateSubject = new BehaviorSubject<LookupFacadeState<T>>(this.defaultState);
  private readonly onSelectSubject = new Subject<T>();
  private readonly onSelectManySubject = new Subject<T[]>();
  private readonly onCloseSubject = new Subject<boolean>();
  protected readonly editorFacade = this;
  public readonly state$ = this.stateSubject.asObservable();
  public readonly onSelect$ = this.onSelectSubject.asObservable();
  public readonly onSelectMany$ = this.onSelectManySubject.asObservable();
  public readonly onClose$ = this.onCloseSubject.asObservable();
  public get state(): LookupFacadeState<T> {
    return this.stateSubject.getValue();
  }
  public readonly isOpen$ = this.state$.pipe(
    map((m) => m.isOpen),
    distinctUntilChanged()
  );
  public readonly isBusy$ = this.state$.pipe(
    map((m) => m.isBusy),
    distinctUntilChanged()
  );
  public readonly items$ = this.state$.pipe(
    map((m) => m.items),
    distinctUntilChanged()
  );
  public readonly selectedItem$ = this.state$.pipe(
    map((m) => m.selectedItem),
    distinctUntilChanged()
  );
  public readonly selectedItems$ = this.state$.pipe(
    map((m) => m.selectedItems),
    distinctUntilChanged()
  );

  constructor(protected modalService: BsModalService, protected uiFacade: UIFacade, protected lookupFacadeManager: LookupFacadeManager) {}

  toggleLookup(filters: Partial<T>, items: T[] = null, selectedItems: T[] = null) {
    if (this.equals(this.state.filters, filters)) {
      this.resetAndClose();
    } else {
      this.lookup(filters, false, items, selectedItems);
    }
  }

  lookup(filters: Partial<T>, dontOpen: boolean = false, items: T[] = null, selectedItems: T[] = null) {
    if (!dontOpen || this.state.isOpen) {
      this.lookupFacadeManager.open(this);
      const newFilters = { ...filters };
      this.updateState({ filters: newFilters, isBusy: true, isOpen: true });
      if (items) {
        this.updateState({ selectedItems, items, isBusy: false });
      } else {
        this.getLookupItemsData().subscribe((items) => {
          const filteredItems = this.applyFilters(items, newFilters);
          this.updateState({ selectedItem: null, items: filteredItems, isBusy: false });
        });
      }
    }
  }

  select(): Observable<T> {
    this.updateState({ isBusy: true });
    combineLatest([this.selectedItem$])
      .pipe(
        delay(0),
        map(([selectedItem]) => {
          this.resetAndClose();
          this.onSelectSubject.next(selectedItem);
        }),
        take(1)
      )
      .subscribe();
    return this.onSelect$.pipe(take(1));
  }

  selectMany(): Observable<T[]> {
    this.updateState({ isBusy: true });
    combineLatest([this.selectedItems$])
      .pipe(
        delay(0),
        map(([selectedItems]) => {
          this.resetAndClose();
          this.onSelectManySubject.next(selectedItems);
        }),
        take(1)
      )
      .subscribe();
    return this.onSelectMany$.pipe(take(1));
  }

  cancel() {
    this.resetAndClose();
  }

  updateSelectedItem(selectedItem: T) {
    if (this.equals(this.state.selectedItem, selectedItem)) {
      this.updateState({ selectedItem: null });
    } else {
      this.updateState({ selectedItem });
    }
  }

  updateSelectedItems(selectedItems: T[]) {
    // if (this.equals(this.state.selectedItems, selectedItems)) {
    //   this.updateState({ selectedItems: [] });
    // } else {
    this.updateState({ selectedItems });
    //}
  }

  protected getLookupItemsData(): Observable<T[]> {
    return of([]);
    // return of(this.dataStoreFacade.state.templates);
  }
  protected applyFilters(items: T[], filters?: Partial<T>): T[] {
    return items.filter((f) => {
      let matched = true;
      for (const key in filters) {
        if (filters.hasOwnProperty(key)) {
          matched = matched && filters[key] === f[key];
        }
      }
      return matched;
    });
  }

  private resetAndClose() {
    this.lookupFacadeManager.close(this);
    this.resetToDefault();
    this.onCloseSubject.next(true);
  }
  resetToDefault() {
    this.stateSubject.next({ ...this.defaultState });
  }

  private updateState(updates: Partial<LookupFacadeState<T>>) {
    // const state = this.stateSubject.getValue();
    // for (const key of Object.keys(updates)) {
    //   state[key] = updates[key];
    // }
    const state = {
      ...this.stateSubject.getValue(),
      ...updates,
    };
    this.stateSubject.next(state);
  }
  private equals(x: any, y: any): boolean {
    return deepEquals(x, y);
  }
}

export interface LookupFacadeState<T> {
  isOpen: boolean;
  isBusy: boolean;
  filters?: Partial<T>;
  items: T[];
  selectedItem: T;
  selectedItems: T[];
}

export interface ICancellable {
  cancel: () => void;
}
