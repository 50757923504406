import { AbstractControl } from '@angular/forms';

export function validateRequiredAreaFn(c: AbstractControl) {
  const areaRequired = {
    areaRequired: {
      valid: false,
    },
  };

  const areaValue = c.value;
  const areaIsValid = areaValue !== '' && areaValue != null ? null : areaRequired;
  return areaIsValid;
}

export function validateRequiredLengthFn(c: AbstractControl) {
  const lengthRequired = {
    lengthRequired: {
      valid: false,
    },
  };

  if (c.parent) {
    const lengthValue = c.value;
    const areaValue = c.parent.get('area').value;
    const areaHasValue = areaValue !== '' && areaValue != null;

    if (!areaHasValue) {
      const lengthIsValid = lengthValue !== '' && lengthValue != null ? null : lengthRequired;
      return lengthIsValid;
    }
  }

  return null;
}

export function validateRequiredBreadthFn(c: AbstractControl) {
  const breadthRequired = {
    breadthRequired: {
      valid: false,
    },
  };

  if (c.parent) {
    const breadthValue = c.value;
    const areaValue = c.parent.get('area').value;
    const areaHasValue = areaValue !== '' && areaValue != null;

    if (!areaHasValue) {
      const breadthIsValid = breadthValue !== '' && breadthValue != null ? null : breadthRequired;
      return breadthIsValid;
    }
  }

  return null;
}
