import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DataStoreFacade } from 'src/app/spaceplan/shared/facades/data-store.facade';
import { CreateTemplateDto, Template, UserRole } from 'src/app/core/services/api-clients';
import { EditorFacadeBase, UserFacade, UIFacade } from '.';
import { bufferCount, filter, map } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';

/**
 * t(spaceplan.Template)
 */
@Injectable({
  providedIn: 'root',
})
export class EditorSpaceplanTemplateFacade extends EditorFacadeBase<CreateTemplateDto> {
  constructor(
    protected toastr: ToastrService,
    protected modalService: BsModalService,
    protected uiFacade: UIFacade,
    protected translocoService: TranslocoService,
    private dataStoreFacade: DataStoreFacade,
    private userFacade: UserFacade
  ) {
    super(toastr, modalService, uiFacade, translocoService);
    this.dataStoreFacade.spaceProgram$
      .pipe(
        bufferCount(2, 1),
        filter(([a, b]) => b?.spaceProgramID !== a?.spaceProgramID),
        map(([a, b]) => b)
      )
      .subscribe(() => {
        this.resetToDefault();
      });
  }
  protected className(): string {
    return this.translocoService.translate('spaceplan.Template');
  }
  protected createDataStore(model: CreateTemplateDto): Observable<CreateTemplateDto> {
    return this.dataStoreFacade.createSpaceProgramTemplate(model).pipe(map((template: any) => template as CreateTemplateDto));
  }
  protected getModelId(model: CreateTemplateDto): string {
    return model?.templateID;
  }
  protected setModelId(model: CreateTemplateDto, id: string) {
    model.templateID = id;
  }
  protected userCanEdit(): Observable<boolean> {
    return this.dataStoreFacade.spaceProgram$.pipe(map((m) => m.roleNo === UserRole.Composer || m.roleNo === UserRole.Editor));
  }
  protected getNewModel(init: Partial<CreateTemplateDto>): Observable<CreateTemplateDto> {
    return of({
      ...init,
      templateID: null,
      partnerID: null,
      folderID: init.folderID || null,
      templateName: null,
    });
  }
}
