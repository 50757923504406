import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LookupTemplateFacade } from '../../facades/lookup-template.facade';

@Component({
  selector: 'app-lookup-template-button',
  template: `
    <div>
      <button
        type="button"
        class="btn btn-light border"
        [disabled]="!partnerID"
        ngClass="{ disabled: !partnerID }"
        style="padding: 5px; border-radius: 4px; margin-left: 4px;"
        (click)="toggleLookup()"
      >
        <span class="k-icon k-i-search"></span>
      </button>
    </div>
  `,
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LookupTemplateButtonComponent implements OnInit, OnChanges {
  @Input() partnerID: string;

  constructor(private lookupTemplateFacade: LookupTemplateFacade) {}

  ngOnInit() {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes.partnerID) {
      this.lookupTemplateFacade.lookup({ partnerID: this.partnerID }, true);
    }
  }

  toggleLookup() {
    this.lookupTemplateFacade.toggleLookup({ partnerID: this.partnerID });
  }
}
