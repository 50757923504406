<ng-container *transloco="let t; read: 'spaceplan'">
  <div id="editor-form-top" *ngIf="form" [formGroup]="form">
    <div class="form-group form-row mb-1 section-header" [id]="'ref-settings-1'">
      <label for="basic-settings" class="col-sm-12 col-form-label font-weight-bold">
        {{ t('editorSpaceProgramSettingsForm.basic-settings.label') }}
      </label>
    </div>

    <div class="form-group form-row mb-1">
      <label for="spaceProgramName" class="col-sm-12 col-form-label">
        {{ t('editorSpaceProgramSettingsForm.spaceProgramName.label') }}:
      </label>
      <div class="col-sm-12">
        <div class="input-group">
          <input type="text" class="form-control" id="spaceProgramName"
            placeholder="{{ t('editorSpaceProgramSettingsForm.spaceProgramName.placeholder') }}" name="spaceProgramName"
            formControlName="spaceProgramName" />
        </div>
        <div *ngIf="isRequesting && form.get('spaceProgramName').errors" class="invalid-feedback">
          <div class="form-error" *ngIf="form.controls['spaceProgramName'].errors.required">
            {{ t('editorSpaceProgramSettingsForm.spaceProgramName.error.required') }}
          </div>
          <div class="form-error" *ngIf="form.controls['spaceProgramName'].errors.duplicate">
            {{ t('editorSpaceProgramSettingsForm.spaceProgramName.error.duplicate') }}
          </div>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-1">
      <label for="spaceProgramDescr" class="col-sm-12 col-form-label">
        {{ t('editorSpaceProgramSettingsForm.spaceProgramDescr.label') }}:
      </label>
      <div class="col-sm-12">
        <div class="input-group mb-1">
          <textarea name="spaceProgramDescr" id="spaceProgramDescr"
            placeholder="{{ t('editorSpaceProgramSettingsForm.spaceProgramDescr.placeholder') }}" class="form-control"
            formControlName="spaceProgramDescr" rows="6" style="resize: none;"></textarea>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-1">
      <label for="spaceProgramDescr" class="col-sm-12 col-form-label">
        {{ t('editorSpaceProgramSettingsForm.spaceProgramFolder.label') }}:
      </label>
      <div class="col-sm-12">
        <div class="input-group">
          <select class="custom-select" id="folderID" name="folderID" formControlName="folderID"
            style="border-radius: 0.25rem;">
            <option [ngValue]="null">{{ t('editorSpaceProgramSettingsForm.folderID.option.null_Please_select_folder')
              }}</option>

            <ng-template ngFor let-folder [ngForOf]="folders">
              <option [ngValue]="folder.folderID">{{ folder.folderName }}</option>
            </ng-template>
          </select>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-1 section-header" [id]="'ref-settings-2'">
      <label for="calculation-settings" class="col-sm-12 col-form-label font-weight-bold">
        {{ t('editorSpaceProgramSettingsForm.calculation-settings.label') }}
      </label>
    </div>

    <div class="form-group form-row mb-1">
      <label for="existingNetArea" class="col-sm-12 col-form-label">{{
        t('editorSpaceProgramSettingsForm.existingNetArea.label') }} ({{ (options.measurementUnit ===
        MeasurementUnit.Metric ? t('UOM_Abbrev_Metric') : t('UOM_Abbrev_Imperial')) }}):</label>
      <div class="col-sm-12">
        <div class="input-group">
          <input type="text" class="form-control" id="existingNetArea"
            placeholder="{{ t('editorSpaceProgramSettingsForm.existingNetArea.placeholder') }}" numberOnly
            name="existingNetArea" formControlName="existingNetArea" />
        </div>
        <div *ngIf="isRequesting && form.get('existingNetArea').errors" class="invalid-feedback">
          <div class="form-error" *ngIf="form.controls['existingNetArea'].errors.min">
            {{ t('editorSpaceProgramSettingsForm.existingNetArea.error.min') }}
          </div>
          <div class="form-error" *ngIf="form.controls['existingNetArea'].errors.max">
            {{ t('editorSpaceProgramSettingsForm.existingNetArea.error.max') }}
          </div>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-1">
      <label for="proposedNetArea" class="col-sm-12 col-form-label">
        {{ t('editorSpaceProgramSettingsForm.proposedNetArea.label') }} ({{ (options.measurementUnit ===
        MeasurementUnit.Metric ? t('UOM_Abbrev_Metric') : t('UOM_Abbrev_Imperial')) }}):
      </label>
      <div class="col-sm-12">
        <div class="input-group">
          <input type="text" class="form-control" id="proposedNetArea"
            placeholder="{{ t('editorSpaceProgramSettingsForm.proposedNetArea.placeholder') }}" numberOnly
            name="proposedNetArea" formControlName="proposedNetArea" />
        </div>
        <div *ngIf="isRequesting && form.get('proposedNetArea').errors" class="invalid-feedback">
          <div class="form-error" *ngIf="form.controls['proposedNetArea'].errors.min">
            {{ t('editorSpaceProgramSettingsForm.proposedNetArea.error.min') }}
          </div>
          <div class="form-error" *ngIf="form.controls['proposedNetArea'].errors.max">
            {{ t('editorSpaceProgramSettingsForm.proposedNetArea.error.max') }}
          </div>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-1">
      <label for="fteToPeopleFactor" class="col-sm-12 col-form-label">
        {{ t('editorSpaceProgramSettingsForm.fteToPeopleFactor.label') }}:
      </label>
      <div class="col-sm-12">
        <div class="input-group">
          <input type="text" class="form-control" id="fteToPeopleFactor"
            placeholder="{{ t('editorSpaceProgramSettingsForm.fteToPeopleFactor.placeholder') }}" numberOnly
            name="fteToPeopleFactor" formControlName="fteToPeopleFactor" />
        </div>
        <div *ngIf="isRequesting && form.get('fteToPeopleFactor').errors" class="invalid-feedback">
          <div class="form-error" *ngIf="form.controls['fteToPeopleFactor'].errors.required">
            {{ t('editorSpaceProgramSettingsForm.fteToPeopleFactor.error.required') }}
          </div>
          <div class="form-error" *ngIf="form.controls['fteToPeopleFactor'].errors.min">
            {{ t('editorSpaceProgramSettingsForm.fteToPeopleFactor.error.min') }}
          </div>
          <div class="form-error" *ngIf="form.controls['fteToPeopleFactor'].errors.max">
            {{ t('editorSpaceProgramSettingsForm.fteToPeopleFactor.error.max') }}
          </div>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-1">
      <label for="areaNetToGrossFactor" class="col-sm-12 col-form-label">
        {{ t('editorSpaceProgramSettingsForm.areaNetToGrossFactor.label') }}:
      </label>
      <div class="col-sm-12">
        <div class="input-group">
          <input type="text" class="form-control" id="areaNetToGrossFactor"
            placeholder="{{ t('editorSpaceProgramSettingsForm.areaNetToGrossFactor.placeholder') }}" numberOnly
            name="areaNetToGrossFactor" formControlName="areaNetToGrossFactor" />
        </div>
        <div *ngIf="isRequesting && form.get('areaNetToGrossFactor').errors" class="invalid-feedback">
          <div class="form-error" *ngIf="form.controls['areaNetToGrossFactor'].errors.required">
            {{ t('editorSpaceProgramSettingsForm.areaNetToGrossFactor.error.required') }}
          </div>
          <div class="form-error" *ngIf="form.controls['areaNetToGrossFactor'].errors.min">
            {{ t('editorSpaceProgramSettingsForm.areaNetToGrossFactor.error.min') }}
          </div>
          <div class="form-error" *ngIf="form.controls['areaNetToGrossFactor'].errors.max">
            {{ t('editorSpaceProgramSettingsForm.areaNetToGrossFactor.error.max') }}
          </div>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-1">
      <label for="percFTEForGuest" class="col-sm-12 col-form-label">
        {{ t('editorSpaceProgramSettingsForm.percFTEForGuest.label') }}:
      </label>
      <div class="col-sm-12">
        <div class="input-group">
          <input type="text" class="form-control" id="percFTEForGuest"
            placeholder="{{ t('editorSpaceProgramSettingsForm.percFTEForGuest.placeholder') }}" numberOnly
            name="percFTEForGuest" formControlName="percFTEForGuest" />
        </div>
        <div *ngIf="isRequesting && form.get('percFTEForGuest').errors" class="invalid-feedback">
          <div class="form-error" *ngIf="form.controls['percFTEForGuest'].errors.required">
            {{ t('editorSpaceProgramSettingsForm.percFTEForGuest.error.required') }}
          </div>
          <div class="form-error" *ngIf="form.controls['percFTEForGuest'].errors.min">
            {{ t('editorSpaceProgramSettingsForm.percFTEForGuest.error.min') }}
          </div>
          <div class="form-error" *ngIf="form.controls['percFTEForGuest'].errors.max">
            {{ t('editorSpaceProgramSettingsForm.percFTEForGuest.error.max') }}
          </div>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-1 section-header" [id]="'ref-settings-5'">
      <label for="adjustment-settings" class="col-12 col-form-label font-weight-bold">
        {{ t('editorSpaceProgramSettingsForm.adjustment-settings.label') }}
      </label>
    </div>

    <div class="form-group form-row mb-2">
      <label for="behaviorFractionAdjustmentMethod" class="col-sm-12 col-form-label">{{
        t('editorSpaceProgramSettingsForm.behaviorFractionAdjustmentMethod.label') }}:</label>
      <div class="col-sm-12">
        <div class="mb-2">
          <kendo-buttongroup [selection]="'single'" [disabled]="form.get('behaviorFractionAdjustmentMethod').disabled">
            <button kendoButton [toggleable]="true" type="button" class="p-2 pl-3 pr-3"
              [primary]="+form.get('behaviorFractionAdjustmentMethod').value === 1"
              [look]="+form.get('behaviorFractionAdjustmentMethod').value !== 1 ? 'outline' : ''"
              (click)="onBehaviorFractionAdjustmentMethod(1)">
              {{ t('editorSpaceProgramSettingsForm.behaviorFractionAdjustmentMethod.option.In-Office_Percentage') }}
            </button>
            <button kendoButton [toggleable]="true" type="button" class="p-2 pl-3 pr-3"
              [primary]="+form.get('behaviorFractionAdjustmentMethod').value === 0"
              [look]="+form.get('behaviorFractionAdjustmentMethod').value !== 0 ? 'outline' : ''"
              (click)="onBehaviorFractionAdjustmentMethod(0)">
              {{
              t('editorSpaceProgramSettingsForm.behaviorFractionAdjustmentMethod.option.In-Primary_Work_Settings_Factor')
              }}
            </button>
          </kendo-buttongroup>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-2" *ngIf="+form.get('behaviorFractionAdjustmentMethod').value === 0">
      <label for="behaviorFractionAdjustmentPWFTo" class="col-sm-12 col-form-label">{{
        t('editorSpaceProgramSettingsForm.behaviorFractionAdjustmentPWFTo.label') }}:</label>
      <div class="col-sm-12">
        <div class="mb-2">
          <kendo-buttongroup [selection]="'single'" [disabled]="form.get('behaviorFractionAdjustmentPWFTo').disabled">
            <button kendoButton [toggleable]="true" type="button" class="p-2 pl-3 pr-3"
              [primary]="+form.get('behaviorFractionAdjustmentPWFTo').value === 0"
              [look]="+form.get('behaviorFractionAdjustmentPWFTo').value !== 0 ? 'outline' : ''"
              (click)="form.get('behaviorFractionAdjustmentPWFTo').setValue(0)">
              {{ t('editorSpaceProgramSettingsForm.behaviorFractionAdjustmentPWFTo.option.All') }}
            </button>
            <button kendoButton [toggleable]="true" type="button" class="p-2 pl-3 pr-3"
              [primary]="+form.get('behaviorFractionAdjustmentPWFTo').value === 1"
              [look]="+form.get('behaviorFractionAdjustmentPWFTo').value !== 1 ? 'outline' : ''"
              (click)="form.get('behaviorFractionAdjustmentPWFTo').setValue(1)">
              {{ t('editorSpaceProgramSettingsForm.behaviorFractionAdjustmentPWFTo.option.In-Flex') }}
            </button>
          </kendo-buttongroup>
        </div>
      </div>
    </div>

    <div formArrayName="groups">
      <div class="form-group form-row mb-2">
        <!-- 1 -->
        <div class="col-5"><label class="form-label">{{ t('editorSpaceProgramSettingsForm.group.groupName.label')
            }}</label></div>
        <!-- 2 -->
        <div class="col-1 item-right"><label class="form-label">{{
            t('editorSpaceProgramSettingsForm.group.behaviorFraction.label') }}</label></div>
        <!-- 3 -->
        <div class="col-1 item-right"><label class="form-label">{{
            t('editorSpaceProgramSettingsForm.group.growthFactor.label') }}</label></div>
        <!-- 4 -->
        <div class="col-1 item-right"><label class="form-label">{{
            t('editorSpaceProgramSettingsForm.group.newSizeFTE.label') }}</label></div>
        <!-- 5 -->
        <div class="col-1 item-right">
          <label class="form-label">{{ t('editorSpaceProgramSettingsForm.group.sourceInOfficeFraction.label') }}</label>
        </div>
        <!-- 6 -->
        <div class="col-1 item-right">
          <label class="form-label">{{ t('editorSpaceProgramSettingsForm.group.measuredPrimaryWorkSettingFactor.label')
            }}</label>
        </div>
        <!-- 7 -->
        <div class="col-1 item-right" *ngIf="+form.get('behaviorFractionAdjustmentMethod').value === 0">
          <label class="form-label">{{ t('editorSpaceProgramSettingsForm.group.behaviorFractionAdjustmentFactor.label')
            }}</label>
        </div>
        <!-- 8 -->
        <div class="col-1 item-right" *ngIf="+form.get('behaviorFractionAdjustmentMethod').value === 1">
          <label class="form-label">{{ t('editorSpaceProgramSettingsForm.group.behaviorFractionAdjustmentFactor.label1')
            }}</label>
        </div>
        <!-- 9 -->
        <div class="col-1 item-right" *ngIf="+form.get('behaviorFractionAdjustmentMethod').value === 1">
          <label class="form-label">{{ t('editorSpaceProgramSettingsForm.group.adjustedInOfficePerc.label') }}</label>
        </div>
      </div>
      <div class="form-group form-row mb-2">
        <!-- 1 -->
        <div class="col-5"></div>
        <!-- 2 -->
        <div class="col-1 item-right"></div>
        <!-- 3 -->
        <div class="col-1 item-right">
          <div class="input-group" *ngIf="!options.readOnly">
            <input type="text" class="form-control item-right" id="growthFactorOverride"
              placeholder="{{ t('editorSpaceProgramSettingsForm.group.growthFactor.placeholder') }}" numberOnly
              name="growthFactorOverride" (keyup)="onGrowthFactorOverride($event)"
              (blur)="$event.currentTarget.value = ''" />
          </div>
        </div>
        <!-- 4 -->
        <div class="col-1 item-right"></div>
        <!-- 5 -->
        <div class="col-1 item-right"></div>
        <!-- 6 -->
        <div class="col-1 item-right"></div>
        <!-- 7 -->
        <div class="col-1 item-right">
          <div class="input-group" *ngIf="!options.readOnly">
            <input type="text" class="form-control item-right" id="behaviorFractionAdjustmentFactorOverride"
              placeholder="{{ t('editorSpaceProgramSettingsForm.group.behaviorFractionAdjustmentFactor.placeholder') }}"
              numberOnly name="behaviorFractionAdjustmentFactorOverride"
              (keyup)="onBehaviorFractionAdjustmentFactorOverride($event)" (blur)="$event.currentTarget.value = ''" />
          </div>
        </div>
        <div class="col-1 item-right" *ngIf="+form.get('behaviorFractionAdjustmentMethod').value === 1"></div>
      </div>
      <ng-template ngFor let-i="index" let-cnt="count" let-group [ngForOf]="groups">
        <div class="mt-1 form-row" formGroupName="{{ group.groupID }}">
          <!-- 1 Group Name -->
          <div class="col-5">
            <div class="input-group">
              <input type="text" class="form-control" [id]="'groupName' + i"
                placeholder="{{ t('editorSpaceProgramSettingsForm.group.groupName.placeholder') }}"
                [name]="'groupName' + i" formControlName="groupName" />
              <div class="input-group-append" *ngIf="!options.readOnly">
                <kendo-colorpicker formControlName="colourCode" [format]="'hex'"
                  [gradientSettings]="uiFacade.colorPickerGradientSettings">
                </kendo-colorpicker>
              </div>
            </div>
          </div>
          <!-- 2 Size (FTE) -->
          <label class="col-1 col-form-label item-right">{{ intl.formatNumber(group.behaviorFraction, 'n0') }}</label>
          <!-- 3 Growth Factor -->
          <div class="col-1">
            <div class="input-group">
              <input type="text" class="form-control item-right" [id]="'growthFactor' + i"
                placeholder="{{ t('editorSpaceProgramSettingsForm.group.growthFactor.placeholder') }}" numberOnly
                [name]="'growthFactor' + i" formControlName="growthFactor" />
            </div>
          </div>
          <!-- 4 New Size (FTE) -->
          <label [for]="'group' + i" class="col-1 col-form-label item-right">{{
            intl.formatNumber(calcNewSizeFTE(group.behaviorFraction, form.get(['groups', group.groupID,
            'growthFactor'])?.value), 'n0')
            }}</label>
          <!-- 5 Measured In-Office % -->
          <label class="col-1 col-form-label item-right">{{ intl.formatNumber(group.sourceInOfficeFraction * 100, 'n0')
            }}</label>
          <!-- 6 Measured Primary Work Setting Factor -->
          <label [for]="'group' + i" class="col-1 col-form-label item-right">
            {{ intl.formatNumber(group.measuredPrimaryWorkSettingFactor, 'n2') }}</label>
          <!-- Desired Primary Work Setting Factor -->
          <div class="col-1 item-right" *ngIf="+form.get('behaviorFractionAdjustmentMethod').value === 0">
            <div class="input-group">
              <input type="text" class="form-control item-right" [id]="'behaviorFractionAdjustmentFactor' + i"
                placeholder="{{ t('editorSpaceProgramSettingsForm.group.behaviorFractionAdjustmentFactor.placeholder') }}"
                numberOnly [name]="'behaviorFractionAdjustmentFactor' + i"
                formControlName="behaviorFractionAdjustmentFactor" />
            </div>
          </div>
          <!-- In-Office % Adjustment Factor -->
          <div class="col-1 item-right" *ngIf="+form.get('behaviorFractionAdjustmentMethod').value === 1">
            <div class="input-group">
              <input type="text" class="form-control item-right" [id]="'behaviorFractionAdjustmentFactor' + i"
                placeholder="{{ t('editorSpaceProgramSettingsForm.group.behaviorFractionAdjustmentFactor.placeholder1') }}"
                numberOnly [name]="'behaviorFractionAdjustmentFactor' + i"
                formControlName="behaviorFractionAdjustmentFactor" />
            </div>
          </div>
          <!-- Adjusted In-Office % -->
          <label class="col-1 col-form-label item-right" [ngClass]="{
              error:
                group.sourceInOfficeFraction * 100 * form.get(['groups', group.groupID, 'behaviorFractionAdjustmentFactor'])?.value > 100
            }" *ngIf="+form.get('behaviorFractionAdjustmentMethod').value === 1">{{
            intl.formatNumber(
            group.sourceInOfficeFraction * 100 * form.get(['groups', group.groupID,
            'behaviorFractionAdjustmentFactor'])?.value,
            'n0'
            )
            }}
          </label>
          <div *ngIf="isRequesting && form.get(['groups', group.groupID, 'groupName']).errors"
            class="col-12 invalid-feedback">
            <div class="form-error" *ngIf="form.get(['groups', group.groupID, 'groupName']).errors.required">
              {{ t('editorSpaceProgramSettingsForm.group.groupName.error.required') }}
            </div>
          </div>
          <div *ngIf="isRequesting && form.get(['groups', group.groupID, 'growthFactor']).errors"
            class="col-12 invalid-feedback">
            <div class="form-error" *ngIf="form.get(['groups', group.groupID, 'growthFactor']).errors.required">
              {{ t('editorSpaceProgramSettingsForm.group.growthFactor.error.required') }}
            </div>
            <div class="form-error" *ngIf="form.get(['groups', group.groupID, 'growthFactor']).errors.min">
              {{ t('editorSpaceProgramSettingsForm.group.growthFactor.error.min') }}
            </div>
            <div class="form-error" *ngIf="form.get(['groups', group.groupID, 'growthFactor']).errors.max">
              {{ t('editorSpaceProgramSettingsForm.group.growthFactor.error.max') }}
            </div>
          </div>
          <div *ngIf="isRequesting && form.get(['groups', group.groupID, 'behaviorFractionAdjustmentFactor']).errors"
            class="col-12 invalid-feedback">
            <ng-container *ngIf="+form.get('behaviorFractionAdjustmentMethod').value === 0">
              <div class="form-error"
                *ngIf="form.get(['groups', group.groupID, 'behaviorFractionAdjustmentFactor']).errors.required">
                {{ t('editorSpaceProgramSettingsForm.group.behaviorFractionAdjustmentFactor.error.required') }}
              </div>
            </ng-container>
            <ng-container *ngIf="+form.get('behaviorFractionAdjustmentMethod').value === 1">
              <div class="form-error"
                *ngIf="form.get(['groups', group.groupID, 'behaviorFractionAdjustmentFactor']).errors.required">
                {{ t('editorSpaceProgramSettingsForm.group.behaviorFractionAdjustmentFactor.error.required1') }}
              </div>
            </ng-container>
            <div class="form-error"
              *ngIf="form.get(['groups', group.groupID, 'behaviorFractionAdjustmentFactor']).errors.min">
              {{ t('editorSpaceProgramSettingsForm.group.behaviorFractionAdjustmentFactor.error.min') }}
            </div>
            <div class="form-error"
              *ngIf="form.get(['groups', group.groupID, 'behaviorFractionAdjustmentFactor']).errors.max">
              {{ t('editorSpaceProgramSettingsForm.group.behaviorFractionAdjustmentFactor.error.max') }}
            </div>
          </div>
        </div>
      </ng-template>


      <div class="mt-2 form-row row-totals" *ngIf="groupsSummary$ | async as groupsSummary">
        <!-- 1 Group Name -->
        <div class="col-5 col-form-label"></div>
        <!-- 2 Size (FTE) -->
        <div class="col-1 col-form-label item-right">{{ intl.formatNumber(groupsSummary.sizeFTE, 'n0') }}</div>
        <!-- 3 Growth Factor -->
        <div class="col-1 col-form-label item-right"></div>
        <!-- 4 New Size (FTE) -->
        <div class="col-1 col-form-label item-right">{{ intl.formatNumber(groupsSummary.newSizeFTE, 'n0') }}</div>
        <!-- 5 Measured In-Office % -->
        <div class="col-1 col-form-label item-right">{{ intl.formatNumber(groupsSummary.measuredInOffice, 'n0') }}</div>
        <!-- 6 Measured Primary Work Setting Factor -->
        <div class="col-1 col-form-label item-right">{{
          intl.formatNumber(groupsSummary.measuredPrimaryWorkSettingFactor, 'n2') }}</div>
        <!-- Desired Primary Work Setting Factor -->
        <div class="col-1 col-form-label item-right" *ngIf="+form.get('behaviorFractionAdjustmentMethod').value === 0">
          <!-- {{ intl.formatNumber(groupsSummary.behaviorFractionAdjustmentFactor, 'n0') }} -->
        </div>
        <!-- In-Office % Adjustment Factor -->
        <div class="col-1 col-form-label item-right" *ngIf="+form.get('behaviorFractionAdjustmentMethod').value === 1">
          <!-- {{ intl.formatNumber(groupsSummary.behaviorFractionAdjustmentFactor, 'n0') }} -->
        </div>
        <!-- Adjusted In-Office % -->
        <div class="col-1 col-form-label item-right" *ngIf="+form.get('behaviorFractionAdjustmentMethod').value === 1">
          {{ intl.formatNumber(groupsSummary.adjustedInOffice, 'n0') }}</div>
      </div>

    </div>

    <div class="form-group form-row mb-1 section-header" [id]="'ref-settings-4'">
      <label for="summary-panel-settings" class="col-12 col-form-label font-weight-bold">
        {{ t('editorSpaceProgramSettingsForm.summary-panel-settings.label') }}</label>
    </div>

    <ng-container formArrayName="summaryContent">
      <ng-template ngFor let-i="index" let-summaryContent [ngForOf]="summaryContentList">
        <div class="form-group form-row mb-2">
          <label [for]="'summaryContent' + summaryContent.code" class="col-sm-12 col-form-label">{{
            t(summaryContent.descr) }}:</label>
          <div class="col-sm-12">
            <div class="mb-2">
              <kendo-buttongroup [selection]="'single'"
                [disabled]="form.get('summaryContent.' + summaryContent.code).disabled">
                <button kendoButton [toggleable]="true" type="button" class="p-2 pl-3 pr-3"
                  [primary]="form.get('summaryContent.' + summaryContent.code).value"
                  [look]="!form.get('summaryContent.' + summaryContent.code).value ? 'outline' : ''"
                  (click)="onSummaryContent(summaryContent.code, true)">
                  {{ t('editorSpaceProgramSettingsForm.summaryContent.option.Show') }}
                </button>
                <button kendoButton [toggleable]="true" type="button" class="p-2 pl-3 pr-3"
                  [primary]="!form.get('summaryContent.' + summaryContent.code).value"
                  [look]="form.get('summaryContent.' + summaryContent.code).value ? 'outline' : ''"
                  (click)="onSummaryContent(summaryContent.code, false)">
                  {{ t('editorSpaceProgramSettingsForm.summaryContent.option.Hide') }}
                </button>
              </kendo-buttongroup>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>

    <div class="form-group form-row mb-2">
      <label for="summaryContentKeyMetrics" class="col-sm-12 col-form-label">{{
        t('editorSpaceProgramSettingsForm.summaryContentKeyMetrics.label') }}:</label>
      <div class="col-sm-12">
        <div class="mb-2">
          <kendo-buttongroup [selection]="'single'" [disabled]="form.get('summaryContent.keyMetricByFTE').disabled">
            <button kendoButton [toggleable]="true" type="button" class="p-2 pl-3 pr-3"
              [primary]="!form.get('summaryContent.keyMetricByFTE').value && !form.get('summaryContent.keyMetricByPeople').value && !form.get('summaryContent.keyMetricByFTEPresent').value"
              [look]="
                form.get('summaryContent.keyMetricByFTE').value || form.get('summaryContent.keyMetricByPeople').value || form.get('summaryContent.keyMetricByFTEPresent').value ? 'outline' : ''
              "
              (click)="onSummaryContent('keyMetricByFTE', false); onSummaryContent('keyMetricByPeople', false); onSummaryContent('keyMetricByFTEPresent', false)">
              {{ t('editorSpaceProgramSettingsForm.keyMetricBy.option.NA') }}
            </button>
            <button kendoButton [toggleable]="true" type="button" class="p-2 pl-3 pr-3"
              [primary]="form.get('summaryContent.keyMetricByFTE').value"
              [look]="!form.get('summaryContent.keyMetricByFTE').value ? 'outline' : ''"
              (click)="onSummaryContent('keyMetricByFTE', true); onSummaryContent('keyMetricByPeople', false); onSummaryContent('keyMetricByFTEPresent', false)">
              {{ t('editorSpaceProgramSettingsForm.keyMetricBy.option.FTE') }}
            </button>
            <button kendoButton [toggleable]="true" type="button" class="p-2 pl-3 pr-3"
              [primary]="form.get('summaryContent.keyMetricByPeople').value"
              [look]="!form.get('summaryContent.keyMetricByPeople').value ? 'outline' : ''"
              (click)="onSummaryContent('keyMetricByPeople', true); onSummaryContent('keyMetricByFTE', false); onSummaryContent('keyMetricByFTEPresent', false)">
              {{ t('editorSpaceProgramSettingsForm.keyMetricBy.option.People') }}
            </button>
            <button kendoButton [toggleable]="true" type="button" class="p-2 pl-3 pr-3"
              *ngIf="+form.get('behaviorFractionAdjustmentMethod').value === 1"
              [primary]="form.get('summaryContent.keyMetricByFTEPresent').value"
              [look]="!form.get('summaryContent.keyMetricByFTEPresent').value ? 'outline' : ''"
              (click)="onSummaryContent('keyMetricByFTEPresent', true); onSummaryContent('keyMetricByPeople', false); onSummaryContent('keyMetricByFTE', false);">
              {{ t('editorSpaceProgramSettingsForm.keyMetricBy.option.FTEPresent') }}
            </button>
          </kendo-buttongroup>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-1 section-header" [id]="'ref-settings-3'">
      <label for="custom-labels" class="col-12 col-form-label font-weight-bold">
        {{ t('editorSpaceProgramSettingsForm.custom-labels.label') }}
      </label>
    </div>

    <ng-container formArrayName="customLabels">
      <ng-template ngFor let-i="index" let-customLabel [ngForOf]="customLabelList">
        <div class="form-group form-row mb-1">
          <label [for]="'customLabel_' + customLabel.code" class="col-sm-12 col-form-label">
            {{ t('customLabel.' + customLabel.code) }}:
          </label>
          <div class="col-sm-12">
            <div class="input-group">
              <input type="text" class="form-control" [id]="'customLabel_' + customLabel.code"
                placeholder="{{ t('editorSpaceProgramSettingsForm.custom-labels.placeholder')}}" name="text"
                [formControlName]="customLabel.code" />
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>


    <div class="form-group form-row mb-1 section-header" [id]="'ref-settings-6'">
      <label for="colours" class="col-sm-12 col-form-label font-weight-bold">
        {{ t('editorSpaceProgramSettingsForm.colours.label') }}
      </label>
    </div>

    <div class="form-group form-row mb-1">
      <label [for]="'colour_notgrouped'" class="col-sm-12 col-form-label">
        {{ t('editorSpaceProgramSettingsForm.colour-notgrouped.label') }}:
      </label>
      <div class="col-sm-12">
        <div class="input-group">
          <kendo-colorpicker formControlName="colourCodeOtherGroup" [format]="'hex'"
            [gradientSettings]="uiFacade.colorPickerGradientSettings">
          </kendo-colorpicker>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-1">
      <label [for]="'colour_nottagged'" class="col-sm-12 col-form-label">
        {{ t('editorSpaceProgramSettingsForm.colour-nottagged.label') }}:
      </label>
      <div class="col-sm-12">
        <div class="input-group">
          <kendo-colorpicker formControlName="colourCodeOtherTag" [format]="'hex'"
            [gradientSettings]="uiFacade.colorPickerGradientSettings">
          </kendo-colorpicker>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-1">
      <label [for]="'colour_unallocatedspace'" class="col-sm-12 col-form-label">
        {{ t('editorSpaceProgramSettingsForm.colour-unallocatedspace.label') }}:
      </label>
      <div class="col-sm-12">
        <div class="input-group">
          <kendo-colorpicker formControlName="colourCodeUnallocated" [format]="'hex'"
            [gradientSettings]="uiFacade.colorPickerGradientSettings">
          </kendo-colorpicker>
        </div>
      </div>
    </div>

  </div>
</ng-container>