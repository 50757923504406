import { Component, ChangeDetectionStrategy, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, AbstractControl, Validators } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AllocationBulkFacade } from 'src/app/spaceplan/allocations/facades/allocation-bulk.facade';
import { AllocationChartFacade } from 'src/app/spaceplan/allocations/facades/allocation-chart.facade';
import { AllocationDataStoreFacade } from 'src/app/spaceplan/shared/facades/allocation-data-store.facade';
import { EditorAllocationFacade, UIFacade } from '../../../../facades';

@Component({
  selector: 'app-editor-allocation',
  templateUrl: './editor-allocation.component.html',
  styleUrls: ['./editor-allocation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorAllocationComponent implements OnDestroy {
  public form: FormGroup;
  public isRequesting = false;
  public options$ = combineLatest([
    this.editorFacade.mode$,
    this.editorFacade.isReadOnly$,
    this.allocationDataStoreFacade.spaceProgram$,
    this.allocationDataStoreFacade.buildings$,
  ]).pipe(
    map(([mode, readOnly, spaceProgram, buildings]) => {
      return {
        mode,
        readOnly,
        buildings: buildings
          .filter((b) => b.measurementUnit === spaceProgram.measurementUnit)
          .sort((a, b) => a.buildingName.localeCompare(b.buildingName)),
      };
    })
  );

  constructor(
    public editorFacade: EditorAllocationFacade,
    private fb: FormBuilder,
    public uiFacade: UIFacade,
    public allocationDataStoreFacade: AllocationDataStoreFacade,
    private allocationChartFacade: AllocationChartFacade,
    private allocationBulkFacade: AllocationBulkFacade
  ) {
    this.form = this.fb.group({});
  }
  ngOnDestroy(): void {
    this.editorFacade.resetToDefault();
  }

  onSubmit() {
    this.isRequesting = true;
    if (!this.form.invalid) {
      this.uiFacade.showLoading();
      this.editorFacade.isNew$.pipe(take(1)).subscribe((isNew) => {
        this.editorFacade.save(true).subscribe(
          (result) => {
            if (result.success) {
              // SAVE SUCESS
              this.form.markAsUntouched();
              this.form.markAsPristine();
              this.isRequesting = false;
              this.uiFacade.hideLoading();

              if (isNew) {
                this.allocationChartFacade.resetToDefault();
                this.allocationBulkFacade.resetToDefault();
                this.uiFacade.navigateToSpaceProgramAllocationDetail(result.model.spaceProgramID, result.model.allocationID);
              }
            }
          },
          (err) => {
            this.uiFacade.hideLoading();
          }
        );
      });
    }
  }
  onClose() {
    this.editorFacade.close();
  }
}
