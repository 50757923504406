import { Injectable } from '@angular/core';
import { LookupFacadeBase, LookupFacadeManager } from './lookup-facade-base';
import { Scenario, ScenarioClient } from 'src/app/core/services/api-clients';
import { UIFacade } from './ui.facade';
import { of } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';

@Injectable({
  providedIn: 'root',
})
export class LookupScenarioFacade extends LookupFacadeBase<Scenario> {
  constructor(
    protected modalService: BsModalService,
    protected uiFacade: UIFacade,
    protected lookupFacadeManager: LookupFacadeManager,
    private scenarioClient: ScenarioClient
  ) {
    super(modalService, uiFacade, lookupFacadeManager);
  }

  getLookupItemsData() {
    const projectID = this.state.filters?.projectID;
    if (projectID) {
        return this.scenarioClient.getList(projectID);
    }
    return of([]);
  }

  applyFilters(items: Scenario[], filters?: Partial<Scenario>): Scenario[] {
    return items;
  }
}
