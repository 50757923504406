import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TemplateGenerator, UserRole } from 'src/app/core/services/api-clients';
import { TranslocoService } from '@ngneat/transloco';
import { bufferCount, filter, map } from 'rxjs/operators';
import { EditorFacadeBase, UIFacade } from 'src/app/spaceplan/shared/facades';
import { deepEquals } from 'src/app/spaceplan/shared/functions/deep-equals';
import { TemplateDataStoreFacade } from './template-data-store.facade';

/**
 * t(spaceplan.Generator)
 */
@Injectable({
  providedIn: 'root',
})
export class EditorTemplateGeneratorFacade extends EditorFacadeBase<TemplateGenerator> {
  constructor(
    protected toastr: ToastrService,
    protected modalService: BsModalService,
    protected uiFacade: UIFacade,
    protected translocoService: TranslocoService,
    private dataStoreFacade: TemplateDataStoreFacade
  ) {
    super(toastr, modalService, uiFacade, translocoService);
    this.dataStoreFacade.template$
      .pipe(
        bufferCount(2, 1),
        filter(([a, b]) => b?.templateID !== a?.templateID),
        map(([a, b]) => b)
      )
      .subscribe(() => {
        this.resetToDefault();
      });
  }
  protected className(): string {
    return this.translocoService.translate('spaceplan.Generator');
  }
  protected getFromDataStore(id: string): Observable<TemplateGenerator> {
    return this.dataStoreFacade.getGenerator(id);
  }
  protected createDataStore(model: TemplateGenerator): Observable<TemplateGenerator> {
    return this.dataStoreFacade.createGenerator(model);
  }
  protected updateDataStore(model: TemplateGenerator): Observable<TemplateGenerator> {
    return this.dataStoreFacade.updateGenerator(model);
  }
  protected deleteDataStore(id: string): Observable<boolean> {
    return this.dataStoreFacade.deleteGenerator(id);
  }
  protected getModelId(model: TemplateGenerator): string {
    return model?.templateGeneratorID;
  }
  protected setModelId(model: TemplateGenerator, id: string) {
    model.templateGeneratorID = id;
  }
  protected userCanEdit(): Observable<boolean> {
    // return this.dataStoreFacade.spaceProgram$.pipe(map((m) => m.roleNo === UserRole.Composer));
    return of(true);
  }
  protected getNewModelForCopy(init: Partial<TemplateGenerator>): Observable<TemplateGenerator> {
    const copyinit = { ...init, generatorName: null };
    return this.getNewModel(copyinit);
  }
  protected getNewModel(init: Partial<TemplateGenerator>): Observable<TemplateGenerator> {
    const base = {
      generatorDescr: null,
      templateGeneratorID: null,
      generatorInputTypeNo: null,
      generatorName: null,
      groups: null,
      inputWorkPlaceTypeID: null,
      multiplier: null,
      outputByGroupInd: false,
      qualifier: null,
      spaceProgramID: null,
      templateSpaceTypeID: null,
      tags: null,
    };
    return of({
      ...base,
      ...init,
      templateGeneratorID: null,
      templateSpaceTypeID: init.templateSpaceTypeID || null,
      generatorInputTypeNo: init.generatorInputTypeNo || null,
      inputWorkPlaceTypeID: init.inputWorkPlaceTypeID || null,
    } as TemplateGenerator);
  }
  protected equals(x: TemplateGenerator, y: TemplateGenerator): boolean {
    const x1 = { ...x, tags: x?.tags?.sort((a, b) => a.localeCompare(b)) };
    const y1 = { ...y, tags: y?.tags?.sort((a, b) => a.localeCompare(b)) };
    return deepEquals(x1, y1);
  }
}
