import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/account/auth.guard';
import { CommonDataResolver, HomeDataResolver } from './spaceplan/shared/resolvers';

const routes: Routes = [
  // {
  //   path: '',
  //   pathMatch: 'full',
  //   redirectTo: 'create',
  // },
  {
    path: '',
    canLoad: [AuthGuard],
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    runGuardsAndResolvers: 'always',
    resolve: {
      data: HomeDataResolver,
    },
  },
  {
    path: 'create',
    canLoad: [AuthGuard],
    loadChildren: () => import('./spaceplan-create/spaceplan-create.module').then((m) => m.SpacePlanCreateModule),
    runGuardsAndResolvers: 'always',
    resolve: {
      data: CommonDataResolver,
    },
  },
  {
    path: 'template/:id',
    loadChildren: () => import('./template/template.module').then((m) => m.TemplateModule),
    runGuardsAndResolvers: 'always',
    canLoad: [AuthGuard],
  },
  {
    path: ':id',
    canLoad: [AuthGuard],
    loadChildren: () => import('./spaceplan/spaceplan.module').then((m) => m.SpacePlanModule),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'as-is-building',
    canLoad: [AuthGuard],
    loadChildren: () => import('./as-is-buildings/as-is-buildings.module').then((m) => m.AsIsBuildingsModule),
    runGuardsAndResolvers: 'always',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
