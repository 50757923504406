import { Component, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, AbstractControl, Validators } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { EditorSpaceTypeFacade, UIFacade, DataStoreFacade } from '../../../../facades';
import { SpaceProgram } from 'src/app/core/services/api-clients';

@Component({
  selector: 'app-editor-spacetype',
  templateUrl: './editor-spacetype.component.html',
  styleUrls: ['./editor-spacetype.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorSpaceTypeComponent {
  public form: FormGroup;
  public isRequesting = false;
  public options$ = combineLatest([this.dataStoreFacade.spaceProgram$, this.editorFacade.isReadOnly$]).pipe(
    map(([spaceProgram, isReadOnly]) => {
      const measurementUnit = (spaceProgram as SpaceProgram).measurementUnit;
      return {
        measurementUnit,
        readOnly: isReadOnly,
      };
    })
  );

  constructor(
    public editorFacade: EditorSpaceTypeFacade,
    private fb: FormBuilder,
    public uiFacade: UIFacade,
    private dataStoreFacade: DataStoreFacade
  ) {
    this.form = this.fb.group({});
  }

  onSubmit() {
    this.isRequesting = true;
    if (!this.form.invalid) {
      this.uiFacade.showLoading();
      this.editorFacade.save().subscribe(
        (result) => {
          if (result.success) {
            // SAVE SUCESS
            this.form.markAsUntouched();
            this.form.markAsPristine();
            this.isRequesting = false;
            this.uiFacade.hideLoading();
          }
        },
        (err) => {
          this.uiFacade.hideLoading();
        }
      );
    }
  }
  onClose() {
    this.editorFacade.close();
  }
}
