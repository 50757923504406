import {
  Component,
  OnInit,
  Input,
  ViewContainerRef,
  ComponentFactoryResolver,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  OnDestroy,
  ViewChild,
  ComponentRef,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { AccountRepository } from 'src/app/auth/account/state/account.repository';
import { BeaconService } from '../../services/beacon.service';
import { PartnerFilterFacade } from 'src/app/home/facades/partner-filter.facade';
import { environment } from 'src/environments/environment';
import { WebSiteSettingsService } from '../../services/web-settings.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppUserMenuComponent implements OnInit, OnDestroy {
  public displayName$: Observable<string>;
  faPaperPlane = faPaperPlane;
  constructor(accountRepository: AccountRepository, private beaconService: BeaconService, public partnerFilterFacade: PartnerFilterFacade, private websiteSettingsService: WebSiteSettingsService) {
    this.displayName$ = accountRepository.displayName$;
  }

  reworcWebsiteUrl = this.websiteSettingsService.settings.reworcWebSiteUrl;

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  onHelpClicked() {
    this.beaconService.open();
  }
}
