import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DataStoreFacade } from 'src/app/spaceplan/shared/facades/data-store.facade';
import { EditorFacadeBase } from './editor-facade-base';
import { UserFacade } from './user.facade';
import { UIFacade } from './ui.facade';
import { TranslocoService } from '@ngneat/transloco';
import { bufferCount, filter, map } from 'rxjs/operators';
import { UpdateActivityToWorkPlaceTypeDto, UserRole } from 'src/app/core/services/api-clients';

/**
 * t(spaceplan.Behaviors)
 */
@Injectable({
  providedIn: 'root',
})
export class EditorBehaviorsFacade extends EditorFacadeBase<UpdateActivityToWorkPlaceTypeDto> {
  constructor(
    protected toastr: ToastrService,
    protected modalService: BsModalService,
    protected uiFacade: UIFacade,
    protected translocoService: TranslocoService,
    private dataStoreFacade: DataStoreFacade,
    private userFacade: UserFacade
  ) {
    super(toastr, modalService, uiFacade, translocoService);
    this.dataStoreFacade.spaceProgram$
      .pipe(
        bufferCount(2, 1),
        filter(([a, b]) => b?.spaceProgramID !== a?.spaceProgramID),
        map(([a, b]) => b)
      )
      .subscribe(() => {
        this.resetToDefault();
      });
  }
  protected className(): string {
    return this.translocoService.translate('spaceplan.Behaviors');
  }
  protected getFromDataStore(id: string): Observable<UpdateActivityToWorkPlaceTypeDto> {
    return this.dataStoreFacade.getActvityToWorkPlaceType(id);
  }
  protected updateDataStore(model: UpdateActivityToWorkPlaceTypeDto): Observable<UpdateActivityToWorkPlaceTypeDto> {
    return this.dataStoreFacade.updateActvityToWorkPlaceType(model);
  }
  protected getModelId(model: UpdateActivityToWorkPlaceTypeDto): string {
    return model?.activityID;
  }
  protected userCanEdit(): Observable<boolean> {
    return this.dataStoreFacade.spaceProgram$.pipe(map((m) => m.roleNo === UserRole.Composer));
  }
}
