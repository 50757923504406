<ng-container *transloco="let t; read: 'spaceplan'">
  <div *ngIf="form" [formGroup]="form" class="content-body">
    <div class="content-matrix d-flex flex-column">
      <div class="content-matrix-1 row flex-grow-1">
        <div class="col-12 p-0 content-scroll">
          <div class="matrix-list-title">
            <div class="flex-grow-1">
              {{ title }}
            </div>
            <div>
              <button class="btn btn-heatmap btn-sm" type="button"
                [ngClass]="{'btn-primary': showHeatmap, 'btn-outline-secondary': !showHeatmap}"
                (click)="showHeatmap=!showHeatmap">{{ t('Heatmap') }}</button>
              <button class="btn btn-copyto btn-sm ml-1" type="button" [ngClass]="{
                  disabled: (editorFacade.isDirty$ | async),
                  'btn-primary': (selectActivitiesForCopyFacade.isOpen$ | async), 
                  'btn-outline-secondary': !(selectActivitiesForCopyFacade.isOpen$ | async)}"
                [disabled]="(editorFacade.isDirty$ | async)" (click)="toggleCopyTo()">{{ t('Copy_To') }}</button>
            </div>

          </div>
          <div class="d-flex h-100">
            <div class="ml-1 pl-1 pr-1 d-flex flex-column-reverse justify-content-between pt-1 pb-4"
              style="margin-top: 5px; width: 15px; background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1))">
              <div class="mb-1">{{ t('COLLABORATION_RATING_min') }}</div>
              <div style="writing-mode: vertical-rl; width: 11px; color: #fff">{{ t('COLLABORATION_RATING') }}</div>
              <div class="text-white">{{ t('COLLABORATION_RATING_mmx') }}</div>
            </div>

            <div class="d-flex flex-column justify-content-center align-items-center flex-grow-1">
              <div class="matrix-list mb-1 flex-grow-1">
                <ng-container *ngFor="let matrixItem of matrixList; let idx = index">
                  <div class="matrix-box">
                    <div cdkDropList id="{{ matrixItem.id }}" [cdkDropListData]="matrixItem.matrix"
                      [cdkDropListConnectedTo]="connectedTo" class="item-list"
                      (cdkDropListDropped)="drop($event, matrixItem)"
                      *ngIf="matrixItem.matrix?.length; else other_content">
                      <ng-container *ngIf="!options.readOnly; else disabled_content">
                        <div class="item-box d-flex flex-column p-1 flex-grow-1"
                          [ngClass]="getFractionClass(matrixItem.behaviourFraction)"
                          *ngFor="let item of matrixItem.matrix" cdkDrag cdkDragBoundary=".content-body">
                          <div class="d-flex justify-content-center flex-grow-1 text-center align-items-center">
                            {{ item.workPlaceTypeName }}
                          </div>
                          <div class="d-flex justify-content-between align-items-center info-bar"
                            [ngClass]="{'opacity-50': !showHeatmap}">
                            <div>
                              <button class="btn btn-light btn-sm p-0" (click)="removeMatrixItem(matrixItem)">
                                <i class="k-icon k-i-trash"></i>
                              </button>
                            </div>
                            <div>
                              {{ intl.formatNumber(matrixItem.behaviourFraction, 'n1') }}
                            </div>
                          </div>
                        </div>
                      </ng-container>
                      <ng-template #disabled_content>
                        <div class="item-box d-flex flex-column p-1 flex-grow-1"
                          [ngClass]="getFractionClass(matrixItem.behaviourFraction)"
                          *ngFor="let item of matrixItem.matrix">
                          <div class="d-flex justify-content-center flex-grow-1 text-center align-items-center p-2">
                            {{ item.workPlaceTypeName }}
                          </div>
                          <div class="d-flex justify-content-between align-items-center"
                            style="opacity: 0.5; min-height: 20px">
                            <div>
                            </div>
                            <div>
                              {{ intl.formatNumber(matrixItem.behaviourFraction, 'n1') }}
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                    <ng-template #other_content>
                      <div cdkDropList id="{{ matrixItem.id }}" [cdkDropListData]="matrixItem.matrix"
                        [cdkDropListConnectedTo]="connectedTo" class="item-list"
                        (cdkDropListDropped)="drop($event, matrixItem)">
                        <div class="item-box d-flex flex-column p-1 flex-grow-1"
                          [ngClass]="getFractionClass(matrixItem.behaviourFraction)">
                          <div class="d-flex justify-content-center flex-grow-1 text-center">&nbsp;</div>
                          <div class="d-flex justify-content-between align-items-center"
                            style="opacity: 0.5; min-height: 20px">
                            <div></div>
                            <div>
                              {{ intl.formatNumber(matrixItem.behaviourFraction, 'n1') }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                </ng-container>
              </div>
              <div class="d-flex justify-content-between w-100 mt-1 mb-1" style="
                  margin-right: 25px;
                  padding-left: 25px;
                  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
                ">
                <div>{{ t('COMPLEXITY_RATING_min') }}</div>
                <div style="color: #fff; height: 14px">{{ t('COMPLEXITY_RATING') }}</div>
                <div class="text-white pr-1">{{ t('COMPLEXITY_RATING_max') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="content-right" *ngIf="!options.readOnly">

      <ng-template [ngIf]="!(selectActivitiesForCopyFacade.isOpen$ | async)">
        <div class="content-right-1 row">
          <div class="col-12 p-0">
            <div>{{ t('Work_Place_Types') }}</div>
          </div>
        </div>
        <div class="content-right-2 row">
          <div class="col-12 p-0 content-right-2-content">
            <div class="source-container">
              <div cdkDropList id="maxtrix-source" [cdkDropListData]="workPlaceTypes" cdkDropListsortingdisabled
                [cdkDropListEnterPredicate]="noReturnPredicate" [cdkDropListConnectedTo]="connectedTo"
                class="source-list">
                <div class="source-box" *ngFor="let item of workPlaceTypes">
                  <div cdkDrag cdkDragBoundary=".content-body" style="cursor: move; width: 100%">
                    <div class="source-box-drag-item text-center">
                      <div class="img-wrapper">
                        <img [src]="item.imageUrl || 'https://place-hold.it/120x120/CCC'" alt="" />
                      </div>
                      <div class="text-wrapper flex-column">
                        <div class="text-name">
                          {{ item.workPlaceTypeName }}
                        </div>
                        <div class="text-desc">
                          {{ item.suitableFor }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template [ngIf]="selectActivitiesForCopyFacade.isOpen$ | async">
        <div class="content-right-1 row">
          <div class="col-12 p-0">
            <div>{{ t('Activity_Copy_To') }}</div>
          </div>
        </div>
        <div class="content-right-2 row">
          <div class="col-12 p-0 content-right-2-content has-footer-action-bar">
            <div class="row">
              <div class="col-12 p-0 scroll-area">
                <div *ngFor="let item of (selectActivitiesForCopyFacade.activitiesList$ | async)" class="m-1">
                  <button class="btn btn-outline-primary btn-block text-left p-3" type="button"
                    (click)="toggleCopyToSelection(item)">
                    <i class="k-icon" [ngClass]="{
                        'k-i-checkbox': !item.isSelected,
                        'k-i-checkbox-checked': item.isSelected
                      }"></i>
                    <span style="line-height: 16px;vertical-align: bottom;">
                      {{ item.activity.activityName }}
                    </span>

                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-right-3 row">
          <div class="col-12 p-0">
            <button class="btn btn-primary btn-sm" (click)="onApplyCopy()"
              [disabled]="!(selectActivitiesForCopyFacade.selectedItems$ | async)?.length"
              [ngClass]="{ disabled: !(selectActivitiesForCopyFacade.selectedItems$ | async)?.length }"> {{ t('Apply')
              }} </button>
          </div>
        </div>
      </ng-template>

    </div>
  </div>
</ng-container>