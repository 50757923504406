import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DataStoreFacade } from 'src/app/spaceplan/shared/facades/data-store.facade';
import { Generator, UserRole } from 'src/app/core/services/api-clients';
import { EditorFacadeBase } from './editor-facade-base';
import { UserFacade } from './user.facade';
import { UIFacade } from './ui.facade';
import { TranslocoService } from '@ngneat/transloco';
import { bufferCount, filter, map } from 'rxjs/operators';
import { deepEquals } from '../functions/deep-equals';

/**
 * t(spaceplan.Generator)
 */
@Injectable({
  providedIn: 'root',
})
export class EditorGeneratorFacade extends EditorFacadeBase<Generator> {
  constructor(
    protected toastr: ToastrService,
    protected modalService: BsModalService,
    protected uiFacade: UIFacade,
    protected translocoService: TranslocoService,
    private dataStoreFacade: DataStoreFacade,
    private userFacade: UserFacade
  ) {
    super(toastr, modalService, uiFacade, translocoService);
    this.dataStoreFacade.spaceProgram$
      .pipe(
        bufferCount(2, 1),
        filter(([a, b]) => b?.spaceProgramID !== a?.spaceProgramID),
        map(([a, b]) => b)
      )
      .subscribe(() => {
        this.resetToDefault();
      });
  }
  protected className(): string {
    return this.translocoService.translate('spaceplan.Generator');
  }
  protected getFromDataStore(id: string): Observable<Generator> {
    return this.dataStoreFacade.getGenerator(id);
  }
  protected createDataStore(model: Generator): Observable<Generator> {
    return this.dataStoreFacade.createGenerator(model);
  }
  protected updateDataStore(model: Generator): Observable<Generator> {
    return this.dataStoreFacade.updateGenerator(model);
  }
  protected deleteDataStore(id: string): Observable<boolean> {
    return this.dataStoreFacade.deleteGenerator(id);
  }
  protected getModelId(model: Generator): string {
    return model?.generatorID;
  }
  protected setModelId(model: Generator, id: string) {
    model.generatorID = id;
  }
  protected userCanEdit(): Observable<boolean> {
    return this.dataStoreFacade.spaceProgram$.pipe(map((m) => m.roleNo === UserRole.Composer));
  }
  protected getNewModelForCopy(init: Partial<Generator>): Observable<Generator> {
    const copyinit = { ...init, generatorName: null };
    return this.getNewModel(copyinit);
  }
  protected getNewModel(init: Partial<Generator>): Observable<Generator> {
    const base = {
      generatorDescr: null,
      generatorID: null,
      generatorInputTypeNo: null,
      generatorName: null,
      groups: null,
      inputWorkPlaceTypeID: null,
      multiplier: null,
      outputByGroupInd: false,
      qualifier: null,
      spaceProgramID: null,
      spaceTypeID: null,
      tags: null,
    };
    return of({
      ...base,
      ...init,
      generatorID: null,
      spaceTypeID: init.spaceTypeID || null,
      generatorInputTypeNo: init.generatorInputTypeNo || null,
      inputWorkPlaceTypeID: init.inputWorkPlaceTypeID || null,
    } as Generator);
  }
  protected equals(x: Generator, y: Generator): boolean {
    const x1 = { ...x, tags: x?.tags?.sort((a, b) => a.localeCompare(b)), groups: x?.groups?.sort((a, b) => a.localeCompare(b)) };
    const y1 = { ...y, tags: y?.tags?.sort((a, b) => a.localeCompare(b)), groups: y?.groups?.sort((a, b) => a.localeCompare(b)) };
    return deepEquals(x1, y1);
  }
}
