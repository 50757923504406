import { NgModule } from '@angular/core';
import { CurrencyPipe, CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IntlModule } from '@progress/kendo-angular-intl';
import { MenusModule } from '@progress/kendo-angular-menu';
import { ButtonModule, ButtonGroupModule } from '@progress/kendo-angular-buttons';
import { ImageToDataUrlModule } from 'ngx-image2dataurl';

import { InputsModule } from '@progress/kendo-angular-inputs';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormValidationErrorsDirective } from '../directives/form-validation-errors.directive';
import { NumberOnlyDirective } from '../directives/number-only.directive';
import { NumberOnlyIntegerDirective } from '../directives/number-only-integer.directive';
import { DecimalNumberOnlyDirective } from '../directives/decimal-number-only.directive';
import { FormHandleEnterKeyDirective } from '../directives/form-handle-enter-key.directive';

import { ModalValidationComponent } from '../components/modal-validation/modal-validation.component';
import { ModalConfirmComponent } from '../components/modal-confirm/modal-confirm.component';
import { ModalErrorComponent } from '../components/modal-error/modal-error.component';
import { EditorAllocationComponent } from '../editors/allocation/containers/editor-allocation/editor-allocation.component';
import { EditorAllocationFormComponent } from '../editors/allocation/components/editor-allocation-form/editor-allocation-form.component';
import { EditorBehavioursFormComponent } from '../editors/behaviours/editor-behaviours-form.component';
import { EditorGeneratorComponent } from '../editors/generator/containers/editor-generator/editor-generator.component';
import { EditorGeneratorFormComponent } from '../editors/generator/components/editor-generator-form/editor-generator-form.component';
import { EditorSpaceTypeComponent } from '../editors/space-type/containers/editor-spacetype/editor-spacetype.component';
import { EditorSpaceTypeFormComponent } from '../editors/space-type/components/editor-spacetype-form/editor-spacetype-form.component';
import { EditorWorkPlaceTypeComponent } from '../editors/workplace-type/containers/editor-workplacetype/editor-workplacetype.component';
import { EditorWorkPlaceTypeFormComponent } from '../editors/workplace-type/components/editor-workplacetype-form/editor-workplacetype-form.component';
import { EditorTagsComponent } from '../editors/tags/container/editor-tags.component';
import { EditorTagListComponent } from '../editors/tags/components/editor-tag-list.component';
import { EditorSpacePlanTemplateComponent } from '../editors/spaceplan-template/containers/editor-spaceplan-template/editor-spaceplan-template.component';
import { EditorSpaceplanTemplateFormComponent } from '../editors/spaceplan-template/components/editor-spaceplan-template-form/editor-spaceplan-template-form.component';
import { TranslocoRootModule } from 'src/app/transloco-root.module';
import { AppUserMenuComponent } from 'src/app/shared/components/user-menu/user-menu.component';
import { ShellNavigationComponent } from 'src/app/shared/components/navigation/navigation.component';
import { AppImageUploadComponent } from '../components/image-upload/app-image-upload.component';
import { RichTextEditorComponent } from '../components/rich-text-editor/rich-text-editor.component';
import { LookupComponent } from '../components/lookup/lookup.component';
import { LookupTemplateButtonComponent } from '../components/lookup-button/lookup-template-button.component';
import { LookupProjectButtonComponent } from '../components/lookup-button/lookup-project-button.component';
import { LookupScenarioButtonComponent } from '../components/lookup-button/lookup-scenario-button.component';
import { DebounceClickDirective } from '../directives/debounce-click.directive';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { EditorBuildingComponent } from '../editors/building/containers/editor-building/editor-building.component';
import { EditorBuildingFormComponent } from '../editors/building/components/editor-building-form/editor-building-form.component';
import { EditorFolderComponent } from '../editors/folder/containers/editor-folder/editor-folder.component';
import { EditorFolderFormComponent } from '../editors/folder/components/editor-folder-form/editor-folder-form.component';
import { EditorSpaceProgramCreateComponent } from '../editors/space-program/create/components/editor-space-program-create/editor-space-program-create.component';
import { EditorSpaceProgramCreateFormComponent } from '../editors/space-program/create/components/editor-space-program-create-form/editor-space-program-create-form.component';
import { EditorSpaceProgramSettingsComponent } from '../editors/space-program/settings/components/editor-space-program-settings/editor-space-program-settings.component';
import { EditorSpaceProgramSettingsFormComponent } from '../editors/space-program/settings/components/editor-space-program-settings-form/editor-space-program-settings-form.component';
import { EditorSpaceProgramPrefaceComponent } from '../editors/space-program/preface/components/editor-space-program-preface/editor-space-program-preface.component';
import { EditorSpaceProgramPrefaceFormComponent } from '../editors/space-program/preface/components/editor-space-program-preface-form/editor-space-program-preface-form.component';
import { EditorTemplateComponent } from '../editors/template/containers/editor-template/editor-template.component';
import { EditorTemplateFormComponent } from '../editors/template/components/editor-template-form/editor-template-form.component';
import { EditorTemplateCopyFormComponent } from '../editors/template/components/editor-template-copy-form/editor-template-copy-form.component';
import { EditorUserFormComponent } from '../editors/users/components/editor-user-form/editor-user-form.component';
import { EditorUserComponent } from '../editors/users/containers/editor-user.component';
import { EditorUsersInviteFormComponent } from '../editors/users/components/editor-users-invite-form/editor-users-invite-form.component';
import { HasPermissionDirective } from '../directives/has-permission.directive';
import { SafeCkEditorHtmlPipe } from '../pipes/safe-ck-editor-html.pipe';
import { faFilePdf, faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { faChevronRight, faPencilAlt, faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { EditorGeneratorReadComponent } from '../editors/generator/containers/editor-generator-read/editor-generator-read.component';
import { EditorSpaceTypeReadComponent } from '../editors/space-type/containers/editor-spacetype-read/editor-spacetype-read.component';
import { GeneratorFriendlyNamePipe } from '../pipes/generator-friendly-name';
import { TemplateGeneratorFriendlyNamePipe } from '../pipes/template-generator-friendly-name';
import { LookupSpaceTypeButtonComponent } from '../components/lookup-button/lookup-spacetypes-button.component';
import { PopupAnchorDirective } from '../directives/popup.anchor-target.directive';
import { GridGroupNamePipe } from '../pipes/grid-group-name';
import { GridGroupGeneratorIDPipe } from '../pipes/grid-group-generatorID';

@NgModule({
  entryComponents: [ModalValidationComponent, ModalConfirmComponent, ModalErrorComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    IntlModule,
    DragDropModule,
    MenusModule,
    ButtonModule,
    ButtonGroupModule,
    ChartsModule,
    ImageToDataUrlModule,
    TranslocoRootModule,
    CKEditorModule,
    PDFExportModule,
    ModalModule,
    TooltipModule.forRoot(),
    InputsModule,
  ],
  declarations: [
    LookupComponent,
    LookupTemplateButtonComponent,
    LookupProjectButtonComponent,
    LookupScenarioButtonComponent,
    LookupSpaceTypeButtonComponent,
    AppImageUploadComponent,
    RichTextEditorComponent,
    FormValidationErrorsDirective,
    NumberOnlyDirective,
    NumberOnlyIntegerDirective,
    DecimalNumberOnlyDirective,
    FormHandleEnterKeyDirective,
    DebounceClickDirective,
    PopupAnchorDirective,
    HasPermissionDirective,
    ModalValidationComponent,
    ModalConfirmComponent,
    ModalErrorComponent,
    EditorBehavioursFormComponent,
    EditorBuildingComponent,
    EditorBuildingFormComponent,
    EditorFolderComponent,
    EditorFolderFormComponent,
    EditorGeneratorComponent,
    EditorGeneratorReadComponent,
    EditorGeneratorFormComponent,
    EditorSpaceTypeComponent,
    EditorSpaceTypeReadComponent,
    EditorSpaceTypeFormComponent,
    EditorSpaceProgramCreateComponent,
    EditorSpaceProgramCreateFormComponent,
    EditorSpaceProgramSettingsComponent,
    EditorSpaceProgramSettingsFormComponent,
    EditorSpaceProgramPrefaceComponent,
    EditorSpaceProgramPrefaceFormComponent,
    EditorTagsComponent,
    EditorTagListComponent,
    EditorWorkPlaceTypeComponent,
    EditorWorkPlaceTypeFormComponent,
    EditorAllocationComponent,
    EditorAllocationFormComponent,
    EditorSpacePlanTemplateComponent,
    EditorSpaceplanTemplateFormComponent,
    EditorTemplateComponent,
    EditorTemplateFormComponent,
    EditorUserComponent,
    EditorUserFormComponent,
    EditorUsersInviteFormComponent,
    EditorTemplateCopyFormComponent,
    AppUserMenuComponent,
    ShellNavigationComponent,
    SafeCkEditorHtmlPipe,
    GeneratorFriendlyNamePipe,
    TemplateGeneratorFriendlyNamePipe,
    GridGroupNamePipe,
    GridGroupGeneratorIDPipe,
  ],
  exports: [
    AppImageUploadComponent,
    FormValidationErrorsDirective,
    HasPermissionDirective,
    RichTextEditorComponent,
    DebounceClickDirective,
    PopupAnchorDirective,
    ModalValidationComponent,
    ModalConfirmComponent,
    ModalErrorComponent,
    EditorBehavioursFormComponent,
    EditorBuildingComponent,
    EditorBuildingFormComponent,
    EditorFolderComponent,
    EditorFolderFormComponent,
    EditorGeneratorComponent,
    EditorGeneratorReadComponent,
    EditorGeneratorFormComponent,
    EditorSpaceTypeComponent,
    EditorSpaceTypeReadComponent,
    EditorSpaceTypeFormComponent,
    EditorSpaceProgramCreateComponent,
    EditorSpaceProgramCreateFormComponent,
    EditorSpaceProgramSettingsComponent,
    EditorSpaceProgramSettingsFormComponent,
    EditorSpaceProgramPrefaceComponent,
    EditorSpaceProgramPrefaceFormComponent,
    EditorTagsComponent,
    EditorTagListComponent,
    EditorWorkPlaceTypeComponent,
    EditorWorkPlaceTypeFormComponent,
    EditorAllocationComponent,
    EditorAllocationFormComponent,
    EditorSpacePlanTemplateComponent,
    EditorSpaceplanTemplateFormComponent,
    EditorTemplateComponent,
    EditorTemplateFormComponent,
    EditorTemplateCopyFormComponent,
    EditorUserComponent,
    EditorUserFormComponent,
    EditorUsersInviteFormComponent,
    AppUserMenuComponent,
    ShellNavigationComponent,
    FontAwesomeModule,
    TranslocoRootModule,
    PDFExportModule,
    ModalModule,
    CKEditorModule,
    ChartsModule,
    SafeCkEditorHtmlPipe,
    GeneratorFriendlyNamePipe,
    TemplateGeneratorFriendlyNamePipe,
    GridGroupNamePipe,
    GridGroupGeneratorIDPipe,
    TooltipModule,
    InputsModule,
  ],
  providers: [
    // BlockUIService
    CurrencyPipe,
  ],
})
export class SharedModule {
  constructor(private library: FaIconLibrary) {
    library.addIcons(faFilePdf, faFileExcel, faChevronRight, faPencilAlt, faArrowDown, faArrowUp);
  }
}
