import { enableProdMode, ApplicationRef, NgModuleRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { devTools } from '@ngneat/elf-devtools';
import { enableElfProdMode } from '@ngneat/elf';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  enableElfProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then((value: NgModuleRef<AppModule>) => {
    // if (!environment.production) {
      devTools({
        postTimelineUpdate: () => value.injector.get(ApplicationRef).tick()
      });
    // }
  })
  .catch(err => console.error(err));
