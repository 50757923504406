<ng-container *transloco="let t; read: 'spaceplancreate'">
  <div id="editor-form-top" *ngIf="form" [formGroup]="form">
    <div class="form-group form-row mb-1">
      <label for="spaceProgramName" class="col-sm-12 col-form-label">{{
        t('editorSpaceProgramCreateForm.spaceProgramName.label') }}:</label>
      <div class="col-sm-12">
        <div class="input-group">
          <input type="text" class="form-control" id="spaceProgramName"
            placeholder="{{ t('editorSpaceProgramCreateForm.spaceProgramName.placeholder') }}" name="spaceProgramName"
            formControlName="spaceProgramName" />
        </div>
        <div *ngIf="isRequesting && form.get('spaceProgramName').errors" class="invalid-feedback">
          <div class="form-error" *ngIf="form.controls['spaceProgramName'].errors.required">
            {{ t('editorSpaceProgramCreateForm.spaceProgramName.error.required') }}
          </div>
          <div class="form-error" *ngIf="form.controls['spaceProgramName'].errors.duplicate">
            {{ t('editorSpaceProgramCreateForm.spaceProgramName.error.duplicate') }}
          </div>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-1">
      <label for="spaceProgramDescr" class="col-sm-12 col-form-label">{{
        t('editorSpaceProgramCreateForm.spaceProgramDescr.label') }}:</label>
      <div class="col-sm-12">
        <div class="input-group mb-1">
          <textarea name="spaceProgramDescr" id="spaceProgramDescr"
            placeholder="{{ t('editorSpaceProgramCreateForm.spaceProgramDescr.placeholder') }}" class="form-control"
            formControlName="spaceProgramDescr" rows="6" style="resize: none;"></textarea>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-1">
      <label for="partnerID" class="col-sm-12 col-form-label">{{ t('editorSpaceProgramCreateForm.partnerID.label')
        }}:</label>
      <div class="col-sm-12">
        <div class="input-group">
          <select class="custom-select" id="partnerID" name="partnerID" formControlName="partnerID"
            style="border-radius: 0.25rem;" (change)="onPartnerChange()">
            <option [ngValue]="null" *ngIf="options.partners.length > 1">
              {{ t('editorSpaceProgramCreateForm.partnerID.option.null_Please_select_partner') }}</option>
            <ng-template ngFor let-partner [ngForOf]="options.partners">
              <option [ngValue]="partner.partnerID">{{ partner.partnerDescr }}</option>
            </ng-template>
          </select>
        </div>
        <div *ngIf="isRequesting && form.get('partnerID').errors" class="invalid-feedback">
          <div class="form-error" *ngIf="form.controls['partnerID'].errors.required">
            {{ t('editorSpaceProgramCreateForm.partnerID.error.required') }}
          </div>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-1" *ngIf="templates$ | async as templates">
      <label for="templateID" class="col-sm-12 col-form-label">{{ t('editorSpaceProgramCreateForm.templateID.label')
        }}:</label>
      <div class="col-sm-12">
        <div class="input-group">
          <select class="custom-select" [attr.disabled]="form.get('partnerID').value === null ? '' : null"
            id="templateID" name="templateID" formControlName="templateID" style="border-radius: 0.25rem;"
            (change)="onTemplateChange()">
            <option [ngValue]="null">{{ t('editorSpaceProgramCreateForm.templateID.option.null_Please_select_template')
              }}</option>
            <ng-template ngFor let-template [ngForOf]="templates">
              <option [ngValue]="template.templateID">{{ template.templateName }}</option>
            </ng-template>
          </select>
          <app-lookup-template-button [partnerID]="form.controls['partnerID'].value" (click)="toggleSpaceTypesLookup()">
          </app-lookup-template-button>
          <!-- <app-lookup-spacetypes-button [templateID]="form.controls['templateID'].value"></app-lookup-spacetypes-button> -->
        </div>
        <div *ngIf="isRequesting && form.get('templateID').errors" class="invalid-feedback">
          <div class="form-error" *ngIf="form.controls['templateID'].errors.required">
            {{ t('editorSpaceProgramCreateForm.templateID.error.required') }}
          </div>
        </div>
      </div>
      <div class="col-sm-12 d-flex"
        *ngIf="form.controls['templateID'].value && (templateSpaceTypes$ | async)?.length && (templateSpaceTypesCounts$ | async) as templateSpaceTypesCounts">
        <div style="margin: 8px 10px 10px 10px;">
          <button type="button" [disabled]="!form.controls['templateID'].value" class="btn btn-link"
            [ngStyle]="{ 'color': ((templateSpaceTypesCounts.selectedCount === templateSpaceTypesCounts.totalCount || !templateSpaceTypesCounts.selectedCount) ? '#454545' : 'orange') }"
            ngClass="{ disabled: !form.controls['templateID'].value }"
            style="padding: 0; margin-left: 4px;font-style: italic;" (click)="toggleTemplateSpaceTypesLookup()">
            <span>{{ templateSpaceTypesCounts.caption }}</span>
          </button>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-1">
      <label for="measurementUnit" class="col-sm-12 col-form-label">{{
        t('editorSpaceProgramCreateForm.measurementUnit.label') }}:</label>
      <div class="col-sm-12">
        <div class="input-group">
          <select class="custom-select" id="measurementUnit" name="measurementUnit" formControlName="measurementUnit"
            style="border-radius: 0.25rem;">
            <option [ngValue]="null">
              {{ t('editorSpaceProgramCreateForm.measurementUnit.option.null_Please_select_unit_of_measure') }}</option>
            <option [ngValue]="0">{{ t('editorSpaceProgramCreateForm.measurementUnit.option.0_Metric') }}</option>
            <option [ngValue]="1">{{ t('editorSpaceProgramCreateForm.measurementUnit.option.1_Imperial') }}</option>
          </select>
        </div>
        <div *ngIf="isRequesting && form.get('measurementUnit').errors" class="invalid-feedback">
          <div class="form-error" *ngIf="form.controls['measurementUnit'].errors.required">
            {{ t('editorSpaceProgramCreateForm.measurementUnit.error.required') }}
          </div>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-1">
      <label for="percFTEForGuest" class="col-sm-12 col-form-label">{{
        t('editorSpaceProgramCreateForm.percFTEForGuest.label') }}:</label>
      <div class="col-sm-12">
        <div class="input-group">
          <input type="text" class="form-control" id="percFTEForGuest"
            placeholder="{{ t('editorSpaceProgramCreateForm.percFTEForGuest.placeholder') }}" name="percFTEForGuest"
            formControlName="percFTEForGuest" />
        </div>
        <div *ngIf="isRequesting && form.get('percFTEForGuest').errors" class="invalid-feedback">
          <div class="form-error" *ngIf="form.controls['percFTEForGuest'].errors.required">
            {{ t('editorSpaceProgramCreateForm.percFTEForGuest.error.required') }}
          </div>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-1" *ngIf="folders$ | async as folders">
      <label for="projectID" class="col-sm-12 col-form-label">{{ t('editorSpaceProgramCreateForm.folderID.label')
        }}:</label>
      <div class="col-sm-12">
        <div class="input-group">
          <select [attr.disabled]="form.get('partnerID').value === null ? '' : null" class="custom-select" id="folderID"
            name="folderID" formControlName="folderID" style="border-radius: 0.25rem;">
            <option [ngValue]="null">{{ t('editorSpaceProgramCreateForm.folderID.option.null_Please_select_folder') }}
            </option>

            <ng-template ngFor let-folder [ngForOf]="folders">
              <option [ngValue]="folder.folderID">{{ folder.folderName }}</option>
            </ng-template>
          </select>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-1" *ngIf="projects$ | async as projects">
      <label for="projectID" class="col-sm-12 col-form-label">{{ t('editorSpaceProgramCreateForm.projectID.label')
        }}:</label>
      <div class="col-sm-12">
        <div class="input-group">
          <select [attr.disabled]="form.get('partnerID').value === null ? '' : null" class="custom-select"
            id="projectID" name="projectID" formControlName="projectID" style="border-radius: 0.25rem;">
            <option [ngValue]="null">{{ t('editorSpaceProgramCreateForm.projectID.option.null_Please_select_project') }}
            </option>

            <ng-template ngFor let-project [ngForOf]="projects">
              <option [ngValue]="project.projectID">{{ project.projectName }}</option>
            </ng-template>
          </select>
          <app-lookup-project-button [partnerID]="form.controls['partnerID'].value" (click)="toggleProjectLookup()">
          </app-lookup-project-button>
        </div>
        <div *ngIf="isRequesting && form.get('projectID').errors" class="invalid-feedback">
          <div class="form-error" *ngIf="form.controls['projectID'].errors.required">
            {{ t('editorSpaceProgramCreateForm.projectID.error.required') }}
          </div>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-1" *ngIf="scenarios$ | async as scenarios">
      <label for="scenarioID" class="col-sm-12 col-form-label">{{ t('editorSpaceProgramCreateForm.scenarioID.label')
        }}:</label>
      <div class="col-sm-12">
        <div class="input-group">
          <select [attr.disabled]="form.get('projectID').value === null ? '' : null" class="custom-select"
            id="scenarioID" name="scenarioID" formControlName="scenarioID" style="border-radius: 0.25rem;">
            <option [ngValue]="null">{{ t('editorSpaceProgramCreateForm.scenarioID.option.null_Please_select_scenario')
              }}</option>

            <ng-template ngFor let-scenario [ngForOf]="scenarios">
              <option [ngValue]="scenario.scenarioID">{{ scenario.scenarioName }}</option>
            </ng-template>
          </select>
          <app-lookup-scenario-button [projectID]="form.controls['projectID'].value" (click)="toggleScenarioLookup()">
          </app-lookup-scenario-button>
        </div>
        <div *ngIf="isRequesting && form.get('scenarioID').errors" class="invalid-feedback">
          <div class="form-error" *ngIf="form.controls['scenarioID'].errors.required">
            {{ t('editorSpaceProgramCreateForm.scenarioID.error.required') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>