import { Injectable, NgZone } from '@angular/core';
import { Observable, BehaviorSubject, forkJoin, of, throwError, combineLatest, Subject } from 'rxjs';
import {
  AllocationClient,
  AllocationRuleClient,
  AllocationBuildingClient,
  BuildingClient,
  BuildingFloorClient,
  BuildingZoneClient,
  BuildingFloorZoneClient,
  Allocation,
  Allocation2,
  AllocationRule,
  AllocationBuilding,
  Building,
  BuildingFloor,
  BuildingZone,
  BuildingFloorZone,
  CreateAllocationDto,
  UpdateAllocationDto,
  UpdateAllocationRulesDto,
  UpdateAllocationRuleDto,
  DuplicateAllocationDto,
} from '../../../core/services/api-clients';
import { map, take, switchMap, catchError, tap, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';
import { UIFacade } from './ui.facade';
import { getBrowserLang, TranslocoService } from '@ngneat/transloco';
import { DataStoreFacade } from './data-store.facade';
import { AllocationAddEditDto } from '.';
import { Guid } from 'guid-typescript';
import keyBy from 'lodash-es/keyBy';

@Injectable({
  providedIn: 'root',
})
export class AllocationDataStoreFacade {
  private readonly dataStoreStateSubject = new BehaviorSubject<AllocationDataStoreState>({ ...AllocationDataStoreStateDefault });
  private readonly resetAllocationChildStateSubject = new Subject<any>();
  state$ = this.dataStoreStateSubject.asObservable();
  resetAllocationChildState$ = this.resetAllocationChildStateSubject.asObservable();
  allocations$ = this.state$.pipe(
    map((m) => m.allocations),
    distinctUntilChanged()
  );
  allocationID$ = this.state$.pipe(
    map((m) => m.allocationID),
    distinctUntilChanged()
  );
  allocation$ = this.state$.pipe(
    map((m) => m.allocation),
    distinctUntilChanged()
  );
  allocationRules$ = this.state$.pipe(
    map((m) => m.allocationRules),
    distinctUntilChanged()
  );
  buildings$ = this.state$.pipe(
    map((m) => m.buildings),
    distinctUntilChanged()
  );
  buildingFloors$ = this.state$.pipe(
    map((m) => m.buildingFloors),
    distinctUntilChanged()
  );
  buildingZones$ = this.state$.pipe(
    map((m) => m.buildingZones),
    distinctUntilChanged()
  );
  buildingFloorZones$ = this.state$.pipe(
    map((m) => m.buildingFloorZones),
    distinctUntilChanged()
  );
  spaceProgram$ = this.dataStoreFacade.spaceProgram$;

  spaceTypesDict$ = this.dataStoreFacade.spaceTypesDict$;

  constructor(
    private dataStoreFacade: DataStoreFacade,
    private allocationClient: AllocationClient,
    private allocationRuleClient: AllocationRuleClient,
    private allocationBuildingClient: AllocationBuildingClient,
    private buildingClient: BuildingClient,
    private buildingFloorClient: BuildingFloorClient,
    private buildingZoneClient: BuildingZoneClient,
    private buildingFloorZoneClient: BuildingFloorZoneClient,
    private uiFacade: UIFacade,
    private translocoService: TranslocoService,
    private ngZone: NgZone
  ) {}

  get state(): AllocationDataStoreState {
    return this.dataStoreStateSubject.getValue();
  }

  loadAllocationsList(spaceProgramID: string) {
    return forkJoin([
      this.allocationClient.getList(spaceProgramID),
      this.allocationBuildingClient.getList(spaceProgramID),
      this.buildingClient.getListForSpaceProgram(spaceProgramID),
    ]).pipe(
      map(([allocations, allocationBuildings, buildings]) => {
        const allocations2 = allocations
          .map((allocation) => {
            const tempBuildings = allocationBuildings
              .filter((f) => f.allocationID === allocation.allocationID)
              .map((ab) => {
                const building = buildings.find((f) => f.buildingID === ab.buildingID);

                return {
                  ...building,
                  noOfRules: ab.noOfRules ?? 0,
                };
              })
              .sort((a, b) => a.buildingName.localeCompare(b.buildingName));

            return {
              ...allocation,
              buildings: tempBuildings,
            };
          })
          .sort((a, b) => a.allocationName.localeCompare(b.allocationName));

        return { allocations: allocations2, buildings };
      }),
      tap(({ allocations, buildings }) => {
        const newstate: AllocationDataStoreState = {
          ...this.dataStoreStateSubject.value,
          allocations,
          buildings,
        };
        this.dataStoreStateSubject.next(newstate);
      }),
      catchError(this.handleError)
    );
  }

  loadAllocation(allocationID: string) {
    return this.allocations$.pipe(
      take(1),
      map((allocations) => {
        return allocations.find((f) => f.allocationID === allocationID);
      }),
      switchMap((allocation) => {
        return combineLatest([
          this.allocationRuleClient.getList(allocationID),
          this.buildingFloorClient.getListForAllocation(allocationID),
          this.buildingZoneClient.getListForAllocation(allocationID),
          this.buildingFloorZoneClient.getListForAllocation(allocationID),
        ]).pipe(
          map(([allocationRules, floors, zones, floorZones]) => {
            const buildingFloors = floors;
            const buildingZones = zones;
            const buildingFloorZones = floorZones;
            return { allocationID, allocation, allocationRules, buildingFloors, buildingZones, buildingFloorZones };
          })
        );
      }),
      tap((results) => {
        const newstate: AllocationDataStoreState = {
          ...this.dataStoreStateSubject.value,
          ...results,
        };
        this.dataStoreStateSubject.next(newstate);
      }),
      catchError(this.handleError)
    );
  }

  clear() {
    this.dataStoreStateSubject.next({ ...AllocationDataStoreStateDefault });
    this.resetAllocationChildStateSubject.next();
  }

  getAllocation(id: string): Observable<AllocationAddEditDto> {
    const allocation = this.dataStoreStateSubject.getValue()?.allocations?.find((f) => f.allocationID === id);
    return of({
      ...allocation,
      buildingIDs: allocation.buildings.map((m) => m.buildingID),
    });
  }
  createAllocation(model: CreateAllocationDto): Observable<AllocationAddEditDto> {
    return this.allocationClient.create(model).pipe(
      withLatestFrom(this.buildings$),
      switchMap(([allocation, buildings]) => {
        const state = this.dataStoreStateSubject.getValue();
        const allocation2: AllocationAddEditDto = { ...allocation };
        delete allocation2.buildingIDs;
        allocation2.buildings = allocation.buildingIDs
          .map((m) => {
            return buildings.find((f) => f.buildingID === m);
          })
          .sort((a, b) => a.buildingName.localeCompare(b.buildingName));
        state.allocations = [...state.allocations, allocation2];
        this.dataStoreStateSubject.next(state);
        return of(allocation);
      })
    );
  }
  duplicateAllocation(model: DuplicateAllocationDto): Observable<DuplicateAllocationDto> {
    return this.allocationClient.duplicate(model).pipe(
      withLatestFrom(this.spaceProgram$),
      switchMap(([_, spaceProgram]) => {
        return this.loadAllocationsList(spaceProgram.spaceProgramID).pipe(
          map(() => {
            return model;
          })
        );
      })
    );
  }
  updateAllocation(model: UpdateAllocationDto): Observable<AllocationAddEditDto> {
    return this.allocationClient.update(model).pipe(
      withLatestFrom(this.buildings$),
      switchMap(([allocation, buildings]) => {
        return this.allocationBuildingClient.getList(model.spaceProgramID).pipe(
          map((allocationBuildings) => {
            //debugger;
            return {
              allocation,
              buildings: buildings.map((building) => {
                return {
                  ...building,
                  noOfRules:
                    allocationBuildings.find((ab) => ab.allocationID === allocation.allocationID && ab.buildingID === building.buildingID)
                      ?.noOfRules ?? 0,
                };
              }),
            };
          })
        );
      }),
      switchMap(({ allocation, buildings }) => {
        const state = this.dataStoreStateSubject.getValue();
        const allocation2: AllocationAddEditDto = { ...allocation };
        delete allocation2.buildingIDs;
        allocation2.buildings = allocation.buildingIDs
          .map((m) => {
            return {
              ...buildings.find((f) => f.buildingID === m),
            };
          })
          .sort((a, b) => a.buildingName.localeCompare(b.buildingName));
        const index = state.allocations?.findIndex((f) => f.allocationID === allocation.allocationID);
        if (index > -1) {
          state.allocations[index] = allocation2;
          state.allocations = [...state.allocations];
          this.dataStoreStateSubject.next(state);
        }
        return of(allocation);
      })
    );
  }
  deleteAllocation(allocationID: string): Observable<boolean> {
    return this.allocationClient.delete(allocationID).pipe(
      switchMap(() => {
        const state = this.dataStoreStateSubject.getValue();
        const allocations = state.allocations?.filter((f) => f.allocationID !== allocationID);
        this.dataStoreStateSubject.next({ ...state, allocations });
        return of(true);
      })
    );
  }

  public updateAllocationRules(
    spaceTypeID: string,
    generatorID: string,
    groupID: string,
    updates: {
      buildingFloorZoneID: string;
      allocatedQty: number;
      overrideQty: number;
    }[]
  ) {
    const allocationRules: UpdateAllocationRuleDto[] = updates.map((m) => {
      return {
        spaceTypeID,
        generatorID,
        groupID,
        ...m,
      };
    });

    return this.allocation$
      .pipe(
        take(1),
        switchMap((allocation) => {
          const model: UpdateAllocationRulesDto = {
            allocationID: allocation.allocationID,
            allocationRules,
          };
          return this.allocationRuleClient.update(model).pipe(map((m) => model));
        })
      )
      .toPromise();
  }

  public updateAllocationRulesBulk(allocationRules: UpdateAllocationRuleDto[]) {
    return this.allocation$
      .pipe(
        take(1),
        switchMap((allocation) => {
          const model: UpdateAllocationRulesDto = {
            allocationID: allocation.allocationID,
            allocationRules,
          };
          return this.allocationRuleClient.update(model).pipe(map((m) => model));
        })
      )
      .toPromise();
  }

  public updateAllocationRulesState(model: UpdateAllocationRulesDto) {
    this.ngZone.runOutsideAngular(() => {
      const currentAllocationRules = [...this.state.allocationRules];
      model.allocationRules.forEach((r) => {
        const idx = currentAllocationRules.findIndex(
          (f) =>
            f &&
            f.spaceTypeID === r.spaceTypeID &&
            f.generatorID === r.generatorID &&
            f.groupID?.toString() === r.groupID?.toString() &&
            f.buildingFloorZoneID === r.buildingFloorZoneID
        );
        if (idx > -1) {
          if (r.allocatedQty || r.allocatedQty === 0 || r.overrideQty || r.overrideQty === 0) {
            currentAllocationRules[idx] = {
              ...currentAllocationRules[idx],
              allocatedQty: r.allocatedQty,
              overrideQty: r.overrideQty,
            };
          } else {
            currentAllocationRules[idx] = null;
          }
        } else {
          currentAllocationRules.push({
            allocationRuleID: Guid.raw(),
            allocationID: model.allocationID,
            spaceTypeID: r.spaceTypeID,
            generatorID: r.generatorID,
            groupID: r.groupID || null,
            buildingFloorZoneID: r.buildingFloorZoneID,
            allocatedQty: r.allocatedQty,
            overrideQty: r.overrideQty,
          });
        }
      });
      const state = { ...this.state, allocationRules: currentAllocationRules.filter((f) => !!f) };
      this.dataStoreStateSubject.next(state);
    });
  }

  handleError(error: any): Observable<any> {
    return throwError(error);
  }
}

export const AllocationDataStoreStateDefault: AllocationDataStoreState = { loading: false, loaded: false };

export interface AllocationDataStoreState {
  loading: boolean;
  loaded: boolean;
  allocations?: Allocation2[];
  allocationID?: string;
  allocation?: Allocation2;
  allocationRules?: AllocationRule[];
  buildings?: Building[];
  buildingFloors?: BuildingFloor[];
  buildingZones?: BuildingZone[];
  buildingFloorZones?: BuildingFloorZone[];
}
