import { Component, ChangeDetectionStrategy, NgZone, OnInit } from '@angular/core';
import { DataStoreFacade, UIFacade } from '../../../facades';
import { IntlService } from '@progress/kendo-angular-intl';
import { Subscription, combineLatest, of } from 'rxjs';
import { map, take, switchMap, catchError } from 'rxjs/operators';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Guid } from 'guid-typescript';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { UserRole } from 'src/app/core/services/api-clients';

@Component({
  selector: 'app-editor-tags',
  templateUrl: './editor-tags.component.html',
  styleUrls: ['./editor-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorTagsComponent implements OnInit {
  public subscriptions: Subscription = new Subscription();
  public form: FormGroup;
  public isRequesting = false;

  public options$ = combineLatest([this.dataStoreFacade.tagGroups$, this.dataStoreFacade.tags$, this.dataStoreFacade.spaceProgram$]).pipe(
    map(([tagGroups, tags, spaceProgram]) => {
      return {
        tagGroups: tagGroups.sort((a, b) => a.tagGroupName.localeCompare(b.tagGroupName)),
        tags,
        readOnly: !(spaceProgram.roleNo === UserRole.Composer),
      };
    })
  );

  constructor(
    public dataStoreFacade: DataStoreFacade,
    private uiFacade: UIFacade,
    public intl: IntlService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      tagGroupName: this.fb.control(null, [Validators.required]),
    });
  }

  onAddTagGroup() {
    this.isRequesting = true;
    if (!this.form.invalid) {
      this.uiFacade.showLoading();
      this.dataStoreFacade.spaceProgram$.pipe(take(1)).subscribe((spaceProgram) => {
        this.dataStoreFacade
          .createTagGroup({
            tagGroupID: Guid.raw(),
            spaceProgramID: spaceProgram.spaceProgramID,
            tagGroupName: this.form.get('tagGroupName').value,
            templateTagGroupID: null,
          })
          .pipe(
            switchMap((tag) => {
              this.toastr.success('Tag Group ' + tag.tagGroupName + ' Added', 'Success');
              this.initForm();
              this.form.markAsUntouched();
              this.form.markAsPristine();
              this.isRequesting = false;
              this.uiFacade.hideLoading();
              return of(tag);
            }),
            catchError((error) => {
              this.uiFacade.handleError(error);
              return of(null);
            })
          )
          .subscribe();
      });
    }
  }

  onSubmit() {}
}
