import { AbstractControl, FormGroup, ValidationErrors, FormArray, FormControl } from '@angular/forms';

export interface AllValidationErrors {
  control_name: string;
  error_name: string;
  error_value: any;
}

export interface FormGroupControls {
  [key: string]: AbstractControl;
}

export function getFormValidationErrors(controls: FormGroupControls): AllValidationErrors[] {
  let errors: AllValidationErrors[] = [];
  Object.keys(controls).forEach((key) => {
    const control = controls[key];
    if (control instanceof FormGroup) {
      errors = errors.concat(getFormValidationErrors(control.controls));
    }

    if (control instanceof FormArray) {
      // debugger;
      if (control.controls) {
        control.controls.forEach((element: FormGroup) => {
          if (element.controls) {
            errors = errors.concat(getFormValidationErrors(element.controls));
          } else if (element.errors) {
            const controlErrors2: ValidationErrors = element.errors;
            if (controlErrors2 !== null) {
              Object.keys(controlErrors2).forEach((keyError) => {
                errors.push({
                  control_name: key,
                  error_name: keyError,
                  error_value: controlErrors[keyError],
                });
              });
            }
            // errors = errors.concat(element.errors);
          }
          // errors = errors.concat(getFormValidationErrors(<FormGroupControls>control.controls));
        });
      }
    }
    const controlErrors: ValidationErrors = controls[key].errors;
    if (controlErrors !== null) {
      Object.keys(controlErrors).forEach((keyError) => {
        errors.push({
          control_name: key,
          error_name: keyError,
          error_value: controlErrors[keyError],
        });
      });
    }
  });
  return errors;
}
