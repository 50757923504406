import { Component, ChangeDetectionStrategy, Input, OnInit, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { DataStoreFacade, UIFacade } from '../../../facades';
import { IntlService } from '@progress/kendo-angular-intl';
import { Subscription, combineLatest, of } from 'rxjs';
import { map, take, switchMap, catchError } from 'rxjs/operators';
import { TagGroup, Tag, TagClient } from 'src/app/core/services/api-clients';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Guid } from 'guid-typescript';
import { confirmDelete } from '../../../functions/confirmations';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-editor-tag-list',
  templateUrl: './editor-tag-list.component.html',
  styleUrls: ['./editor-tag-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorTagListComponent implements OnInit, OnChanges {
  public subscriptions: Subscription = new Subscription();
  public form: FormGroup;
  public isRequesting = false;
  public tags: Tag[];

  @Input()
  tagGroup: TagGroup;

  @Input()
  options: {
    tags: Tag[];
    readOnly: boolean;
  };

  constructor(
    public dataStoreFacade: DataStoreFacade,
    public uiFacade: UIFacade,
    public intl: IntlService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tagGroup || changes.options) {
      this.initForm();
    }
  }

  prepareLookups() {
    this.tags = this.options.tags
      .filter((t) => t.tagGroupID === this.tagGroup.tagGroupID)
      .sort((a, b) => (a.displaySequence < b.displaySequence ? -1 : 1 || a.tagName.localeCompare(b.tagName)));
  }

  initForm() {
    this.prepareLookups();

    const tags = this.fb.group(
      this.tags.reduce((value, tag) => {
        value[tag.tagID] = this.fb.group({
          tagID: this.fb.control(tag.tagID),
          tagName: this.fb.control({ value: tag.tagName, disabled: this.options.readOnly }),
          displaySequence: this.fb.control(tag.displaySequence),
          colourCode: this.fb.control(tag.colourCode),
        });
        return value;
      }, {})
    );

    //

    this.form = this.fb.group({
      tagGroupName: this.fb.control({ value: this.tagGroup?.tagGroupName, disabled: this.options.readOnly }),
      tagName: this.fb.control(null),
      colourCode: this.fb.control(null),
      tags,
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.tags, event.previousIndex, event.currentIndex);
    this.setDisplaySequence();

    this.initForm();
  }

  setDisplaySequence() {
    this.tags.forEach((tag: Tag, idx: number) => {
      tag.displaySequence = idx + 1;
    });
    this.uiFacade.showLoading();
    this.dataStoreFacade.spaceProgram$.pipe(take(1)).subscribe((spaceProgram) => {
      this.dataStoreFacade
        .resequenceTags({
          spaceProgramID: spaceProgram.spaceProgramID,
          tagGroupID: this.tagGroup.tagGroupID,
          tagList: this.tags.map((tag) => tag.tagID),
        })
        .subscribe(
          (dto) => {
            this.initForm();
            this.uiFacade.hideLoading();
          },
          (err) => {
            this.uiFacade.hideLoading();
          }
        );
    });
  }

  public onColorChange(color: string): void {
    this.cdRef.detectChanges();
  }

  onUpdateTagGroup() {
    this.isRequesting = true;
    // if (!this.form.invalid) {
    this.uiFacade.showLoading();
    this.dataStoreFacade
      .updateTagGroup({
        ...this.tagGroup,
        tagGroupName: this.form.get('tagGroupName').value,
      })
      .pipe(
        switchMap((tagGroup) => {
          this.toastr.success('Tag Group ' + tagGroup.tagGroupName + ' Updated', 'Success');
          this.initForm();
          this.form.markAsUntouched();
          this.form.markAsPristine();
          this.isRequesting = false;
          this.uiFacade.hideLoading();
          return of(tagGroup);
        }),
        catchError((error) => {
          this.uiFacade.handleError(error);
          return of(null);
        }),
        take(1)
      )
      .subscribe();
    // }
  }

  onDeleteTagGroup() {
    confirmDelete(this.modalService).subscribe((result) => {
      if (result) {
        this.isRequesting = true;
        this.uiFacade.showLoading();
        this.dataStoreFacade
          .deleteTagGroup(this.tagGroup.tagGroupID)
          .pipe(
            switchMap((tagGroup) => {
              this.initForm();
              this.isRequesting = false;
              this.uiFacade.hideLoading();
              return of(tagGroup);
            }),
            catchError((error) => {
              this.uiFacade.handleError(error);
              return of(null);
            }),
            take(1)
          )
          .subscribe();
      }
    });
  }

  onUpdateTag(tagID: string) {
    const sourceTag = this.tags.find((f) => f.tagID === tagID);

    const tagToUpdate: Tag = this.form.get(['tags', tagID]).value;
    if (
      tagToUpdate &&
      tagToUpdate.tagName &&
      (tagToUpdate.tagName !== sourceTag.tagName || tagToUpdate.colourCode !== sourceTag.colourCode)
    ) {
      this.uiFacade.showLoading();
      this.dataStoreFacade.spaceProgram$.pipe(take(1)).subscribe((spaceProgram) => {
        this.dataStoreFacade
          .updateTag({
            tagID: tagToUpdate.tagID,
            tagGroupID: this.tagGroup.tagGroupID,
            tagName: tagToUpdate.tagName,
            spaceProgramID: spaceProgram.spaceProgramID,
            displaySequence: tagToUpdate.displaySequence,
            colourCode: tagToUpdate.colourCode,
          })
          .subscribe(
            (tag) => {
              this.toastr.success('Tag ' + tag.tagName + ' Saved', 'Success');

              this.initForm();
              this.form.markAsUntouched();
              this.form.markAsPristine();
              this.isRequesting = false;
              this.uiFacade.hideLoading();
            },
            (error) => {
              this.uiFacade.handleError(error);
            }
          );
      });
    }
  }

  onAddTag(tagCount: number) {
    this.isRequesting = true;
    if (!this.form.invalid) {
      this.uiFacade.showLoading();
      this.dataStoreFacade.spaceProgram$.pipe(take(1)).subscribe((spaceProgram) => {
        this.dataStoreFacade
          .createTag({
            tagID: Guid.raw(),
            tagGroupID: this.tagGroup.tagGroupID,
            tagName: this.form.get('tagName').value,
            spaceProgramID: spaceProgram.spaceProgramID,
            displaySequence: tagCount + 1,
            colourCode: this.form.get('colourCode').value,
          })
          .subscribe(
            (tag) => {
              this.toastr.success('Tag ' + tag.tagName + ' Added', 'Success');

              this.initForm();
              this.form.markAsUntouched();
              this.form.markAsPristine();
              this.isRequesting = false;
              this.uiFacade.hideLoading();
            },
            (error) => {
              this.uiFacade.handleError(error);
            }
          );
      });
    }
  }

  onDeleteTag(tagID: string) {
    confirmDelete(this.modalService).subscribe((result) => {
      if (result) {
        this.isRequesting = true;
        this.uiFacade.showLoading();
        this.dataStoreFacade.deleteTag(tagID).subscribe(
          (tag) => {
            this.initForm();
            this.isRequesting = false;
            this.uiFacade.hideLoading();
          },
          (error) => {
            this.uiFacade.handleError(error);
          }
        );
      }
    });
  }

  onSubmit() {}
}
