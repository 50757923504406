import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators, AsyncValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
import { WorkPlaceType } from 'src/app/core/services/api-clients';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { DataStoreFacade, UIFacade } from 'src/app/spaceplan/shared/facades';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'app-editor-workplacetype-form',
  styleUrls: ['./editor-workplacetype-form.component.scss'],
  templateUrl: './editor-workplacetype-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorWorkPlaceTypeFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public parentForm: FormGroup;
  @Input()
  model: WorkPlaceType;
  @Input()
  options = { readOnly: false };
  @Input()
  public isRequesting: boolean;
  @Output()
  public updateModelWithChanges = new EventEmitter<Partial<WorkPlaceType>>();
  public form: FormGroup;
  private readonly subscription: Subscription = new Subscription();
  private readonly onChanges = new BehaviorSubject<SimpleChanges>(null);

  constructor(private fb: FormBuilder, public uiFacade: UIFacade, private dataStoreFacade: DataStoreFacade) {}

  ngOnInit() {
    this.prepareLookups();
    this.initForm();
    if (this.parentForm) {
      this.parentForm.addControl('workPlaceType', this.form);
    }
    this.subscription.add(
      this.onChanges.subscribe((changes: SimpleChanges) => {
        if (changes?.model) {
          const model = changes.model.currentValue as WorkPlaceType;
          const values = this.modelToForm(model);
          this.form.patchValue(values, { emitEvent: false });
        }
      })
    );
    this.subscription.add(
      this.form.valueChanges.pipe().subscribe((value) => {
        const values = this.form.getRawValue();
        this.model = this.formToModel(values);
        this.updateModelWithChanges.emit(this.model);
      })
    );
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.onChanges.next(changes);
  }
  prepareLookups() {}
  initForm() {
    this.form = this.fb.group({
      workPlaceTypeName: this.fb.control('', [Validators.required], this.validateNameDuplicateValidator()),
      suitableFor: this.fb.control(''),
      inFlexFactorCalcInd: this.fb.control(false),
      imageUrl: this.fb.control(null),
      colourCode: this.fb.control(null),
    });
    if (this.options.readOnly) {
      this.form.disable({ onlySelf: true });
    }
  }
  formToModel(values: any): Partial<WorkPlaceType> {
    const model: Partial<WorkPlaceType> = {
      ...this.model,
      workPlaceTypeName: values.workPlaceTypeName,
      suitableFor: values.suitableFor,
      inFlexFactorCalcInd: values.inFlexFactorCalcInd,
      imageUrl: values.imageUrl,
      colourCode: values.colourCode,
    };
    return model;
  }
  modelToForm(model: WorkPlaceType): { [key: string]: any } {
    return (
      (model && {
        workPlaceTypeName: model.workPlaceTypeName,
        suitableFor: model.suitableFor,
        inFlexFactorCalcInd: model.inFlexFactorCalcInd,
        imageUrl: model.imageUrl,
        colourCode: model.colourCode,
      }) ||
      {}
    );
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  validateNameDuplicateValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.dataStoreFacade.workPlaceTypes$.pipe(
        map((workPlaceTypes) => {
          const duplicate = !!workPlaceTypes.find(
            (f) => f.workPlaceTypeID !== this.model.workPlaceTypeID && f.workPlaceTypeName === control.value
          );
          if (duplicate) {
            return {
              duplicate: true,
            };
          }
          return null;
        }),
        take(1)
      );
    };
  }
}
