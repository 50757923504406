import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, forkJoin, of, throwError } from 'rxjs';
import { map, take, switchMap, catchError, filter, withLatestFrom, pluck } from 'rxjs/operators';
import { UIFacade } from '../../spaceplan/shared/facades/ui.facade';
import { getBrowserLang, TranslocoService } from '@ngneat/transloco';
import { getCustomLabels } from '../../spaceplan/shared/functions/get-custom-labels';
import {
  Partner,
  PartnerClient,
  Folder,
  FolderClient,
  SpaceProgramClient,
  TemplateClient,
  SpaceProgram,
  Template,
} from 'src/app/core/services/api-clients';

@Injectable({
  providedIn: 'root',
})
export class FoldersDataStoreFacade {
  private readonly dataStoreStateSubject = new BehaviorSubject<FoldersDataStoreState>({ ...FoldersDataStoreStateDefault });
  state$ = this.dataStoreStateSubject.asObservable();
  folders$ = this.state$.pipe(map((m) => m.folders));
  folder$ = this.state$.pipe(map((m) => m.folder));
  partners$ = this.state$.pipe(map((m) => m.partners));
  spacePrograms$ = this.state$.pipe(map((m) => m.spacePrograms));
  templates$ = this.state$.pipe(map((m) => m.templates));
  private foldersClient: any;

  constructor(
    private folderClient: FolderClient,
    private partnerClient: PartnerClient,
    private spaceProgramClient: SpaceProgramClient,
    private templateClient: TemplateClient,
    private uiFacade: UIFacade,
    private translocoService: TranslocoService
  ) {}

  get state(): FoldersDataStoreState {
    return this.dataStoreStateSubject.getValue();
  }

  loadFoldersList() {
    return forkJoin([
      this.folderClient.getList(),
      this.partnerClient.getList(),
      this.spaceProgramClient.getList(),
      this.templateClient.getList(),
    ]).pipe(
      take(1),
      map(([folders, partners, spacePrograms, templates]) => {
        return {
          folders: folders?.sort((a, b) => a.folderName.localeCompare(b.folderName)),
          partners,
          spacePrograms: spacePrograms?.sort((a, b) => a.spaceProgramName.localeCompare(b.spaceProgramName)),
          templates,
        };
      }),
      map(({ folders, partners, spacePrograms, templates }) => {
        const newstate: FoldersDataStoreState = {
          ...this.dataStoreStateSubject.value,
          folders,
          partners,
          spacePrograms,
          templates,
        };
        this.dataStoreStateSubject.next(newstate);
        return {
          folders,
        };
      }),
      catchError(this.handleError)
    );
  }

  getFolder(id: string): Observable<Folder> {
    const model = this.dataStoreStateSubject.getValue()?.folders?.find((f) => f.folderID === id);
    return of(model);
  }
  createFolder(model: Folder): Observable<Folder> {
    return this.folderClient.insert(model).pipe(
      switchMap((folder) => {
        const state = this.dataStoreStateSubject.getValue();
        state.folders.push(folder);
        this.dataStoreStateSubject.next(state);
        return of(folder);
      })
    );
  }
  updateFolder(model: Folder): Observable<Folder> {
    return this.folderClient.update(model).pipe(
      switchMap((folder) => {
        const state = this.dataStoreStateSubject.getValue();
        const index = state.folders?.findIndex((f) => f.folderID === folder.folderID);
        if (index > -1) {
          state.folders[index] = folder;
          this.dataStoreStateSubject.next(state);
        }
        return of(folder);
      })
    );
  }
  deleteFolder(folderID: string): Observable<boolean> {
    return this.folderClient.delete(folderID).pipe(
      switchMap(() => {
        const state = this.dataStoreStateSubject.getValue();
        const folders = state.folders?.filter((f) => f.folderID !== folderID);
        this.dataStoreStateSubject.next({ ...state, folders });
        return of(true);
      })
    );
  }

  clear() {
    this.dataStoreStateSubject.next({ ...FoldersDataStoreStateDefault });
  }

  refreshFolders() {
    this.folderClient
      .getList()
      .pipe(
        switchMap((folders) => {
          return of(folders);
        })
      )
      .subscribe((folders) => {
        const state = this.dataStoreStateSubject.getValue();
        state.folders = folders;
        this.dataStoreStateSubject.next(state);
      });
  }

  handleError(error: any): Observable<any> {
    return throwError(error);
  }
}

export const FoldersDataStoreStateDefault: FoldersDataStoreState = { loading: false, loaded: false };

export interface FoldersDataStoreState {
  folders?: Folder[];
  partners?: Partner[];
  spacePrograms?: SpaceProgram[];
  templates?: Template[];
  folderID?: string;
  loading: boolean;
  loaded: boolean;
  folder?: any;
}
