import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modal-confirm',
  styleUrls: ['./modal-confirm.component.scss'],
  template: `
    <div class="modal-header">
      <h4 class="modal-title">{{ title }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="onCancel()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div [innerHTML]="message"></div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="onCancel()">{{ cancelButtonText }}</button>
      <button type="button" class="btn btn-primary" (click)="onConfirm()">{{ confirmButtonText }}</button>
    </div>
  `,
})
export class ModalConfirmComponent implements OnInit {
  errors: string;
  public onClose: Subject<boolean>;

  title = 'Confirm';
  message: string;
  confirmButtonText = 'Confirm';
  cancelButtonText = 'Cancel';

  constructor(public activeModal: BsModalRef) {}

  public ngOnInit(): void {
    this.onClose = new Subject();
  }

  public onConfirm(): void {
    this.onClose.next(true);
    this.activeModal.hide();
  }

  public onCancel(): void {
    this.onClose.next(false);
    this.activeModal.hide();
  }
}
