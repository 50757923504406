import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';
import { DataStoreFacade, EditorFolderFacade } from 'src/app/spaceplan/shared/facades';

@Injectable({
  providedIn: 'root',
})
export class PartnerFilterFacade {
  private readonly partnerIdSubject = new BehaviorSubject<string>('');
  public readonly partnerId$ = this.partnerIdSubject.asObservable();

  public partners$ = this.dataStoreFacade.partners$.pipe(
    map((partners) => partners?.sort((a, b) => a.partnerDescr.localeCompare(b.partnerDescr))),
    shareReplay(1)
  );

  constructor(private dataStoreFacade: DataStoreFacade, private editorFolderFacade: EditorFolderFacade) {}

  selectPartnerId(id: string) {
    this.partnerIdSubject.next(id);
  }
}
