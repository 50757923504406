import { Component } from '@angular/core';
import { FaConfig } from '@fortawesome/angular-fontawesome';
import { TranslocoService, getBrowserLang, translate } from '@ngneat/transloco';
import { AccountRepository } from './auth/account/state/account.repository';
import { BeaconService } from './shared/services/beacon.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'wpa-space-planning';

  constructor(faConfig: FaConfig, translactionService: TranslocoService, accountRepository: AccountRepository, beaconService: BeaconService) {
    faConfig.fixedWidth = true;
    const browserLang = getBrowserLang();
    const availableLangs = translactionService.getAvailableLangs() as string[];
    const activeLang = availableLangs?.find((f) => f === browserLang) || translactionService.getDefaultLang();
    translactionService.setActiveLang(activeLang);
    window['translate'] = translate;
    accountRepository.isAuthenticated$.subscribe((isAuthenticated) => {
      if (isAuthenticated && !beaconService.isBeaconActive()) {
        beaconService.init();
      } else {
        beaconService.destroy();
      }
    });
  }
}
