import { Injectable } from '@angular/core';
import { select } from '@ngneat/elf';
import { combineLatest, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import {
  Account,
  AccountsContollerClient,
  RoleGroup,
  UserRoleGroupDto,
  // PartnerDto,
  // UserAccessDto,
} from 'src/app/core/services/api-clients';
import { environment } from 'src/environments/environment';
// import { LoggingService } from '../../logging/services/logging.service';
import { AccountProps, accountStore, unAuthenticatedState } from './account.store';

@Injectable({
  providedIn: 'root',
})
export class AccountRepository {
  constructor(private client: AccountsContollerClient /*, private loggingService: LoggingService */) {
    this.checkSessionFull().subscribe();

    this.account$
      .pipe(
        map((account) => account?.authenicationUserID ?? null),
        distinctUntilChanged(),
        tap((authenicationUserID) => {
          // this.loggingService.setAuthenticatedUserId(authenicationUserID);
        })
      )
      .subscribe();

    // this.partnerID$.pipe(
    //   tap(partnerID => {
    //     if (partnerID) {
    //       this.loadUserAccess(partnerID).subscribe();
    //     }
    //   })
    // ).subscribe();
  }

  isAuthenticated$ = accountStore.pipe(
    filter((state) => !state.authenticating),
    map((state) => state.authenticated)
  );

  account$ = accountStore.pipe(select((state) => state.account));

  permissions$ = accountStore.pipe(select((state) => state.permissions));

  authenicationUserID$ = this.account$.pipe(
    map((account) => account?.authenicationUserID ?? null),
    distinctUntilChanged(),
    tap((authenicationUserID) => {
      // this.loggingService.setAuthenticatedUserId(authenicationUserID);
    })
  );

  // partners$ = accountStore.pipe(select(state => state.partners));

  // partnerID$ = accountStore.pipe(select(state => state.partnerID));

  // userAccess$ = accountStore.pipe(select(state => state.userAccess));

  // partner$ = combineLatest([this.partners$, this.partnerID$]).pipe(
  //   map(([partners, partnerID]) =>
  //     partners?.find(f => f.partnerID === partnerID)
  //   )
  // );

  displayName$ = this.account$.pipe(
    map((account) =>
      [account?.firstName, account?.lastName]
        .map((m) => m?.substring(0, 1))
        .filter((f) => !!f)
        .join('')
        .toUpperCase()
    )
  );

  checkSession() {
    return this.client.me().pipe(
      map((account: Account) => {
        accountStore.update((state) => ({
          ...state,
          account,
          authenticating: false,
          authenticated: true,
        }));
      }),
      catchError(() => of(accountStore.update(() => unAuthenticatedState)))
    );
  }

  checkSessionFull() {
    return this.client.me().pipe(
      switchMap((account: Account) => {
        return this.client.userRoleGroup().pipe(
          tap((userRoleGroup: UserRoleGroupDto) => {
            accountStore.update((state) => ({
              ...state,
              account,
              roleGroup: userRoleGroup?.roleGroup,
              permissions: userRoleGroup?.roleGroup === RoleGroup.Partner ? ['Partner'] : ['SpaceProgram'],
              authenticating: false,
              authenticated: true,
            }));
          })
        );
      }),
      catchError(() => of(accountStore.update(() => unAuthenticatedState)))
    );
  }

  // checkSessionFull() {
  //   return this.client.me().pipe(
  //     switchMap((account: Account) => {
  //       return this.client.partners().pipe(
  //         switchMap((partners: PartnerDto[]) => {
  //           let [partner] = partners;
  //           const partnerID = localStorage.getItem('partnerID') || null;
  //           partner = partners.find(f => f.partnerID === partnerID) ?? partner;

  //           const userAccess$ = !!partner
  //             ? this.client.userAcesss(partner.partnerID)
  //             : of({
  //                 isAdminApplicationInd: false,
  //                 isAdminClientInd: false,
  //                 isAdminPartnerInd: false,
  //                 isProjectUserInd: false,
  //                 isSpaceProgramUserInd: false,
  //               } as UserAccessDto);
  //           return userAccess$.pipe(
  //             map((userAccess: UserAccessDto) => {
  //               // TODO REMOVE TO USE PROPER PERMISSIONS
  //               userAccess = this.getTestUserAccess(userAccess);

  //               accountStore.update(state => ({
  //                 ...state,
  //                 account,
  //                 partners,
  //                 partnerID: partner?.partnerID,
  //                 userAccess,
  //                 authenticating: false,
  //                 authenticated: true,
  //               }));
  //             })
  //           );
  //         })
  //       );
  //     }),
  //     catchError(() => of(accountStore.update(() => unAuthenticatedState)))
  //   );
  // }

  // loadUserAccess(partnerID: string) {
  //   return this.client.userAcesss(partnerID).pipe(
  //     map((userAccess: UserAccessDto) => {
  //       // TODO REMOVE TO USE PROPER PERMISSIONS
  //       userAccess = this.getTestUserAccess(userAccess);

  //       accountStore.update(state => ({
  //         ...state,
  //         userAccess,
  //       }));
  //     })
  //   );
  // }

  // getTestUserAccess(userAccess: any) {
  //   // return {
  //   //   isAdminApplicationInd: false,
  //   //   isAdminPartnerInd: false,
  //   //   isAdminClientInd: false,
  //   //   isProjectUserInd: true,
  //   //   isSpaceProgramUserInd: true,
  //   // };
  //   return userAccess;
  // }

  // selectPartner(partnerID: string) {
  //   const statevalue = accountStore.getValue();
  //   const partner = statevalue.partners?.find(
  //     f => f.partnerID.toLowerCase() === partnerID.toLowerCase()
  //   );
  //   if (partner) {
  //     localStorage.setItem('partnerID', partnerID);
  //     accountStore.update(state => ({
  //       ...state,
  //       partnerID: partner?.partnerID,
  //     }));
  //   }
  // }

  redirectToLogin(redirectUri: string) {
    window.location.assign(`/MicrosoftIdentity/Account/SignIn?redirectUri=${redirectUri}`);
  }

  signOut() {
    window.location.assign('/MicrosoftIdentity/Account/SignOut?redirectUri=/');
  }
}
