<ng-container *transloco="let t; read: 'spaceplan'">
  <div id="editor-form-top" *ngIf="form" [formGroup]="form">
    <div class="form-group form-row mb-1">
      <label for="templateName" class="col-sm-12 col-form-label">{{ t('editorSpaceplanTemplateForm.templateName.label')
        }}:</label>
      <div class="col-sm-12">
        <div class="input-group">
          <input type="text" class="form-control" id="name"
            placeholder="{{ t('editorSpaceplanTemplateForm.templateName.placeholder') }}" name="templateName"
            formControlName="templateName" />
        </div>
        <div *ngIf="isRequesting && (form.get('templateName').errors || form.errors?.duplicate)"
          class="invalid-feedback">
          <div class="form-error" *ngIf="form.controls['templateName'].errors?.required">
            {{ t('editorSpaceplanTemplateForm.templateName.error.required') }}
          </div>
          <div class="form-error" *ngIf="form.errors?.duplicate">
            {{ t('editorSpaceplanTemplateForm.templateName.error.duplicate') }}
          </div>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-1">
      <label for="partnerID" class="col-sm-12 col-form-label">{{ t('editorSpaceplanTemplateForm.partnerID.label')
        }}:</label>
      <div class="col-sm-12">
        <div class="input-group">
          <select class="custom-select" id="partnerID" name="partnerID" formControlName="partnerID"
            style="border-radius: 0.25rem;">
            <option [ngValue]="null" *ngIf="options.partners.length > 1">
              {{ t('editorSpaceplanTemplateForm.partnerID.option.null_Please_select_partner') }}
            </option>
            <ng-template ngFor let-partner [ngForOf]="options.partners">
              <option [ngValue]="partner.partnerID">{{ partner.partnerDescr }}</option>
            </ng-template>
          </select>
        </div>
        <div *ngIf="isRequesting && form.get('partnerID').errors" class="invalid-feedback">
          <div class="form-error" *ngIf="form.controls['partnerID'].errors.required">
            {{ t('editorSpaceplanTemplateForm.partnerID.error.required') }}
          </div>
        </div>
      </div>
    </div>


    <div class="form-group form-row mb-1">
      <label for="spaceProgramDescr" class="col-sm-12 col-form-label">
        {{ t('editorSpaceplanTemplateForm.folder.label') }}:
      </label>
      <div class="col-sm-12">
        <div class="input-group">
          <select [attr.disabled]="!form.get('partnerID').value ? '' : null" class="custom-select" id="folderID"
            name="folderID" formControlName="folderID" style="border-radius: 0.25rem;">
            <option [ngValue]="null">{{ t('editorSpaceplanTemplateForm.folderID.option.null_Please_select_folder')
              }}</option>

            <ng-template ngFor let-folder [ngForOf]="folders$ | async">
              <option [ngValue]="folder.folderID">{{ folder.folderName }}</option>
            </ng-template>
          </select>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-1">
      <label for="templateDescr" class="col-sm-12 col-form-label">{{
        t('editorSpaceplanTemplateForm.templateDescr.label') }}:</label>
      <div class="col-sm-12">
        <div class="input-group mb-1">
          <textarea name="templateDescr" id="templateDescr"
            placeholder="{{ t('editorSpaceplanTemplateForm.templateDescr.placeholder') }}" class="form-control"
            formControlName="templateDescr" rows="6" style="resize: none;"></textarea>
        </div>
      </div>
    </div>



  </div>
</ng-container>