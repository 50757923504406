import { HttpClient } from '@angular/common/http';
import {
  TRANSLOCO_LOADER,
  Translation,
  TranslocoLoader,
  TranslocoMissingHandler,
  TRANSLOCO_CONFIG,
  TRANSLOCO_MISSING_HANDLER,
  translocoConfig,
  TranslocoModule,
  TranslocoConfig,
  TranslocoService,
  TRANSLOCO_SCOPE,
} from '@ngneat/transloco';
import { Injectable, NgModule } from '@angular/core';
import { environment } from '../environments/environment';

const SUPPORTED_LANGUAGES = ['en', 'nl', 'fr', 'de'];

/**
 * @param loader `(lang: string) => import(`./i18n/${lang}.json`)` to create a relative import to the correct dir
 */
export function InlineLoaderFactory(loader: (lang: string) => Promise<JSON>) {
  return SUPPORTED_LANGUAGES.reduce((acc, lang) => {
    acc[lang] = () => loader(lang);
    return acc;
  }, {});
}

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}

@Injectable({ providedIn: 'root' })
export class CustomTranslocoMissingHandler implements TranslocoMissingHandler {
  handle(key: string, config: TranslocoConfig) {
    const newkey = key.split('.').slice(1).join('.');
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: SUPPORTED_LANGUAGES,
        defaultLang: 'en',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production,
      }),
    },
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        loader: InlineLoaderFactory((lang: string) => import(`../app/root/i18n/${lang}.json`)),
        scope: 'root',
      },
      multi: true,
    },
    // { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
    // {
    //   provide: TRANSLOCO_MISSING_HANDLER,
    //   useClass: CustomTranslocoMissingHandler,
    // }
  ],
})
export class TranslocoRootModule {}
