<ng-container *transloco="let t; read: 'spaceplan'">
  <div id="editor-form-top" *ngIf="form" [formGroup]="form">
    <app-rich-text-editor
    id="preface"
    name="preface"
    formControlName="preface"></app-rich-text-editor>

    <!-- <div class="form-group form-row mb-1">
      <label for="spaceProgramName" class="col-sm-12 col-form-label">{{ t('editorSpaceProgramPrefaceForm.preface.label') }}:</label>
      <div class="col-sm-12">
        <div class="input-group">
          <app-rich-text-editor
            id="preface"
            name="preface"
            formControlName="preface"></app-rich-text-editor>
        </div>
        <div *ngIf="isRequesting && form.get('spaceProgramName').errors" class="invalid-feedback">
          <div class="form-error" *ngIf="form.controls['spaceProgramName'].errors.required">
            {{ t('editorSpaceProgramCreateForm.spaceProgramName.error.required') }}
          </div>
          <div class="form-error" *ngIf="form.controls['spaceProgramName'].errors.duplicate">
            {{ t('editorSpaceProgramCreateForm.spaceProgramName.error.duplicate') }}
          </div>
        </div>
      </div>
    </div> -->
  </div>
  </ng-container>
