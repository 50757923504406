import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'gridGroupGeneratorID' })
export class GridGroupGeneratorIDPipe implements PipeTransform {
  constructor() { }
  transform(value) {
    const parts = value.split('||');
    return (parts?.length && parts[1]) || null;
  }
}
