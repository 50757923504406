<ng-container *transloco="let t; read: 'spaceplan'">
  <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate [logValidationErrors]="form" appFormHandleEnterKey>
    <div class="card">
      <div class="card-header">
        <div class="p-1 d-flex align-items-center">
          <div class="flex-fill">
            <div class="input-group">
              <input type="text" class="form-control"
                placeholder="{{ t('editorTagListForm.tagGroupName.placeholder') }}" aria-label="Enter tag group"
                aria-describedby="basic-addon2" formControlName="tagGroupName" (blur)="onUpdateTagGroup()" />
              <!-- <div class="input-group-append" *ngIf="!options.readOnly">
                <button class="btn btn-light" type="button" (click)="onUpdateTagGroup()"
                  style="border: 1px solid rgba(33, 37, 41, 0.15);" [disabled]="!this.form.get('tagGroupName').value">
                  <i class="k-icon k-i-save"></i>
                </button>
              </div> -->
            </div>
          </div>
          <div style="width: 50px;" class="d-flex justify-content-end" *ngIf="!options.readOnly">
            <button class="btn btn-light" type="button" (click)="onDeleteTagGroup()" [disabled]="tags?.length">
              <i class="k-icon k-i-delete"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="pl-3 ml-4">
          <div *ngIf="tags" formArrayName="tags">
            <div cdkDropList (cdkDropListDropped)="drop($event)">
              <div class="p-1 d-flex align-items-center" *ngFor="let tag of tags; let i = index" cdkDrag>
                <div style="width: 50px;" cdkDragHandle *ngIf="!options.readOnly">
                  <i class="k-icon k-i-reorder"></i>
                </div>
                <div class="flex-fill">
                  <div class="input-group" formGroupName="{{ tag.tagID }}">
                    <input type="text" class="form-control" (blur)="onUpdateTag(tag.tagID)"
                      placeholder="{{ t('editorTagListForm.tagName.placeholder') }}" aria-label="Enter tag"
                      aria-describedby="basic-addon2" formControlName="tagName" />
                    <div class="input-group-append" *ngIf="!options.readOnly">
                      <kendo-colorpicker formControlName="colourCode" [format]="'hex'"
                        (valueChange)="onColorChange($event)" (blur)="onUpdateTag(tag.tagID)"
                        [gradientSettings]="uiFacade.colorPickerGradientSettings">
                      </kendo-colorpicker>
                      <!-- <button class="btn btn-light" type="button" (click)="onUpdateTag(tag.tagID)"
                        style="border: 1px solid rgba(33, 37, 41, 0.15);"
                        [disabled]="!this.form.get(['tags', tag.tagID, 'tagName']).value">
                        <i class="k-icon k-i-save"></i>
                      </button> -->
                    </div>
                  </div>
                </div>
                <div style="width: 50px;" class="d-flex justify-content-end" *ngIf="!options.readOnly">
                  <button class="btn btn-light" type="button" (click)="onDeleteTag(tag.tagID)"><i
                      class="k-icon k-i-delete"></i></button>
                </div>
              </div>
            </div>
          </div>

          <div class="p-1 d-flex align-items-center" *ngIf="!options.readOnly">
            <div style="width: 50px;"></div>
            <div class="flex-fill">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="{{ t('editorTagListForm.tagName.placeholder1') }}"
                  aria-label="Enter tag" aria-describedby="basic-addon2" formControlName="tagName" />
                <div class="input-group-append">
                  <kendo-colorpicker formControlName="colourCode" [format]="'hex'" (valueChange)="onColorChange($event)"
                    [gradientSettings]="uiFacade.colorPickerGradientSettings">
                  </kendo-colorpicker>

                </div>
              </div>
            </div>
            <div style="width: 50px;" class="d-flex justify-content-end">
              <button class="btn btn-light" type="button" (click)="onAddTag(options.tags.length)"
                style="border: 1px solid rgba(33, 37, 41, 0.15);"
                [disabled]="!form.valid || !this.form.get('tagName').value">
                <i class="k-icon k-i-plus"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-container>