import { DomSanitizer } from '@angular/platform-browser';
import { PipeTransform, Pipe } from '@angular/core';
import { GeneratorInputType, TemplateGenerator } from 'src/app/core/services/api-clients';
import { TranslocoService } from '@ngneat/transloco';
import { TemplateDataStoreFacade } from 'src/app/template/facades';

@Pipe({ name: 'templateGeneratorFriendlyName' })
export class TemplateGeneratorFriendlyNamePipe implements PipeTransform {
  constructor(
    private sanitized: DomSanitizer,
    private translocoService: TranslocoService,
    private dataStoreFacade: TemplateDataStoreFacade
  ) {}
  transform(generator: TemplateGenerator) {
    const spaceTypeName = this.dataStoreFacade.state.spaceTypes.find((f) => f.templateSpaceTypeID === generator.templateSpaceTypeID)
      ?.spaceTypeName;
    const outputGroupByName = (generator.outputByGroupInd
      ? this.translocoService.translate('spaceplan.editorGeneratorForm.outputGroupby.option.1_All_Groups')
      : this.translocoService.translate('spaceplan.editorGeneratorForm.outputGroupby.option.0_Total')
    ).toLowerCase();

    if (generator.generatorInputTypeNo !== 6) {
      let generatorInputTypeName: string = null;
      if (generator.generatorInputTypeNo === 2) {
        // generatorInputTypeName = generator.inputWorkPlaceTypeID &&
        //     this.dataStoreFacade.state.workPlaceTypes.find((f) => f.workPlaceTypeID === generator.inputWorkPlaceTypeID)?.workPlaceTypeName;
        generatorInputTypeName = generatorInputTypeName && '`' + generatorInputTypeName + '`';
      } else {
        generatorInputTypeName =
          (generator.generatorInputTypeNo || generator.generatorInputTypeNo === 0) &&
          this.translocoService.translate(`spaceplan.generatorInputType.${GeneratorInputType[generator.generatorInputTypeNo]}`);
      }

      if (generatorInputTypeName && spaceTypeName) {
        // For Every
        const message = this.translocoService.translate('spaceplan.generatorFriendly.For_every');
        const result = message
          .replace('{N}', generator.qualifier === 1 ? '' : (generator.qualifier || 0).toString())
          .replace('{in}', generatorInputTypeName)
          .replace('{selector}', outputGroupByName)
          .replace('{X}', (generator.multiplier || 0).toString())
          .replace('{spacetype}', '`' + spaceTypeName + '`')
          .replace('  ', ' ');
        // return this.sanitized.bypassSecurityTrustHtml(result);
        return result;
      }
    } else {
      // Constant
      if (spaceTypeName) {
        const message = this.translocoService.translate('spaceplan.generatorFriendly.Constant');
        const result = message
          .replace('{N}', generator.qualifier === 1 ? '' : (generator.qualifier || 0).toString())
          .replace('{selector}', outputGroupByName)
          .replace('{X}', (generator.multiplier || 0).toString())
          .replace('{spacetype}', '`' + spaceTypeName + '`')
          .replace('  ', ' ');
        // return this.sanitized.bypassSecurityTrustHtml(result);
        return result;
      }
    }

    return '';
  }
}
