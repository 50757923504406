import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators, AsyncValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { Partner, CreateTemplateDto, Folder, FolderUsageType } from 'src/app/core/services/api-clients';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { DataStoreFacade } from 'src/app/spaceplan/shared/facades';
import { distinctUntilChanged, map, take } from 'rxjs/operators';

@Component({
  selector: 'app-editor-spaceplan-template-form',
  templateUrl: './editor-spaceplan-template-form.component.html',
  styleUrls: ['./editor-spaceplan-template-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorSpaceplanTemplateFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public parentForm: FormGroup;
  @Input()
  model: CreateTemplateDto;
  @Input()
  options = {
    folders: [] as Folder[],
    partners: [] as Partner[],
    readOnly: false,
  };
  @Input()
  public isRequesting: boolean;
  @Output()
  public updateModelWithChanges = new EventEmitter<Partial<CreateTemplateDto>>();
  public form: FormGroup;
  private readonly subscription: Subscription = new Subscription();
  private readonly onChanges = new BehaviorSubject<SimpleChanges>(null);
  private readonly partnerIDSubject = new BehaviorSubject<string>(null);
  public readonly partnerID$ = this.partnerIDSubject.asObservable();

  public readonly folders$ = this.partnerID$.pipe(
    distinctUntilChanged(),
    map((partnerID) => {
      if (partnerID) {
        return this.options.folders.filter((t) => t.usageType !== FolderUsageType.SpaceProgram && t.partnerID === partnerID);
      }
      return [] as Folder[];
    })
  );

  constructor(private fb: FormBuilder, private dataStoreFacade: DataStoreFacade) {}

  ngOnInit() {
    this.prepareLookups();
    this.initForm();
    if (this.parentForm) {
      this.parentForm.addControl('template', this.form);
    }
    this.subscription.add(
      this.onChanges.subscribe((changes: SimpleChanges) => {
        if (changes?.model) {
          const model = changes.model.currentValue as CreateTemplateDto;
          const values = this.modelToForm(model);
          this.form.patchValue(values, { emitEvent: false });
        }
      })
    );
    this.subscription.add(
      this.form.valueChanges.pipe().subscribe((value) => {
        const values = this.form.getRawValue();
        const partnerID = this.model.partnerID;
        this.model = this.formToModel(values);
        if (this.model.partnerID !== partnerID) {
          this.form.get('templateName')?.updateValueAndValidity();
          this.form.get('folderID').setValue(null);
        }
        this.updateModelWithChanges.emit(this.model);
        this.partnerIDSubject.next(value.partnerID);
      })
    );
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.onChanges.next(changes);
  }
  prepareLookups() {}
  initForm() {
    this.form = this.fb.group(
      {
        templateName: this.fb.control('', [Validators.required]),
        templateDescr: this.fb.control(''),
        folderID: this.fb.control(null),
        partnerID: this.fb.control(null, Validators.required),
      },
      {
        asyncValidators: this.validateNameDuplicateFormValidator(),
      }
    );
    if (this.options.readOnly) {
      this.form.disable({ onlySelf: true });
    }

    setTimeout(() => {
      if (this.options.partners.length === 1) {
        this.form.patchValue({
          partnerID: this.options.partners[0].partnerID,
        });
      }
    }, 100);
  }
  formToModel(values: any): Partial<CreateTemplateDto> {
    const model: Partial<CreateTemplateDto> = {
      ...this.model,
      templateName: values.templateName,
      templateDescr: values.templateDescr,
      folderID: values.folderID,
      partnerID: values.partnerID,
    };
    return model;
  }
  modelToForm(model: CreateTemplateDto): { [key: string]: any } {
    return (
      (model && {
        templateName: model.templateName,
        templateDescr: model.templateDescr,
        folderID: model.folderID,
        partnerID: model.partnerID,
      }) ||
      {}
    );
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  validateNameDuplicateFormValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.dataStoreFacade.templates$.pipe(
        map((templates) => {
          const partnerID = this.form.get('partnerID')?.value;
          const templateName = this.form.get('templateName')?.value;
          const duplicate =
            templateName &&
            partnerID &&
            !!templates.find((f) => f.templateID !== this.model.templateID && f.partnerID === partnerID && f.templateName === templateName);
          if (duplicate) {
            return {
              duplicate: true,
            };
          }
          return null;
        }),
        take(1)
      );
    };
  }
}
