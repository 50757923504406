import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  WorkPlaceType,
  UpdateActivityToWorkPlaceTypeDto,
  ActivityToWorkPlaceTypeDto,
  BehaviourFractionSummaryDto,
  Activity,
} from 'src/app/core/services/api-clients';
import { Subscription, BehaviorSubject, combineLatest } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { IntlService } from '@progress/kendo-angular-intl';
import { DataStoreFacade, EditorBehaviorsFacade, UIFacade } from '../../facades';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { SelectActivitiesForCopyFacade } from '../../facades/select-activities-for-copy.facade';

@Component({
  selector: 'app-editor-behaviours-form',
  styleUrls: ['./editor-behaviours-form.component.scss'],
  templateUrl: './editor-behaviours-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorBehavioursFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public parentForm: FormGroup;
  @Input()
  model: UpdateActivityToWorkPlaceTypeDto;
  @Input()
  options: {
    workPlaceTypes: WorkPlaceType[];
    behaviourActivitySummaries: BehaviourFractionSummaryDto[];
    readOnly: boolean;
  };
  @Input()
  public isRequesting: boolean;
  @Input()
  public title: string;
  @Output()
  public updateModelWithChanges = new EventEmitter<Partial<UpdateActivityToWorkPlaceTypeDto>>();
  public form: FormGroup;
  private readonly subscription: Subscription = new Subscription();
  private readonly onChanges = new BehaviorSubject<SimpleChanges>(null);

  public workPlaceTypes: WorkPlaceType[];
  public matrixList = [];
  public connectedTo = [];
  public fractionBands = null;

  public showHeatmap = true;

  constructor(
    private dataStoreFacade: DataStoreFacade,
    public editorFacade: EditorBehaviorsFacade,
    public selectActivitiesForCopyFacade: SelectActivitiesForCopyFacade,
    public uiFacade: UIFacade,
    private fb: FormBuilder,
    public intl: IntlService
  ) {}

  ngOnInit() {
    this.prepareLookups();
    this.initForm();
    if (this.parentForm) {
      this.parentForm.addControl('behaviours', this.form);
    }
    this.subscription.add(
      this.onChanges.subscribe((changes: SimpleChanges) => {
        if (changes?.model) {
          const model = changes.model.currentValue as UpdateActivityToWorkPlaceTypeDto;
          const values = this.modelToForm(model);
          this.form.patchValue(values, { emitEvent: false });
          this.initMatrix(model.activityToWorkPlaceTypes);

          this.selectActivitiesForCopyFacade.close();
        }
      })
    );
    this.subscription.add(
      this.form.valueChanges.pipe().subscribe((value) => {
        const values = this.form.getRawValue();
        this.model = this.formToModel(values);
        this.updateModelWithChanges.emit(this.model);
      })
    );
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.onChanges.next(changes);
  }
  prepareLookups() {
    const fractions = this.options?.behaviourActivitySummaries.map((b) => b.behaviourFraction);
    this.fractionBands = {
      min: fractions.length === 0 ? 0 : Math.min(...fractions),
      max: fractions.length === 0 ? 0 : Math.max(...fractions),
      range: [],
    };

    this.fractionBands.range = this.rangeBetween(
      this.fractionBands.min,
      this.fractionBands.max,
      (this.fractionBands.max - this.fractionBands.min) / 10
    );

    this.workPlaceTypes = this.options?.workPlaceTypes?.sort((a, b) => (a.workPlaceTypeName > b.workPlaceTypeName ? 1 : -1));

    this.matrixList = [];

    const rows = [...Array(5).keys()].reverse();
    const cols = [...Array(5).keys()];

    rows.forEach((row) => {
      cols.forEach((col) => {
        this.matrixList.push({
          id: 'matrix-' + row + '-' + col,
          collaborationRatingID: row + 1,
          complexityRatingID: col + 1,
          behaviourFraction: 0,
          // behaviourActivitySummaries
          matrix: [],
        });
      });
    });

    for (const matrix of this.matrixList) {
      this.connectedTo.push(matrix.id);
    }
  }
  initForm() {
    this.form = this.fb.group({
      activityToWorkPlaceTypes: this.fb.control([]),
    });
    if (this.options.readOnly) {
      this.form.disable({ onlySelf: true });
    }
  }

  removeMatrixItem(matrixItem: any) {
    this.selectActivitiesForCopyFacade.cancel();
    matrixItem.matrix = [];

    this.matrixListToModel();
  }

  matrixListToModel() {
    const activityToWorkPlaceTypes = this.matrixList
      .filter((f) => f.matrix?.length > 0)
      .map((m) => ({
        collaborationRatingID: m.collaborationRatingID,
        complexityRatingID: m.complexityRatingID,
        workPlaceTypeID: m.matrix[0].workPlaceTypeID,
      }));
    this.model = { ...this.model, activityToWorkPlaceTypes };
    this.updateModelWithChanges.emit(this.model);
  }

  formToModel(values: any): Partial<UpdateActivityToWorkPlaceTypeDto> {
    const model: Partial<UpdateActivityToWorkPlaceTypeDto> = {
      ...this.model,
      activityToWorkPlaceTypes: values.activityToWorkPlaceTypes,
    };
    return model;
  }
  modelToForm(model: UpdateActivityToWorkPlaceTypeDto): { [key: string]: any } {
    return (
      (model && {
        activityToWorkPlaceTypes: model.activityToWorkPlaceTypes,
      }) ||
      {}
    );
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  initMatrix(activityToWorkPlaceTypes: ActivityToWorkPlaceTypeDto[]) {
    const activityBehaviourActivitySummaries =
      this.options.behaviourActivitySummaries?.filter((f) => f.activityID === this.model.activityID) || [];
    // behaviourActivitySummaries?.find(f => f.)
    this.matrixList.forEach((matrixListItem) => {
      const behaviourActivitySummary = activityBehaviourActivitySummaries.find(
        (f) =>
          f.collaborationRatingID === matrixListItem.collaborationRatingID && f.complexityRatingID === matrixListItem.complexityRatingID
      );

      matrixListItem.behaviourFraction = behaviourActivitySummary?.behaviourFraction || 0;

      const list = activityToWorkPlaceTypes
        .filter((awp) => {
          return (
            awp.collaborationRatingID === matrixListItem.collaborationRatingID &&
            awp.complexityRatingID === matrixListItem.complexityRatingID
          );
        })
        .map((itm) => {
          return {
            workPlaceTypeID: itm.workPlaceTypeID,
            workPlaceTypeName: this.workPlaceTypes?.find((w) => w.workPlaceTypeID === itm.workPlaceTypeID).workPlaceTypeName,
          };
        });

      matrixListItem.matrix = list;
    });
  }
  drop(event: CdkDragDrop<string[]>, matrixItem: any) {
    const droppedItem = JSON.parse(JSON.stringify(event.previousContainer.data[event.previousIndex]));

    if (matrixItem === null) {
      // removed item from grid
    } else {
      // row / col dropped on
    }

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      // transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
      // copyArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
      const matrixValue: any[] = [
        {
          // activityID: this.behavioursUiFacade.activityID,
          workPlaceTypeID: droppedItem.workPlaceTypeID,
          workPlaceTypeName: droppedItem.workPlaceTypeName,
        },
      ];
      matrixItem.matrix = matrixValue;
    }

    this.matrixListToModel();
  }

  noReturnPredicate() {
    return false;
  }

  rangeBetween(start, end, increment) {
    const arro = new Array(10);

    for (let j = 0; j < arro.length; j++, start += increment) {
      arro[j] = start;
    }
    return arro;
  }

  getFractionClass(behaviourFraction: number) {
    if (this.showHeatmap) {
      const newRange = this.fractionBands.range.concat(behaviourFraction);
      newRange.sort((a, b) => a - b);
      const rangeIdx = newRange.indexOf(behaviourFraction);
      return 'heatmap-' + (rangeIdx === newRange.length - 1 ? rangeIdx - 1 : rangeIdx);
    }
    return '';
  }

  toggleCopyTo() {
    combineLatest([this.editorFacade.model$, this.dataStoreFacade.activities$])
      .pipe(take(1))
      .subscribe(([model, activities]) => {
        this.selectActivitiesForCopyFacade.toggleLookup(
          model.activityID,
          activities.filter((f: Activity) => {
            return f.activityID !== model.activityID;
          }),
          []
        );
      });
  }

  toggleCopyToSelection(item: { activity: Activity; isSelected: boolean }) {
    this.selectActivitiesForCopyFacade.toggleSelectedItem(item);
  }

  onApplyCopy() {
    this.uiFacade.showLoading();
    this.selectActivitiesForCopyFacade.save().subscribe(
      (result) => {
        setTimeout(() => {
          this.uiFacade.hideLoading();
        }, 300);
      },
      (err) => {
        this.uiFacade.hideLoading();
      }
    );
  }
}

// export interface UpdateActivityToWorkPlaceTypeDto {
//     spaceProgramID?: string;
//     activityID?: string;
//     activityToWorkPlaceTypes?: ActivityToWorkPlaceTypeDto[] | undefined;
// }

// export interface ActivityToWorkPlaceTypeDto {
//     workPlaceTypeID?: string;
//     collaborationRatingID?: number;
//     complexityRatingID?: number;
// }
