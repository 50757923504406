import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable, of, combineLatest } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UIFacade } from './ui.facade';
import { take, map, distinctUntilChanged, delay, mergeMap, debounceTime, switchMap } from 'rxjs/operators';
import { deepEquals } from '../functions/deep-equals';
import { Activity, ActivityToWorkPlaceTypeClient, GeneratorInputType } from 'src/app/core/services/api-clients.generated';
import { confirmClose } from '../functions/confirmations';
import { DataStoreFacade } from './data-store.facade';

@Injectable({
  providedIn: 'root',
})
export class SelectActivitiesForCopyFacade {
  private readonly defaultState: SelectActivitiesForCopyFacadeState = {
    isOpen: false,
    isBusy: false,
    items: [],
    selectedItems: [],
    activityID: null,
  };

  private readonly stateSubject = new BehaviorSubject<SelectActivitiesForCopyFacadeState>(this.defaultState);
  private readonly onSelectSubject = new Subject<Activity[]>();
  private readonly onCloseSubject = new Subject<boolean>();

  protected isDirty = false;
  protected canClose(): Observable<boolean> {
    return combineLatest([this.isDirty$]).pipe(
      take(1),
      mergeMap(([isDirty]) => {
        if (isDirty) {
          return confirmClose(this.modalService);
        }
        return of(true);
      })
    );
  }

  public readonly state$ = this.stateSubject.asObservable();
  public readonly onSelect$ = this.onSelectSubject.asObservable();
  public readonly onClose$ = this.onCloseSubject.asObservable();
  public get state(): SelectActivitiesForCopyFacadeState {
    return this.stateSubject.getValue();
  }
  public readonly isOpen$ = this.state$.pipe(
    map((m) => m.isOpen),
    distinctUntilChanged()
  );
  public readonly isBusy$ = this.state$.pipe(
    map((m) => m.isBusy),
    distinctUntilChanged()
  );

  public readonly isDirty$ = this.state$.pipe(
    debounceTime(200),
    map((state) => {
      const isDirty = state.isOpen && state.selectedItems.length > 0;
      this.isDirty = isDirty;
      return isDirty;
    })
  );

  public readonly items$ = this.state$.pipe(
    map((m) => m.items.sort((a, b) => a.activityName.localeCompare(b.activityName))),
    distinctUntilChanged()
  );
  public readonly selectedItems$ = this.state$.pipe(
    map((m) => m.selectedItems),
    distinctUntilChanged()
  );

  public readonly activitiesList$ = combineLatest([this.selectedItems$, this.items$]).pipe(
    map(([selectedItems, activities]) => {
      return activities.map((activity) => {
        return {
          activity,
          isSelected: !!selectedItems.find((s) => s.activityID === activity.activityID),
        };
      });
    })
  );

  constructor(
    protected modalService: BsModalService,
    protected uiFacade: UIFacade,
    private client: ActivityToWorkPlaceTypeClient,
    private dataStore: DataStoreFacade
  ) {}

  // shortcut for implementing canDeactive in a simple case where only one editor used in container
  canDeactivate(): Observable<{ canDeactivate: boolean; handled?: boolean }> {
    return this.close().pipe(
      take(1),
      map((canDeactivate) => ({ canDeactivate, handled: true }))
    );
  }

  toggleLookup(activityID: string, items: Activity[], selectedItems: Activity[]) {
    if (this.state.isOpen) {
      this.resetAndClose();
    } else {
      this.lookup(activityID, items, selectedItems);
    }
  }

  lookup(activityID: string, items: Activity[], selectedItems: Activity[], dontOpen: boolean = false) {
    if (!dontOpen || this.state.isOpen) {
      // this.lookupFacadeManager.open(this);
      this.updateState({ activityID, items, selectedItems, isBusy: true, isOpen: true });
      // this.getLookupItemsData().subscribe((items) => {
      this.updateState({ isBusy: false });
      // });
    }
  }

  refreshData(items: Activity[], selectedItems: Activity[]) {
    this.updateState({ items, selectedItems, isBusy: true });
    this.updateState({ isBusy: false });
  }

  toggleSelectedItem(item: { activity: Activity; isSelected: boolean }) {
    let selectedItems = [];
    if (item.isSelected) {
      selectedItems = this.state.selectedItems.filter((s) => s.activityID !== item.activity.activityID);
    } else {
      selectedItems = [...this.state.selectedItems, item.activity];
    }
    this.updateState({ selectedItems });
  }

  // save(closePanel: boolean = true): Observable<Activity[]> {
  //   this.updateState({ isBusy: true });
  //   combineLatest([this.selectedItems$])
  //     .pipe(
  //       delay(0),
  //       map(([selectedItems]) => {
  //         if (closePanel) {
  //           this.resetAndClose();
  //         }
  //         this.onSelectSubject.next(selectedItems);
  //       }),
  //       take(1)
  //     )
  //     .subscribe();
  //   return this.onSelect$.pipe(take(1));
  // }

  save(): Observable<boolean> {
    const onSaveSubject = new Subject<boolean>();
    this.updateState({ isBusy: true });
    combineLatest([this.selectedItems$])
      .pipe(
        delay(0),
        switchMap(([selectedItems]) => {
          return this.client.copy({
            fromActivityID: this.state.activityID,
            toActivityIDs: selectedItems.map((s) => s.activityID),
          });
        }),
        map((result) => {
          this.resetAndClose();
          this.dataStore.spaceProgram$.pipe(take(1)).subscribe((spaceProgram) => {
            this.dataStore.refreshActvityToWorkPlaceType(spaceProgram.spaceProgramID);
            this.dataStore.refreshGeneratorOutputs(spaceProgram.spaceProgramID, null, GeneratorInputType.WorkPlace);
            onSaveSubject.next(true);
          });
        }),
        take(1)
      )
      .subscribe();
    return onSaveSubject.asObservable();
  }

  cancel() {
    this.resetAndClose();
  }

  close(): Observable<boolean> {
    this.canClose()
      .pipe(delay(0))
      .subscribe((canClose) => {
        if (canClose) {
          this.resetAndClose();
        } else {
          this.onCloseSubject.next(false);
        }
      });
    return this.onClose$.pipe(take(1));
  }

  updateSelectedItems(selectedItems: Activity[]) {
    this.updateState({ selectedItems });
  }
  private resetAndClose() {
    this.resetToDefault();
    this.onCloseSubject.next(true);
  }
  resetToDefault() {
    this.isDirty = false;
    this.stateSubject.next({ ...this.defaultState });
  }
  private updateState(updates: Partial<SelectActivitiesForCopyFacadeState>) {
    // const state = this.stateSubject.getValue();
    // for (const key of Object.keys(updates)) {
    //   state[key] = updates[key];
    // }
    const state = {
      ...this.stateSubject.getValue(),
      ...updates,
    };
    this.stateSubject.next(state);
  }
  private equals(x: any, y: any): boolean {
    return deepEquals(x, y);
  }
}

export interface SelectActivitiesForCopyFacadeState {
  isOpen: boolean;
  isBusy: boolean;
  items: Activity[];
  selectedItems: Activity[];
  activityID: string;
}
