import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LookupScenarioFacade } from '../../facades/lookup-scenario.facade';

@Component({
  selector: 'app-lookup-scenario-button',
  template: `
    <div>
      <button
        type="button"
        class="btn btn-light border"
        [disabled]="!projectID"
        ngClass="{ disabled: !projectID }"
        style="padding: 5px; border-radius: 4px; margin-left: 4px;"
        (click)="toggleLookup()"
      >
        <span class="k-icon k-i-search"></span>
      </button>
    </div>
  `,
})
export class LookupScenarioButtonComponent implements OnInit, OnChanges {
  @Input() projectID?: number;

  constructor(private lookupFacade: LookupScenarioFacade) {}

  ngOnInit() {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes.projectID) {
      this.lookupFacade.lookup({ projectID: this.projectID }, true);
    }
  }

  toggleLookup() {
    this.lookupFacade.toggleLookup({ projectID: this.projectID });
  }
}
