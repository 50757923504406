import { AbstractControl, ValidatorFn } from '@angular/forms';

export function validateRequiredQualifierFn(c: AbstractControl) {
  const obj = c.value;
  let returnValue = null;

  if (obj) {
    const hasValue = obj != null;
    returnValue = hasValue
      ? null
      : {
          qualifierRequired: {
            valid: false,
          },
        };
  } else {
    if (c.parent) {
      const generatorInputTypeNo = +c.parent.get('generatorInputTypeNo').value;
      if (generatorInputTypeNo !== 6) {
        if (!obj) {
          returnValue = {
            qualifierRequired: {
              valid: false,
            },
          };
        }
      }
    }
  }
  return returnValue;
}

export function validateRequiredElementFn(c: AbstractControl) {
  const obj = c.value;
  let returnValue = null;

  if (obj) {
    const hasValue = obj.hasOwnProperty('type');
    returnValue = hasValue
      ? null
      : {
          elementRequired: {
            valid: false,
          },
        };
  } else {
    if (c.parent) {
      const generatorInputTypeNo = +c.parent.get('generatorInputTypeNo').value;
      if (generatorInputTypeNo !== 6 && !obj) {
        returnValue = {
          elementRequired: {
            valid: false,
          },
        };
      }
    }
  }
  return returnValue;
}

export function validateRequiredOutputGroupByFn(c: AbstractControl) {
  const obj = c.value;
  const hasValue = obj !== null && obj !== '';
  return hasValue
    ? null
    : {
        outputGroupbyRequired: {
          valid: false,
        },
      };
}

export function validateRequiredOutputGroupByIsSelectedFn(c: AbstractControl) {
  if (c.parent) {
    const selectedGroups = Object.keys(c.value).filter((g) => !!c.value[g]);
    const outputGroupBy: string = c.parent.get('outputGroupby').value;
    const groupsIsValid = outputGroupBy != '2' || (outputGroupBy == '2' && selectedGroups.length > 0);

    return groupsIsValid
      ? null
      : {
          groupsRequired: {
            valid: false,
          },
        };
  }
}

export function greaterThanNumber(config: { minimumNumber: number }): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    // if (ValidatorValueChecker.pass(control, config)) {
    if (!((control.value || control.value === 0) && parseFloat(control.value) > config.minimumNumber)) {
      return {
        /*control.value, config.minimumNumber, config.maximumNumber */
      };
    }
    // }
    return null;
  };
}
