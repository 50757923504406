import { Injectable } from '@angular/core';
import { LookupFacadeBase, LookupFacadeManager } from './lookup-facade-base';
import { Template } from 'src/app/core/services/api-clients';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UIFacade } from './ui.facade';
import { of } from 'rxjs';
import { DataStoreFacade } from './data-store.facade';

@Injectable({
  providedIn: 'root',
})
export class LookupTemplateFacade extends LookupFacadeBase<Template> {
  constructor(
    protected modalService: BsModalService,
    protected uiFacade: UIFacade,
    protected lookupFacadeManager: LookupFacadeManager,
    private dataStoreFacade: DataStoreFacade
  ) {
    super(modalService, uiFacade, lookupFacadeManager);
  }

  getLookupItemsData() {
    const availableTemplates = this.dataStoreFacade.state.templates.filter((f) => !f.archivedInd);
    return of(availableTemplates);
  }
  // applyFilters(items: Scenario[], filters?: Partial<Scenario>): Scenario[] {
  //   return items;
  // }
}
