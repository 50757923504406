import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, forkJoin, of, throwError } from 'rxjs';
import {
  PartnerClient,
  TemplateClient,
  Generator,
  Partner,
  SpaceType,
  Tag,
  TagGroup,
  Template,
  CreateTemplateDto,
  UpdateSpaceProgramSettingsDto,
  UpdateTagsSequenceDto,
  SummaryContent,
  CustomLabel,
  DuplicateTemplateDto,
  TemplateGenerator,
  TemplateSpaceType,
  TemplateTag,
  TemplateTagGroup,
  TemplateGeneratorClient,
  TemplateSpaceTypeClient,
  TemplateTagClient,
  TemplateTagGroupClient,
  UpdateTemplateTagsSequenceDto,
  TemplateWorkPlaceTypeClient,
  TemplateWorkPlaceType,
  UpdateTemplateSettingsDto,
  FolderClient,
  Folder,
  UpdateTemplateColoursDto,
  ColourEntityType,
} from '../../core/services/api-clients';
import { map, take, switchMap, catchError, filter, withLatestFrom, pluck } from 'rxjs/operators';
import { UIFacade } from '../../spaceplan/shared/facades/ui.facade';
import { getBrowserLang, TranslocoService } from '@ngneat/transloco';
import { getCustomLabels } from '../../spaceplan/shared/functions/get-custom-labels';
import { keyBy } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class TemplateDataStoreFacade {
  private readonly dataStoreStateSubject = new BehaviorSubject<TemplateDataStoreState>({ ...TemplateDataStoreStateDefault });
  state$ = this.dataStoreStateSubject.asObservable();
  templates$ = this.state$.pipe(map((m) => m.templates));
  template$ = this.state$.pipe(map((m) => m.template));
  folders$ = this.state$.pipe(map((m) => m.folders));
  generators$ = this.state$.pipe(map((m) => m.generators));
  partners$ = this.state$.pipe(map((m) => m.partners));
  spaceTypes$ = this.state$.pipe(map((m) => m.spaceTypes));
  tags$ = this.state$.pipe(map((m) => m.tags));
  tagGroups$ = this.state$.pipe(map((m) => m.tagGroups));
  workPlaceTypes$ = this.state$.pipe(map((m) => m.workPlaceTypes));

  constructor(
    private templateClient: TemplateClient,
    private folderClient: FolderClient,
    private generatorClient: TemplateGeneratorClient,
    private partnerClient: PartnerClient,
    private spaceTypeClient: TemplateSpaceTypeClient,
    private tagClient: TemplateTagClient,
    private tagGroupClient: TemplateTagGroupClient,
    private workPlaceTypeClient: TemplateWorkPlaceTypeClient,
    private uiFacade: UIFacade,
    private translocoService: TranslocoService
  ) {}

  get state(): TemplateDataStoreState {
    return this.dataStoreStateSubject.getValue();
  }

  loadTemplatesList() {
    return forkJoin([this.templateClient.getList(), this.partnerClient.getList(), this.folderClient.getList()]).pipe(
      take(1),
      map(([templates, partners, folders]) => {
        return {
          templates: templates?.sort((a, b) => a.templateName.localeCompare(b.templateName)),
          partners: partners?.sort((a, b) => a.partnerDescr.localeCompare(b.partnerDescr)),
          folders: folders?.sort((a, b) => a.folderName.localeCompare(b.folderName)),
        };
      }),
      map(({ templates, partners, folders }) => {
        const newstate: TemplateDataStoreState = {
          ...this.dataStoreStateSubject.value,
          templates,
          partners,
          folders,
        };
        this.dataStoreStateSubject.next(newstate);
        return {
          templates,
          partners,
          folders,
        };
      }),
      catchError(this.handleError)
    );
  }

  load() {
    return forkJoin([this.templateClient.getList(), this.partnerClient.getList(), this.folderClient.getList()]).pipe(
      take(1),
      map(([templates, partners, folders]) => {
        const newstate: TemplateDataStoreState = {
          ...this.dataStoreStateSubject.value,
          templates: templates as Template[],
          partners: partners as Partner[],
          folders: folders as Folder[],
        };
        this.dataStoreStateSubject.next(newstate);
        return {
          templates: (templates as Template[])?.sort((a, b) => a.templateName.localeCompare(b.templateName)),
          partners: (partners as Partner[])?.sort((a, b) => a.partnerDescr.localeCompare(b.partnerDescr)),
          folders: (folders as Folder[])?.sort((a, b) => a.folderName.localeCompare(b.folderName)),
        };
      }),
      catchError(this.handleError)
    );
  }

  loadTemplate(templateID: string) {
    return forkJoin([this.templateClient.getList(), this.folderClient.getList()]).pipe(
      take(1),
      map(([templates, folders]) => {
        // update spaceProgram loaded in space programs list
        const template = (templates as Template[]).find((f) => f.templateID === templateID);
        folders = (folders as Folder[]).filter((f) => f.partnerID === template.partnerID);

        const newstate: TemplateDataStoreState = {
          ...this.dataStoreStateSubject.value,
          templates: templates as Template[],
          folders: folders as Folder[],
          templateID,
          loading: false,
          template,
        };
        this.dataStoreStateSubject.next(newstate);

        return {
          templates,
          template,
          folders,
        };
      }),
      catchError(this.handleError)
    );
  }

  loadTemplateDetail(templateID: string) {
    return forkJoin([
      this.templateClient.getList(),
      this.templateClient.getSummartContentList(templateID),
      this.templateClient.getCustomLabelList(templateID),
      this.generatorClient.getList(templateID),
      this.partnerClient.getList(),
      this.folderClient.getList(),
      this.spaceTypeClient.getList(templateID),
      this.tagClient.getList(templateID),
      this.tagGroupClient.getList(templateID),
      this.workPlaceTypeClient.getList(templateID),
    ]).pipe(
      take(1),
      map(([templates, summaryContent, customLabels, generators, partners, folders, spaceTypes, tags, tagGroups, workPlaceTypes]) => {
        const template = (templates as Template[]).find((f) => f.templateID === templateID);
        template.summaryContent = (summaryContent as SummaryContent[]).map((m) => m.contentCode).sort((a, b) => a.localeCompare(b));
        template.customLabels = (customLabels as CustomLabel[]).sort((a, b) => a.labelCode.localeCompare(b.labelCode));

        const newstate: TemplateDataStoreState = {
          ...this.dataStoreStateSubject.value,
          templates: templates as Template[],
          templateID,
          loading: false,
          loaded: true,
          template,
          generators: generators as TemplateGenerator[],
          partners: (partners as Partner[]).sort((a, b) => a.partnerDescr.localeCompare(b.partnerDescr)),
          folders: (folders as Folder[])
            .filter((f) => f.partnerID === template.partnerID)
            .sort((a, b) => a.folderName.localeCompare(b.folderName)),
          spaceTypes: spaceTypes as TemplateSpaceType[],
          tags: tags as Tag[],
          tagGroups: tagGroups as TagGroup[],
          workPlaceTypes: workPlaceTypes as TemplateWorkPlaceType[],
        };
        this.dataStoreStateSubject.next(newstate);
        const customLabelList = getCustomLabels();
        customLabelList.forEach((label) => {
          this.translocoService.setTranslationKey(
            'spaceplan.customLabelOverride.' + label.code,
            '{{spaceplan.customLabel.' + label.code + '}}'
          );
        });
        template.customLabels.forEach((label) => {
          this.translocoService.setTranslationKey('spaceplan.customLabelOverride.' + label.labelCode, label.labelText);
        });

        return {
          templates,
          template,
          generators,
          partners,
          folders,
          spaceTypes,
          tags,
          tagGroups,
          workPlaceTypes,
        };
      }),
      catchError(this.handleError)
    );
  }

  // SPACE PROGRAM SETTINGS
  getTemplateSettings(id: string): Observable<UpdateTemplateSettingsDto> {
    const state = this.dataStoreStateSubject.getValue();
    const template = state?.templates?.find((f) => f.templateID === id);
    // const groups =
    //   state?.groups
    //     .sort((a, b) => a.groupName.localeCompare(b.groupName))
    //     .map((grp) => ({
    //       groupID: grp.groupID,
    //       groupName: grp.groupName,
    //       growthFactor: grp.growthFactor,
    //     })) || [];
    const model: UpdateTemplateSettingsDto = {
      ...template,
      // groups,
    };
    return of(model);
  }
  updateTemplateSettings(model: UpdateTemplateSettingsDto): Observable<UpdateTemplateSettingsDto> {
    return this.templateClient.updateSettings(model).pipe(
      switchMap((updateSettings: UpdateTemplateSettingsDto) => {
        const state = this.dataStoreStateSubject.getValue();

        const { ...template } = updateSettings;
        // update space program
        const index = state.templates?.findIndex((f) => f.templateID === updateSettings.templateID);
        if (index > -1) {
          // exclude fields not on spaceProgram
          const newtemplate = { ...state.templates[index], ...template };
          state.templates[index] = newtemplate;
          state.template = newtemplate;
          // possibly update groups with new growth factor value
        }

        this.dataStoreStateSubject.next({ ...state });

        const result: UpdateTemplateSettingsDto = {
          ...template,
        };
        return of(result);
      })
    );
  }

  updateTemplateColours(coloursDto: UpdateTemplateColoursDto) {
    return this.templateClient.updateColours(coloursDto).pipe(
      map((settings) => {
        const state = this.dataStoreStateSubject.getValue();

        switch (coloursDto.entityType) {
          case ColourEntityType.TemplateSpaceType:
            const spaceTypes = keyBy(state.spaceTypes, 'templateSpaceTypeID');
            coloursDto.entityColours.forEach((item) => {
              spaceTypes[item.entityID] = {
                ...spaceTypes[item.entityID],
                colourCode: item.colourCode,
              };
            });
            state.spaceTypes = Object.values(spaceTypes);
            break;
          // case ColourEntityType.Group:
          //   const groups = keyBy(state.groups, 'groupID');
          //   coloursDto.entityColours.forEach((item) => {
          //     groups[item.entityID] = {
          //       ...groups[item.entityID],
          //       colourCode: item.colourCode,
          //     };
          //   });
          //   state.groups = Object.values(groups);
          //   break;
          // case ColourEntityType.WorkPlaceType:
          //   const workPlaceTypes = keyBy(state.workPlaceTypes, 'workPlaceTypeID');
          //   coloursDto.entityColours.forEach((item) => {
          //     workPlaceTypes[item.entityID] = {
          //       ...workPlaceTypes[item.entityID],
          //       colourCode: item.colourCode,
          //     };
          //   });
          //   state.workPlaceTypes = Object.values(workPlaceTypes);
          //   break;
          case ColourEntityType.TemplateTag:
            const tags = keyBy(state.tags, 'templateTagID');
            coloursDto.entityColours.forEach((item) => {
              tags[item.entityID] = {
                ...tags[item.entityID],
                colourCode: item.colourCode,
              };
            });
            state.tags = Object.values(tags);
            break;

          default:
            break;
        }

        this.dataStoreStateSubject.next(state);
      })
    );
  }

  // TEMPLATES

  getTemplate(id: string): Observable<Template> {
    const model = this.dataStoreStateSubject.getValue()?.templates?.find((f) => f.templateID === id);
    return of(model);
  }

  createTemplate(model: CreateTemplateDto): Observable<Template> {
    return this.templateClient.insert(model).pipe(
      switchMap((template) => {
        this.refreshTemplates();
        return of(template);
      })
    );
  }

  updateTemplate(model: DuplicateTemplateDto): Observable<Template> {
    // return this.templateClient.update(model).pipe(
    //   switchMap((template) => {
    //     this.refreshTemplates();
    //     return of(template);
    //   })
    // );
    return of(null);
  }

  duplicateTemplate(model: DuplicateTemplateDto): Observable<Template> {
    return this.templateClient.duplicate(model).pipe(
      switchMap((template) => {
        this.refreshTemplates();
        return of(template);
      })
    );
  }

  archiveTemplate(id: string): Observable<Template> {
    return this.templateClient.archive(id).pipe(
      switchMap((template) => {
        this.refreshTemplates();
        return of(template);
      })
    );
  }

  unarchiveTemplate(id: string): Observable<Template> {
    return this.templateClient.unarchive(id).pipe(
      switchMap((template) => {
        this.refreshTemplates();
        return of(template);
      })
    );
  }

  // GENERATORS
  getGenerator(id: string): Observable<TemplateGenerator> {
    const model = this.dataStoreStateSubject.getValue()?.generators?.find((f) => f.templateGeneratorID === id);
    return of(model);
  }
  createGenerator(model: TemplateGenerator): Observable<TemplateGenerator> {
    return this.generatorClient.insert(model).pipe(
      switchMap((generator) => {
        // this.refreshGeneratorOutputs(generator.spaceProgramID, generator.generatorID);
        const state = this.dataStoreStateSubject.getValue();
        state.generators.push(generator);
        this.dataStoreStateSubject.next(state);
        // this.refreshGeneratorOutputs(generator.spaceProgramID, generator.generatorID);
        return of(generator);
      })
    );
    // return of(model);
  }
  updateGenerator(model: TemplateGenerator): Observable<TemplateGenerator> {
    return this.generatorClient.update(model).pipe(
      switchMap((generator) => {
        const state = this.dataStoreStateSubject.getValue();
        const index = state.generators?.findIndex((f) => f.templateGeneratorID === generator.templateGeneratorID);
        if (index > -1) {
          state.generators[index] = generator;
          this.dataStoreStateSubject.next(state);
        }
        // this.refreshGeneratorOutputs(generator.spaceProgramID, generator.generatorID);
        return of(generator);
      })
    );
  }
  deleteGenerator(generatorID: string): Observable<boolean> {
    return this.generatorClient.delete(generatorID).pipe(
      switchMap(() => {
        const state = this.dataStoreStateSubject.getValue();
        const generators = state.generators?.filter((f) => f.templateGeneratorID !== generatorID);
        this.dataStoreStateSubject.next({ ...state, generators });
        return of(true);
      })
    );
  }

  // SPACE TYPES

  getSpaceType(id: string): Observable<TemplateSpaceType> {
    const model = this.dataStoreStateSubject.getValue()?.spaceTypes?.find((f) => f.templateSpaceTypeID === id);
    return of(model);
  }
  createSpaceType(model: TemplateSpaceType): Observable<TemplateSpaceType> {
    return this.spaceTypeClient.insert(model).pipe(
      switchMap((spaceType) => {
        const state = this.dataStoreStateSubject.getValue();
        state.spaceTypes.push(spaceType);
        this.dataStoreStateSubject.next(state);
        return of(spaceType);
      })
    );
  }
  updateSpaceType(model: TemplateSpaceType): Observable<TemplateSpaceType> {
    return this.spaceTypeClient.update(model).pipe(
      switchMap((spaceType) => {
        const state = this.dataStoreStateSubject.getValue();
        const index = state.spaceTypes?.findIndex((f) => f.templateSpaceTypeID === spaceType.templateSpaceTypeID);
        if (index > -1) {
          state.spaceTypes[index] = spaceType;
          this.dataStoreStateSubject.next(state);
        }
        return of(spaceType);
      })
    );
  }
  deleteSpaceType(templateSpaceTypeID: string): Observable<boolean> {
    return this.spaceTypeClient.delete(templateSpaceTypeID).pipe(
      switchMap(() => {
        const state = this.dataStoreStateSubject.getValue();
        const spaceTypes = state.spaceTypes?.filter((f) => f.templateSpaceTypeID !== templateSpaceTypeID);
        this.dataStoreStateSubject.next({ ...state, spaceTypes });
        return of(true);
      })
    );
  }

  // TAG GROUP

  createTagGroup(model: TemplateTagGroup): Observable<TemplateTagGroup> {
    return this.tagGroupClient.insert(model).pipe(
      switchMap((tag) => {
        const state = this.dataStoreStateSubject.getValue();
        state.tagGroups.push(tag);
        this.dataStoreStateSubject.next(state);
        return of(tag);
      })
    );
  }

  updateTagGroup(model: TemplateTagGroup): Observable<TemplateTagGroup> {
    return this.tagGroupClient.update(model).pipe(
      switchMap((tagGroup) => {
        const state = this.dataStoreStateSubject.getValue();
        const index = state.tagGroups?.findIndex((f) => f.templateTagGroupID === tagGroup.templateTagGroupID);
        if (index > -1) {
          state.tagGroups[index] = tagGroup;
          this.dataStoreStateSubject.next(state);
        }
        return of(tagGroup);
      })
    );
  }

  deleteTagGroup(templateTagGroupID: string): Observable<boolean> {
    return this.tagGroupClient.delete(templateTagGroupID).pipe(
      switchMap(() => {
        const state = this.dataStoreStateSubject.getValue();
        const tagGroups = state.tagGroups?.filter((f) => f.templateTagGroupID !== templateTagGroupID);
        // reset displaySequence on tags, and call update sequence method, then return
        this.dataStoreStateSubject.next({ ...state, tagGroups });
        return of(true);
      })
    );
  }

  // TAG

  createTag(model: TemplateTag): Observable<TemplateTag> {
    return this.tagClient.insert(model).pipe(
      switchMap((tag) => {
        const state = this.dataStoreStateSubject.getValue();
        state.tags.push(tag);
        this.dataStoreStateSubject.next(state);
        return of(tag);
      })
    );
  }

  updateTag(model: TemplateTag): Observable<TemplateTag> {
    return this.tagClient.update(model).pipe(
      switchMap((tag) => {
        const state = this.dataStoreStateSubject.getValue();
        const index = state.tags?.findIndex((f) => f.templateTagID === tag.templateTagID);
        if (index > -1) {
          state.tags[index] = tag;
          this.dataStoreStateSubject.next(state);
        }
        return of(tag);
      })
    );
  }

  resequenceTags(model: UpdateTemplateTagsSequenceDto): Observable<UpdateTemplateTagsSequenceDto> {
    return this.tagClient.updateTagSequence(model).pipe(
      switchMap((dto) => {
        const state = this.dataStoreStateSubject.getValue();
        // const index = state.tags?.findIndex((f) => f.tagID === dto.tagID);
        // if (index > -1) {
        //   state.tags[index] = tag;
        //   this.dataStoreStateSubject.next(state);
        // }
        return of(dto);
      })
    );
  }

  deleteTag(templateTagID: string): Observable<boolean> {
    return this.tagClient.delete(templateTagID).pipe(
      switchMap(() => {
        const state = this.dataStoreStateSubject.getValue();
        const tags = state.tags?.filter((f) => f.templateTagID !== templateTagID);
        // reset displaySequence on tags, and call update sequence method, then return
        this.dataStoreStateSubject.next({ ...state, tags });
        return of(true);
      })
    );
  }

  clear() {
    this.dataStoreStateSubject.next({ ...TemplateDataStoreStateDefault });
  }

  refreshTemplates() {
    this.templateClient
      .getList()
      .pipe(
        switchMap((templates) => {
          return of(templates);
        })
      )
      .subscribe((templates) => {
        const state = this.dataStoreStateSubject.getValue();
        state.templates = templates;
        this.dataStoreStateSubject.next(state);
      });
  }

  handleError(error: any): Observable<any> {
    return throwError(error);
  }
}

export const TemplateDataStoreStateDefault: TemplateDataStoreState = { loading: false, loaded: false };

export interface TemplateDataStoreState {
  templates?: Template[];
  templateID?: string;
  loading: boolean;
  loaded: boolean;
  template?: Template;
  generators?: TemplateGenerator[];
  partners?: Partner[];
  folders?: Folder[];
  spaceTypes?: TemplateSpaceType[];
  tags?: TemplateTag[];
  tagGroups?: TemplateTagGroup[];
  workPlaceTypes?: TemplateWorkPlaceType[];
}
