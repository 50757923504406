import { Component, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, AbstractControl, Validators } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { EditorFolderFacade, UIFacade } from '../../../../facades';
import { FoldersDataStoreFacade } from '../../../../../../folders/facades';

@Component({
  selector: 'app-editor-folder',
  templateUrl: './editor-folder.component.html',
  styleUrls: ['./editor-folder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorFolderComponent {
  public form: FormGroup;
  public isRequesting = false;
  public options$ = combineLatest([this.dataStoreFacade.partners$, this.editorFacade.isReadOnly$]).pipe(
    map(([partners, isReadOnly]) => {
      return {
        partners: partners.sort((a, b) => a.partnerDescr.localeCompare(b.partnerDescr)),
        readOnly: isReadOnly,
      };
    })
  );

  constructor(
    public editorFacade: EditorFolderFacade,
    private dataStoreFacade: FoldersDataStoreFacade,
    private fb: FormBuilder,
    public uiFacade: UIFacade
  ) {
    this.form = this.fb.group({});
  }

  onSubmit() {
    this.isRequesting = true;
    if (!this.form.invalid) {
      this.uiFacade.showLoading();
      this.editorFacade.save(true).subscribe(
        (result) => {
          if (result.success) {
            // SAVE SUCESS
            this.form.markAsUntouched();
            this.form.markAsPristine();
            this.isRequesting = false;
            this.uiFacade.hideLoading();
          }
        },
        (err) => {
          this.uiFacade.hideLoading();
        }
      );
    }
  }
  onClose() {
    this.editorFacade.close();
  }
}
