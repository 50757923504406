import { Injectable, ComponentFactory, Component } from '@angular/core';
import { BehaviorSubject, from, Subscription } from 'rxjs';
import {
  Router,
  ActivatedRoute,
  NavigationStart,
  NavigationEnd,
  Event,
  GuardsCheckStart,
  GuardsCheckEnd,
  NavigationCancel,
} from '@angular/router';
import { BlockUI, NgBlockUI, BlockUIService } from 'ng-block-ui';
import { showError } from '../functions/confirmations';
import { BsModalService } from 'ngx-bootstrap/modal';
import { GradientSettings } from '@progress/kendo-angular-inputs';

@Injectable({
  providedIn: 'root',
})
export class UIFacade {
  private readonly defaultUIFacadeState: UIFacadeState = {
    loading: false,
  };
  private readonly stateSubject = new BehaviorSubject<UIFacadeState>(this.defaultUIFacadeState);
  private readonly isVisibleFooterSubject = new BehaviorSubject<boolean>(false);
  private readonly componentSubject = new BehaviorSubject(null);
  private readonly subscription = new Subscription();
  public readonly state$ = this.stateSubject.asObservable();
  public readonly isVisibleFooter$ = this.isVisibleFooterSubject.asObservable();
  public readonly component$ = this.componentSubject.asObservable();

  @BlockUI() blockUI: NgBlockUI;

  public colorPickerGradientSettings: GradientSettings = {
    opacity: false,
    clearButton: true,
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private blockUIService: BlockUIService,
    private modalService: BsModalService
  ) {
    this.router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        this.showLoading();
      }
      if (routerEvent instanceof NavigationEnd) {
        this.hideLoading();
      }
      if (routerEvent instanceof GuardsCheckStart) {
        this.hideLoading();
      }
      if (routerEvent instanceof GuardsCheckEnd) {
        this.showLoading();
      }
      if (routerEvent instanceof NavigationCancel) {
        this.hideLoading();
      }
    });
  }

  set isVisibleFooter(value: boolean) {
    this.isVisibleFooterSubject.next(value);
  }
  set component(value: any) {
    this.componentSubject.next(value);
    this.isVisibleFooterSubject.next(true);
  }
  clearFooter() {
    this.componentSubject.next(null);
    this.isVisibleFooterSubject.next(false);
  }
  showLoading(message?: string) {
    this.blockUI.start(message);
    this.updateState({ loading: true });
  }
  hideLoading(message?: string) {
    this.updateState({ loading: false });
    this.blockUI.stop();
  }
  navigateToHome() {
    this.router.navigate(['/']);
  }
  navigateToLogin() {
    this.router.navigate(['/auth', 'login']);
  }
  navigateToSpaceProgram(spaceProgramID) {
    this.router.navigate(['/', spaceProgramID]);
  }
  navigateToSpaceProgramSettings(spaceProgramID) {
    this.router.navigate(['/', spaceProgramID, 'settings']);
  }
  navigateToSpaceProgramAllocations(spaceProgramID) {
    this.router.navigate(['/' + spaceProgramID + '/allocations']);
  }
  navigateToSpaceProgramAllocationDetail(spaceProgramID, allocationID) {
    this.router.navigate(['/' + spaceProgramID + '/allocations/' + allocationID + '/detail']);
  }

  navigateToTemplate(templateID) {
    this.router.navigate(['/template', templateID]);
  }

  // showLoading(message?: string, target?: string | string[]) {
  //   message = message || 'Loading...';
  //   target = target || 'app';
  //   this.blockUIService.start(target, message);
  // }
  // hideLoading(target?: string | string[]) {
  //   target = target || 'app';
  //   this.blockUIService.stop(target);
  // }
  private updateState(updates: Partial<UIFacadeState>) {
    const state = {
      ...this.stateSubject.getValue(),
      ...updates,
    };
    this.stateSubject.next(state);
  }
  handleErrorPage(error: any, extraData: any = null) {
    this.hideLoading();
    const route = this.router.config.find((r) => r.path === 'errors');
    route.data = { error, extraData };
    this.router.navigateByUrl('/errors');
  }
  handleError(error: any, extraData: any = null) {
    this.hideLoading();
    const title: string = error?.message;
    const message: string = error?.message;
    showError(this.modalService, title, message);
  }
}

export class UIFacadeState {
  loading: boolean;
}
