import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, InjectionToken, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedModule } from './spaceplan/shared/module/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { BlockUIModule } from 'ng-block-ui';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { CheckBoxModule } from '@progress/kendo-angular-inputs';
import { API_BASE_URL, SystemClient } from './core/services/api-clients';
import { MenuModule } from '@progress/kendo-angular-menu';
import { ErrorInterceptor } from './spaceplan/shared/interceptors/error.interceptor';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { LayoutModule } from '@progress/kendo-angular-layout';
import 'hammerjs';
import { AuthModule } from './auth/auth.module';
import { TranslocoRootModule } from './transloco-root.module';
import { ErrorHandlerService } from './core/services/error-handler.service';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { WebSiteSettingsService } from './shared/services/web-settings.service';

// const API_BASE_URL = new InjectionToken<string>('BaseUrl');
export const configFactory = (webSiteSettingsService: WebSiteSettingsService) => {
  return () => webSiteSettingsService.loadSettings();
};
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    SharedModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BlockUIModule.forRoot({
      delayStart: 500,
      delayStop: 0,
    }),
    ModalModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      closeButton: true,
    }),
    ButtonsModule,
    InputsModule,
    CheckBoxModule,
    MenuModule,
    ChartsModule,
    LayoutModule,
    AuthModule,
    TranslocoRootModule,
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
  ],
  providers: [
    { provide: API_BASE_URL, useValue: '' },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: configFactory,
      deps: [WebSiteSettingsService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {}
}
