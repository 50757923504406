import { DomSanitizer } from '@angular/platform-browser';
import { PipeTransform, Pipe } from '@angular/core';
import { Generator, GeneratorInputType } from 'src/app/core/services/api-clients';
import { DataStoreFacade } from '../facades';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({ name: 'generatorFriendlyName' })
export class GeneratorFriendlyNamePipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer, private translocoService: TranslocoService, private dataStoreFacade: DataStoreFacade) {}
  transform(generator: Generator) {
    const spaceTypeName = this.dataStoreFacade.state.spaceTypes.find((f) => f.spaceTypeID === generator.spaceTypeID)?.spaceTypeName;
    const outputGroupByName = (generator.outputByGroupInd
      ? generator.groups?.length
        ? this.translocoService.translate('spaceplan.editorGeneratorForm.outputGroupby.option.2_Selected_Groups')
        : this.translocoService.translate('spaceplan.editorGeneratorForm.outputGroupby.option.1_All_Groups')
      : this.translocoService.translate('spaceplan.editorGeneratorForm.outputGroupby.option.0_Total')
    ).toLowerCase();

    if (generator.generatorInputTypeNo !== 6) {
      let generatorInputTypeName: string = null;
      if (generator.generatorInputTypeNo === 2) {
        generatorInputTypeName = generator.inputWorkPlaceTypeID &&
            this.dataStoreFacade.state.workPlaceTypes.find((f) => f.workPlaceTypeID === generator.inputWorkPlaceTypeID)?.workPlaceTypeName;
        generatorInputTypeName = generatorInputTypeName && '`' + generatorInputTypeName + '`';
      } else {
        generatorInputTypeName = (generator.generatorInputTypeNo || generator.generatorInputTypeNo === 0) &&
        this.translocoService.translate(`spaceplan.generatorInputType.${GeneratorInputType[generator.generatorInputTypeNo]}`);
      }

      if (generatorInputTypeName && spaceTypeName) {
        // For Every
        const message = this.translocoService.translate('spaceplan.generatorFriendly.For_every');
        const result = message
          .replace('{N}', generator.qualifier === 1 ? '' : (generator.qualifier || 0).toString())
          .replace('{in}', generatorInputTypeName)
          .replace('{selector}', outputGroupByName)
          .replace('{X}', (generator.multiplier || 0).toString())
          .replace('{spacetype}', '`' + spaceTypeName + '`')
          .replace('  ', ' ');
        // return this.sanitized.bypassSecurityTrustHtml(result);
        return result;
      }
    } else {
      // Constant
      if (spaceTypeName) {
        const message = this.translocoService.translate('spaceplan.generatorFriendly.Constant');
        const result = message
          .replace('{N}', generator.qualifier === 1 ? '' : (generator.qualifier || 0).toString())
          .replace('{selector}', outputGroupByName)
          .replace('{X}', (generator.multiplier || 0).toString())
          .replace('{spacetype}', '`' + spaceTypeName + '`')
          .replace('  ', ' ');
        // return this.sanitized.bypassSecurityTrustHtml(result);
        return result;
      }
    }

    return '';
  }
}
