import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TemplateSpaceType, UserRole } from 'src/app/core/services/api-clients';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { bufferCount, filter, map } from 'rxjs/operators';
import { EditorFacadeBase, UIFacade, UserFacade } from 'src/app/spaceplan/shared/facades';
import { TemplateDataStoreFacade } from './template-data-store.facade';

/**
 * t(spaceplan.SpaceType)
 */
@Injectable({
  providedIn: 'root',
})
export class EditorTemplateSpaceTypeFacade extends EditorFacadeBase<TemplateSpaceType> {
  constructor(
    protected toastr: ToastrService,
    protected modalService: BsModalService,
    protected uiFacade: UIFacade,
    protected translocoService: TranslocoService,
    private dataStoreFacade: TemplateDataStoreFacade,
    private userFacade: UserFacade
  ) {
    super(toastr, modalService, uiFacade, translocoService);
    this.dataStoreFacade.template$
      .pipe(
        bufferCount(2, 1),
        filter(([a, b]) => b?.templateID !== a?.templateID),
        map(([a, b]) => b)
      )
      .subscribe(() => {
        this.resetToDefault();
      });
  }
  protected className(): string {
    return this.translocoService.translate('spaceplan.SpaceType');
  }
  protected getFromDataStore(id: string): Observable<TemplateSpaceType> {
    return this.dataStoreFacade.getSpaceType(id);
  }
  protected createDataStore(model: TemplateSpaceType): Observable<TemplateSpaceType> {
    return this.dataStoreFacade.createSpaceType(model);
  }
  protected updateDataStore(model: TemplateSpaceType): Observable<TemplateSpaceType> {
    return this.dataStoreFacade.updateSpaceType(model);
  }
  protected deleteDataStore(id: string): Observable<boolean> {
    return this.dataStoreFacade.deleteSpaceType(id);
  }
  protected getModelId(model: TemplateSpaceType): string {
    return model?.templateSpaceTypeID;
  }
  protected setModelId(model: TemplateSpaceType, id: string) {
    model.templateSpaceTypeID = id;
  }
  protected getNewModelForCopy(init: Partial<TemplateSpaceType>): Observable<TemplateSpaceType> {
    const copyinit = { ...init, spaceTypeName: null };
    return this.getNewModel(copyinit);
  }
  public canDelete(id: string): Observable<boolean> {
    const state = this.dataStoreFacade.state;
    const canDelete = state.generators.filter((f) => f.templateSpaceTypeID === id)?.length === 0;
    return of(canDelete);
    // return of(true);
  }
  protected userCanEdit(): Observable<boolean> {
    return of(true);
  }
  protected getNewModel(init: Partial<TemplateSpaceType>): Observable<TemplateSpaceType> {
    return of({
      ...init,
      templateSpaceTypeID: null,
    });
  }
}
