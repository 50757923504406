import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DataStoreFacade } from 'src/app/spaceplan/shared/facades/data-store.facade';
import { SpaceType, UserRole } from 'src/app/core/services/api-clients';
import { EditorFacadeBase } from './editor-facade-base';
import { UserFacade } from './user.facade';
import { UIFacade } from './ui.facade';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { bufferCount, filter, map } from 'rxjs/operators';

/**
 * t(spaceplan.SpaceType)
 */
@Injectable({
  providedIn: 'root',
})
export class EditorSpaceTypeFacade extends EditorFacadeBase<SpaceType> {
  constructor(
    protected toastr: ToastrService,
    protected modalService: BsModalService,
    protected uiFacade: UIFacade,
    protected translocoService: TranslocoService,
    private dataStoreFacade: DataStoreFacade,
    private userFacade: UserFacade
  ) {
    super(toastr, modalService, uiFacade, translocoService);
    this.dataStoreFacade.spaceProgram$
      .pipe(
        bufferCount(2, 1),
        filter(([a, b]) => b?.spaceProgramID !== a?.spaceProgramID),
        map(([a, b]) => b)
      )
      .subscribe(() => {
        this.resetToDefault();
      });
  }
  protected className(): string {
    return this.translocoService.translate('spaceplan.SpaceType');
  }
  protected getFromDataStore(id: string): Observable<SpaceType> {
    return this.dataStoreFacade.getSpaceType(id);
  }
  protected createDataStore(model: SpaceType): Observable<SpaceType> {
    return this.dataStoreFacade.createSpaceType(model);
  }
  protected updateDataStore(model: SpaceType): Observable<SpaceType> {
    return this.dataStoreFacade.updateSpaceType(model);
  }
  protected deleteDataStore(id: string): Observable<boolean> {
    return this.dataStoreFacade.deleteSpaceType(id);
  }
  protected getModelId(model: SpaceType): string {
    return model?.spaceTypeID;
  }
  protected setModelId(model: SpaceType, id: string) {
    model.spaceTypeID = id;
  }
  protected getNewModelForCopy(init: Partial<SpaceType>): Observable<SpaceType> {
    const copyinit = { ...init, spaceTypeName: null };
    return this.getNewModel(copyinit);
  }
  public canDelete(id: string): Observable<boolean> {
    const state = this.dataStoreFacade.state;
    const canDelete = state.generators.filter((f) => f.spaceTypeID === id)?.length === 0;
    return of(canDelete);
    // return of(true);
  }
  protected userCanEdit(): Observable<boolean> {
    return this.dataStoreFacade.spaceProgram$.pipe(
      map(m =>
        m.roleNo === UserRole.Composer
      )
    );
  }
  protected getNewModel(init: Partial<SpaceType>): Observable<SpaceType> {
    return of({
      ...init,
      spaceTypeID: null,
    });
  }
}
