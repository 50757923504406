<ng-container *transloco="let t; read: 'home'">
  <div id="editor-form-top" *ngIf="form" [formGroup]="form">
    <div class="form-group form-row mb-1">
      <label for="buildingName" class="col-sm-12 col-form-label">{{ t('editorBuildingForm.buildingName.label')
        }}:</label>
      <div class="col-sm-12">
        <div class="input-group">
          <input type="text" class="form-control" id="name"
            placeholder="{{ t('editorBuildingForm.buildingName.placeholder') }}" name="buildingName"
            formControlName="buildingName" />
        </div>
        <div *ngIf="isRequesting && form.get('buildingName').errors" class="invalid-feedback">
          <div class="form-error" *ngIf="form.controls['buildingName'].errors.required">
            {{ t('editorBuildingForm.buildingName.error.required') }}
          </div>
          <!-- <div class="form-error" *ngIf="form.controls['buildingName'].errors.duplicate">
            {{ t('editorBuildingForm.buildingName.error.duplicate') }}
          </div> -->
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-2">
      <label for="partnerID" class="col-sm-12 col-form-label">{{ t('editorBuildingForm.partner.label') }}:</label>
      <div class="col-sm-12">
        <!-- [attr.disabled]="model.buildingID || (!model.buildingID && partners.length === 1) ? '' : null" -->
        <select class="custom-select" id="partnerID" name="partnerID" formControlName="partnerID"
          style="border-radius: 0.25rem">
          <option [ngValue]="null">{{ t('editorBuildingForm.partner.option.null_Please_select_partner') }}</option>
          <ng-template ngFor let-partner [ngForOf]="partners">
            <option [ngValue]="partner.partnerID">{{ partner.partnerDescr }}</option>
          </ng-template>
        </select>
        <div *ngIf="isRequesting && form.get('partnerID').errors" class="invalid-feedback">
          <div class="form-error" *ngIf="form.get('partnerID').errors && form.controls['partnerID'].errors.required">
            {{ t('editorBuildingForm.partnerID.error.required') }}
          </div>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-1">
      <label for="buildingDescr" class="col-sm-12 col-form-label">{{ t('editorBuildingForm.buildingDescr.label')
        }}:</label>
      <div class="col-sm-12">
        <div class="input-group mb-1">
          <textarea name="buildingDescr" id="buildingDescr"
            placeholder="{{ t('editorBuildingForm.buildingDescr.placeholder') }}" class="form-control"
            formControlName="buildingDescr" rows="6" style="resize: none"></textarea>
        </div>
      </div>
    </div>



    <div class="form-group form-row mb-2">
      <label for="folderID" class="col-sm-12 col-form-label">{{ t('editorBuildingForm.folder.label') }}:</label>
      <div class="col-sm-12">
        <!-- [attr.disabled]="model.buildingID || (!model.buildingID && partners.length === 1) ? '' : null" -->
        <select class="custom-select" id="folderID" name="folderID" formControlName="folderID"
          style="border-radius: 0.25rem">
          <option [ngValue]="null">{{ t('editorBuildingForm.folder.option.null_Please_select_folder') }}</option>
          <ng-template ngFor let-folder [ngForOf]="folders">
            <option [ngValue]="folder.folderID">{{ folder.folderName }}</option>
          </ng-template>
        </select>
        <div *ngIf="isRequesting && form.get('folderID').errors" class="invalid-feedback">
          <div class="form-error" *ngIf="form.get('folderID').errors && form.controls['folderID'].errors.required">
            {{ t('editorBuildingForm.folderID.error.required') }}
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="form-group form-row mb-1">
      <label for="folderDescr" class="col-sm-12 col-form-label">
          Folder:
      </label>
      <div class="col-sm-12">
          <div class="input-group">
              <select class="custom-select" [disabled]="!folders.length"
                  [ngModel]="folderSelected" (ngModelChange)="onFolderSelected($event)">
                  <option [ngValue]="null">Please select the folder
                  </option>
                  <option *ngFor="let folder of folders" [ngValue]="folder">
                      {{ folder.folderName }}
                  </option>
              </select>
          </div>
      </div>
  </div> -->

    <div class="form-group form-row mb-2">
      <label for="measurementUnit" class="col-sm-12 col-form-label">{{
        t('editorBuildingForm.buildingMeasurementUnit.label') }}:</label>
      <div class="col-sm-12">
        <select class="custom-select" id="us" name="measurementUnit" formControlName="measurementUnit"
          style="border-radius: 0.25rem">
          <!-- <option [ngValue]="null">{{ t('editorBuildingForm.partner.option.null_Please_select_buildingMeasurementUnit') }}</option> -->
          <ng-template ngFor let-buildingMeasurementUnit [ngForOf]="buildingMeasurementUnits">
            <option [ngValue]="buildingMeasurementUnit.type">{{ t('measurementUnit.' + buildingMeasurementUnit.text) }}
            </option>
          </ng-template>
        </select>
      </div>
    </div>

    <div class="form-group form-row mb-1">
      <label for="maximumFloorNo" class="col-sm-12 col-form-label">{{ t('editorBuildingForm.maximumFloorNo.label')
        }}:</label>
      <div class="col-sm-12">
        <div class="input-group">
          <input type="text" class="form-control" id="name"
            placeholder="{{ t('editorBuildingForm.maximumFloorNo.placeholder') }}" name="maximumFloorNo"
            formControlName="maximumFloorNo" />
        </div>
        <div *ngIf="isRequesting && form.get('maximumFloorNo').errors" class="invalid-feedback">
          <div class="form-error" *ngIf="form.controls['maximumFloorNo'].errors.required">
            {{ t('editorBuildingForm.maximumFloorNo.error.required') }}
          </div>
          <!-- <div class="form-error" *ngIf="form.controls['maximumFloorNo'].errors.duplicate">
            {{ t('editorBuildingForm.maximumFloorNo.error.duplicate') }}
          </div> -->
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-1">
      <label for="lowestUndergroundFloorNo" class="col-sm-12 col-form-label">{{
        t('editorBuildingForm.lowestUndergroundFloorNo.label') }}:</label>
      <div class="col-sm-12">
        <div class="input-group">
          <input type="text" class="form-control" id="name"
            placeholder="{{ t('editorBuildingForm.lowestUndergroundFloorNo.placeholder') }}"
            name="lowestUndergroundFloorNo" formControlName="lowestUndergroundFloorNo" />
        </div>
        <div *ngIf="isRequesting && form.get('lowestUndergroundFloorNo').errors" class="invalid-feedback">
          <div class="form-error" *ngIf="form.controls['lowestUndergroundFloorNo'].errors.required">
            {{ t('editorBuildingForm.lowestUndergroundFloorNo.error.required') }}
          </div>
          <!-- <div class="form-error" *ngIf="form.controls['lowestUndergroundFloorNo'].errors.duplicate">
            {{ t('editorBuildingForm.lowestUndergroundFloorNo.error.duplicate') }}
          </div> -->
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-2">
      <label for="noOfZones" class="col-sm-12 col-form-label">{{ t('editorBuildingForm.noOfZones.label') }}:</label>
      <div class="col-sm-12">
        <select class="custom-select" id="us" name="noOfZones" formControlName="noOfZones"
          style="border-radius: 0.25rem">
          <option [ngValue]="null">{{ t('editorBuildingForm.noOfZones.option.null_Please_select_noOfZones') }}</option>
          <ng-template ngFor let-noOfZone [ngForOf]="noOfZonesList">
            <option [ngValue]="noOfZone.value">{{ noOfZone.text }}</option>
          </ng-template>
        </select>
      </div>
    </div>

    <div class="form-group form-row mb-1" formGroupName="zones" *ngIf="zones?.length">
      <label for="zoneName" class="col-sm-12 col-form-label">{{ t('editorBuildingForm.zones.label') }}:</label>
      <div class="col-sm-12 mb-1" *ngFor="let zone of zones">
        <div class="input-group" formGroupName="{{ zone.buildingZoneID }}">
          <input type="text" class="form-control" id="zoneName" maxlength="5"
            placeholder="{{ t('editorBuildingForm.zoneName.placeholder') }}" formControlName="zoneName"
            name="zoneName" />
        </div>
        <div *ngIf="isRequesting && form.get(['zones', zone.buildingZoneID, 'zoneName']).errors"
          class="invalid-feedback">
          <div class="form-error"
            *ngIf="form.get(['zones', '' + zone.buildingZoneID + '', 'zoneName']).errors.required">
            {{ t('editorBuildingForm.zoneName.error.required') }}
          </div>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-1 mt-3">

      <label class="col-sm-12 col-form-label">{{ t('editorBuildingForm.area.label') }}</label>

      <ng-container *ngIf="!floors?.length">
        <div class="alert alert-warning flex-grow-1 mb-0">
          {{ t('editorBuildingForm.info.selectHighestLowestFloor') }}
        </div>
      </ng-container>
      <ng-container *ngIf="floors?.length">
        <div class="alert alert-info flex-grow-1 mb-0">
          {{ t('editorBuildingForm.info.floorSize') }}
        </div>
      </ng-container>

    </div>
    <ng-container *ngIf="floors?.length && zones?.length">
      <div class="form-group form-row mb-0">
        <div class="alert alert-info flex-grow-1 mb-0">
          {{ t('editorBuildingForm.info.floorAreaAndZones') }}
        </div>
      </div>
    </ng-container>


    <ng-container *ngIf="isEditWithAllocations()">
      <div class="form-group form-row mb-0 mt-1">
        <div class="alert alert-danger flex-grow-1 mb-0">
          Please note that there are already allocations for this building. Any changes to the floor space might impact
          your existing allocations.
        </div>
      </div>
    </ng-container>



    <div class="form-group form-row mb-2 d-flex flex-column" formGroupName="floors">
      <ng-template ngFor let-i="index" let-cnt="count" let-floor [ngForOf]="floors">
        <ng-container *ngIf="i === 0">
          <div class="building-row-header mt-3 form-row d-flex flex-nowrap align-items-center justify-content-between">
            <div class="building-column-floorname text-center" style="max-width: 70px; width: 70px; min-width: 70px">
              Floor</div>
            <div class="building-column-floorarea d-flex mx-1 flex-grow-1 text-align-center">&nbsp;</div>
            <ng-container *ngIf="form.get('noOfZones').value">
              <ng-container *ngFor="let zone of model.zones">
                <div class="building-column-zonearea d-flex mx-1 flex-grow-1" style="justify-content: center">
                  {{ zone.zoneName }}
                </div>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>

        <div class="building-row-detail mt-0 form-row d-flex flex-nowrap align-items-center justify-content-between"
          formGroupName="{{ floor.buildingFloorID }}">
          <div class="building-column-floorname" style="max-width: 70px; width: 70px; min-width: 70px">
            <input type="text" class="form-control" [id]="'floorName' + i" maxlength="10"
              placeholder="{{ t('editorBuildingForm.floorName.placeholder') }}" [name]="'floorName' + i"
              formControlName="floorName" size="4" style="width: 100%" />
          </div>
          <div class="building-column-floorarea mx-1 flex-grow-1">
            <input type="text" class="form-control" formControlName="availableArea" [id]="'floorArea' + i"
              [placeholder]="+form.get('measurementUnit').value === 0 ? 'm²' : 'ft²'" [name]="'floorArea' + i" size="4"
              style="width: 100%" (keyup)="onChangeFloorArea(floor.buildingFloorID)" />
          </div>
          <ng-container *ngIf="form.get('noOfZones').value">
            <ng-container formGroupName="zones">
              <ng-container *ngFor="let zone of zones">
                <div class="building-column-zonearea d-flex mx-1 flex-grow-1" formGroupName="{{ zone.buildingZoneID }}">
                  <input type="text" class="form-control"
                    [placeholder]="+form.get('measurementUnit').value === 0 ? 'm²' : 'ft²'"
                    formControlName="availableArea" size="3" style="width: 100%"
                    (keyup)="onChangeFloorZoneArea(floor.buildingFloorID, zone.buildingZoneID)" />
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </ng-template>
      <div class="building-row-header form-row d-flex flex-nowrap align-items-center justify-content-between px-1"
        *ngIf="floors?.length">
        <div class="alert alert-info d-flex flex-grow-1 mb-0 mt-1" style="justify-content: center;">
          {{ t('editorBuildingForm.totalArea') }} {{ intl.formatNumber(totalArea, 'n0') }}{{
          +form.get('measurementUnit').value === 0 ? 'm²' : 'ft²' }}
        </div>
      </div>
      <div *ngIf="isRequesting && form.errors" class="invalid-feedback">
        <div class="form-error" *ngIf="form.errors.noarea">
          {{ t('editorBuildingForm.errors.noarea') }}
        </div>
      </div>
    </div>
  </div>
</ng-container>