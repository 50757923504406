import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DataStoreFacade, UIFacade } from '../facades';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BehaviourWorkPlaceTypesDataResolver implements Resolve<boolean> {
  constructor(private service: DataStoreFacade, private uiFacade: UIFacade) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    const spaceProgramID = route.parent?.paramMap.get('id') || route.parent?.parent?.paramMap.get('id');
    return this.service.loadBehaviourWorkPlaceTypes(spaceProgramID).pipe(
      catchError((error: any, caught: Observable<any>) => {
        this.uiFacade.handleErrorPage(error, { spaceProgramID });
        return of(null);
      })
    );
  }
}
