import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FoldersDataStoreFacade } from 'src/app/folders/facades';
import { UIFacade } from '../facades';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FoldersDataResolver implements Resolve<boolean> {
  constructor(private service: FoldersDataStoreFacade, private uiFacade: UIFacade) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this.service.loadFoldersList().pipe(
      catchError((error: any, caught: Observable<any>) => {
        this.uiFacade.handleErrorPage(error);
        return of(null);
      })
    );
  }
}
