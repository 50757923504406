import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, AbstractControl, Validators, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Folder, FolderUsageType, Partner, Template } from 'src/app/core/services/api-clients';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { take, map, distinctUntilChanged } from 'rxjs/operators';
import { TemplateDataStoreFacade } from 'src/app/template/facades/template-data-store.facade';

@Component({
  selector: 'app-editor-template-copy-form',
  styleUrls: ['./editor-template-copy-form.component.scss'],
  templateUrl: './editor-template-copy-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorTemplateCopyFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public parentForm: FormGroup;
  @Input()
  model: Template;
  @Input()
  options = {
    partners: [] as Partner[],
    folders: [] as Folder[],
    readOnly: false,
  };
  @Input()
  public isRequesting: boolean;
  @Output()
  public updateModelWithChanges = new EventEmitter<Partial<Template>>();
  public form: FormGroup;
  private readonly subscription: Subscription = new Subscription();
  private readonly onChanges = new BehaviorSubject<SimpleChanges>(null);
  private readonly partnerIDSubject = new BehaviorSubject<string>(null);
  public readonly partnerID$ = this.partnerIDSubject.asObservable();

  public readonly folders$ = this.partnerID$.pipe(
    distinctUntilChanged(),
    map((partnerID) => {
      if (partnerID) {
        return this.options.folders.filter((t) => t.usageType !== FolderUsageType.SpaceProgram && t.partnerID === partnerID);
      }
      return [] as Folder[];
    })
  );

  constructor(private fb: FormBuilder, private dataStoreFacade: TemplateDataStoreFacade) {}

  ngOnInit() {
    this.prepareLookups();
    this.initForm();
    if (this.parentForm) {
      this.parentForm.addControl('template', this.form);
    }
    this.subscription.add(
      this.onChanges.subscribe((changes: SimpleChanges) => {
        if (changes?.model) {
          const model = changes.model.currentValue as Template;
          const values = this.modelToForm(model);
          this.form.patchValue(values, { emitEvent: false });
          this.form.get('partnerID').updateValueAndValidity({ onlySelf: true });
          this.form.get('templateName').updateValueAndValidity({ onlySelf: true });
        }
      })
    );
    this.subscription.add(
      this.form.valueChanges.pipe().subscribe((value) => {
        const values = this.form.getRawValue();
        const partnerID = this.model.partnerID;
        this.model = this.formToModel(values);
        if (this.model.partnerID !== partnerID) {
          this.form.get('templateName')?.updateValueAndValidity();
          this.form.get('folderID').setValue(null);
        }
        this.updateModelWithChanges.emit(this.model);
        this.partnerIDSubject.next(value.partnerID);
      })
    );
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.onChanges.next(changes);
  }
  prepareLookups() {}
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  initForm() {
    this.form = this.fb.group(
      {
        templateName: this.fb.control('', [Validators.required]),
        templateDescr: this.fb.control(''),
        folderID: this.fb.control(null),
        partnerID: this.fb.control(null, Validators.required),
      },
      {
        asyncValidators: this.validateNameDuplicateFormValidator(),
      }
    );
    if (this.options.readOnly) {
      this.form.disable({ onlySelf: true });
    }
  }
  formToModel(values: any): Partial<Template> {
    const model: Partial<Template> = {
      ...this.model,
      templateName: values.templateName,
      partnerID: values.partnerID,
      folderID: values.folderID,
      templateDescr: values.templateDescr,
    };
    return model;
  }
  modelToForm(template: Template) {
    return (
      (template && {
        templateName: template.templateName,
        partnerID: template.partnerID,
        folderID: template.folderID,
        templateDescr: template.templateDescr,
      }) ||
      {}
    );
  }
  validateNameDuplicateFormValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.dataStoreFacade.templates$.pipe(
        map((templates) => {
          const partnerID = this.form.get('partnerID')?.value;
          const templateName = this.form.get('templateName')?.value;
          const duplicate =
            templateName &&
            partnerID &&
            !!templates.find((f) => f.templateID !== this.model.templateID && f.partnerID === partnerID && f.templateName === templateName);
          if (duplicate) {
            return {
              duplicate: true,
            };
          }
          return null;
        }),
        take(1)
      );
    };
  }
}
