import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'gridGroupName' })
export class GridGroupNamePipe implements PipeTransform {
  constructor() { }
  transform(value) {
    const parts = value.split('||');
    return (parts?.length && parts[0]) || value;
  }
}
