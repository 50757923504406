import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, AsyncValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { Project, ProjectClient, ScenarioClient, Template, Partner, UpdateSpaceProgramPrefaceDto } from 'src/app/core/services/api-clients';
import { Subscription, BehaviorSubject, of, Observable } from 'rxjs';
import { IntlService } from '@progress/kendo-angular-intl';
import { distinctUntilChanged, switchMap, map, take } from 'rxjs/operators';
import { DataStoreFacade } from 'src/app/spaceplan/shared/facades';

@Component({
  selector: 'app-editor-space-program-preface-form',
  styleUrls: ['./editor-space-program-preface-form.component.scss'],
  templateUrl: './editor-space-program-preface-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorSpaceProgramPrefaceFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public parentForm: FormGroup;
  @Input()
  model: UpdateSpaceProgramPrefaceDto;
  @Input()
  options: {
    partners: Partner[];
    templates: Template[];
    readOnly: boolean;
  };
  @Input()
  public isRequesting: boolean;
  @Output()
  public updateModelWithChanges = new EventEmitter<Partial<UpdateSpaceProgramPrefaceDto>>();
  public form: FormGroup;
  private readonly subscription: Subscription = new Subscription();
  private readonly onChanges = new BehaviorSubject<SimpleChanges>(null);

  constructor(private fb: FormBuilder, public intl: IntlService, private dataStoreFacade: DataStoreFacade) {}

  ngOnInit() {
    this.prepareLookups();
    this.initForm();
    if (this.parentForm) {
      this.parentForm.addControl('spaceProgram', this.form);
    }
    this.subscription.add(
      this.onChanges.subscribe((changes: SimpleChanges) => {
        if (changes?.model) {
          const model = changes.model.currentValue as UpdateSpaceProgramPrefaceDto;
          const values = this.modelToForm(model);
          this.form.patchValue(values, { emitEvent: false });
        }
      })
    );
    this.subscription.add(
      this.form.valueChanges.pipe().subscribe((value) => {
        const values = this.form.getRawValue();
        this.model = this.formToModel(values);
        this.updateModelWithChanges.emit(this.model);
      })
    );
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.onChanges.next(changes);
  }
  prepareLookups() {}
  initForm() {
    // const spaceProgramID = Guid.raw();
    this.form = this.fb.group({
      preface: this.fb.control(''),
    });
    if (this.options.readOnly) {
      this.form.disable({ onlySelf: true });
    }
  }

  formToModel(values: any): Partial<UpdateSpaceProgramPrefaceDto> {
    const model: Partial<UpdateSpaceProgramPrefaceDto> = {
      ...this.model,
      preface: values.preface,
    };
    return model;
  }
  modelToForm(model: UpdateSpaceProgramPrefaceDto): { [key: string]: any } {
    return (
      (model && {
        spaceProgramID: model.spaceProgramID,
        preface: model.preface,
      }) ||
      {}
    );
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
