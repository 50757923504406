import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
  Type,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AsyncValidatorFn,
  ValidationErrors,
  AbstractControl,
  FormArray,
  ValidatorFn,
} from '@angular/forms';
import { Building } from 'src/app/core/services/api-clients';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { AllocationAddEditDto, UIFacade } from 'src/app/spaceplan/shared/facades';
import { AllocationDataStoreFacade } from 'src/app/spaceplan/shared/facades/allocation-data-store.facade';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'app-editor-allocation-form',
  styleUrls: ['./editor-allocation-form.component.scss'],
  templateUrl: './editor-allocation-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorAllocationFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public parentForm: FormGroup;
  @Input()
  model: AllocationAddEditDto;
  @Input()
  options: {
    mode: string;
    readOnly: boolean;
    buildings: Building[];
  } = {
    mode: 'edit',
    readOnly: false,
    buildings: [],
  };
  @Input()
  public isRequesting: boolean;

  @Output()
  public updateModelWithChanges = new EventEmitter<Partial<AllocationAddEditDto>>();

  public form: FormGroup;
  private readonly subscription: Subscription = new Subscription();
  private readonly onChanges = new BehaviorSubject<SimpleChanges>(null);

  public buildings: Building[];

  constructor(
    private fb: FormBuilder,
    public uiFacade: UIFacade,
    private dataStoreFacade: AllocationDataStoreFacade,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.prepareLookups();
    this.initForm();
    if (this.parentForm) {
      this.parentForm.addControl('allocation', this.form);
    }
    this.subscription.add(
      this.onChanges.subscribe((changes: SimpleChanges) => {
        if (changes?.model) {
          const model = changes.model.currentValue as AllocationAddEditDto;
          const values = this.modelToForm(model);
          this.form.patchValue(values, { emitEvent: false });
        }
      })
    );
    this.subscription.add(
      this.form.valueChanges.pipe().subscribe((value) => {
        const values = this.form.getRawValue();
        this.model = this.formToModel(values);
        this.updateModelWithChanges.emit(this.model);
      })
    );
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.onChanges.next(changes);
  }
  prepareLookups() {
    this.buildings = this.options.buildings
      .sort((a, b) => a.buildingName.localeCompare(b.buildingName))
      .map((building) => {
        return {
          ...building,
          noOfRules: (this.model.buildings?.find((f) => f.buildingID === building.buildingID) as any)?.noOfRules ?? 0,
        };
      });
  }
  initForm() {
    this.form = this.fb.group({
      allocationName: this.fb.control('', [Validators.required], this.validateNameDuplicateValidator()),
      allocationDescr: this.fb.control(''),
      noOfRules: this.fb.control(0),
      // buildings: this.fb.array([], [Validators.required, validateRequiredBuildingsFn.bind(this)]),
      buildings: this.fb.group(
        this.buildings.reduce((value, item) => {
          value[item.buildingID] = this.fb.control(false);
          return value;
        }, {})
      ),
    });
    if (this.options.readOnly) {
      this.form.disable({ onlySelf: true });
    }
    this.form.setValidators(this.validateRequiredBuildingsFn());
  }
  formToModel(values: any): Partial<AllocationAddEditDto> {
    const buildings = this.buildings
      .reduce((value, item) => {
        value.push(values.buildings[item.buildingID]);
        return value;
      }, [])
      .filter((f) => !!f);

    const buildingIDs = Object.entries(values.buildings)
      .filter(([buildingID, isSelected]) => {
        return isSelected;
      })
      .map(([buildingID, isSelected]) => {
        return buildingID;
      });

    const model: Partial<AllocationAddEditDto> = {
      ...this.model,
      allocationName: values.allocationName,
      allocationDescr: values.allocationDescr,
      buildingIDs,
    };
    return model;
  }
  modelToForm(model: AllocationAddEditDto): { [key: string]: any } {
    return (
      (model && {
        allocationName: model.allocationName,
        allocationDescr: model.allocationDescr,
        noOfRules: model.noOfRules,
        buildings: this.buildings.reduce((value, item) => {
          const isSelected = !!model.buildingIDs?.find((f) => f === item.buildingID);
          value[item.buildingID] = isSelected;
          return value;
        }, {}),
      }) ||
      {}
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  isVisible(building) {
    if (!!this.model.allocationID) {
      const isSelected = !!this.model.buildingIDs?.find((f2) => f2 === building.buildingID);
      return isSelected || !building.archivedInd;
    } else {
      return !building.archivedInd;
    }
  }

  validateNameDuplicateValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.dataStoreFacade.allocations$.pipe(
        map((allocations) => {
          const duplicate = !!allocations.find((f) => f.allocationID !== this.model.allocationID && f.allocationName === control.value);
          if (duplicate) {
            return {
              duplicate: true,
            };
          }
          return null;
        }),
        take(1)
      );
    };
  }

  validateRequiredBuildingsFn(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      const buildingCtrl = group.controls['buildings'];
      const hasBuilding = !!Object.entries(buildingCtrl.value).filter(([id, value]) => {
        return value;
      }).length;

      if (!hasBuilding) {
        if (+this.model.noOfRules > 0) {
          buildingCtrl.setErrors(null);
        } else {
          buildingCtrl.setErrors({ nobuildings: true });
        }
      } else {
        buildingCtrl.setErrors(null);
      }
      return;
    };
  }
}
