import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { User, UserRole } from 'src/app/core/services/api-clients';
import { Subscription, BehaviorSubject } from 'rxjs';
import { DataStoreFacade } from 'src/app/spaceplan/shared/facades';

@Component({
  selector: 'app-editor-user-form',
  styleUrls: ['./editor-user-form.component.scss'],
  templateUrl: './editor-user-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorUserFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public parentForm: FormGroup;
  @Input()
  model: User;
  @Input()
  options: {
    readOnly: false;
  };
  @Input()
  public isRequesting: boolean;
  @Output()
  public updateModelWithChanges = new EventEmitter<Partial<User>>();
  public form: FormGroup;
  public userRoles: { roleNo: number; name: string }[];
  private readonly subscription: Subscription = new Subscription();
  private readonly onChanges = new BehaviorSubject<SimpleChanges>(null);

  constructor(private fb: FormBuilder, private dataStoreFacade: DataStoreFacade) {}

  ngOnInit() {
    this.prepareLookups();
    this.initForm();
    if (this.parentForm) {
      this.parentForm.addControl('user', this.form);
    }
    this.subscription.add(
      this.onChanges.subscribe((changes: SimpleChanges) => {
        if (changes?.model) {
          const model = changes.model.currentValue as User;
          const values = this.modelToForm(model);
          this.form.patchValue(values, { emitEvent: false });
        }
      })
    );
    this.subscription.add(
      this.form.valueChanges.pipe().subscribe((value) => {
        const values = this.form.getRawValue();
        this.model = this.formToModel(values);
        this.updateModelWithChanges.emit(this.model);
      })
    );
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.onChanges.next(changes);
  }
  prepareLookups() {
    this.userRoles = [
      { roleNo: 0, name: UserRole[0] },
      { roleNo: 1, name: UserRole[1] },
      { roleNo: 2, name: UserRole[2] },
    ];
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  initForm() {
    this.form = this.fb.group({
      userName: this.fb.control({ value: null, disabled: true }),
      roleNo: this.fb.control(null),
    });
    if (this.options.readOnly) {
      this.form.disable({ onlySelf: true });
    }
  }
  formToModel(values: any): Partial<User> {
    const model: Partial<User> = {
      ...this.model,
      userName: values.userName,
      roleNo: values.roleNo,
    };
    return model;
  }
  modelToForm(model: User) {
    return (
      (model && {
        userName: model.userName,
        roleNo: model.roleNo,
      }) ||
      {}
    );
  }
}
