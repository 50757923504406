import { GeneratorOutputSummary } from '../models/generator-output-summary';

export function summarizeGeneratorOutputs(
  results: {
    capacity: number;
    area: number;
    workPlaceFactor: number;
    areaNetToGrossFactor: number;
    generatorOutputID?: string;
    generatorID?: string;
    groupID?: string;
    generatedNoOfSpaceTypes?: number;
    plannedNoOfSpaceTypes?: number;
    primaryFactor?: number;
    behaviorFraction?: number;
    growthFactor?: number;
    fteToPeopleFactor?: number;
  }[],
  totals: {
    fte: number;
    people: number;
    adjustedInOffice?: number;
  }
): GeneratorOutputSummary {
  const summary = results.reduce(
    (value, item) => {
      const effectiveNoOfSpaceTypes =
        item.plannedNoOfSpaceTypes || item.plannedNoOfSpaceTypes === 0 ? item.plannedNoOfSpaceTypes : item.generatedNoOfSpaceTypes;

      const generatedSpaces = item.generatedNoOfSpaceTypes;
      const plannedSpaces = effectiveNoOfSpaceTypes;

      const fte = (item.behaviorFraction ?? 0) * (item.growthFactor ?? 0);
      const people = fte * (item.fteToPeopleFactor ?? 0);

      const workPlaceFactor = item.workPlaceFactor ? item.workPlaceFactor : 0;
      const primaryWorkspaces = item.primaryFactor ? item.primaryFactor * plannedSpaces : 0;
      const capacityRatio = item.capacity ? item.capacity * plannedSpaces * workPlaceFactor : 0;
      const capacity = item.capacity ? item.capacity * plannedSpaces : 0;
      const plannedNetSurface = item.area ? item.area * plannedSpaces : 0;
      const plannedGrossSurface = item.area ? item.area * plannedSpaces * (item.areaNetToGrossFactor ?? 1) : 0;
      const generatedNetSurface = item.area ? item.area * generatedSpaces : 0;

      value.generatedSpaces += generatedSpaces;
      value.plannedSpaces += plannedSpaces;

      value.fte += fte;
      value.ftePresent += fte;
      value.people += people;

      value.primaryWorkspaces += primaryWorkspaces;
      value.capacityRatio += capacityRatio;
      value.capacity += capacity;
      value.plannedNetSurface += plannedNetSurface;
      value.plannedGrossSurface += plannedGrossSurface;
      value.generatedNetSurface += generatedNetSurface;

      return value;
    },
    {
      generatedSpaces: 0,
      plannedSpaces: 0,
      deltaSpaces: 0,

      fte: 0,
      ftePresent: 0,
      people: 0,

      primaryWorkspaces: 0,
      capacityRatio: 0,
      capacity: 0,
      plannedNetSurface: 0,
      plannedGrossSurface: 0,
      deltaSurface: 0,
      generatedNetSurface: 0,

      primaryWorkspacesPerFTE: 0,
      capacityRatioPerFTE: 0,
      capacityPerFTE: 0,
      plannedNetSurfacePerFTE: 0,
      plannedGrossSurfacePerFTE: 0,
      deltaSurfacePerFTE: 0,

      primaryWorkspacesPerFTEPresent: 0,
      capacityRatioPerFTEPresent: 0,
      capacityPerFTEPresent: 0,
      plannedNetSurfacePerFTEPresent: 0,
      plannedGrossSurfacePerFTEPresent: 0,
      deltaSurfacePerFTEPresent: 0,

      primaryWorkspacesPerPerson: 0,
      capacityRatioPerPerson: 0,
      capacityPerPerson: 0,
      plannedNetSurfacePerPerson: 0,
      plannedGrossSurfacePerPerson: 0,
      deltaSurfacePerPerson: 0,
    }
  );


  summary.fte = totals ? totals?.fte : summary.fte;
  summary.ftePresent = totals?.adjustedInOffice ? summary.fte * totals.adjustedInOffice : summary.fte;
  summary.people = totals ? totals?.people : summary.people;

  summary.deltaSpaces = summary.plannedSpaces - summary.generatedSpaces;

  summary.plannedNetSurface = Math.round(summary.plannedNetSurface);
  summary.plannedGrossSurface = Math.round(summary.plannedGrossSurface);
  summary.deltaSurface = summary.plannedGrossSurface - summary.plannedNetSurface;
  summary.generatedNetSurface = Math.round(summary.generatedNetSurface);

  // summary.plannedNetSurfacePerPerson = Math.round(summary.plannedNetSurfacePerPerson);
  // summary.plannedGrossSurfacePerFTE = Math.round(summary.plannedGrossSurfacePerFTE);
  // summary.deltaSurfacePerFTE = summary.plannedGrossSurfacePerFTE - summary.plannedNetSurfacePerPerson;

  if (summary.fte) {
    summary.primaryWorkspacesPerFTE = summary.primaryWorkspaces / summary.fte;
    summary.capacityRatioPerFTE = summary.capacityRatio / summary.fte;
    summary.capacityPerFTE = summary.capacity / summary.fte;
    summary.plannedNetSurfacePerFTE = summary.plannedNetSurface / summary.fte;
    summary.plannedGrossSurfacePerFTE = summary.plannedGrossSurface / summary.fte;

    // summary.plannedNetSurfacePerFTE = Math.round(summary.plannedNetSurfacePerFTE);
    // summary.plannedGrossSurfacePerFTE = Math.round(summary.plannedGrossSurfacePerFTE);
    summary.deltaSurfacePerFTE = summary.plannedGrossSurfacePerFTE - summary.plannedNetSurfacePerFTE;
  }

  if (summary.ftePresent) {
    summary.primaryWorkspacesPerFTEPresent = summary.primaryWorkspaces / summary.ftePresent;
    summary.capacityRatioPerFTEPresent = summary.capacityRatio / summary.ftePresent;
    summary.capacityPerFTEPresent = summary.capacity / summary.ftePresent;
    summary.plannedNetSurfacePerFTEPresent = summary.plannedNetSurface / summary.ftePresent;
    summary.plannedGrossSurfacePerFTEPresent = summary.plannedGrossSurface / summary.ftePresent;

    // summary.plannedNetSurfacePerFTEPresent = Math.round(summary.plannedNetSurfacePerFTEPresent);
    // summary.plannedGrossSurfacePerFTEPresent = Math.round(summary.plannedGrossSurfacePerFTEPresent);
    summary.deltaSurfacePerFTEPresent = summary.plannedGrossSurfacePerFTEPresent - summary.plannedNetSurfacePerFTEPresent;
  }

  if (summary.people) {
    summary.primaryWorkspacesPerPerson = summary.primaryWorkspaces / summary.people;
    summary.capacityRatioPerPerson = summary.capacityRatio / summary.people;
    summary.capacityPerPerson = summary.capacity / summary.people;
    summary.plannedNetSurfacePerPerson = summary.plannedNetSurface / summary.people;
    summary.plannedGrossSurfacePerPerson = summary.plannedGrossSurface / summary.people;

    // summary.plannedNetSurfacePerPerson = Math.round(summary.plannedNetSurfacePerPerson);
    // summary.plannedGrossSurfacePerPerson = Math.round(summary.plannedGrossSurfacePerPerson);
    summary.deltaSurfacePerPerson = summary.plannedGrossSurfacePerPerson - summary.plannedNetSurfacePerPerson;
  }

  //console.log ('GeneratorOutputSummary', { results, summary });
  return summary;
}
