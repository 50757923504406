import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, AsyncValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import {
  Project,
  ProjectClient,
  ScenarioClient,
  CreateSpaceProgramDto,
  Template,
  Partner,
  Scenario,
  TemplateSpaceType,
  TemplateSpaceTypeClient,
  Folder,
} from 'src/app/core/services/api-clients';
import { Subscription, BehaviorSubject, of, Observable, combineLatest } from 'rxjs';
import { IntlService } from '@progress/kendo-angular-intl';
import { distinctUntilChanged, switchMap, map, take, tap } from 'rxjs/operators';
import { DataStoreFacade } from 'src/app/spaceplan/shared/facades';
import { LookupSpaceTypesFacade } from 'src/app/spaceplan/shared/facades/lookup-spacetypes.facade';
import { LookupProjectFacade } from 'src/app/spaceplan/shared/facades/lookup-project.facade';
import { LookupScenarioFacade } from 'src/app/spaceplan/shared/facades/lookup-scenario.facade';
import { LookupTemplateFacade } from 'src/app/spaceplan/shared/facades/lookup-template.facade';
import { TranslocoService } from '@ngneat/transloco';

// t('spaceplancreate.label.Using_space_types')
// t('spaceplancreate.label.All')
@Component({
  selector: 'app-editor-space-program-create-form',
  styleUrls: ['./editor-space-program-create-form.component.scss'],
  templateUrl: './editor-space-program-create-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorSpaceProgramCreateFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public parentForm: FormGroup;
  @Input()
  model: CreateSpaceProgramDto;
  @Input()
  options: {
    partners: Partner[];
    templates: Template[];
    folders: Folder[];
    readOnly: boolean;
  };
  @Input()
  public isRequesting: boolean;
  @Output()
  public updateModelWithChanges = new EventEmitter<Partial<CreateSpaceProgramDto>>();

  public form: FormGroup;
  public templateSpaceTypes: TemplateSpaceType[];
  public selectedTemplateSpaceTypes: TemplateSpaceType[];
  private readonly subscription: Subscription = new Subscription();
  private readonly onChanges = new BehaviorSubject<SimpleChanges>(null);
  private readonly partnerIDSubject = new BehaviorSubject<string>(null);
  private readonly projectIDSubject = new BehaviorSubject<number>(null);
  private readonly templateIDSubject = new BehaviorSubject<string>(null);
  private readonly templateSpaceTypeIDsSubject = new BehaviorSubject<string[]>(null);

  public readonly partnerID$ = this.partnerIDSubject.asObservable();
  public readonly projectID$ = this.projectIDSubject.asObservable();
  public readonly templateID$ = this.templateIDSubject.asObservable();
  public readonly templateSpaceTypeIDs$ = this.templateSpaceTypeIDsSubject.asObservable();

  public readonly templates$ = this.partnerID$.pipe(
    distinctUntilChanged(),
    map((partnerID) => {
      if (partnerID) {
        return this.options.templates.filter((t) => t.partnerID === partnerID);
      }
      return [] as Template[];
    })
  );
  public readonly folders$ = this.partnerID$.pipe(
    distinctUntilChanged(),
    map((partnerID) => {
      if (partnerID) {
        return this.options.folders.filter((t) => t.partnerID === partnerID);
      }
      return [] as Folder[];
    })
  );
  public readonly projects$ = this.partnerID$.pipe(
    distinctUntilChanged(),
    switchMap((partnerID): Observable<Project[]> => {
      if (partnerID) {
        return this.projectClient.getList(partnerID);
      }
      return of([] as Project[]);
    })
  );
  public readonly scenarios$ = this.projectID$.pipe(
    distinctUntilChanged(),
    switchMap((projectID): Observable<Scenario[]> => {
      if (projectID) {
        return this.scenarioClient.getList(projectID);
      }
      return of([] as Scenario[]);
    }),
    tap((scenarios) => {
      let scenarioID = null;
      if (scenarios?.length) {
        if (scenarios?.length === 1) {
          scenarioID = scenarios[0].scenarioID;
        }
      }
      this.form.patchValue({
        scenarioID,
      });
    })
  );
  public readonly templateSpaceTypes$ = this.templateID$.pipe(
    distinctUntilChanged(),
    switchMap((templateID): Observable<TemplateSpaceType[]> => {
      if (templateID) {
        return this.templateSpaceTypeClient.getList(templateID);
      }
      this.lookupSpaceTypesFacade.cancel();
      return of([] as TemplateSpaceType[]);
    }),
    tap((spaceTypes) => {
      this.form.patchValue({
        templateSpaceTypeIDs: spaceTypes.map((s) => s.templateSpaceTypeID),
      });
      this.lookupSpaceTypesFacade.refreshData(spaceTypes, spaceTypes);
      this.templateSpaceTypes = [...spaceTypes];
      this.templateSpaceTypeIDsSubject.next(spaceTypes.map((s) => s.templateSpaceTypeID));
    })
  );

  public readonly templateSpaceTypesCounts$ = this.templateSpaceTypeIDs$.pipe(
    distinctUntilChanged(),
    map((templateSpaceTypeIDs) => {
      const caption = !templateSpaceTypeIDs
        ? this.translactionService.translate('spaceplancreate.label.Using_space_types') +
          ' ' +
          this.translactionService.translate('spaceplancreate.label.All') +
          ' ' +
          this.templateSpaceTypes?.length
        : templateSpaceTypeIDs?.length
        ? templateSpaceTypeIDs.length === this.templateSpaceTypes.length
          ? this.translactionService.translate('spaceplancreate.label.Using_space_types') +
            ' ' +
            this.translactionService.translate('spaceplancreate.label.All') +
            ' ' +
            this.templateSpaceTypes?.length
          : this.translactionService.translate('spaceplancreate.label.Using_space_types') +
            ' ' +
            templateSpaceTypeIDs.length +
            ' / ' +
            this.templateSpaceTypes.length
        : this.translactionService.translate('spaceplancreate.label.Using_space_types') +
          ' ' +
          this.translactionService.translate('spaceplancreate.label.All') +
          ' ' +
          this.templateSpaceTypes?.length;
      return {
        caption,
        selectedCount: templateSpaceTypeIDs?.length,
        totalCount: this.templateSpaceTypes.length,
      };
    })
  );

  constructor(
    private fb: FormBuilder,
    public intl: IntlService,
    private projectClient: ProjectClient,
    private scenarioClient: ScenarioClient,
    private templateSpaceTypeClient: TemplateSpaceTypeClient,
    private dataStoreFacade: DataStoreFacade,
    public lookupSpaceTypesFacade: LookupSpaceTypesFacade,
    public lookupTemplateFacade: LookupTemplateFacade,
    public lookupProjectFacade: LookupProjectFacade,
    public lookupScenarioFacade: LookupScenarioFacade,
    private translactionService: TranslocoService
  ) {}

  ngOnInit() {
    this.prepareLookups();
    this.initForm();
    if (this.parentForm) {
      this.parentForm.addControl('spaceProgram', this.form);
    }
    this.subscription.add(
      this.onChanges.subscribe((changes: SimpleChanges) => {
        if (changes?.model) {
          const model = changes.model.currentValue as CreateSpaceProgramDto;
          const values = this.modelToForm(model);
          this.form.patchValue(values, { emitEvent: false });
        }
      })
    );
    this.subscription.add(
      this.form.valueChanges.pipe().subscribe((value) => {
        const values = this.form.getRawValue();
        this.model = this.formToModel(values);
        this.updateModelWithChanges.emit(this.model);
        this.partnerIDSubject.next(value.partnerID);
        this.projectIDSubject.next(value.projectID);
        this.templateIDSubject.next(value.templateID);
        this.templateSpaceTypeIDsSubject.next(value.templateSpaceTypeIDs);
      })
    );
    this.subscription.add(
      this.templateSpaceTypes$.subscribe((templateSpaceTypes) => {
        this.templateSpaceTypes = [...templateSpaceTypes];
      })
    );
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.onChanges.next(changes);
  }
  prepareLookups() {}
  initForm() {
    // const spaceProgramID = Guid.raw();
    this.form = this.fb.group({
      // spaceProgramID: this.fb.control(spaceProgramID),
      spaceProgramName: this.fb.control('', [Validators.required], this.validateNameDuplicateValidator()),
      spaceProgramDescr: this.fb.control(''),
      partnerID: this.fb.control(null, Validators.required),
      templateID: this.fb.control(null, Validators.required),
      folderID: this.fb.control(null),
      projectID: this.fb.control(null, Validators.required),
      scenarioID: this.fb.control(null, Validators.required),
      percFTEForGuest: this.fb.control(null, Validators.required),
      measurementUnit: this.fb.control(null, Validators.required),
      templateSpaceTypeIDs: this.fb.control([]),
    });
    if (this.options.readOnly) {
      this.form.disable({ onlySelf: true });
    }

    if (this.options.partners.length === 1) {
      this.form.patchValue({
        partnerID: this.options.partners[0].partnerID,
      });
      setTimeout(() => {
        this.onPartnerChange();
      }, 100);
    }

    // this.partners$.subscribe((templates) => {
    //   let templateID = null;
    //   let measurementUnit = null;

    //   if (templates && templates.length === 1) {
    //     templateID = templates[0].templateID;
    //     measurementUnit = templates[0].measurementUnit;
    //   }

    //   this.form.patchValue({
    //     templateID,
    //     measurementUnit,
    //     projectID: null,
    //     scenarioID: null,
    //   });
    // });
  }

  formToModel(values: any): Partial<CreateSpaceProgramDto> {
    const model: Partial<CreateSpaceProgramDto> = {
      ...this.model,
      spaceProgramID: values.spaceProgramID ? values.spaceProgramID : null,
      spaceProgramName: values.spaceProgramName,
      spaceProgramDescr: values.spaceProgramDescr,
      templateID: values.templateID ? values.templateID : null,
      folderID: values.folderID ? values.folderID : null,
      measurementUnit: values.measurementUnit !== null ? +values.measurementUnit : null,
      percFTEForGuest: values.percFTEForGuest || values.percFTEForGuest?.toString() === '0' ? +values.percFTEForGuest : null,
      projectID: values.projectID ? +values.projectID : null,
      scenarioID: values.scenarioID ? +values.scenarioID : null,
      templateSpaceTypeIDs: values.templateSpaceTypeIDs,
    };
    return model;
  }
  modelToForm(model: CreateSpaceProgramDto): { [key: string]: any } {
    return (
      (model && {
        spaceProgramID: model.spaceProgramID,
        spaceProgramName: model.spaceProgramName,
        spaceProgramDescr: model.spaceProgramDescr,
        templateID: model.templateID,
        folderID: model.folderID,
        measurementUnit: model.measurementUnit,
        percFTEForGuest: model.percFTEForGuest,
        projectID: model.projectID,
        scenarioID: model.scenarioID,
        templateSpaceTypeIDs: model.templateSpaceTypeIDs,
      }) ||
      {}
    );
  }
  onPartnerChange() {
    this.closeLookupPanels();
    combineLatest([this.templates$, this.projects$]).subscribe(([templates, projects]) => {
      let templateID = null;
      let measurementUnit = null;
      let projectID = null;

      if (templates && templates.length === 1) {
        templateID = templates[0].templateID;
        measurementUnit = templates[0].measurementUnit;
      }

      if (projects && projects.length === 1) {
        projectID = projects[0].projectID;
      }

      this.form.patchValue({
        templateID,
        measurementUnit,
        projectID: projectID,
        folderID: null,
        scenarioID: null,
      });
    });
  }
  onTemplateChange() {
    this.templates$.subscribe((templates) => {
      let measurementUnit = null;
      const templateID = this.form.get('templateID').value;
      if (templateID) {
        measurementUnit = templates.find((t) => t.templateID === templateID).measurementUnit;
      }
      this.form.patchValue({
        measurementUnit,
      });
    });
  }

  toggleTemplateSpaceTypesLookup() {
    this.closeLookupPanels();
    const templateSpaceTypeIDs = this.form.get('templateSpaceTypeIDs')?.value;
    if (templateSpaceTypeIDs?.length) {
      const selected = this.templateSpaceTypes.filter((f) => templateSpaceTypeIDs.find((f1) => f1 === f.templateSpaceTypeID));
      this.lookupSpaceTypesFacade.toggleLookup(this.templateSpaceTypes, selected);
    } else {
      this.lookupSpaceTypesFacade.toggleLookup(this.templateSpaceTypes, [...this.templateSpaceTypes]);
    }
  }

  toggleSpaceTypesLookup() {
    this.lookupSpaceTypesFacade.cancel();
    this.lookupProjectFacade.cancel();
    this.lookupScenarioFacade.cancel();
  }
  toggleProjectLookup() {
    this.lookupTemplateFacade.cancel();
    this.lookupSpaceTypesFacade.cancel();
    this.lookupScenarioFacade.cancel();
  }
  toggleScenarioLookup() {
    this.lookupSpaceTypesFacade.cancel();
    this.lookupProjectFacade.cancel();
    this.lookupTemplateFacade.cancel();
  }

  closeLookupPanels() {
    this.lookupTemplateFacade.cancel();
    this.lookupProjectFacade.cancel();
    this.lookupScenarioFacade.cancel();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  validateNameDuplicateValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.dataStoreFacade.spacePrograms$.pipe(
        map((spacePrograms) => {
          const duplicate = !!spacePrograms.find(
            (f) => f.spaceProgramID !== this.model.spaceProgramID && f.spaceProgramName === control.value
          );
          if (duplicate) {
            return {
              duplicate: true,
            };
          }
          return null;
        }),
        take(1)
      );
    };
  }
}
