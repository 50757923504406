<ng-container *transloco="let t; read: 'spaceplan'">
  <div class="content-body">
    <div class="detail">
      <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate [logValidationErrors]="form" appFormHandleEnterKey>
        <div *ngIf="headerVisible" class="header">
          <div class="title">{{ editorFacade.title$ | async }}</div>
          <div class="actions">
            <button *ngIf="canClose" (click)="onClose()" type="button" class="btn btn-primary btn-sm d-flex">
              <i class="k-icon k-i-close"></i>
            </button>
          </div>
        </div>
        <div class="body overflow-hidden">
          <div class="p-1">
            <app-editor-space-program-preface-form [parentForm]="form" [model]="editorFacade.model$ | async"
              [options]="options$ | async" (updateModelWithChanges)="editorFacade.updateModelWithChanges($event)"
              [isRequesting]="isRequesting">
            </app-editor-space-program-preface-form>
          </div>
        </div>
        <div class="footer" *ngIf="editorFacade.saveButtonIsVisible$ | async">
          <div class="buttons-wrapper">
            <button [disabled]="!(editorFacade.isDirty$ | async)"
              [ngClass]="{ disabled: !(editorFacade.isDirty$ | async) }" class="btn btn-primary" type="submit"
              style="min-width: 150px;">
              {{ t('Save') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-container>