import { BehaviorSubject, Observable, Subject, of, combineLatest } from 'rxjs';
import { map, take, catchError, distinctUntilChanged, debounceTime, delay, switchMap, mergeMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { confirmClose, confirmDelete, showError } from '../functions/confirmations';
import { deepEquals } from '../functions/deep-equals';
import { EditorFacadeState } from '../models/editor-facade-state';
import { Guid } from 'guid-typescript';
import { UIFacade } from './ui.facade';
import { TranslocoService } from '@ngneat/transloco';
import { ApiException } from 'src/app/core/services/api-clients';
import { environment } from 'src/environments/environment';

/**
 * t(root.editor.New.title)
 * t(root.editor.Edit.title)
 * t(root.editor.View.title)
 * t(root.editor.Copy.title)
 * t(root.editor.Duplicate.title)
 * t(root.editor.toast.success.title)
 * t(root.editor.toast.success.message)
 * t(dialog.error.title)
 * t(dialog.error.message)
 */
export abstract class EditorFacadeBase<T extends object> {
  private readonly defaultEditorFacadeState: EditorFacadeState<T> = {
    isOpen: false,
    isReadOnly: false,
    isBusy: false,
    hasError: false,
    title: '',
    saveButtonTitle: '',
    isNew: false,
    isCopy: false,
    isDuplicate: false,
    mode: null,
    closeOnSaveSuccess: false,
  };
  protected isDirty = false;
  protected readonly stateSubject = new BehaviorSubject<EditorFacadeState<T>>(this.defaultEditorFacadeState);
  protected readonly onSaveSubject = new Subject<DataStoreResult<T>>();
  protected readonly onCloseSubject = new Subject<boolean>();
  protected readonly editorFacade = this;
  public readonly state$ = this.stateSubject.asObservable();
  public readonly onSave$ = this.onSaveSubject.asObservable();
  public readonly onClose$ = this.onCloseSubject.asObservable();
  public get state(): EditorFacadeState<T> {
    return this.stateSubject.getValue();
  }
  public readonly model$ = this.state$.pipe(
    map((m) => m.model),
    distinctUntilChanged()
  );
  public readonly modelWithChanges$ = this.state$.pipe(
    map((m) => m.modelWithChanges),
    distinctUntilChanged()
  );
  public readonly currentId$ = this.state$.pipe(
    map((m) => (m.model && this.getModelId(m.model)) || null),
    distinctUntilChanged()
  );
  public readonly isOpen$ = this.state$.pipe(
    map((m) => m.isOpen),
    distinctUntilChanged()
  );
  public readonly isReadOnly$ = this.state$.pipe(
    map((m) => m.isReadOnly),
    distinctUntilChanged()
  );
  public readonly isBusy$ = this.state$.pipe(
    map((m) => m.isBusy),
    distinctUntilChanged()
  );
  public readonly title$ = this.state$.pipe(
    map((m) => m.title),
    distinctUntilChanged()
  );
  public readonly saveButtonTitle$ = this.state$.pipe(
    map((m) => m.saveButtonTitle),
    distinctUntilChanged()
  );
  public readonly saveButtonIsVisible$ = this.state$.pipe(
    debounceTime(200),
    map((m) => m.mode && m.mode !== 'view'),
    distinctUntilChanged()
  );
  public readonly mode$ = this.state$.pipe(
    map((m) => m.mode),
    distinctUntilChanged()
  );
  public readonly closeOnSaveSuccess$ = this.state$.pipe(
    map((m) => m.closeOnSaveSuccess),
    distinctUntilChanged()
  );
  public readonly isNew$ = this.state$.pipe(
    map((m) => m.isNew),
    distinctUntilChanged()
  );
  public readonly isCopy$ = this.state$.pipe(
    map((m) => m.isCopy),
    distinctUntilChanged()
  );
  public readonly hasError$ = this.state$.pipe(
    map((m) => m.hasError),
    distinctUntilChanged()
  );
  public readonly isDirty$ = combineLatest([this.model$, this.modelWithChanges$, this.isReadOnly$]).pipe(
    debounceTime(200),
    map(([model, modelWithChanges, isReadOnly]) => {
      const isDirty = !isReadOnly && !this.equals(model, modelWithChanges);
      this.isDirty = isDirty;
      return isDirty;
    })
  );

  private initNew: Partial<T> = null;

  constructor(
    protected toastr: ToastrService,
    protected modalService: BsModalService,
    protected uiFacade: UIFacade,
    protected translocoService: TranslocoService
  ) {
    if (window && !window['editors']) {
      window['editors'] = {};
    }
    if (window && window['editors'] && !window['editors'][this.className()]) {
      window['editors'][this.className()] = this;
    }
  }

  toggleView(id: string) {
    const currentId = this.getModelId(this.stateSubject.getValue()?.model);
    if (id === currentId) {
      this.close();
    } else {
      this.view(id);
    }
  }
  toggleEdit(id: string) {
    const currentId = this.getModelId(this.stateSubject.getValue()?.model);
    if (id === currentId) {
      this.close();
    } else {
      this.edit(id);
    }
  }
  copy(init: Partial<T> | null = null, closeOnSaveSuccess: boolean = true) {
    this.canOpen().subscribe((canOpen) => {
      if (canOpen) {
        this.updateState({
          isBusy: true,
          isOpen: true,
          isCopy: true,
          mode: 'copy',
          title: this.translocoService.translate('root.editor.Copy.title') + ' ' + this.className(),
          closeOnSaveSuccess,
        });
        this.getNewModelForCopy(init || {}).subscribe((model) => {
          this.updateState({ model, modelWithChanges: { ...model }, isBusy: false });
        });
        this.scrollTo('editor-form-top');
      }
    });
  }
  duplicate(init: Partial<T> | null = null, closeOnSaveSuccess: boolean = true) {
    this.canOpen().subscribe((canOpen) => {
      if (canOpen) {
        this.updateState({
          isBusy: true,
          isOpen: true,
          isDuplicate: true,
          mode: 'duplicate',
          title: this.translocoService.translate('root.editor.Duplicate.title') + ' ' + this.className(),
          closeOnSaveSuccess,
        });
        this.getNewModelForDuplicate(init || {}).subscribe((model) => {
          this.updateState({ model, modelWithChanges: { ...model }, isBusy: false });
        });
        this.scrollTo('editor-form-top');
      }
    });
  }
  new(init: Partial<T> | null = null, closeOnSaveSuccess: boolean = false) {
    this.initNew = init;
    this.canOpen().subscribe((canOpen) => {
      if (canOpen) {
        this.updateState({
          isBusy: true,
          isOpen: true,
          isNew: true,
          mode: 'new',
          title: this.translocoService.translate('root.editor.New.title') + ' ' + this.className(),
          closeOnSaveSuccess,
        });
        this.getNewModel(init || {}).subscribe((model) => {
          this.updateState({ model, modelWithChanges: { ...model }, isBusy: false });
        });
        this.scrollTo('editor-form-top');
      }
    });
  }
  view(id: string) {
    this.canOpen().subscribe((canOpen) => {
      if (canOpen) {
        this.updateState({
          isBusy: true,
          isOpen: true,
          isReadOnly: true,
          mode: 'view',
          title: this.translocoService.translate('root.editor.View.title') + ' ' + this.className(),
        });
        this.getFromDataStore(id).subscribe((model) => {
          const modelWithChanges = { ...model };
          this.updateState({ model, modelWithChanges, isBusy: false });
        });
        this.scrollTo('editor-form-top');
      }
    });
  }
  edit(id: string) {
    this.canOpen()
      .pipe(
        mergeMap((canOpen) => {
          if (canOpen) {
            return this.userCanEdit().pipe(
              take(1),
              map((canEdit) => {
                return { canOpen, isReadOnly: !canEdit };
              })
            );
          } else {
            return of({ canOpen, isReadOnly: false });
          }
        }),
        take(1)
      )
      .subscribe(({ canOpen, isReadOnly }) => {
        if (canOpen) {
          this.updateState({
            isBusy: true,
            isOpen: true,
            isReadOnly,
            mode: isReadOnly ? 'view' : 'edit',
            title: isReadOnly
              ? this.translocoService.translate('root.editor.View.title') + ' ' + this.className()
              : this.translocoService.translate('root.editor.Edit.title') + ' ' + this.className(),
          });
          this.getFromDataStore(id).subscribe((model) => {
            if (model) {
              const modelWithChanges = { ...model };
              this.updateState({ model, modelWithChanges, isBusy: false });
            } else {
              this.updateState({ isBusy: false });
            }
          });
          this.scrollTo('editor-form-top');
        }
      });
  }
  delete(id: string) {
    // close row if in view / edit
    const currentId = this.getModelId(this.stateSubject.getValue()?.model);
    if (id === currentId) {
      // cancel any changes is dirty because record about to be deleted
      this.resetAndClose();
    }

    this.canDelete(id)
      .pipe(
        take(1),
        mergeMap((canDelete) => {
          if (!canDelete) {
            alert(`Unabled to delete ${this.className()} because it is in use.`);
            return of(false);
          }
          return this.userCanDelete().pipe(take(1));
        }),
        mergeMap((canDelete) => {
          if (!canDelete) {
            alert(`Unabled to delete ${this.className()} user does not have permission.`);
            return of(false);
          }
          return confirmDelete(this.modalService);
        }),
        mergeMap((canDelete) => {
          if (!canDelete) {
            return of(false);
          }
          return this.deleteDataStore(id).pipe(
            switchMap(() => {
              return of(true);
            }),
            catchError((error) => this.handleDataStoreError(error))
          );
        })
      )
      .subscribe((canDelete) => {
        if (canDelete) {
        } else {
        }
      });
  }
  save(closeOnSaveSuccess: boolean = false): Observable<DataStoreResult<T>> {
    this.updateState({ isBusy: true });
    combineLatest([this.mode$, this.modelWithChanges$])
      .pipe(
        delay(0),
        switchMap(([mode, modelWithChanges]) => {
          if (this.modeHasCustomSave(mode)) {
            return this.saveCustom(mode, modelWithChanges);
          } else {
            if (this.getModelId(modelWithChanges)) {
              return this.saveUpdate(modelWithChanges);
            } else {
              return this.saveCreate(modelWithChanges);
            }
          }
        }),
        map((result) => {
          if (result.success) {
            if (closeOnSaveSuccess || this.state.closeOnSaveSuccess) {
              this.resetAndClose();
            }
            this.onSaveSubject.next(result);
          } else {
            this.onSaveSubject.next(result);
          }
        }),
        take(1)
      )
      .subscribe();
    return this.onSave$.pipe(take(1));
  }
  protected modeHasCustomSave(mode: string) {
    return false;
  }
  protected saveCustom(mode: string, modelWithChanges: T): Observable<DataStoreResult<T>> {
    return of(null);
  }
  protected saveCreate(modelWithChanges: T): Observable<DataStoreResult<T>> {
    const tempModelWithChanges = { ...modelWithChanges };
    this.setModelId(tempModelWithChanges, Guid.raw());
    return this.createDataStore(tempModelWithChanges).pipe(
      map((createModel) => {
        this.successToast();
        // this.onSaveSubject.next(true);
        this.updateState({ isBusy: false });
        this.afterSaveNew(createModel);
        return { model: createModel, success: true };
      }),
      catchError((error) => {
        // this.onSaveSubject.next(false);
        this.updateState({ isBusy: false });
        return this.handleDataStoreError(error).pipe(map((storeError) => ({ error: storeError, success: false })));
      })
    );
  }
  protected saveUpdate(modelWithChanges: T): Observable<DataStoreResult<T>> {
    return this.updateDataStore(modelWithChanges).pipe(
      map((updatedModel) => {
        this.successToast();
        // this.onSaveSubject.next(true);
        this.updateState({ isBusy: false });
        this.afterSaveEdit(updatedModel);
        return { model: updatedModel, success: true };
      }),
      catchError((error) => {
        // this.onSaveSubject.next(error);
        this.updateState({ isBusy: false });
        return this.handleDataStoreError(error).pipe(map((storeError) => ({ error: storeError, success: false })));
      })
    );
  }
  protected scrollTo(id: string, config: ScrollIntoViewOptions = { behavior: 'smooth', block: 'start', inline: 'start' }): boolean {
    const elem = document.getElementById(id) as HTMLElement;
    if (elem !== null) {
      elem.scrollIntoView(config);
      return true;
    }
    return false;
  }
  protected successToast() {
    this.toastr.success(
      this.translocoService.translate('root.editor.toast.success.message'),
      this.translocoService.translate('root.editor.toast.success.title')
    );
  }
  protected afterSaveNew_NewAnother(modelWithChanges: T) {
    this.getNewModel(this.initNew || {}).subscribe((model) => {
      this.updateState({ model, modelWithChanges: { ...model } });
      this.scrollTo('editor-form-top');
    });
  }
  protected afterSave_Edit(modelWithChanges: T) {
    this.updateState({
      model: modelWithChanges,
      modelWithChanges: { ...modelWithChanges },
      isNew: false,
      isCopy: false,
      isDuplicate: false,
      mode: 'edit',
    });
    // this.scrollTo('editor-form-top');
  }
  protected afterSaveNew(modelWithChanges: T) {
    this.afterSaveNew_NewAnother(modelWithChanges);
    // OR this.afterSave_Edit(modelWithChanges);
  }
  protected afterSaveEdit(modelWithChanges: T) {
    this.afterSave_Edit(modelWithChanges);
  }
  close(): Observable<boolean> {
    this.canClose()
      .pipe(delay(0))
      .subscribe((canClose) => {
        if (canClose) {
          this.resetAndClose();
        } else {
          this.onCloseSubject.next(false);
        }
      });
    return this.onClose$.pipe(take(1));
  }
  // ??
  updateModelWithChanges(updates: Partial<T>) {
    const modelWithChanges = {
      ...this.stateSubject.getValue().modelWithChanges,
      ...updates,
    };
    this.updateState({ modelWithChanges });
  }
  // shortcut for implementing canDeactive in a simple case where only one editor used in container
  canDeactivate(): Observable<{ canDeactivate: boolean; handled?: boolean }> {
    return this.close().pipe(
      take(1),
      map((canDeactivate) => ({ canDeactivate, handled: true }))
    );
  }
  protected abstract className(): string;
  protected getFromDataStore(id: string): Observable<T> {
    throw new Error('Not implemented!');
  }
  protected updateDataStore(model: T): Observable<T> {
    throw new Error('Not implemented!');
  }
  protected createDataStore(model: T): Observable<T> {
    throw new Error('Not implemented!');
  }
  protected deleteDataStore(id: string): Observable<boolean> {
    throw new Error('Not implemented!');
  }
  protected validateFormCreate(model: T): Observable<{ [key: string]: any } | null> {
    return of(null);
  }
  protected validateFormUpdate(model: T): Observable<{ [key: string]: any } | null> {
    return of(null);
  }
  protected validateCreate(model: T): Observable<boolean> {
    return of(null);
  }
  protected validateUpdate(model: T): Observable<boolean> {
    return of(null);
  }
  public handleDataStoreError(error: any): Observable<any> {
    let title: string = error?.message || this.translocoService.translate('dialog.error.title');
    let message: string = error?.message || this.translocoService.translate('dialog.error.message');
    let detail: string = '';
    if (error instanceof ApiException) {
      try {
        const response = error.response && JSON.parse(error.response);
        title = this.translateError(error.message, response.data);
        message = this.translateError(response.title, response.data);
        detail = this.translateError(response.detail, response.data);
      } catch {}
    }
    this.uiFacade.hideLoading();
    showError(this.modalService, title, message, detail);
    return of(error);
  }
  private translateError(source: string, data: any) {
    if (source?.startsWith('root.validation.error.')) {
      console.log({
        source,
        data,
      });
      return this.translocoService.translate(source, data);
    }
    return source;
  }
  protected abstract getModelId(model: Partial<T>): string;
  protected setModelId(model: Partial<T>, id: string) {}
  protected getNewModelForCopy(init: Partial<T>): Observable<T> {
    return this.getNewModel(init);
  }
  protected getNewModelForDuplicate(init: Partial<T>): Observable<T> {
    return this.getNewModel(init);
  }
  protected getNewModel(init: Partial<T>): Observable<T> {
    // const base = this.keysOfProps.reduce((p, c) => {
    //   p[c] = null;
    //   return p;
    // }, { })
    return of({ ...init } as T);
  }
  public canCreateNew(): Observable<boolean> {
    return this.userCanCreateNew();
  }
  public canCopy(id?: string): Observable<boolean> {
    return this.userCanCopy().pipe(take(1));
  }
  public canDuplicate(id?: string): Observable<boolean> {
    return this.userCanDuplicate().pipe(take(1));
  }
  public canEdit(id?: string): Observable<boolean> {
    return this.userCanEdit().pipe(take(1));
  }
  public canDelete(id: string): Observable<boolean> {
    return this.userCanEdit().pipe(take(1));
  }
  protected userCanCreateNew(): Observable<boolean> {
    return this.userCanEdit();
  }
  protected userCanCopy(): Observable<boolean> {
    return this.userCanCreateNew();
  }
  protected userCanDuplicate(): Observable<boolean> {
    return this.userCanCreateNew();
  }
  protected userCanEdit(): Observable<boolean> {
    return of(true);
  }
  protected userCanDelete(): Observable<boolean> {
    return this.userCanEdit();
  }
  protected canOpen(): Observable<boolean> {
    const state = this.stateSubject.getValue();
    // const currentId = this.getModelId(this.stateSubject.getValue()?.modelWithChanges);
    if (state.isOpen) {
      return this.canClose();
    }
    return of(true);
  }
  protected canClose(): Observable<boolean> {
    return combineLatest([this.isDirty$]).pipe(
      take(1),
      mergeMap(([isDirty]) => {
        if (isDirty) {
          return confirmClose(this.modalService);
        }
        return of(true);
      })
    );
  }
  protected resetAndClose() {
    this.resetToDefault();
    this.onCloseSubject.next(true);
  }
  resetToDefault() {
    this.isDirty = false;
    this.initNew = null;
    this.stateSubject.next({ ...this.defaultEditorFacadeState });
  }

  protected updateState(updates: Partial<EditorFacadeState<T>>) {
    // const state = this.stateSubject.getValue();
    // for (const key of Object.keys(updates)) {
    //   state[key] = updates[key];
    // }
    const state = {
      ...this.stateSubject.getValue(),
      ...updates,
    };
    this.stateSubject.next(state);
  }
  protected equals(x: any, y: any): boolean {
    return deepEquals(x, y);
  }
  protected log(message: string, ...optionalParams: any[]) {}
}

export interface DataStoreResult<T> {
  success: boolean;
  model?: T;
  error?: any;
  validationErrors?: { [key: string]: any };
}
