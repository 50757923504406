import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  AbstractControl,
  Validators,
  FormArray,
  AsyncValidatorFn,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import {
  validateRequiredOutputGroupByIsSelectedFn,
  validateRequiredElementFn,
  validateRequiredOutputGroupByFn,
  validateRequiredQualifierFn,
} from '../../editor-generator-validation';
import { Generator, TagGroup, SpaceType, Tag, GeneratorInputType, Group, WorkPlaceType } from 'src/app/core/services/api-clients';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { DataStoreFacade } from 'src/app/spaceplan/shared/facades';
import { take, map } from 'rxjs/operators';
import { CustomValidators } from 'src/app/spaceplan/shared/custom-validators';

/**
 * t(spaceplan.generatorInputType.FTE)
 * t(spaceplan.generatorInputType.Person)
 * t(spaceplan.gneratorInputType.Workplace)
 * t(spaceplan.generatorInputType.FTEPresent)
 * t(spaceplan.generatorInputType.PersonPresent)
 * t(spaceplan.generatorInputType.PersonPresentGuestPresent)
 * t(spaceplan.generatorInputType.Number)
 * t(spaceplan.geasurementUnit.Metric)
 * t(spaceplan.geasurementUnit.Imperial)
 */

@Component({
  selector: 'app-editor-generator-form',
  styleUrls: ['./editor-generator-form.component.scss'],
  templateUrl: './editor-generator-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorGeneratorFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public parentForm: FormGroup;
  @Input()
  model: Generator;
  @Input()
  options: {
    groups: Group[];
    tagGroups: TagGroup[];
    tags: Tag[];
    spaceTypes: SpaceType[];
    workPlaceTypes: WorkPlaceType[];
    readOnly: false;
  };
  @Input()
  public isRequesting: boolean;
  @Output()
  public updateModelWithChanges = new EventEmitter<Partial<Generator>>();
  public form: FormGroup;
  private readonly subscription: Subscription = new Subscription();
  private readonly onChanges = new BehaviorSubject<SimpleChanges>(null);

  public generatorInputTypes: { text: string; value?: string; type: number }[];
  public workPlaceTypes: { text: string; value?: string | number; type: number }[];
  public groups: Group[];
  public tagGroups: TagGroup[];
  public tags: Tag[];
  public tagsForGroups: { [id: string]: Tag[] };
  public spaceTypes: SpaceType[];

  constructor(private fb: FormBuilder, private dataStoreFacade: DataStoreFacade) {}

  ngOnInit() {
    this.prepareLookups();
    this.initForm();
    if (this.parentForm) {
      this.parentForm.addControl('generator', this.form);
    }
    this.subscription.add(
      this.onChanges.subscribe((changes: SimpleChanges) => {
        if (changes?.model) {
          const model = changes.model.currentValue as Generator;
          const values = this.modelToForm(model);
          this.form.patchValue(values, { emitEvent: false });
          this.form.get(['qualifier']).updateValueAndValidity();
        }
      })
    );
    this.subscription.add(
      this.form.valueChanges.pipe().subscribe((value) => {
        const values = this.form.getRawValue();
        this.model = this.formToModel(values);
        this.updateModelWithChanges.emit(this.model);
      })
    );
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.onChanges.next(changes);
  }
  prepareLookups() {
    this.generatorInputTypes = [
      {
        text: GeneratorInputType[GeneratorInputType.FTE],
        type: GeneratorInputType.FTE,
      },
      {
        text: GeneratorInputType[GeneratorInputType.FTEPresent],
        type: GeneratorInputType.FTEPresent,
      },
      {
        text: GeneratorInputType[GeneratorInputType.Person],
        type: GeneratorInputType.Person,
      },
      {
        text: GeneratorInputType[GeneratorInputType.PersonPresent],
        type: GeneratorInputType.PersonPresent,
      },
      {
        text: GeneratorInputType[GeneratorInputType.PersonPresentGuestPresent],
        type: GeneratorInputType.PersonPresentGuestPresent,
      },
    ];
    this.workPlaceTypes = this.options.workPlaceTypes
      .sort((a, b) => a.workPlaceTypeName.localeCompare(b.workPlaceTypeName))
      .map((m) => ({ value: m.workPlaceTypeID, text: m.workPlaceTypeName, type: 2 }));
    this.groups = this.options.groups.sort((a, b) => a.groupName.localeCompare(b.groupName));
    this.tagGroups = this.options.tagGroups.sort((a, b) => a.tagGroupName.localeCompare(b.tagGroupName));
    this.tags = this.options.tags;
    this.tagsForGroups = this.tagGroups.reduce((value, item) => {
      value[item.tagGroupID] = this.options.tags
        .filter((f) => f.tagGroupID === item.tagGroupID)
        .sort((a, b) => a.displaySequence - b.displaySequence);
      // TODO SORT BY TAG SEQ
      return value;
    }, {} as { [id: string]: Tag[] });
    this.spaceTypes = this.options.spaceTypes.sort((a, b) => a.spaceTypeName.localeCompare(b.spaceTypeName));
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  initForm() {
    this.form = this.fb.group({
      generatorName: this.fb.control('', [Validators.required], this.validateNameDuplicateValidator()),
      generatorDescr: this.fb.control(''),
      // qualifier: this.fb.control(null, [
      //   validateRequiredQualifierFn.bind(this),
      //   CustomValidators.greaterThanNumber(0),
      //   CustomValidators.lessThanNumber(100000),
      // ]),
      qualifier: this.fb.control(
        null,
        CustomValidators.conditionalValidator(
          () => this.form.get('generatorInputTypeNo').value != 6,
          Validators.compose([Validators.required, CustomValidators.greaterThanNumber(0), CustomValidators.lessThanNumber(100000)])
        )
      ),
      generatorInputTypeNo: this.fb.control(null),
      element: this.fb.control(null, [validateRequiredElementFn.bind(this)]),
      outputGroupby: this.fb.control(null, [validateRequiredOutputGroupByFn.bind(this)]),
      groups: this.fb.group(
        this.groups.reduce((value, item) => {
          value[item.groupID] = this.fb.control(false);
          return value;
        }, {}),
        { validator: validateRequiredOutputGroupByIsSelectedFn }
      ),
      multiplier: this.fb.control(null, [
        // Validators.required,
        CustomValidators.greaterThanOrEqualToNumber(0),
        CustomValidators.lessThanNumber(100000),
      ]),
      spacetype: this.fb.control(null, Validators.required),
      tags: this.fb.group(
        this.tagGroups.reduce((value, item) => {
          value[item.tagGroupID] = this.fb.control('');
          return value;
        }, {})
      ),
    });
    if (this.options.readOnly) {
      this.form.disable({ onlySelf: true });
    }
  }
  formToModel(values: any): Partial<Generator> {
    const groups =
      values.outputGroupby === 2
        ? Object.keys(values.groups)
            .filter((f) => !!values.groups[f])
            .map((m) => m)
        : [];

    const tags = this.tagGroups
      .reduce((value, item) => {
        value.push(values.tags[item.tagGroupID]);
        return value;
      }, [])
      .filter((f) => !!f);

    let generatorInputTypeNo = null;

    if (values.generatorInputTypeNo) {
      generatorInputTypeNo = +values.generatorInputTypeNo;
    } else if (values.element) {
      generatorInputTypeNo = +values.element.type;
    }

    const model: Partial<Generator> = {
      ...this.model,
      generatorName: values.generatorName || null,
      generatorDescr: values.generatorDescr || null,
      generatorInputTypeNo,
      inputWorkPlaceTypeID: values.element?.type === 2 ? values.element.value : null,
      spaceTypeID: values.spacetype,
      outputByGroupInd: !!values.outputGroupby,
      qualifier: values.generatorInputTypeNo ? null : values.qualifier || values.qualifier === 0 ? +values.qualifier : null,
      multiplier: values.multiplier || values.multiplier === 0 ? +values.multiplier : null,
      groups: groups?.length ? groups : null,
      tags: tags?.length ? tags?.sort((a, b) => a.localeCompare(b)) : null,
    };
    return model;
  }
  modelToForm(generator: Generator) {
    return (
      (generator && {
        generatorName: generator.generatorName,
        generatorDescr: generator.generatorDescr,
        qualifier: generator.qualifier,
        generatorInputTypeNo: generator.generatorInputTypeNo === GeneratorInputType.Number ? generator.generatorInputTypeNo : null,
        element:
          (generator.generatorInputTypeNo === GeneratorInputType.WorkPlace
            ? this.workPlaceTypes.find((f) => f.value === generator.inputWorkPlaceTypeID)
            : this.generatorInputTypes.find((f) => f.type === generator.generatorInputTypeNo)) || null,
        outputGroupby: generator.outputByGroupInd ? (generator.groups?.length ? 2 : 1) : 0,
        groups: this.groups.reduce((value, item) => {
          value[item.groupID] = generator.groups?.findIndex((f) => f === item.groupID) > -1;
          return value;
        }, {}),
        multiplier: generator.multiplier,
        spacetype: generator.spaceTypeID,
        tags: this.tagGroups.reduce((value, item) => {
          const tagId = generator.tags?.reduce((v, i) => {
            return v || this.tagsForGroups[item.tagGroupID].find((f) => f.tagID === i)?.tagID;
          }, null);
          value[item.tagGroupID] = tagId || '';
          return value;
        }, {}),
      }) ||
      {}
    );
  }
  onOutputGroupBy() {
    // revalidate groups on outputGroupByChange
    this.form.get('groups').updateValueAndValidity();
  }
  compareElementFn(optionOne, optionTwo): boolean {
    return optionOne?.type === optionTwo?.type && optionOne?.value === optionTwo?.value;
  }

  validateNameDuplicateValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.dataStoreFacade.generators$.pipe(
        map((generators) => {
          const duplicate = !!generators.find((f) => f.generatorID !== this.model.generatorID && f.generatorName === control.value);
          if (duplicate) {
            return {
              duplicate: true,
            };
          }
          return null;
        }),
        take(1)
      );
    };
  }
}
