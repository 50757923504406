import { createStore, withProps, select } from '@ngneat/elf';
import { Account, RoleGroup } from 'src/app/core/services/api-clients';
// import { PartnerDto, UserAccessDto } from 'src/app/core/api/app.api';

export interface AccountProps {
  authenticating: boolean;
  authenticated: boolean;
  account?: Account;
  // partners?: PartnerDto[];
  // partnerID?: string;
  roleGroup?: RoleGroup;
  // userAccess?: UserAccessDto;
  // userAccess?: { [key: string]: UserAccessDto };
  permissions: string[];
}


export const startAuthenticationState: AccountProps  = {
  authenticating: true,
  authenticated: false,
  account: undefined,
  // partners: undefined,
  // partnerID: undefined,
  roleGroup: undefined,
  // userAccess: undefined
  permissions: []
};

export const unAuthenticatedState: AccountProps = {
  authenticating: false,
  authenticated: false,
  account: undefined,
  // partners: undefined,
  // partnerID: undefined,
  roleGroup: undefined,
  // userAccess: undefined
  permissions: undefined
}

export const accountStore = createStore({ name: 'account' }, withProps<AccountProps>(startAuthenticationState))
