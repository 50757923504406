import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, AbstractControl, Validators } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { EditorSpaceProgramSettingsFacade, DataStoreFacade, UIFacade } from '../../../../../facades';
import { FolderUsageType, MeasurementUnit, SpaceProgram } from 'src/app/core/services/api-clients';

@Component({
  selector: 'app-editor-space-program-settings',
  templateUrl: './editor-space-program-settings.component.html',
  styleUrls: ['./editor-space-program-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorSpaceProgramSettingsComponent {
  @Input()
  public headerVisible = true;
  @Input()
  public canClose = true;
  public form: FormGroup;
  public isRequesting = false;
  public options$ = combineLatest([
    this.dataStoreFacade.spaceProgram$,
    this.dataStoreFacade.folders$,
    this.dataStoreFacade.groups$,
    this.dataStoreFacade.behaviourWorkPlaceTypes$,
    this.dataStoreFacade.workPlaceTypes$,
    this.editorFacade.isReadOnly$,
  ]).pipe(
    map(([spaceProgram, folders, groups, behaviourWorkPlaceTypes, workPlaceTypes, isReadOnly]) => {
      const measurementUnit = (spaceProgram as SpaceProgram).measurementUnit;
      return {
        measurementUnit,
        folders: folders.filter((f) => f.partnerID === spaceProgram.partnerID),
        groups,
        behaviourWorkPlaceTypes,
        workPlaceTypes,
        readOnly: isReadOnly,
      };
    })
  );

  constructor(
    public editorFacade: EditorSpaceProgramSettingsFacade,
    private fb: FormBuilder,
    private dataStoreFacade: DataStoreFacade,
    public uiFacade: UIFacade
  ) {
    this.form = this.fb.group({});
  }

  onSubmit() {
    this.isRequesting = true;
    if (!this.form.invalid) {
      this.uiFacade.showLoading();
      this.editorFacade.save().subscribe(
        (result) => {
          if (result.success) {
            // SAVE SUCESS
            this.form.markAsUntouched();
            this.form.markAsPristine();
            this.isRequesting = false;
            this.uiFacade.hideLoading();
          }
        },
        (err) => {
          this.uiFacade.hideLoading();
        }
      );
    }
  }
  onClose() {
    this.editorFacade.close();
  }
}
