import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators, AsyncValidatorFn, ValidationErrors, AbstractControl, FormControl } from '@angular/forms';
import { ThemeService } from '@progress/kendo-angular-charts';
import { GroupIndicatorComponent } from '@progress/kendo-angular-grid';
import { IntlService } from '@progress/kendo-angular-intl';
import { Guid } from 'guid-typescript';
import { merge } from 'lodash';
import { Subscription, BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { BuildingsDataStoreFacade } from '../../../../../../buildings/facades';
import {
  Building,
  BuildingFloor,
  BuildingFloorZone,
  BuildingZone,
  Folder,
  MeasurementUnit,
  Partner,
  UpdateBuildingDto,
} from '../../../../../../core/services/api-clients.generated';

@Component({
  selector: 'app-editor-building-form',
  styleUrls: ['./editor-building-form.component.scss'],
  templateUrl: './editor-building-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorBuildingFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public parentForm: FormGroup;
  @Input()
  model: UpdateBuildingDto;
  @Input()
  options: {
    partners: Partner[];
    folders: Folder[];
    readOnly: false;
  };
  @Input()
  public isRequesting: boolean;
  @Output()
  public updateModelWithChanges = new EventEmitter<Partial<UpdateBuildingDto>>();
  public form: FormGroup;
  private readonly subscription: Subscription = new Subscription();
  private readonly onChanges = new BehaviorSubject<SimpleChanges>(null);
  private modelChanging = false;

  public partners: Partner[];
  public folders: Folder[];
  public buildingMeasurementUnits: { text: string; value?: string; type: number }[];
  public noOfZonesList: { text: string; value?: number }[];

  public floors = [];
  public zones = [];

  public floorMax = 50;
  public floorMin = -10;

  public totalArea = 0;

  constructor(
    private fb: FormBuilder,
    private dataStoreFacade: BuildingsDataStoreFacade,
    private cdRef: ChangeDetectorRef,
    public intl: IntlService
  ) {}

  ngOnInit() {
    this.prepareLookups();
    this.initForm();
    if (this.parentForm) {
      this.parentForm.addControl('building', this.form);
    }
    this.subscription.add(
      this.onChanges.subscribe((changes: SimpleChanges) => {
        if (changes?.model) {
          this.modelChanging = true;
          this.cdRef.detach();
          const model = changes.model.currentValue as UpdateBuildingDto;
          this.prepareLookupsMeasurementUnit();
          this.prepareFolders(model.partnerID);

          const zones = this.fb.group(
            (this.model.zones ?? []).reduce((v, i) => {
              v[i.buildingZoneID] = this.getBuildingZoneFormGroup(i);
              return v;
            }, {})
          );

          const floors = this.fb.group(
            (this.model.floors ?? []).reduce((v, i) => {
              const availableArea =
                model.floorZones.find((f) => f.buildingFloorID === i.buildingFloorID && f.buildingZoneID === null)?.availableArea ?? '';
              v[i.buildingFloorID] = this.getBuildingFloorFormGroup({ ...i, availableArea });
              return v;
            }, {})
          );

          this.form.removeControl('floors');
          this.form.removeControl('zones');
          this.form.addControl('floors', floors);
          this.form.addControl('zones', zones);
          this.rebuildFloorZones();
          const values = this.modelToForm(model);
          this.form.patchValue(values, { emitEvent: false });
          this.updateEnabledState();
          this.zones = model.zones?.sort((a, b) => a.displaySequence - b.displaySequence);
          this.floors = model.floors?.sort((a, b) => b.displaySequence - a.displaySequence);
          this.updateTotalArea();
          this.cdRef.reattach();
          this.cdRef.detectChanges();
          this.modelChanging = false;
        }
      })
    );

    this.subscription.add(
      this.form.controls.maximumFloorNo.valueChanges.subscribe(() => {
        if (!this.modelChanging) {
          const values = this.form.getRawValue();

          if (values.maximumFloorNo) {
            let maximumFloorNo = +values.maximumFloorNo;
            if (maximumFloorNo > this.floorMax) {
              maximumFloorNo = this.floorMax;
              this.form.get('maximumFloorNo').patchValue(maximumFloorNo, { emitEvent: false });
            } else if (maximumFloorNo < this.floorMin) {
              maximumFloorNo = this.floorMin;
              this.form.get('maximumFloorNo').patchValue(maximumFloorNo, { emitEvent: false });
            }
            if (values.lowestUndergroundFloorNo) {
              if (maximumFloorNo < +values.lowestUndergroundFloorNo) {
                this.form.get('lowestUndergroundFloorNo').patchValue(maximumFloorNo, { emitEvent: false });
              }
            }
            this.rebuildFloors(maximumFloorNo, this.form.get('lowestUndergroundFloorNo').value);
          } else {
            this.rebuildFloors(values.maximumFloorNo, this.form.get('lowestUndergroundFloorNo').value);
          }
          this.rebuildFloorZones();
          this.updateTotalArea();
        }
      })
    );

    this.subscription.add(
      this.form.controls.lowestUndergroundFloorNo.valueChanges.subscribe(() => {
        if (!this.modelChanging) {
          const values = this.form.getRawValue();
          if (values.lowestUndergroundFloorNo) {
            let lowestUndergroundFloorNo = +values.lowestUndergroundFloorNo;
            if (lowestUndergroundFloorNo < this.floorMin) {
              lowestUndergroundFloorNo = this.floorMin;
              this.form.get('lowestUndergroundFloorNo').patchValue(lowestUndergroundFloorNo, { emitEvent: false });
            } else if (lowestUndergroundFloorNo > this.floorMax) {
              lowestUndergroundFloorNo = this.floorMax;
              this.form.get('lowestUndergroundFloorNo').patchValue(lowestUndergroundFloorNo, { emitEvent: false });
            }

            if (values.maximumFloorNo) {
              if (lowestUndergroundFloorNo > +values.maximumFloorNo) {
                this.form.get('maximumFloorNo').patchValue(lowestUndergroundFloorNo, { emitEvent: false });
              }
            }
            this.rebuildFloors(this.form.get('maximumFloorNo').value, lowestUndergroundFloorNo);
          } else {
            this.rebuildFloors(this.form.get('maximumFloorNo').value, values.lowestUndergroundFloorNo);
          }

          this.rebuildFloorZones();
        }
      })
    );

    this.subscription.add(
      this.form.controls.noOfZones.valueChanges.subscribe((noOfZones) => {
        if (!this.modelChanging) {
          this.rebuildZones(noOfZones);
          this.rebuildFloorZones();
        }
      })
    );

    this.subscription.add(
      this.form.valueChanges.pipe().subscribe((value) => {
        if (!this.modelChanging) {
          Object.values(this.form.get('floors')?.value ?? []).forEach((f: any, index: number) => {
            if (!f.floorName) {
              const floorName = this.model.floors.find((f1) => f1.buildingFloorID === f.buildingFloorID)?.floorName;
              this.form.get(['floors', f.buildingFloorID, 'floorName']).patchValue(floorName, { emitEvent: false });
            }
          });
          const values = this.form.getRawValue();
          this.model = this.formToModel(values);
          this.updateModelWithChanges.emit(this.model);
        }
      })
    );
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.onChanges.next(changes);
  }
  prepareLookups() {
    this.partners = this.options.partners.sort((a, b) => a.partnerDescr.localeCompare(b.partnerDescr));

    this.noOfZonesList = new Array(10).fill(0).map((_, i) => {
      let num = i + 1;
      return {
        text: num.toString(),
        value: num,
      };
    });

    // this.noOfZonesList = [
    //   {
    //     text: '2',
    //     value: 2,
    //   },
    //   {
    //     text: '3',
    //     value: 3,
    //   },
    //   {
    //     text: '4',
    //     value: 4,
    //   },
    // ];
  }
  prepareLookupsMeasurementUnit() {
    this.buildingMeasurementUnits = [
      {
        text: MeasurementUnit[MeasurementUnit.Metric],
        type: MeasurementUnit.Metric,
      },
      {
        text: MeasurementUnit[MeasurementUnit.Imperial],
        type: MeasurementUnit.Imperial,
      },
    ];
  }

  prepareFolders(partnerID: string | null) {
    this.folders = [];
    if (!!partnerID) {
      this.folders = this.options.folders.filter((f) => f.partnerID === partnerID).sort((a, b) => a.folderName.localeCompare(b.folderName));
    }
  }

  rebuildFloors(maximumFloorNo: number, lowestUndergroundFloorNo: number) {
    const floors = [];

    if (this.isNumeric(maximumFloorNo) && this.isNumeric(lowestUndergroundFloorNo)) {
      for (let index = +(maximumFloorNo || 0); index > +(lowestUndergroundFloorNo || 0) - 1; index--) {
        const id = Guid.raw();
        floors.push({
          buildingFloorID: id,
          floorName: index.toString(),
          displaySequence: 0,
          availableArea: '',
          zones: null,
        });
      }
    }

    floors.forEach((f, i) => {
      f.displaySequence = floors.length - i;
    });

    const formFloors = floors.reduce((v, i) => {
      v[i.buildingFloorID] = this.getBuildingFloorFormGroup(i);
      return v;
    }, {});

    this.form.removeControl('floors');
    this.form.addControl('floors', this.fb.group(formFloors));
    this.floors = floors;
  }

  isNumeric(data) {
    return !isNaN(parseFloat(data)) && isFinite(data) && data.constructor !== Array;
  }

  rebuildZones(noOfZones: number) {
    const zones = [];
    if (noOfZones) {
      let displaySequence = 0;

      const zoneDefaultNames = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];

      for (let index = 0; index < noOfZones; index++) {
        const id = Guid.raw();
        zones.push({
          buildingZoneID: id,
          zoneName: zoneDefaultNames[index], // (index + 1).toString(),
          displaySequence: displaySequence++,
        });
      }
    }

    const formZones = zones.reduce((v, i) => {
      v[i.buildingZoneID] = this.getBuildingZoneFormGroup(i);
      return v;
    }, {});

    this.zones = zones;

    this.form.removeControl('zones');
    this.form.addControl('zones', this.fb.group(formZones));
  }

  rebuildFloorZones() {
    const tmpzones = Object.keys(this.form.controls.zones.value).map((buildingZoneID) => buildingZoneID);
    const floors = this.form.controls.floors.value;
    Object.values(floors).forEach(({ buildingFloorID, availableArea }) => {
      const floorzones = {};
      tmpzones.forEach((buildingZoneID) => {
        floorzones[buildingZoneID] = this.getBuildingFloorZoneFormGroup({
          buildingFloorZoneID: Guid.raw(),
          buildingFloorID,
          buildingZoneID,
          availableArea: '',
        });
      });

      (this.form.controls.floors.get(buildingFloorID) as FormGroup).removeControl('zones');
      (this.form.controls.floors.get(buildingFloorID) as FormGroup).addControl('zones', this.fb.group(floorzones));
      if (availableArea) {
        (this.form.controls.floors.get(buildingFloorID) as FormGroup).get('zones').disable();
      } else {
      }
    });
  }

  getBuildingZoneFormGroup(z: any): FormGroup {
    return this.fb.group({
      buildingZoneID: this.fb.control(z.buildingZoneID),
      zoneName: this.fb.control(z.zoneName, Validators.required),
      displaySequence: this.fb.control(z.displaySequence),
    });
  }

  getBuildingFloorFormGroup(f: any): FormGroup {
    return this.fb.group(
      {
        buildingFloorID: this.fb.control(f.buildingFloorID),
        floorName: this.fb.control(f.floorName, {
          validators: [Validators.required],
          updateOn: 'blur',
        }),
        displaySequence: this.fb.control(f.displaySequence),
        availableArea: this.fb.control(f.availableArea),
        zones: this.fb.group(f.zones ?? {}),
      },
      this.validateAvailableArea()
    );
  }

  getBuildingFloorZoneFormGroup(fz: any): FormGroup {
    return this.fb.group({
      buildingFloorZoneID: this.fb.control(fz.buildingFloorZoneID),
      buildingFloorID: this.fb.control(fz.buildingFloorID),
      buildingZoneID: this.fb.control(fz.buildingZoneID),
      availableArea: this.fb.control(fz.availableArea),
    });
  }

  initForm() {
    this.form = this.fb.group(
      {
        buildingName: this.fb.control('', [Validators.required] /*, this.validateNameDuplicateValidator()*/),
        partnerID: this.fb.control('', [Validators.required]),
        folderID: this.fb.control(''),
        buildingDescr: this.fb.control(''),
        measurementUnit: this.fb.control(MeasurementUnit.Metric),
        maximumFloorNo: this.fb.control('', {
          validators: [Validators.required, Validators.min(-10), Validators.max(50)],
          updateOn: 'blur',
        }),
        lowestUndergroundFloorNo: this.fb.control('', {
          validators: [Validators.required, Validators.min(-10), Validators.max(50)],
          updateOn: 'blur',
        }),
        noOfZones: this.fb.control(null),
        floors: this.fb.group({}),
        zones: this.fb.group({}),
        // floorZones: this.fb.group({}),
      },
      {
        asyncValidators: this.validateFloorZonesValidator(),
      }
    );
    if (this.options.readOnly) {
      this.form.disable({ onlySelf: true });
    }
  }
  formToModel(values: any): Partial<UpdateBuildingDto> {
    const floors = Object.values(values.floors ?? {}).map((m: any) => {
      return {
        buildingFloorID: m.buildingFloorID,
        floorName: m.floorName,
        displaySequence: m.displaySequence,
      };
    });
    const zones = Object.values(values.zones ?? {});
    const floorZones = []
      .concat(
        ...Object.values(values.floors ?? {}).map((floor: any) => {
          if (floor.availableArea) {
            return [
              {
                buildingFloorZoneID: Guid.raw(),
                buildingFloorID: floor.buildingFloorID,
                buildingZoneID: null,
                availableArea: floor.availableArea ? +floor.availableArea : '',
              },
            ];
          } else {
            return Object.values(floor.zones ?? {}).map((zone: any) => {
              const availableArea = zone.availableArea ? +zone.availableArea : '';
              return {
                ...zone,
                availableArea,
              };
            });
          }
        })
      )
      .filter((f) => !!f.availableArea);

    if (!!values.partnerID) {
      this.prepareFolders(values.partnerID);
      this.form.get('folderID')?.enable({ emitEvent: false });
    } else {
      this.prepareFolders(null);
      this.form.get('folderID')?.setValue(null, { emitEvent: false });
      this.form.get('folderID')?.disable({ emitEvent: false });
    }

    const model: Partial<UpdateBuildingDto> = {
      ...this.model,
      buildingName: values.buildingName,
      partnerID: values.partnerID,
      folderID: values.folderID,
      buildingDescr: values.buildingDescr,
      measurementUnit: +values.measurementUnit,
      maximumFloorNo: values.maximumFloorNo ? +values.maximumFloorNo : null,
      lowestUndergroundFloorNo: values.lowestUndergroundFloorNo ? +values.lowestUndergroundFloorNo : null,
      floors,
      zones,
      floorZones,
    };

    return model;
  }
  modelToForm(model: UpdateBuildingDto): { [key: string]: any } {
    const zones = (model.zones ?? []).reduce((v, i) => {
      v[i.buildingZoneID] = { ...i };
      return v;
    }, {});

    const floors = (model.floors ?? []).reduce((v, i) => {
      const availableArea = model.floorZones.find(
        (f) => f.buildingFloorID === i.buildingFloorID && f.buildingZoneID === null
      )?.availableArea;

      const tzones = model.zones.reduce((v1, i1) => {
        const fz = model.floorZones.find((f) => f.buildingFloorID === i.buildingFloorID && f.buildingZoneID === i1.buildingZoneID);
        if (fz) {
          v1[i1.buildingZoneID] = { ...fz };
        } else {
          v1[i1.buildingZoneID] = {
            buildingFloorZoneID: Guid.raw(),
            buildingFloorID: i.buildingFloorID,
            buildingZoneID: i1.buildingZoneID,
            availableArea: '',
          };
        }
        return v1;
      }, {});

      v[i.buildingFloorID] = {
        ...i,
        availableArea,
        zones: tzones,
      };
      return v;
    }, {});
    return (
      (model && {
        buildingName: model.buildingName,
        partnerID: model.partnerID,
        folderID: model.folderID,
        buildingDescr: model.buildingDescr,
        measurementUnit: +model.measurementUnit,
        maximumFloorNo: model.maximumFloorNo || model.maximumFloorNo === 0 ? model.maximumFloorNo.toString() : '',
        lowestUndergroundFloorNo:
          model.lowestUndergroundFloorNo || model.lowestUndergroundFloorNo === 0 ? model.lowestUndergroundFloorNo.toString() : '',
        noOfZones: !!model?.zones?.length ? model.zones.length : null,
        zones,
        floors,
      }) ||
      {}
    );
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  validateFloorZonesValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      let hasValue = false;
      Object.values(control.get('floors')?.value ?? {}).forEach((f: any) => {
        hasValue = hasValue || !!f.availableArea;
        if (!hasValue) {
          const zones = Object.values(control.get(['floors', '' + f.buildingFloorID + '', 'zones'])?.value || {});
          zones.forEach((z: any) => {
            hasValue = hasValue || !!z.availableArea;
          });
        }
      });

      if (!hasValue) {
        return of({
          noarea: true,
        });
      }
      return of(null);
    };
  }

  onChangeFloorArea(buildingFloorID: string) {
    const floorControl = (this.form.controls.floors as FormGroup).get(buildingFloorID);
    const zoneControls = floorControl.get('zones') as FormGroup;
    const hasValue = floorControl.value?.availableArea;
    if (hasValue) {
      zoneControls.disable();
    } else {
      zoneControls.enable();
    }

    this.updateTotalArea();
  }

  onChangeFloorZoneArea(buildingFloorID: string, buildingFloorZoneID: string) {
    const floorControl = (this.form.controls.floors as FormGroup).get(buildingFloorID) as FormGroup;
    const availableAreaContol = floorControl.get('availableArea');
    const zoneControls = (floorControl.get('zones') as FormGroup).controls;
    const zoneControl = floorControl.get('zones').get(buildingFloorZoneID) as FormControl;
    let hasValue = false;
    Object.values(zoneControls).forEach((control) => {
      hasValue = hasValue || control.value?.availableArea;
    });
    if (hasValue) {
      availableAreaContol.disable();
    } else {
      availableAreaContol.enable();
    }

    this.updateTotalArea();
  }

  updateTotalArea() {
    this.totalArea =
      this.model?.floorZones?.reduce((v, i) => {
        v += i.availableArea;
        return v;
      }, 0) ?? 0;
  }

  updateEnabledState() {
    const isEdit = !!this.model.buildingID;
    const hasAllocations = this.model.buildingID && (this.model as any).noOfAllocations > 0;
    if (isEdit) {
      this.form.get('partnerID').disable({ emitEvent: false });
    } else {
      if (this.options.partners && this.options.partners.length > 1) {
        this.form.get('partnerID').enable({ emitEvent: false });
      } else {
        this.form.get('partnerID').disable({ emitEvent: false });
      }
    }

    if (!this.model.partnerID) {
      this.form.get('folderID').disable({ emitEvent: false });
    } else {
      this.form.get('folderID').enable({ emitEvent: false });
    }

    if (hasAllocations) {
      this.form.get('measurementUnit').disable({ emitEvent: false });
      this.form.get('maximumFloorNo').disable({ emitEvent: false });
      this.form.get('lowestUndergroundFloorNo').disable({ emitEvent: false });
      this.form.get('noOfZones').disable({ emitEvent: false });
    } else {
      this.form.get('measurementUnit').enable({ emitEvent: false });
      this.form.get('maximumFloorNo').enable({ emitEvent: false });
      this.form.get('lowestUndergroundFloorNo').enable({ emitEvent: false });
      this.form.get('noOfZones').enable({ emitEvent: false });
    }

    Object.values((this.form.controls.floors as FormGroup).controls).forEach((floorControl) => {
      const zoneControls = floorControl.get('zones') as FormGroup;
      const availableAreaContol = floorControl.get('availableArea');
      const hasValue = floorControl.value?.availableArea;
      if (hasValue) {
        zoneControls.disable({ emitEvent: false });
      } else {
        zoneControls.enable({ emitEvent: false });
        let zonesHasValue = false;
        Object.values(zoneControls.controls).forEach((control) => {
          zonesHasValue = zonesHasValue || control.value?.availableArea;
        });
        if (zonesHasValue) {
          availableAreaContol.disable({ emitEvent: false });
        } else {
          availableAreaContol.enable({ emitEvent: false });
        }
      }
    });
  }

  isEditWithAllocations() {
    return this.model.buildingID && (this.model as any).noOfAllocations > 0;
  }

  validateAvailableArea(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return null;
    };
  }
}
