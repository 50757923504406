import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BeaconService {

  public beaconID = 'fa9ad7df-dc93-4561-a82a-044d7a275369';
  private initialized = false;

  constructor() {
  }

  init() {
    (window as any).Beacon('init', this.beaconID);
    this.initialized = true;
  }

  open() {
    if (!this.initialized) {
      this.init();
    }
    (window as any).Beacon('open');
  }

  destroy() {
    (window as any).Beacon('destroy');
    this.initialized = false;
  }

  isBeaconActive() {
    return this.initialized;
  }
}
