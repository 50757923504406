import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { EditorSpaceProgramCreateFacade, DataStoreFacade, UIFacade } from '../../../../../facades';
import { LookupTemplateFacade } from 'src/app/spaceplan/shared/facades/lookup-template.facade';
import { LookupProjectFacade } from 'src/app/spaceplan/shared/facades/lookup-project.facade';
import { LookupScenarioFacade } from 'src/app/spaceplan/shared/facades/lookup-scenario.facade';
import { LookupSpaceTypesFacade } from 'src/app/spaceplan/shared/facades/lookup-spacetypes.facade';
import { FolderUsageType } from 'src/app/core/services/api-clients.generated';

@Component({
  selector: 'app-editor-space-program-create',
  templateUrl: './editor-space-program-create.component.html',
  styleUrls: ['./editor-space-program-create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorSpaceProgramCreateComponent {
  @Input()
  public headerVisible = true;
  @Input()
  public canClose = true;
  @Output()
  public afterSubmit = new EventEmitter<any>();
  public form: FormGroup;
  public isRequesting = false;
  public options$ = combineLatest([
    this.dataStoreFacade.partners$,
    this.dataStoreFacade.templates$,
    this.dataStoreFacade.folders$,
    this.editorFacade.isReadOnly$,
  ]).pipe(
    map(([partners, templates, folders, isReadOnly]) => {
      const availableTemplates = templates.filter((f) => !f.archivedInd);
      return {
        partners,
        templates: availableTemplates,
        folders: folders.filter((f) => f.usageType !== FolderUsageType.Template),
        readOnly: isReadOnly,
      };
    })
  );

  lookupIsOpen$ = combineLatest([
    this.lookupTemplateFacade.isOpen$,
    this.lookupProjectFacade.isOpen$,
    this.lookupScenarioFacade.isOpen$,
    this.lookupSpaceTypeFacade.isOpen$,
  ]).pipe(map(([template, project, scenario, spaceTypes]) => template || project || scenario || spaceTypes));

  constructor(
    public editorFacade: EditorSpaceProgramCreateFacade,
    public lookupTemplateFacade: LookupTemplateFacade,
    public lookupProjectFacade: LookupProjectFacade,
    public lookupScenarioFacade: LookupScenarioFacade,
    public lookupSpaceTypeFacade: LookupSpaceTypesFacade,
    private fb: FormBuilder,
    private dataStoreFacade: DataStoreFacade,
    public uiFacade: UIFacade
  ) {
    this.form = this.fb.group({});
  }

  onSubmit() {
    this.isRequesting = true;
    if (!this.form.invalid) {
      this.uiFacade.showLoading();
      this.editorFacade.save(true).subscribe(
        (result) => {
          if (result.success) {
            // SAVE SUCESS
            this.form.markAsUntouched();
            this.form.markAsPristine();
            this.isRequesting = false;
            this.uiFacade.hideLoading();
          }
          this.afterSubmit.emit(result.model);
        },
        (err) => {
          this.uiFacade.hideLoading();
        }
      );
    }
  }
  onClose() {
    this.editorFacade.close();
  }

  onSelectTemplate() {
    this.lookupTemplateFacade.select().subscribe((template) => {
      this.form.get('spaceProgram.templateID').setValue(template?.templateID);
      this.form.get('spaceProgram.measurementUnit').setValue(template?.measurementUnit);
    });
  }
  onSelectProject() {
    this.lookupProjectFacade.select().subscribe((project) => {
      this.form.get('spaceProgram.projectID').setValue(project?.projectID);
    });
  }
  onSelectScenario() {
    this.lookupScenarioFacade.select().subscribe((scenario) => {
      this.form.get('spaceProgram.scenarioID').setValue(scenario?.scenarioID);
    });
  }
  onSelectSpaceTypes(closePanel: boolean = true) {
    this.lookupSpaceTypeFacade.select(closePanel).subscribe((spaceTypes) => {
      this.form.get('spaceProgram.templateSpaceTypeIDs').setValue(spaceTypes?.map((s) => s.templateSpaceTypeID));
    });
  }
}
