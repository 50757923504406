import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DataStoreFacade } from 'src/app/spaceplan/shared/facades/data-store.facade';
import { CreateSpaceProgramDto, UserRole } from 'src/app/core/services/api-clients';
import { EditorFacadeBase } from './editor-facade-base';
import { UserFacade } from './user.facade';
import { UIFacade } from './ui.facade';
import { TranslocoService } from '@ngneat/transloco';
import { bufferCount, filter, map } from 'rxjs/operators';
import { formatDate } from '@angular/common';

/**
 * t(spaceplan.SpaceProgram)
 * t(spaceplancreate.preface.Created_on)
 * t(spaceplancreate.preface.Population)
 * t(spaceplancreate.preface.Scenario)
 * t(spaceplancreate.preface.Template)
 * t(spaceplancreate.preface.No_of_generators)
 * t(spaceplancreate.preface.No_of_space_types)
 * t(spaceplancreate.preface.Total_area_of_space_types)
 */
@Injectable({
  providedIn: 'root',
})
export class EditorSpaceProgramCreateFacade extends EditorFacadeBase<CreateSpaceProgramDto> {
  constructor(
    protected toastr: ToastrService,
    protected modalService: BsModalService,
    protected uiFacade: UIFacade,
    protected translocoService: TranslocoService,
    private dataStoreFacade: DataStoreFacade,
    private userFacade: UserFacade,
    @Inject(LOCALE_ID) private locale: string
  ) {
    super(toastr, modalService, uiFacade, translocoService);
    this.dataStoreFacade.spaceProgram$
      .pipe(
        bufferCount(2, 1),
        filter(([a, b]) => b?.spaceProgramID !== a?.spaceProgramID),
        map(([a, b]) => b)
      )
      .subscribe(() => {
        this.resetToDefault();
      });
  }
  protected className(): string {
    return this.translocoService.translate('spaceplan.SpaceProgram');
  }
  protected createDataStore(model: CreateSpaceProgramDto): Observable<CreateSpaceProgramDto> {
    return this.dataStoreFacade.createSpaceProgram(model);
  }
  protected getModelId(model: CreateSpaceProgramDto): string {
    model.preface = this.getPreface(model);
    return model?.spaceProgramID;
  }
  protected setModelId(model: CreateSpaceProgramDto, id: string) {
    model.spaceProgramID = id;
  }
  // protected userCanEdit(): Observable<boolean> {
  //   return this.dataStoreFacade.spaceProgram$.pipe(
  //     map(m => (m.roleNo === UserRole.Composer || m.roleNo === UserRole.Editor))
  //   );
  // }
  protected getNewModel(init: Partial<CreateSpaceProgramDto>): Observable<CreateSpaceProgramDto> {
    return of({
      ...init,
      spaceProgramID: null,
      templateID: null,
      folderID: null,
      measurementUnit: null,
      projectID: null,
      scenarioID: null,
      percFTEForGuest: 0,
      spaceProgramDescr: null,
      spaceProgramName: null,
      templateSpaceTypeIDs: []
    });
  }

  private getPreface(model: CreateSpaceProgramDto) {
    // mediumDate
    return (
      `<h2>{SPACEPROGRAMNAME}</h2>` +
      `<p>&nbsp;</p>` +
      `<p>${this.translocoService.translate('spaceplancreate.preface.Created_on')}: ` +
      `&nbsp;${formatDate(Date.now(), 'mediumDate', this.locale)}</p>` +
      `<p>${this.translocoService.translate('spaceplancreate.preface.Population')}: {PROJECTNAME}</p>` +
      `<p>${this.translocoService.translate('spaceplancreate.preface.Scenario')}: {SCENARIONAME}</p>` +
      `<p>${this.translocoService.translate('spaceplancreate.preface.Template')}: {TEMPLATENAME}</p>` +
      `<p>&nbsp;</p>` +
      `<p>${this.translocoService.translate('spaceplancreate.preface.No_of_generators')}: {NOOFGENERATORS}</p>` +
      `<p>${this.translocoService.translate('spaceplancreate.preface.No_of_space_types')}: {NOOFSPACETYPES}</p>` +
      `<p>${this.translocoService.translate('spaceplancreate.preface.Total_area_of_space_types')}: {TOTALGENERATEDAREA} m2</p>`
    );
  }
}
