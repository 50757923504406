import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[numberOnly]',
})
export class NumberOnlyDirective {
  @Input() decimalSeparator: string = this.whatDecimalSeparator();

  // Allow decimal numbers and negative values
  private regex: RegExp = new RegExp(/^-?[0-9]+(\.[0-9]*){0,1}$/g);
  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight'];

  constructor(private el: ElementRef) {}

  @HostListener('keyup', ['$event']) onKeyUp(e: any) {
    // console.log('keyup', this.el.nativeElement.value);
    this.validateValue(this.el.nativeElement.value);
  }

  @HostListener('blur', ['$event'])
  onBlur(event: any) {
    let value = this.el.nativeElement.value;
    // console.log('blur', value);

    let lastCharacter = value.charAt(value.length - 1);
    if (lastCharacter == this.decimalSeparator) value = value + 0;
    let valid: boolean = new RegExp(this.regex).test(value);
    this.el.nativeElement.value = valid ? value : 0;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    const current: string = this.el.nativeElement.value;
    let next: string = current.concat(event.key);

    // console.log({
    //   current,
    //   next,
    // });

    let lastCharacter = next.charAt(next.length - 1);
    if (lastCharacter != this.decimalSeparator) {
      if (next && !String(next).match(this.regex)) {
        // console.log('prevent');
        event.preventDefault();
      }
    }
  }

  whatDecimalSeparator() {
    const n = 1.1;
    return n.toLocaleString().substring(1, 2);
  }

  validateValue(value: string): void {
    console.log({ value });

    // when a numbers begins with a decimal separator,
    // fix it adding a zero in the beginning
    let firstCharacter = value.charAt(0);
    if (firstCharacter == this.decimalSeparator) value = 0 + value;

    let valid: boolean = new RegExp(this.regex).test(value);
    this.el.nativeElement.value = valid ? value : '';
  }
}
