import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Folder, FolderUsageType, UserRole } from 'src/app/core/services/api-clients';
import { EditorFacadeBase } from './editor-facade-base';
import { UserFacade } from './user.facade';
import { UIFacade } from './ui.facade';
import { TranslocoService } from '@ngneat/transloco';
import { bufferCount, filter, map } from 'rxjs/operators';
import { FoldersDataStoreFacade } from 'src/app/folders/facades';

/**
 * t(spaceplan.WorkPlaceType)
 */
@Injectable({
  providedIn: 'root',
})
export class EditorFolderFacade extends EditorFacadeBase<Folder> {
  constructor(
    protected toastr: ToastrService,
    protected modalService: BsModalService,
    protected uiFacade: UIFacade,
    protected translocoService: TranslocoService,
    private dataStoreFacade: FoldersDataStoreFacade,
    private userFacade: UserFacade
  ) {
    super(toastr, modalService, uiFacade, translocoService);
    // this.dataStoreFacade.spaceProgram$
    //   .pipe(
    //     bufferCount(2, 1),
    //     filter(([a, b]) => b?.spaceProgramID !== a?.spaceProgramID),
    //     map(([a, b]) => b)
    //   )
    //   .subscribe(() => {
    //     this.resetToDefault();
    //   });
  }
  protected className(): string {
    return this.translocoService.translate('spaceplan.Folder');
  }
  protected getFromDataStore(id: string): Observable<Folder> {
    return this.dataStoreFacade.getFolder(id);
  }
  protected createDataStore(model: Folder): Observable<Folder> {
    return this.dataStoreFacade.createFolder(model);
  }
  protected updateDataStore(model: Folder): Observable<Folder> {
    return this.dataStoreFacade.updateFolder(model);
  }
  protected deleteDataStore(id: string): Observable<boolean> {
    return this.dataStoreFacade.deleteFolder(id);
  }
  protected getModelId(model: Folder): string {
    return model?.folderID;
  }
  protected setModelId(model: Folder, id: string) {
    model.folderID = id;
  }
  protected userCanEdit(): Observable<boolean> {
    // return this.dataStoreFacade.spaceProgram$.pipe(
    //   map(m => (m.roleNo === UserRole.Composer))
    // );
    return of(true);
  }
  public canDelete(id: string): Observable<boolean> {
    const state = this.dataStoreFacade.state;
    const canDelete =
      state.spacePrograms.filter((f) => f.folderID === id)?.length === 0 && state.templates.filter((f) => f.folderID === id)?.length === 0;
    return of(canDelete);
  }
  protected getNewModel(init: Partial<Folder>): Observable<Folder> {
    return of({
      ...init,
      folderID: null,
      partnerID: init.partnerID || null,
      usageType: FolderUsageType.All,
    });
  }
}
