import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable, of, combineLatest } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UIFacade } from './ui.facade';
import { take, map, distinctUntilChanged, delay } from 'rxjs/operators';
import { deepEquals } from '../functions/deep-equals';
import { TemplateSpaceType } from 'src/app/core/services/api-clients.generated';
import { LookupFacadeManager } from './lookup-facade-base';

@Injectable({
  providedIn: 'root',
})
export class LookupSpaceTypesFacade {
  private readonly defaultState: LookupSpaceTypesFacadeState = {
    isOpen: false,
    isBusy: false,
    items: [],
    selectedItems: [],
  };

  private readonly stateSubject = new BehaviorSubject<LookupSpaceTypesFacadeState>(this.defaultState);
  private readonly onSelectSubject = new Subject<TemplateSpaceType[]>();
  private readonly onCloseSubject = new Subject<boolean>();
  protected readonly editorFacade = this;
  public readonly state$ = this.stateSubject.asObservable();
  public readonly onSelect$ = this.onSelectSubject.asObservable();
  public readonly onClose$ = this.onCloseSubject.asObservable();
  public get state(): LookupSpaceTypesFacadeState {
    return this.stateSubject.getValue();
  }
  public readonly isOpen$ = this.state$.pipe(
    map((m) => m.isOpen),
    distinctUntilChanged()
  );
  public readonly isBusy$ = this.state$.pipe(
    map((m) => m.isBusy),
    distinctUntilChanged()
  );
  public readonly items$ = this.state$.pipe(
    map((m) => m.items.sort((a, b) => a.spaceTypeName.localeCompare(b.spaceTypeName))),
    distinctUntilChanged()
  );
  public readonly selectedItems$ = this.state$.pipe(
    map((m) => m.selectedItems),
    distinctUntilChanged()
  );

  constructor(protected modalService: BsModalService, protected uiFacade: UIFacade, protected lookupFacadeManager: LookupFacadeManager) {}

  toggleLookup(items: TemplateSpaceType[], selectedItems: TemplateSpaceType[]) {
    if (this.state.isOpen) {
      this.resetAndClose();
    } else {
      this.lookup(items, selectedItems);
    }
  }

  lookup(items: TemplateSpaceType[], selectedItems: TemplateSpaceType[], dontOpen: boolean = false) {
    if (!dontOpen || this.state.isOpen) {
      // this.lookupFacadeManager.open(this);
      this.updateState({ items, selectedItems, isBusy: true, isOpen: true });
      // this.getLookupItemsData().subscribe((items) => {
      this.updateState({ isBusy: false });
      // });
    }
  }

  refreshData(items: TemplateSpaceType[], selectedItems: TemplateSpaceType[]) {
    this.updateState({ items, selectedItems, isBusy: true });
    this.updateState({ isBusy: false });
  }

  select(closePanel: boolean = true): Observable<TemplateSpaceType[]> {
    this.updateState({ isBusy: true });
    combineLatest([this.selectedItems$])
      .pipe(
        delay(0),
        map(([selectedItems]) => {
          if (closePanel) {
            this.resetAndClose();
          }
          this.onSelectSubject.next(selectedItems);
        }),
        take(1)
      )
      .subscribe();
    return this.onSelect$.pipe(take(1));
  }

  cancel() {
    this.resetAndClose();
  }
  updateSelectedItems(selectedItems: TemplateSpaceType[]) {
    this.updateState({ selectedItems });
  }
  private resetAndClose() {
    this.lookupFacadeManager.close(this);
    this.resetToDefault();
    this.onCloseSubject.next(true);
  }
  resetToDefault() {
    this.stateSubject.next({ ...this.defaultState });
  }
  private updateState(updates: Partial<LookupSpaceTypesFacadeState>) {
    // const state = this.stateSubject.getValue();
    // for (const key of Object.keys(updates)) {
    //   state[key] = updates[key];
    // }
    const state = {
      ...this.stateSubject.getValue(),
      ...updates,
    };
    this.stateSubject.next(state);
  }
  private equals(x: any, y: any): boolean {
    return deepEquals(x, y);
  }
}

export interface LookupSpaceTypesFacadeState {
  isOpen: boolean;
  isBusy: boolean;
  items: TemplateSpaceType[];
  selectedItems: TemplateSpaceType[];
}
