<ng-container *transloco="let t; read: 'spaceplancreate'">
  <div class="content-body">
    <div class="content-left">
      <div class="detail">
        <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate [logValidationErrors]="form" appFormHandleEnterKey>
          <div *ngIf="headerVisible" class="header">
            <div class="title">{{ editorFacade.title$ | async }}</div>
            <div class="actions">
              <button *ngIf="canClose" (click)="onClose()" type="button" class="btn btn-primary btn-sm d-flex">
                <i class="k-icon k-i-close"></i>
              </button>
            </div>
          </div>
          <div class="body">
            <div class="p-1">
              <app-editor-space-program-create-form [parentForm]="form" [model]="editorFacade.model$ | async"
                [options]="options$ | async" (updateModelWithChanges)="editorFacade.updateModelWithChanges($event)"
                [isRequesting]="isRequesting">
              </app-editor-space-program-create-form>
            </div>
          </div>
          <div class="footer">
            <div class="buttons-wrapper">
              <button [disabled]="!(editorFacade.isDirty$ | async)"
                [ngClass]="{ disabled: !(editorFacade.isDirty$ | async) }" class="btn btn-primary" type="submit"
                style="min-width: 150px">
                {{ t('Save') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="content-right" *ngIf="lookupIsOpen$ | async">
      <app-lookup [title]="t('label.Select_a_template')" [selectedItem]="lookupTemplateFacade.selectedItem$ | async"
        [items]="lookupTemplateFacade.items$ | async"
        (updateSelectedItem)="lookupTemplateFacade.updateSelectedItem($event)" (select)="onSelectTemplate()"
        (close)="lookupTemplateFacade.cancel()" *ngIf="lookupTemplateFacade.isOpen$ | async">
        <ng-template let-item="item">
          <!--Here can be any component-->
          <div style="border: #ccc 1px solid; margin-top: 2px; min-height: 120px; line-height: 18px" class="p-2 mb-2">
            <div class="mb-1">
              <div class="text-truncate" style="font-weight: 600">{{ item.templateName }}</div>
            </div>

            <div class="d-flex">
              <div class="flex-grow-1 w-100 mr-1 text-muted">{{ t('label.Unit_of_Measure') }}:</div>
              <div class="flex-grow-1 w-100 ml-1 text-muted">{{ t('label.Adjustment_Method') }}:</div>
            </div>
            <div class="d-flex">
              <div class="flex-grow-1 w-100 mr-1 text-truncate">
                {{ item.measurementUnit === 0 ? t('label.Metric') : t('label.Imperial') }}
              </div>
              <div class="flex-grow-1 w-100 ml-1 text-truncate">
                {{
                item.behaviorFractionAdjustmentMethod === 0 ? t('label.In_Primary_Work_Settings_Factor') :
                t('label.In_Office_Percentage')
                }}
              </div>
            </div>

            <div class="d-flex flex-column" *ngIf="item.tagGroups?.length">
              <div class="flex-grow-1 w-100 text-muted">{{ t('label.Tag_Groups') }}:</div>
              <div class="flex-grow-1 w-100">{{ item.tagGroups?.join(', ') }}</div>
            </div>

            <div class="d-flex border-top mt-1 pt-1" *ngIf="item.templateDescr">
              {{ item.templateDescr }}
            </div>
          </div>
        </ng-template>
      </app-lookup>
      <app-lookup [title]="t('label.Select_a_project')" [selectedItem]="lookupProjectFacade.selectedItem$ | async"
        [items]="lookupProjectFacade.items$ | async"
        (updateSelectedItem)="lookupProjectFacade.updateSelectedItem($event)" (select)="onSelectProject()"
        (close)="lookupProjectFacade.cancel()" *ngIf="lookupProjectFacade.isOpen$ | async">
        <ng-template let-item="item">
          <!--Here can be any component-->
          <div style="border: #ccc 1px solid; margin-top: 2px; min-height: 120px; line-height: 18px" class="p-2 mb-2">
            <div class="mb-1">
              <div class="text-truncate" style="font-weight: 600">{{ item.projectName }}</div>
            </div>
            <div class="d-flex">
              <div class="flex-grow-1 w-100 mr-1 text-muted">{{ t('label.Customer') }}:</div>
              <div class="flex-grow-1 w-100 ml-1 text-muted">{{ t('label.Created') }}:</div>
            </div>
            <div class="d-flex">
              <div class="flex-grow-1 w-100 mr-1 text-truncate">{{ item.clientDescr }}</div>
              <div class="flex-grow-1 w-100 ml-1 text-truncate">{{ item.createdDate | date: 'mediumDate' }}</div>
            </div>
            <div class="d-flex mt-1">
              <div class="flex-grow-1 w-100 mr-1 text-muted">{{ t('label.Employees') }}:</div>
              <div class="flex-grow-1 w-100 ml-1 text-muted">{{ t('label.FTE') }}:</div>
            </div>
            <div class="d-flex">
              <div class="flex-grow-1 w-100 mr-1 text-truncate">{{ item.totalEmployees }}</div>
              <div class="flex-grow-1 w-100 ml-1 text-truncate">{{ item.totalFTE | number: '1.0-0' }}</div>
            </div>
            <div class="d-flex border-top mt-1 pt-1" *ngIf="item.projectDescr">
              {{ item.projectDescr }}
            </div>
          </div>
        </ng-template>
      </app-lookup>
      <app-lookup [title]="t('label.Select_a_scenario')" [selectedItem]="lookupScenarioFacade.selectedItem$ | async"
        [items]="lookupScenarioFacade.items$ | async"
        (updateSelectedItem)="lookupScenarioFacade.updateSelectedItem($event)" (select)="onSelectScenario()"
        (close)="lookupScenarioFacade.cancel()" *ngIf="lookupScenarioFacade.isOpen$ | async">
        <ng-template let-item="item">
          <!--Here can be any component-->
          <div style="border: #ccc 1px solid; margin-top: 2px; min-height: 120px; line-height: 18px" class="p-2 mb-2">
            <div class="mb-1">
              <div class="text-truncate" style="font-weight: 600">{{ item.scenarioName }}</div>
            </div>

            <div class="d-flex">
              <div class="flex-grow-1 w-100 ml-1 text-muted">{{ t('label.Created') }}:</div>
            </div>
            <div class="d-flex">
              <div class="flex-grow-1 w-100 ml-1 text-truncate">{{ item.createdDate | date: 'mediumDate' }}</div>
            </div>

            <div class="d-flex flex-column" *ngIf="item.groups?.length">
              <div class="flex-grow-1 w-100 text-muted">{{ t('label.Groups') }}:</div>
              <div class="flex-grow-1 w-100">{{ item.groups?.join(', ') }}</div>
            </div>
          </div>
        </ng-template>
      </app-lookup>
      <app-lookup [title]="t('label.Select_spacetypes')" [selectedItems]="lookupSpaceTypeFacade.selectedItems$ | async"
        [items]="lookupSpaceTypeFacade.items$ | async" [isMultiple]="true" [showFooter]="false"
        (updateSelectedItems)="lookupSpaceTypeFacade.updateSelectedItems($event); onSelectSpaceTypes(false);"
        (select)="onSelectSpaceTypes()" (close)="lookupSpaceTypeFacade.cancel()"
        *ngIf="lookupSpaceTypeFacade.isOpen$ | async">
        <ng-template let-item="item" let-isSelected="isSelected">
          <div
            style="background: #FFF; border: #ccc 1px solid; margin-top: 2px; min-height: 80px; max-height: 80px; line-height: 18px;overflow: visible;"
            class="p-2 mb-2">
            <div style="float:left;line-height: 17px;">
              <i *ngIf="!isSelected" class="k-icon k-i-checkbox"></i>
              <i *ngIf="isSelected" class="k-icon k-i-checkbox-checked"></i>
            </div>
            <div class="mb-1" style="clear:right;">
              <!-- <div class="img-wrapper">
                <img [src]="item.imageUrl || 'https://place-hold.it/120x120/CCC'" alt="" />
              </div> -->
              <div class="text-truncate" style="font-weight: 600; padding-left: 5px;">{{ item.spaceTypeName }}</div>
            </div>
            <div class="border-top mt-1 pt-1" *ngIf="item.spaceTypeDescr"
              style="clear:both; overflow-y: auto; height: 40px; max-height: 40px;min-height: 40px;position:relative; padding-left: 20px;">

              {{ item.spaceTypeDescr }}

            </div>
          </div>
        </ng-template>
      </app-lookup>
    </div>
  </div>
</ng-container>