import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { AccountRepository } from 'src/app/auth/account/state/account.repository';

@Injectable({
  providedIn: 'root',
})
// https://github.com/microsoft/applicationinsights-js#configuration
export class LoggingService {
  appInsights: ApplicationInsights;

  constructor(private accountRepository: AccountRepository) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights?.instrumentationKey,
        enableAutoRouteTracking: true, // option to log all route changes
      },
    });
    this.appInsights.loadAppInsights();

    const telemetryInitializer = (envelope: ITelemetryItem) => {
      // tslint:disable-next-line: no-string-literal
      envelope.data['AspNetCoreEnvironment'] = environment.production ? 'Production' : 'Development';
        // const user = this.accountRepository.account$.getValue();
        // if (user) {
        //   // tslint:disable-next-line: no-string-literal
        //   envelope.data['UserId'] = user.id;
        //   // tslint:disable-next-line: no-string-literal
        //   envelope.data['UserName'] = user.userName;
        // }
    };
    this.appInsights.addTelemetryInitializer(telemetryInitializer);
  }

  //   appInsights.trackPageView({name: 'some page'});
  logPageView(name?: string, url?: string) {
    // option to call manually
    this.appInsights.trackPageView({
      name,
      uri: url,
    });
  }

  //   appInsights.trackEvent({name: 'some event'});
  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name }, properties);
  }

  // appInsights.startTrackEvent("event");
  logStartEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.startTrackEvent(name);
  }

  // appInsights.stopTrackEvent("event", null, {customProp1: "some value"});
  logStopEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.stopTrackEvent(name, properties);
  }

  //   appInsights.trackMetric({name: 'some metric', average: 42});
  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({ name, average }, properties);
  }

  //   appInsights.trackException({exception: new Error('some error')});
  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({ exception, severityLevel });
  }

  //   appInsights.trackTrace({message: 'some trace'});
  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message }, properties);
  }

  // appInsights.flush();
  flush() {
    this.appInsights.flush();
  }

  // appInsights.trackPageViewPerformance({name : 'some page', url: 'some url'});
  // appInsights.trackDependencyData({absoluteUrl: 'some url', responseCode: 200, method: 'GET', id: 'some id'});
  // appInsights.startTrackPage("pageName");
  // appInsights.stopTrackPage("pageName", null, {customProp1: "some value"});
}
