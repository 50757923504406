import { Injectable, OnDestroy } from '@angular/core';
import { DataStoreFacade } from 'src/app/spaceplan/shared/facades/data-store.facade';
import { Observable, of, combineLatest, BehaviorSubject, Subscription } from 'rxjs';
import {
  take,
  map,
  tap,
  distinctUntilChanged,
  shareReplay,
  delay,
  withLatestFrom,
  filter,
  bufferCount,
  debounceTime,
} from 'rxjs/operators';
import { Tag, SpaceType, Group, TagGroup, Generator, SpaceProgram, GeneratorOutput, UserRole } from 'src/app/core/services/api-clients';
import { __asyncValues } from 'tslib';
import { EditorGeneratorFacade, EditorSpaceTypeFacade } from '../../shared/facades';
import { GeneratorOutputSummary } from '../../shared/models/generator-output-summary';
import { summarizeGeneratorOutputs } from '../../shared/functions/summarize-generator-outputs';
import * as multisort from 'multisort';
import { TranslocoService } from '@ngneat/transloco';
import { GeneratorFriendlyNamePipe } from '../../shared/pipes/generator-friendly-name';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class SpaceplanReadFacade implements OnDestroy {
  private holdLoadRHS = false;
  private subscriptions: Subscription = new Subscription();
  private readonly defaultState: SpaceplanReadState = { loading: false, showPanelShortcuts: false, groupBy: [] };
  private readonly stateSubject = new BehaviorSubject<SpaceplanReadState>({ ...this.defaultState });

  private emptyColourCode = EMPTY_COLOUR_CODE;

  readonly state$ = this.stateSubject.asObservable();
  readonly loading$ = this.state$.pipe(map((m) => m.loading));
  readonly showPanelShortcuts$ = this.state$.pipe(map((m) => m.showPanelShortcuts));
  readonly groupBy$ = this.state$.pipe(
    map((m) => m.groupBy),
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    shareReplay(1)
  );
  readonly selectedSpaceTypeGeneratorOutputSummaries$ = this.state$.pipe(
    map((m) => m.selectedSpaceTypeGeneratorOutputSummaries),
    distinctUntilChanged(),
    shareReplay(1)
  );

  readonly expandedGroups$ = this.state$.pipe(
    map((m) => m.expandedGroups || []),
    distinctUntilChanged(),
    shareReplay(1)
  );

  readonly editorSpaceTypeOpen$ = this.editorSpaceTypeFacade.isOpen$;
  readonly editorGeneratorOpen$ = this.editorGeneratorFacade.isOpen$;

  readonly generatorFriendlyNamePipe = new GeneratorFriendlyNamePipe(this.sanitized, this.translactionService, this.dataStoreFacade);

  constructor(
    private dataStoreFacade: DataStoreFacade,
    private editorSpaceTypeFacade: EditorSpaceTypeFacade,
    private editorGeneratorFacade: EditorGeneratorFacade,
    private translactionService: TranslocoService,
    private sanitized: DomSanitizer
  ) {
    this.subscriptions.add(
      dataStoreFacade.spaceProgram$
        .pipe(
          bufferCount(2, 1),
          filter(([a, b]) => b?.spaceProgramID !== a?.spaceProgramID),
          map(([a, b]) => b)
        )
        .subscribe((spaceProgram) => {
          this.resetToDefault();
        })
    );
  }
  public readonly allGeneratorOutputResults$ = combineLatest([
    this.dataStoreFacade.spaceProgram$,
    this.dataStoreFacade.generatorOutputs$,
    this.dataStoreFacade.generators$,
    this.dataStoreFacade.spaceTypes$,
    this.dataStoreFacade.groups$,
    this.dataStoreFacade.tags$,
    this.dataStoreFacade.tagGroups$,
  ]).pipe(
    delay(0),
    debounceTime(300),
    map(([spaceProgram, generatorOutputs, generators, spaceTypes, groups, tags]) =>
      this.getAllGeneratorOutputResult(
        spaceProgram as SpaceProgram,
        generatorOutputs as GeneratorOutput[],
        generators as Generator[],
        spaceTypes as SpaceType[],
        groups as Group[],
        tags as Tag[]
      )
    ),
    shareReplay(1)
  );

  public readonly spaceProgram$ = this.dataStoreFacade.spaceProgram$;

  public readonly groupsChartData$ = this.dataStoreFacade.generatorOutputs$.pipe(
    withLatestFrom(this.dataStoreFacade.generators$, this.dataStoreFacade.spaceTypes$, this.dataStoreFacade.groups$, this.spaceProgram$),
    map(([generatorOutputs, generators, spaceTypes, groups, spaceProgram]) => {
      const ungroupedName = this.translactionService.translate('spaceplan.chart.NotGrouped');
      const emptyColourCode = this.emptyColourCode;

      const generatorOutputsExt = generatorOutputs.map((go) => {
        const g = generators.find((f) => f.generatorID === go.generatorID);
        const s = spaceTypes.find((f) => f.spaceTypeID === g.spaceTypeID);
        return {
          ...go,
          area: s.area,
        };
      });

      const groupvalues = groupBy(
        generatorOutputsExt.filter((f) => !!f.groupID),
        'groupID'
      );
      const ungrouped = generatorOutputsExt
        .filter((f) => !f.groupID)
        .reduce((v, c) => {
          if (!!c.plannedNoOfSpaceTypes || c.plannedNoOfSpaceTypes === 0) {
            v += c.plannedNoOfSpaceTypes * c.area;
          } else {
            v += c.generatedNoOfSpaceTypes * c.area;
          }
          // v += (c.plannedNoOfSpaceTypes || c.generatedNoOfSpaceTypes || 0) * c.area;
          return v;
        }, 0);

      const results = Object.keys(groupvalues)
        .map((key) => ({
          groupID: key,
          groupName: groups.find((f) => f.groupID === key)?.groupName || ungroupedName,
          value: groupvalues[key].reduce((v, c) => {
            if (!!c.plannedNoOfSpaceTypes || c.plannedNoOfSpaceTypes === 0) {
              v += c.plannedNoOfSpaceTypes * c.area;
            } else {
              v += c.generatedNoOfSpaceTypes * c.area;
            }
            // v += (c.plannedNoOfSpaceTypes || c.generatedNoOfSpaceTypes || 0) * c.area;
            return v;
          }, 0),
          colourCode: groups.find((f) => f.groupID === key)?.colourCode || emptyColourCode,
        }))
        .sort((a, b) => a.groupName.localeCompare(b.groupName));
      if (ungrouped) {
        results.push({
          groupID: null,
          groupName: ungroupedName,
          value: ungrouped,
          colourCode: spaceProgram.colourCodeOtherGroup || emptyColourCode,
        });
      }
      return results;
    })
  );

  public readonly tagGroupsChartData$ = this.dataStoreFacade.generatorOutputs$.pipe(
    withLatestFrom(
      this.dataStoreFacade.generators$,
      this.dataStoreFacade.spaceTypes$,
      this.dataStoreFacade.tagGroups$,
      this.dataStoreFacade.tags$,
      this.spaceProgram$
    ),
    map(([generatorOutputs, generators, spaceTypes, tagGroups, tags, spaceProgram]) => {
      const untaggedName = this.translactionService.translate('spaceplan.chart.NotTagged');

      const generatorOutputsExt = generatorOutputs.map((go) => {
        const g = generators.find((f) => f.generatorID === go.generatorID);
        const s = spaceTypes.find((f) => f.spaceTypeID === g.spaceTypeID);

        const tagGroupsTags = tagGroups.reduce((v, c) => {
          v[c.tagGroupID] = null;
          return v;
        }, {});

        const gts =
          generators
            .find((f) => f.generatorID === go.generatorID)
            ?.tags?.map((gt) => {
              return tags.find((f) => f.tagID === gt);
            }) || [];

        for (const gt of gts) {
          tagGroupsTags[gt.tagGroupID] = gt.tagID;
        }

        return {
          ...go,
          ...tagGroupsTags,
          area: s.area,
        };
      });
      const results = tagGroups
        .map((tg) => {
          const groupvalues = groupBy(
            generatorOutputsExt.filter((f) => !!f[tg.tagGroupID]),
            tg.tagGroupID
          );

          const untagged = generatorOutputsExt
            .filter((f) => !f[tg.tagGroupID])
            .reduce((v, c) => {
              if (!!c.plannedNoOfSpaceTypes || c.plannedNoOfSpaceTypes === 0) {
                v += c.plannedNoOfSpaceTypes * c.area;
              } else {
                v += c.generatedNoOfSpaceTypes * c.area;
              }
              // v += (c.plannedNoOfSpaceTypes || c.generatedNoOfSpaceTypes || 0) * c.area;
              return v;
            }, 0);

          const tagsresults = Object.keys(groupvalues)
            .map((key) => {
              const tag = tags.find((f) => f.tagID === key);
              return {
                tagID: key,
                tagName: tag?.tagName || untaggedName,
                displaySequence: tag?.displaySequence || 0,
                value: groupvalues[key].reduce((v, c) => {
                  if (!!c.plannedNoOfSpaceTypes || c.plannedNoOfSpaceTypes === 0) {
                    v += c.plannedNoOfSpaceTypes * c.area;
                  } else {
                    v += c.generatedNoOfSpaceTypes * c.area;
                  }
                  // v += (c.plannedNoOfSpaceTypes || c.generatedNoOfSpaceTypes || 0) * c.area;
                  return v;
                }, 0),
                colourCode: tag?.colourCode || this.emptyColourCode,
              };
            })
            .sort((a, b) => a.displaySequence - b.displaySequence);

          if (untagged) {
            tagsresults.push({
              tagID: null,
              tagName: untaggedName,
              displaySequence: null,
              value: untagged,
              colourCode: spaceProgram.colourCodeOtherTag || this.emptyColourCode,
            });
          }

          return {
            tagGroupdID: tg.tagGroupID,
            tagGroupName: tg.tagGroupName,
            tags: tagsresults,
          };
        })
        .sort((a, b) => a.tagGroupName.localeCompare(b.tagGroupName));
      return results;
    })
  );

  public readonly readOnly$ = this.spaceProgram$.pipe(map((s) => !(s.roleNo === UserRole.Composer || s.roleNo === UserRole.Editor)));

  public readonly tagGroups$ = this.dataStoreFacade.tagGroups$.pipe(
    map((m) => m.sort(sortTagGroup)),
    shareReplay(1)
  );

  public readonly groupingGroupsAvailable$: Observable<GroupingGroup[]> = this.dataStoreFacade.tagGroups$.pipe(
    withLatestFrom(this.spaceProgram$, this.dataStoreFacade.groups$, this.dataStoreFacade.tags$),
    map(([tagGroups, spaceProgram, groups, tags]) => {
      const groupsAvailable: GroupingGroup[] = [
        {
          id: 'Group',
          name: this.translactionService.translate('spaceplan.grouping.groups.name'),
          idField: 'groupID',
          nameField: 'groupName',
          colourCodeField: 'groupColourCode',
          sequenceField: null,
          includeEmptyGroup: true,
          emptyGroupName: this.translactionService.translate('spaceplan.grouping.groups.NotGrouped'),
          emptyColourCode: spaceProgram.colourCodeUnallocated,
          emptyGroupColourCode: spaceProgram.colourCodeOtherGroup,
          hasColourCodes: !!groups.find((f1) => f1.colourCode),
          extendedData: (item: any) => {

            const summaryContent = spaceProgram.summaryContent?.reduce((value, item) => {
              value[item] = true;
              return value;
            }, {
              chart: false,
              fte: false,
              people: false,
              deltaSpace: false,
              primaryWorkspaces: false,
              capacityRatio: false,
              capacity: false,
              plannedNetSurface: false,
              plannedGrossSurface: false,
              deltaSurface: false,
              keyMetricByFTE: false,
              keyMetricByPeople: false,
              keyMetricByFTEPresent: false,
            });

            return {
              behaviorFraction: item.behaviorFraction,
              growthFactor: item.growthFactor,
              sourceInOfficeFraction: spaceProgram.sourceInOfficeFraction,
              fteToPeopleFactor: spaceProgram.fteToPeopleFactor,
              summaryContent: summaryContent
            };
          }
        },
      ];

      if (tagGroups && tagGroups.length > 0) {
        tagGroups.forEach((f) => {
          groupsAvailable.push({
            id: f.tagGroupID,
            name: f.tagGroupName,
            idField: 'tags.' + f.tagGroupID + '.tagID',
            nameField: 'tags.' + f.tagGroupID + '.tagName',
            colourCodeField: 'tags.' + f.tagGroupID + '.colourCode',
            sequenceField: 'tags.' + f.tagGroupID + '.displaySequence',
            includeEmptyGroup: true,
            emptyGroupName: this.translactionService.translate('spaceplan.grouping.tags.NotTagged'),
            emptyColourCode: spaceProgram.colourCodeUnallocated,
            emptyGroupColourCode: spaceProgram.colourCodeOtherTag,
            hasColourCodes: !!tags.find((f1) => f1.tagGroupID === f.tagGroupID && f1.colourCode)
          });
        });
      }
      return groupsAvailable;
    }),
    shareReplay(1)
  );

  public readonly groupingGroupsSelected$: Observable<GroupingGroup[]> = combineLatest([this.groupingGroupsAvailable$, this.groupBy$]).pipe(
    map(([availableGroups, groupBys]) => {
      if (groupBys && groupBys.length > 0) {
        return groupBys.map((m1) => availableGroups.find((f) => f.id === m1)).filter((f) => f);
      }
      return [];
    }),
    shareReplay(1)
  );

  public readonly groupingGroupsAvailableToSelect$ = combineLatest([this.groupingGroupsAvailable$, this.groupingGroupsSelected$]).pipe(
    map(([groupingGroupsAvailable, groupingGroupsSelected]) => {
      if (groupingGroupsSelected && groupingGroupsSelected.length > 0) {
        // tslint:disable-next-line:triple-equals
        return groupingGroupsAvailable.filter((f) => !groupingGroupsSelected.find((f1) => f1.id == f.id));
      }
      return groupingGroupsAvailable;
    }),
    shareReplay(1)
  );

  public readonly groupingGroups$ = combineLatest([this.groupingGroupsSelected$, this.groupingGroupsAvailableToSelect$]).pipe(
    map(([selected, available]) => {
      return (selected || [])
        .map((m) => ({ ...m, isSelectedInd: true } as GroupingGroupSelect))
        .concat((available || []).map((m) => ({ ...m } as GroupingGroupSelect)));
    }),
    shareReplay(1)
  );

  public readonly spaceTypeGeneratorOutputSummaries$ = combineLatest([
    this.groupingGroupsSelected$,
    this.allGeneratorOutputResults$,
    this.selectedSpaceTypeGeneratorOutputSummaries$,
    this.expandedGroups$,
  ]).pipe(
    withLatestFrom(this.dataStoreFacade.spaceProgram$, this.dataStoreFacade.groups$),
    map(
      ([
        [groupGroupsSelected, allGeneratorOutputResults, selectedSpaceTypeGeneratorOutputSummaries, expandedGroups],
        spaceProgram,
        groups,
      ]) => {
        return groupSummary(groupGroupsSelected, 0, allGeneratorOutputResults, selectedSpaceTypeGeneratorOutputSummaries, expandedGroups);
      }
    ),
    shareReplay(1)
  );

  public readonly generatorOutputResultsCaption$ = combineLatest([
    this.spaceTypeGeneratorOutputSummaries$,
    this.selectedSpaceTypeGeneratorOutputSummaries$,
  ]).pipe(
    map(([summaries, selected]) => {
      if (selected?.length > 0) {
        if (selected?.length === 1) {
          const name = (summaries as SpaceTypeGeneratorOutputSummary[])?.find((f) => f.id === (selected[0].id || '-'))?.name;
          return name;
        } else {
          let items: SpaceTypeGeneratorOutputSummaryGroup[] = summaries as SpaceTypeGeneratorOutputSummaryGroup[];
          for (let index = 0; index < selected.length - 1; index++) {
            const item = items?.find((f) => f.id === (selected[index].id || '-'));
            items = item.items as SpaceTypeGeneratorOutputSummaryGroup[];
          }
          const name = items?.find((f) => f.id === (selected[selected.length - 1].id || '-'))?.name;
          return name;
        }
      }
      return null;
    }),
    distinctUntilChanged(),
    shareReplay(1)
  );

  public readonly generatorOutputResultsCaptionNew$ = combineLatest([
    this.spaceTypeGeneratorOutputSummaries$,
    this.selectedSpaceTypeGeneratorOutputSummaries$,
  ]).pipe(
    map(([summariesResults, selected]) => {
      if (selected?.length > 0) {
        Object.keys(selected).reduce((item, value) => {
          return value;
        }, null);
      }
      return '[nothing]';
    }),
    distinctUntilChanged(),
    shareReplay(1)
  );

  // RHS
  public readonly generatorOutputResults$ = combineLatest([
    this.allGeneratorOutputResults$,
    this.selectedSpaceTypeGeneratorOutputSummaries$,
  ]).pipe(
    filter(() => !this.holdLoadRHS),
    delay(0),
    withLatestFrom(this.groupingGroupsSelected$),
    map(([[results, selected], groupingGroupsSelected]) => this.getGeneratorOutputResults(results, selected, groupingGroupsSelected)),
    shareReplay(1)
  );

  public readonly generatorOutputResultsNew$ = combineLatest([
    this.allGeneratorOutputResults$,
    this.selectedSpaceTypeGeneratorOutputSummaries$,
  ]).pipe(
    filter(() => !this.holdLoadRHS),
    delay(0),
    withLatestFrom(this.groupingGroupsSelected$),
    map(([[results, selected], groupingGroupsSelected]) => this.getGeneratorOutputResultsNew(results, selected, groupingGroupsSelected)),
    shareReplay(1)
  );

  public readonly isPanelSideDetailOpen$ = combineLatest([this.editorSpaceTypeFacade.isOpen$, this.editorGeneratorFacade.isOpen$]).pipe(
    delay(0),
    map(([editorSpaceTypeOpen, editorGeneratorOpen]) => editorSpaceTypeOpen || editorGeneratorOpen)
  );
  public readonly isPanelSideOpen$ = combineLatest([this.isPanelSideDetailOpen$, this.selectedSpaceTypeGeneratorOutputSummaries$]).pipe(
    delay(0),
    map(
      ([isPanelSideDetailOpen, selectedSpaceTypeGeneratorOutputSummaries]) =>
        isPanelSideDetailOpen || selectedSpaceTypeGeneratorOutputSummaries?.length
    )
  );

  // SUMMARY
  public readonly generatorOutputSummary$ = this.dataStoreFacade.generatorOutputSummary$;

  resetToDefault() {
    this.stateSubject.next({ ...this.defaultState });
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  setGroupingGroups(ids: string[]) {
    const state = this.stateSubject.value;
    const newGroupBy = [...ids];
    this.stateSubject.next({ ...state, groupBy: newGroupBy });
  }
  clearGroupingGroups() {
    this.stateSubject.next({ ...this.stateSubject.value, groupBy: null, expandedGroups: null });
  }
  setGroupingGroupGeneratorOutputSummaryFilters(filters: { id: string; name: string }[]) {
    this.canDeactivateEditors()
      .pipe(take(1))
      .subscribe(({ canDeactivate }) => {
        if (canDeactivate) {
          const state = this.stateSubject.value;
          if (JSON.stringify(filters) !== JSON.stringify(state.selectedSpaceTypeGeneratorOutputSummaries)) {
            this.stateSubject.next({ ...state, selectedSpaceTypeGeneratorOutputSummaries: filters && [...filters] });
          } else {
            this.clearGroupingGroupGeneratorOutputSummaryFilters();
          }
        }
      });
  }
  clearGroupingGroupGeneratorOutputSummaryFilters() {
    this.stateSubject.next({ ...this.stateSubject.value, selectedSpaceTypeGeneratorOutputSummaries: null });
  }
  setGrouping(groups: GroupingGroup[]) {
    this.canDeactivateEditors()
      .pipe(take(1))
      .subscribe(({ canDeactivate }) => {
        if (canDeactivate) {
          if (groups.length === 0) {
            this.clearGroupingGroups();
          } else {
            this.setGroupingGroups(groups.map((grp) => grp.id));
          }
          this.clearGroupingGroupGeneratorOutputSummaryFilters();
        }
      });
  }

  toggleGroupExpand(item: any) {
    const state = this.stateSubject.getValue();
    let expandedGroups = state.expandedGroups || [];
    const expandedGroup = expandedGroups.find((f) => f === item.id);
    if (expandedGroup) {
      expandedGroups = expandedGroups.filter((f) => f !== item.id);
    } else {
      expandedGroups = [...expandedGroups, item.id];
    }
    this.stateSubject.next({ ...state, expandedGroups });
  }
  clearExpandedGroups() {
    this.stateSubject.next({ ...this.stateSubject.getValue(), expandedGroups: null });
  }
  closePanelSide() {
    this.canDeactivateEditors()
      .pipe(take(1))
      .subscribe(({ canDeactivate }) => {
        if (canDeactivate) {
          this.clearGroupingGroupGeneratorOutputSummaryFilters();
        }
      });
  }
  togglePanelShortcuts() {
    this.canDeactivateEditors()
      .pipe(take(1))
      .subscribe(({ canDeactivate }) => {
        if (canDeactivate) {
          // this.clearGroupingGroupGeneratorOutputSummaryFilters();
          const state = this.stateSubject.getValue();
          this.stateSubject.next({ ...state, showPanelShortcuts: !state.showPanelShortcuts });
        }
      });
  }
  updateGeneratorOutputPlannedNoOfSpaceTypes(generatorOutputID: string, plannedNoOfSpaceTypes?: number) {
    this.holdLoadRHS = true;
    return this.dataStoreFacade.updateGeneratorOutputPlannedNoOfSpaceTypes(generatorOutputID, plannedNoOfSpaceTypes).pipe(
      map(() => {
        setTimeout(() => {
          this.holdLoadRHS = false;
        });
      })
    );
  }

  openSpaceType(id: string) {
    this.editorGeneratorFacade.close().subscribe((closed) => {
      if (closed) {
        this.editorSpaceTypeFacade.toggleEdit(id);
      }
    });
  }
  openGenerator(id: string) {
    this.editorSpaceTypeFacade.close().subscribe((closed) => {
      if (closed) {
        this.editorGeneratorFacade.toggleEdit(id);
      }
    });
  }
  canDeactivate(): Observable<{ canDeactivate: boolean; handled?: boolean }> {
    return this.canDeactivateEditors();
  }
  canDeactivateEditors(): Observable<{ canDeactivate: boolean; handled?: boolean }> {
    return combineLatest([this.editorSpaceTypeFacade.close(), this.editorGeneratorFacade.close()]).pipe(
      map(([canDeactivateSpaceType, canDeactivateGenerator]) => ({
        canDeactivate: canDeactivateSpaceType && canDeactivateGenerator,
        handled: true,
      }))
    );
  }
  public getGeneratorOutputResults(results, selected, groupingGroupsSelected) {
    let innerResults = getFilteredGeneratorOutputResults(results, selected, groupingGroupsSelected);
    innerResults = innerResults
      .map((m) => {
        const result = {
          ...m,
          plannedNoOfSpaceTypes:
            m.plannedNoOfSpaceTypes || m.plannedNoOfSpaceTypes === 0 ? m.plannedNoOfSpaceTypes : m.generatedNoOfSpaceTypes,
        };
        return result;
      })
      .sort(sortGeneratorOutputResult);
    return innerResults;
  }
  public getGeneratorOutputResultsNew(results, selected, groupingGroupsSelected) {
    let innerResults = getFilteredGeneratorOutputResults(results, selected, groupingGroupsSelected);
    innerResults = innerResults.map((m) => {
      const result = {
        ...m,
        plannedNoOfSpaceTypes:
          m.plannedNoOfSpaceTypes || m.plannedNoOfSpaceTypes === 0 ? m.plannedNoOfSpaceTypes : m.generatedNoOfSpaceTypes,
      };
      return result;
    });

    const spaceTypesGrouped = groupBy(innerResults, 'spaceTypeID');
    const spaceTypes = Object.keys(spaceTypesGrouped)
      .map((spaceTypeID) => {
        const item = spaceTypesGrouped[spaceTypeID][0];
        const generatorsGrouped = groupBy(spaceTypesGrouped[spaceTypeID], 'generatorID');
        const generators = Object.keys(generatorsGrouped)
          .map((generatorID) => {
            const itemGenerator = generatorsGrouped[generatorID][0];
            const groupsGrouped = groupBy(generatorsGrouped[generatorID], 'groupID');
            const groups = Object.keys(groupsGrouped)
              .map((groupID) => {
                const itemGroup = groupsGrouped[groupID][0];
                const group = {
                  generatorOutputID: itemGroup.generatorOutputID,
                  id: itemGroup.groupID,
                  name: (itemGroup.groupID && itemGroup.groupName) || this.translactionService.translate('spaceplan.overrides-panel.Total'),
                  plannedNoOfSpaceTypes: itemGroup.plannedNoOfSpaceTypes,
                  generatedNoOfSpaceTypes: itemGroup.generatedNoOfSpaceTypes,
                };
                return group;
              })
              .sort(sortByName);
            const generator = {
              id: itemGenerator.generatorID,
              name: itemGenerator.generatorFriendlyName || itemGenerator.name,
              tags: Object.keys(itemGenerator.tags)
                .map((tagId) => itemGenerator.tags[tagId].tagName)
                .join(', '),
              groups,
            };

            return generator;
          })
          .sort(sortByName);

        const spaceType = {
          id: item.spaceTypeID,
          name: item.spaceTypeName,
          generators,
        };
        return spaceType;
      })
      .sort(sortByName);
    return spaceTypes;
  }
  private getAllGeneratorOutputResult(
    spaceProgram: SpaceProgram,
    generatorOutputs: GeneratorOutput[],
    generators: Generator[],
    spaceTypes: SpaceType[],
    groups: Group[],
    tags: Tag[]
  ): GeneratorOutputResult[] {
    if (spaceProgram && generatorOutputs?.length && generators?.length && spaceTypes?.length) {
      return generatorOutputs?.map((go) => {
        const g = generators.find((f) => f.generatorID === go.generatorID);
        const st = g && spaceTypes.find((f) => f.spaceTypeID === g.spaceTypeID);
        const grp = groups.find((f) => f.groupID === go.groupID);
        const grptagIds = (g && g.tags) || [];
        const grpgrouptIds = (g && g.groups) || [];
        const grptags =
          (tags && grptagIds.map((m1) => tags.find((f) => f.tagID === m1))).reduce((result, item) => {
            result[item.tagGroupID] = item;
            return result;
          }, {}) || {};
        const grpgroups =
          (groups && grpgrouptIds.map((m1) => groups.find((f) => f.groupID === m1))).reduce((result, item) => {
            result[item.groupID] = item;
            return result;
          }, {}) || {};

        return {
          ...go,
          generatorName: g?.generatorName,
          generatorFriendlyName: g && this.generatorFriendlyNamePipe.transform(g),
          groupName: grp?.groupName,
          groupColourCode: grp?.colourCode,
          spaceTypeID: g.spaceTypeID,
          spaceTypeName: st.spaceTypeName,
          spaceTypeCode: st.spaceTypeCode,
          spaceTypeDescr: st.spaceTypeDescr,
          colourCode: st.colourCode,
          imageUrl: st.imageUrl,
          capacity: st.capacity,
          length: st.length,
          breadth: st.breadth,
          area: st.area,
          workPlaceFactor: st.workPlaceFactor,
          areaNetToGrossFactor: spaceProgram.areaNetToGrossFactor,
          primaryFactor: st.primaryFactor,
          behaviorFraction: grp?.behaviorFraction,
          growthFactor: grp?.growthFactor,
          fteToPeopleFactor: spaceProgram?.fteToPeopleFactor,
          tags: grptags,
          groups: grpgroups,
        } as GeneratorOutputResult;
      });
    }
    return [];
  }
}

export const EMPTY_COLOUR_CODE = '#FFD823';

export function groupSummary(
  groupGroupsSelected: GroupingGroup[],
  level: number,
  items: GeneratorOutputResult[],
  selectedOutputSummaries: { id: string; name: string }[],
  expandedGroups: string[],
  groupingGroups: { id: string; name: string }[] = null
): SpaceTypeGeneratorOutputSummaryGroup[] | SpaceTypeGeneratorOutputSummaryItem[] {
  const emptyColourCode = EMPTY_COLOUR_CODE;
  if (items && items.length > 0) {
    if (groupGroupsSelected && groupGroupsSelected.length > 0 && level <= groupGroupsSelected.length - 1) {
      const results: SpaceTypeGeneratorOutputSummaryGroup[] = [];
      const grouped = groupByDeepValue(items, groupGroupsSelected[level].idField);
      for (const key in grouped) {
        if (grouped.hasOwnProperty(key)) {
          const item = grouped[key][0];
          const id = deepValue(item, groupGroupsSelected[level].idField);
          const displaySequence = groupGroupsSelected[level].sequenceField ? deepValue(item, groupGroupsSelected[level].sequenceField) : 0;
          const isEmptyGroup = !id;
          if (!isEmptyGroup || groupGroupsSelected[level].includeEmptyGroup) {
            const name = deepValue(item, groupGroupsSelected[level].nameField) || groupGroupsSelected[level].emptyGroupName;
            const colourCode = isEmptyGroup
              ? groupGroupsSelected[level].emptyGroupColourCode || (groupGroupsSelected[level].hasColourCodes ? emptyColourCode : null)
              : deepValue(item, groupGroupsSelected[level].colourCodeField) ||
                groupGroupsSelected[level].emptyColourCode ||
                (groupGroupsSelected[level].hasColourCodes ? emptyColourCode : null);
            const tempGroupingGroups = [...(groupingGroups || []), { id, name }];
            const resultTemp: SpaceTypeGeneratorOutputSummaryGroup = {
              id: id || '-',
              name,
              displaySequence,
              isEmptyGroup,
              groupingGroupId: groupGroupsSelected[level].id,
              groupingGroupName: groupGroupsSelected[level].name,
              groupingGroups: tempGroupingGroups,
              items: groupSummary(
                groupGroupsSelected,
                level + 1,
                grouped[key],
                selectedOutputSummaries,
                expandedGroups,
                tempGroupingGroups
              ),
              colourCode,
              isExpanded: !!expandedGroups?.find((f) => f === (id || '-')),
              isSelected:
                tempGroupingGroups?.length === selectedOutputSummaries?.length &&
                tempGroupingGroups.reduce((i, v) => i && !!selectedOutputSummaries?.find((f) => f.id === v.id), true),
              summary: summarizeGeneratorOutputs(grouped[key], null),
              extendedData: !!groupGroupsSelected[level].extendedData ? groupGroupsSelected[level].extendedData(item) : {}
            };
            results.push(resultTemp);
          }
        }
      }
      multisort(results, ['~isEmptyGroup', 'displaySequence', 'name']);
      // results.sort((a, b) => (a.isEmptyGroup ? -1 : 1 || a.name.localeCompare(b.name)));
      return results;
    } else {
      const results: SpaceTypeGeneratorOutputSummaryItem[] = [];
      const grouped = groupBy(items, 'spaceTypeID');
      for (const key in grouped) {
        if (grouped.hasOwnProperty(key)) {
          const item = grouped[key][0];
          const id = item.spaceTypeID;
          const name = item.spaceTypeName;
          const colourCode = item.colourCode || emptyColourCode;
          const displaySequence = 0;
          const tempGroupingGroups = [...(groupingGroups || []), { id, name }];
          const resultTemp: SpaceTypeGeneratorOutputSummaryItem = {
            // ...summarizeGeneratorOutputs(grouped[key], null),
            id,
            name,
            displaySequence,
            imageUrl: item.imageUrl,
            length: item.length,
            breadth: item.breadth,
            area: item.area,
            capacity: item.capacity,
            workPlaceFactor: item.workPlaceFactor,
            primaryFactor: item.primaryFactor,
            spaceTypeCode: item.spaceTypeCode,
            spaceTypeDescr: item.spaceTypeDescr,
            groupingGroups: tempGroupingGroups,
            colourCode,
            isSelected:
              tempGroupingGroups?.length === selectedOutputSummaries?.length &&
              tempGroupingGroups.reduce((i, v) => i && !!selectedOutputSummaries?.find((f) => f.id === v.id), true),
            summary: summarizeGeneratorOutputs(grouped[key], null),
          };
          results.push(resultTemp);
        }
      }
      results.sort(sortByName);
      return results;
    }
  }
  return [];
}

export function getFilteredGeneratorOutputResults(results: any, selected: any, groupingGroupsSelected: any) {
  if (selected && selected.length > 0) {
    let filteredResults = results;
    // if have groups assume filter from groups top down first
    if (groupingGroupsSelected && groupingGroupsSelected.length > 0) {
      filteredResults = filteredResults.filter((f) => {
        return groupingGroupsSelected.slice(0, selected.length).reduce((result, item, index) => {
          // tslint:disable-next-line:triple-equals
          return result && deepValue(f, item.idField) == selected[index].id;
        }, true);
      });
    }

    // if have no groups or there is a filter for every group level assume last item is spaceTypeId
    if (selected.length > ((groupingGroupsSelected && groupingGroupsSelected.length) || 0)) {
      // tslint:disable-next-line:triple-equals
      filteredResults = filteredResults.filter((f) => f.spaceTypeID == selected[selected.length - 1].id);
    }
    return filteredResults;
  }
  return results;
}

export function sortByName(a: { name: string }, b: { name: string }) {
  return a.name.localeCompare(b.name);
}

export function sortTagGroup(a: TagGroup, b: TagGroup) {
  return a.tagGroupName.localeCompare(b.tagGroupName);
}

export function sortGeneratorOutputResult(a: GeneratorOutputResult, b: GeneratorOutputResult) {
  return (
    a.spaceTypeName.localeCompare(b.spaceTypeName) ||
    a.generatorName.localeCompare(b.generatorName) ||
    a.groupName.localeCompare(b.groupName)
  );
}

export interface SpaceplanReadState {
  loading: boolean;
  showPanelShortcuts: boolean;
  selectedSpaceTypeGeneratorOutputSummaries?: { id: string; name: string }[];
  expandedGroups?: string[];
  groupBy?: string[];
  editId?: string;
  editSpaceType?: SpaceType;
  editGenerator?: Generator;
}

export interface GeneratorOutputResult {
  generatorOutputID?: string;
  generatorID?: string;
  generatorName: string;
  generatorFriendlyName: string;
  groupID?: string | null;
  groupName: string;
  groupColourCode: string;
  spaceTypeID: string;
  spaceTypeName: string;
  spaceTypeCode: string;
  spaceTypeDescr: string;
  colourCode: string;
  imageUrl: string;
  generatedNoOfSpaceTypes?: number;
  plannedNoOfSpaceTypes?: number | null;
  capacity?: number | null;
  length?: number | null;
  breadth?: number | null;
  area?: number | null;
  workPlaceFactor?: number | null;
  areaNetToGrossFactor?: number | null;
  primaryFactor?: number | null;
  behaviorFraction?: number | null;
  growthFactor?: number | null;
  fteToPeopleFactor?: number | null;
  tags: { [key: string]: Tag };
  groups: { [key: string]: Group };
}

export interface SpaceTypeGeneratorOutputSummaryGroup extends SpaceTypeGeneratorOutputSummary {
  groupingGroupId: string;
  groupingGroupName: string;
  isEmptyGroup: boolean;
  items: SpaceTypeGeneratorOutputSummaryItem[];
  colourCode?: string;
  isExpanded: boolean;
  isSelected: boolean;
  extendedData: any;
}

export interface SpaceTypeGeneratorOutputSummaryItem extends SpaceTypeGeneratorOutputSummary {
  spaceTypeCode?: string | null;
  spaceTypeDescr?: string | null;
  spaceTypeGroupID?: string | null;
  imageUrl?: string | null;
  length?: number | null;
  breadth?: number | null;
  area?: number | null;
  capacity?: number;
  workPlaceFactor?: number;
  primaryFactor?: number;
  templateSpaceTypeID?: string | null;
  colourCode?: string;
  isSelected: boolean;
}

export interface SpaceTypeGeneratorOutputSummary {
  id: string;
  name: string;
  displaySequence: number;
  groupingGroups: { id: string; name: string }[];
  summary: GeneratorOutputSummary;
}

export interface GroupingGroupSelect extends GroupingGroup {
  sequence: number;
  isSelectedInd: boolean;
}

export interface GroupingGroup {
  id: string;
  name: string;
  idField: string;
  nameField: string;
  colourCodeField: string;
  sequenceField: string;
  includeEmptyGroup: boolean;
  emptyGroupName: string;
  emptyColourCode: string;
  emptyGroupColourCode: string;
  hasColourCodes: boolean;
  extendedData?: Function;
}

export function groupBy<T>(xs, key) {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export function groupByDeepValue<T>(xs, key) {
  return xs.reduce((rv, x) => {
    (rv[deepValue(x, key)] = rv[deepValue(x, key)] || []).push(x);
    return rv;
  }, {});
}

export function deepValue(obj, path) {
  let paths: string[] = path.split('.');
  while (obj && paths && paths.length > 0) {
    obj = obj[paths[0]];
    paths = paths.slice(1);
  }
  return obj;
}
