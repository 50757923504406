import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[appFormHandleEnterKey]'
})
export class FormHandleEnterKeyDirective {
    @HostListener('keydown.enter', ['$event']) //keydown, not keyup
    onKeyDown(event: any) {
        // 
        this.preventEnterKeyUnlessInTextArea(event);
    }

    preventEnterKeyUnlessInTextArea(event: any) {
        let preventEnter = true;
        if (event.target instanceof Element) {
            if (event.target instanceof HTMLTextAreaElement) {
                preventEnter = false;
            }
        }
        if (preventEnter) {
            event.preventDefault();
        }
    }
}