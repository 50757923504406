import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AllValidationErrors, getFormValidationErrors } from '../functions/get-form-validation-errors';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalValidationComponent } from '../components/modal-validation/modal-validation.component';
import { ToastrService } from 'ngx-toastr';

@Directive({
  selector: '[logValidationErrors]',
})
export class FormValidationErrorsDirective {
  @Input('logValidationErrors')
  form: NgForm;
  bsModalRef: BsModalRef;

  constructor(private el: ElementRef, private modalService: BsModalService, private toastr: ToastrService) {}

  @HostListener('submit', ['$event'])
  onSubmit(event: any) {
    if (this.form.invalid) {
      this.toastr.clear();
      const errors: AllValidationErrors[] = getFormValidationErrors(this.form.controls);
      this.toastr.warning('Validation errors occurred', 'Validation Unsuccessful', {
        disableTimeOut: true,
      });

      const firstElementWithError = document.querySelector('.ng-invalid:not(form):not([formgroupname]):not(div)');

      if (firstElementWithError) {
        firstElementWithError.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  showErrors(errors: AllValidationErrors[]) {
    const initialState = {
      title: 'Modal with component',
      errors,
    };
    this.bsModalRef = this.modalService.show(ModalValidationComponent, { initialState });
  }
}
