import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ScreenLayout, UiFacade } from 'src/app/shared/facades/ui.facade';
import { WebSiteSettingsService } from '../../services/web-settings.service';

@Component({
  selector: 'app-shell-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShellNavigationComponent implements OnInit {
  @Output()
  navClicked: EventEmitter<any> = new EventEmitter();

  constructor(private uiFacade: UiFacade, private websiteSettingsService: WebSiteSettingsService) {}

  reworcWebSiteUrl = this.websiteSettingsService.settings.reworcWebSiteUrl;
  workNavigatorWebSiteUrl = this.websiteSettingsService.settings.workNavigatorWebSiteUrl;

  ngOnInit() {}

  onNavItem() {
    this.navClicked.emit();
  }

  @ViewChild('navtooltip')
  public tooltip!: ElementRef<HTMLLIElement>;

  private removeTooltip!: any;
  // private tooltip = document.querySelector('.tooltip');

  onMouseOver(e: any, text?: string) {
    clearTimeout(this.removeTooltip);
    const screenLayout = this.uiFacade.getCurrentScreenLayout();
    if (screenLayout === ScreenLayout.Layout1_1440px) {
      // console.log(!!this.tooltip);
      // console.log(e.srcElement);

      if (this.tooltip) {
        const tooltip = this.tooltip.nativeElement;
        tooltip.innerHTML = text ?? e.srcElement.text; // 'This is the tooltip';

        var w = window;
        var tooltipTopPosition = e.srcElement.offsetTop + e.srcElement.clientHeight / 2 - 64 / 2;
        var leftPosition = e.srcElement.offsetLeft + e.srcElement.offsetWidth;
        // var toolTipWidth = w.innerWidth - leftPosition - 5;

        // console.log({
        //   tooltipHeight: tooltip.clientHeight,
        //   tooltipTopPosition,
        //   leftPosition,
        //   // toolTipWidth,
        // });

        tooltip.style.display = 'flex';
        tooltip.style.top = tooltipTopPosition + 'px';
        tooltip.style.left = leftPosition + 'px';
        // tooltip.style.width = toolTipWidth + 'px';
      }
    }
  }

  onMouseLeave(e: any) {
    clearTimeout(this.removeTooltip);
    if (this.tooltip) {
      const tooltip = this.tooltip.nativeElement;
      this.removeTooltip = setTimeout(function () {
        tooltip.innerHTML = '';
        tooltip.style.display = 'none';
      }, 0);
    }
  }
}
