import { Injectable } from '@angular/core';
import { LookupFacadeBase, LookupFacadeManager } from './lookup-facade-base';
import { Project, ProjectClient } from 'src/app/core/services/api-clients';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UIFacade } from './ui.facade';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LookupProjectFacade extends LookupFacadeBase<Project> {
  constructor(
    protected modalService: BsModalService,
    protected uiFacade: UIFacade,
    protected lookupFacadeManager: LookupFacadeManager,
    private projectClient: ProjectClient
  ) {
    super(modalService, uiFacade, lookupFacadeManager);
  }

  getLookupItemsData() {
    const partnerID = this.state.filters?.partnerID;
    if (partnerID) {
        return this.projectClient.getList(partnerID);
    }
    return of([]);
  }

  applyFilters(items: Project[], filters?: Partial<Project>): Project[] {
    return items;
  }
}
