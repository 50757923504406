import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DataStoreFacade } from 'src/app/spaceplan/shared/facades/data-store.facade';
import { InviteUsersDto, User, UserRole } from 'src/app/core/services/api-clients';
import { EditorFacadeBase } from './editor-facade-base';
import { UserFacade } from './user.facade';
import { UIFacade } from './ui.facade';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { bufferCount, catchError, filter, map } from 'rxjs/operators';

/**
 * t(spaceplan.User)
 */
@Injectable({
  providedIn: 'root',
})
export class EditorUserFacade extends EditorFacadeBase<User> {
  constructor(
    protected toastr: ToastrService,
    protected modalService: BsModalService,
    protected uiFacade: UIFacade,
    protected translocoService: TranslocoService,
    private dataStoreFacade: DataStoreFacade,
    private userFacade: UserFacade
  ) {
    super(toastr, modalService, uiFacade, translocoService);
    this.dataStoreFacade.spaceProgram$
      .pipe(
        bufferCount(2, 1),
        filter(([a, b]) => b?.spaceProgramID !== a?.spaceProgramID),
        map(([a, b]) => b)
      )
      .subscribe(() => {
        this.resetToDefault();
      });
  }
  invite(init: Partial<User> | null = null) {
    this.canOpen().subscribe((canOpen) => {
      if (canOpen) {
        this.updateState({
          isBusy: true,
          isOpen: true,
          mode: 'invite',
          title: this.translocoService.translate('spaceplan.editorUsersInviteForm.title'),
          saveButtonTitle: this.translocoService.translate('spaceplan.editorUsersInviteForm.saveButton.caption'),
        });

        const model = { ...(init || {}), userName: null, roleNo: null };
        const modelWithChanges = { ...model };
        this.updateState({ model, modelWithChanges, isBusy: false });
        this.scrollTo('editor-form-top');
      }
    });
  }

  protected className(): string {
    return this.translocoService.translate('spaceplan.User');
  }
  protected getFromDataStore(id: string): Observable<User> {
    return this.dataStoreFacade.getUser(id);
  }
  protected updateDataStore(model: User): Observable<User> {
    return this.dataStoreFacade.updateUser(model);
  }
  protected deleteDataStore(id: string): Observable<boolean> {
    return this.dataStoreFacade.deleteUser(id);
  }
  protected getModelId(model: User): string {
    return model?.spaceProgramUserID;
  }
  protected setModelId(model: User, id: string) {
    model.spaceProgramUserID = id;
  }
  protected userCanEdit(): Observable<boolean> {
    // user role = Partner ??
    return of(true);
  }
  protected getNewModel(init: Partial<User>): Observable<User> {
    return of({
      ...init,
      spaceProgramUserID: null,
      userId: null,
    });
  }
  protected modeHasCustomSave(mode: string) {
    return mode === 'invite';
  }
  protected saveCustom(mode: string, modelWithChanges: User) /*: Observable<DataStoreResult<User>>*/ {
    const inviteUsers: InviteUsersDto = {
      spaceProgramID: modelWithChanges.spaceProgramID,
      // userList: modelWithChanges.userName.split(/(?:,| |;)+/),
      userList: modelWithChanges.userName.split(/(?:[\s;,])+/),
      roleNo: modelWithChanges.roleNo,
    };

    return this.dataStoreFacade.inviteUsers(inviteUsers).pipe(
      map(() => {
        this.successToast();
        this.resetAndClose();
        return { success: true };
      }),
      catchError((error) => {
        this.updateState({ isBusy: false });
        return this.handleDataStoreError(error).pipe(map((storeError) => ({ error: storeError, success: false })));
      })
    );
  }
}
