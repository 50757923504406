import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ViewChild, ElementRef, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { Options, ImageResult, ImageToDataUrlDirective } from 'ngx-image2dataurl';
import { ToastrService } from 'ngx-toastr';

const CUSTOM_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AppImageUploadComponent),
  multi: true,
};

@Component({
  selector: 'app-file-upload',
  providers: [CUSTOM_VALUE_ACCESSOR],
  template: `
    <input style="display: none" type="file" [imageToDataUrl]="options" (imageSelected)="onImageSelected($event)" #fileInput />
    <div>
      <div>
        <img
          width="120"
          height="120"
          [src]="imageUrl || '/assets/images/placeholder_image.png'"
          alt="Generic placeholder image"
          (click)="showFileDialog()"
        />
      </div>
      <div style="position: relative;margin-top: -29px;margin-left: 92px;opacity: 0.6;" *ngIf="imageUrl">
        <button type="button" style="padding: 4px;border-radius: 8px;" (click)="removeImage()">
          <span class="k-icon k-i-trash"></span>
        </button>
      </div>
    </div>
  `,
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppImageUploadComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef<HTMLInputElement>;

  private onChange: (_: any) => void;
  private onTouched: () => void;
  private disabled: boolean;
  imageUrl: string;
  options: Options = {
    resize: {
      maxHeight: 120,
      maxWidth: 120,
      quality: 1,
    },
    allowedExtensions: ['JPG', 'JPEG', 'PNG'],
  };

  constructor(private toastr: ToastrService, protected translocoService: TranslocoService) {
    this.onChange = (_: any) => {};
    this.onTouched = () => {};
  }

  ngOnInit(): void {}

  ngOnDestroy() {}

  showFileDialog(): void {
    if (!this.disabled) {
      this.fileInput.nativeElement.click();
    }
  }

  removeImage() {
    this.imageUrl = '';
    this.onChange(this.imageUrl);
    this.onTouched();
  }

  onImageSelected(imageResult: ImageResult) {
    console.log('imageResult', imageResult);
    if (imageResult.error) {
      this.toastr.error(this.translocoService.translate('root.validation.error.images.error_2'));
      return;
    }

    // const imageContent = ((imageResult.resized && imageResult.resized.dataURL) || imageResult.dataURL).split(',')[1];
    this.imageUrl = (imageResult.resized && imageResult.resized.dataURL) || imageResult.dataURL;
    this.onChange(this.imageUrl);
    this.onTouched();
    this.fileInput.nativeElement.value = '';
  }

  writeValue(obj: string): void {
    this.imageUrl = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}

export interface IImageUploadImage {
  imageUrl?: string;
  imageContent?: string;
}
