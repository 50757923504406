import { DomSanitizer } from '@angular/platform-browser';
import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'safeCkEditorHtml' })
export class SafeCkEditorHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    // this.sanitizer.bypassSecurityTrustStyle(value);
    // this.sanitizer.bypassSecurityTrustHtml(value);
    // this.sanitizer.bypassSecurityTrustXxx(value); // - see docs [https://angular.io/api/platform-browser/DomSanitizer]
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
