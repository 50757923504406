import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { ModalConfirmComponent } from '../components/modal-confirm/modal-confirm.component';
import { ModalErrorComponent } from '../components/modal-error/modal-error.component';
import { translate } from '@ngneat/transloco';

/**
 * t(dialog.unsavedChanges.title)
 * t(dialog.unsavedChanges.message)
 * t(dialog.unsavedChanges.confirmButton.caption)
 * t(dialog.unsavedChanges.cancelButton.caption)
 * t(dialog.confirmDelete.title)
 * t(dialog.confirmDelete.message)
 * t(dialog.confirmDelete.confirmButton.caption)
 * t(dialog.confirmDelete.cancelButton.caption)
 * t(dialog.confirmDuplicate.title)
 * t(dialog.confirmDuplicate.message)
 * t(dialog.confirmDuplicate.confirmButton.caption)
 * t(dialog.confirmDuplicate.cancelButton.caption)
 * t(dialog.error.title)
 * t(dialog.error.message)
 * t(dialog.error.cancelButtonText.caption)
 */

export function confirmAction(
  modalService: BsModalService,
  title: string,
  message: string,
  confirmButton: string,
  cancelButton: string
): Observable<boolean> {
  return confirmActionModal(modalService, title, message, confirmButton, cancelButton);
}

export function confirmClose(modalService: BsModalService): Observable<boolean> {
  return confirmCloseModal(
    modalService,
    translate('root.dialog.unsavedChanges.title'),
    translate('root.dialog.unsavedChanges.message'),
    translate('root.dialog.unsavedChanges.confirmButton.caption'),
    translate('root.dialog.unsavedChanges.cancelButton.caption')
  );
}

export function confirmDelete(modalService: BsModalService): Observable<boolean> {
  return confirmDeleteModal(
    modalService,
    translate('root.dialog.confirmDelete.title'),
    translate('root.dialog.confirmDelete.message'),
    translate('root.dialog.confirmDelete.confirmButton.caption'),
    translate('root.dialog.confirmDelete.cancelButton.caption')
  );
}

export function confirmArchive(modalService: BsModalService): Observable<boolean> {
  return confirmDeleteModal(
    modalService,
    translate('root.dialog.confirmArchive.title'),
    translate('root.dialog.confirmArchive.message'),
    translate('root.dialog.confirmArchive.confirmButton.caption'),
    translate('root.dialog.confirmArchive.cancelButton.caption')
  );
}

export function confirmUnarchive(modalService: BsModalService): Observable<boolean> {
  return confirmDeleteModal(
    modalService,
    translate('root.dialog.confirmUnarchive.title'),
    translate('root.dialog.confirmUnarchive.message'),
    translate('root.dialog.confirmUnarchive.confirmButton.caption'),
    translate('root.dialog.confirmUnarchive.cancelButton.caption')
  );
}

export function confirmDuplicate(modalService: BsModalService): Observable<boolean> {
  return confirmDuplicateModal(
    modalService,
    translate('root.dialog.confirmDuplicate.title'),
    translate('root.dialog.confirmDuplicate.message'),
    translate('root.dialog.confirmDuplicate.confirmButton.caption'),
    translate('root.dialog.confirmDuplicate.cancelButton.caption')
  );
}

export function showError(modalService: BsModalService, title: string, message: string, detail?: string): Observable<boolean> {
  return showErrorModal(
    modalService,
    title || translate('root.dialog.error.title'),
    message || translate('root.dialog.error.message'),
    detail,
    translate('root.dialog.error.cancelButtonText.caption')
  );
}
export function confirmActionModal(
  modalService: BsModalService,
  title: string,
  message: string,
  confirmButtonText: string,
  cancelButtonText: string
): Observable<boolean> {
  const initialState = {
    title,
    message,
    confirmButtonText,
    cancelButtonText,
  };
  const bsModalRef = modalService.show(ModalConfirmComponent, { initialState });
  return bsModalRef.content.onClose;
}

export function confirmCloseModal(
  modalService: BsModalService,
  title: string,
  message: string,
  confirmButtonText: string,
  cancelButtonText: string
): Observable<boolean> {
  const initialState = {
    title,
    message,
    confirmButtonText,
    cancelButtonText,
  };
  const bsModalRef = modalService.show(ModalConfirmComponent, { initialState });
  return bsModalRef.content.onClose;
}

export function confirmDeleteModal(
  modalService: BsModalService,
  title: string,
  message: string,
  confirmButtonText: string,
  cancelButtonText: string
): Observable<boolean> {
  const initialState = {
    title,
    message,
    confirmButtonText,
    cancelButtonText,
  };
  const bsModalRef = modalService.show(ModalConfirmComponent, { initialState });
  return bsModalRef.content.onClose;
}

export function confirmDuplicateModal(
  modalService: BsModalService,
  title: string,
  message: string,
  confirmButtonText: string,
  cancelButtonText: string
): Observable<boolean> {
  const initialState = {
    title,
    message,
    confirmButtonText,
    cancelButtonText,
  };
  const bsModalRef = modalService.show(ModalConfirmComponent, { initialState });
  return bsModalRef.content.onClose;
}

export function showErrorModal(
  modalService: BsModalService,
  title: string,
  message: string,
  detail: string,
  cancelButtonText: string
): Observable<boolean> {
  const initialState = {
    title,
    message,
    detail,
    cancelButtonText,
  };
  const bsModalRef = modalService.show(ModalErrorComponent, { initialState });
  return bsModalRef.content.onClose;
}
