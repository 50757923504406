import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { AllValidationErrors } from '../../functions/get-form-validation-errors';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-validation',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">
        <small>{{ title }}</small>
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="onClose(false)">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row" *ngFor="let error of errors">
        <div class="col-4">
          {{ error.control_name }}
        </div>
        <div class="col-8 text-right">
          {{ error.error_name }}
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="onClose(true)">{{ okButtonText }}</button>
    </div>
  `,
})
export class ModalValidationComponent implements OnInit {
  @Input() title: string = 'Ok';
  @Input() errors: AllValidationErrors[];
  @Input() okButtonText: string = 'Ok';

  constructor(private bsModalRef: BsModalRef) {}

  ngOnInit() {}

  public onClose(value: any): void {
    this.bsModalRef.content.onClose.next(value);
    this.bsModalRef.hide();
  }
}
