<ng-container *transloco="let t; read: 'spaceplan'">
  <div id="editor-form-top" *ngIf="form" [formGroup]="form">
    <div class="form-group form-row mb-1">
      <label for="folderName" class="col-sm-12 col-form-label">{{ t('editorFolderForm.folderName.label') }}:</label>
      <div class="col-sm-12">
        <div class="input-group">
          <input type="text" class="form-control" id="name"
            placeholder="{{ t('editorFolderForm.folderName.placeholder') }}" name="folderName"
            formControlName="folderName" />
        </div>
        <div *ngIf="isRequesting && form.get('folderName').errors" class="invalid-feedback">
          <div class="form-error" *ngIf="form.controls['folderName'].errors.required">
            {{ t('editorFolderForm.folderName.error.required') }}
          </div>
          <!-- <div class="form-error" *ngIf="form.controls['folderName'].errors.duplicate">
            {{ t('editorFolderForm.folderName.error.duplicate') }}
          </div> -->
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-2">
      <label for="partnerID" class="col-sm-12 col-form-label">{{ t('editorFolderForm.partner.label') }}:</label>
      <div class="col-sm-12">
        <select class="custom-select"
          [attr.disabled]="model.folderID || (!model.folderID && partners.length === 1) ? '' : null" id="partnerID"
          name="partnerID" formControlName="partnerID" style="border-radius: 0.25rem;">
          <option [ngValue]="null">{{ t('editorFolderForm.partner.option.null_Please_select_partner') }}</option>
          <ng-template ngFor let-partner [ngForOf]="partners">
            <option [ngValue]="partner.partnerID">{{ partner.partnerDescr }}</option>
          </ng-template>
        </select>
        <div *ngIf="isRequesting && form.get('partnerID').errors" class="invalid-feedback">
          <div class="form-error" *ngIf="form.get('partnerID').errors && form.controls['partnerID'].errors.required">
            {{ t('editorFolderForm.partnerID.error.required') }}
          </div>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-2">
      <label for="usageType" class="col-sm-12 col-form-label">{{ t('editorFolderForm.folderUsageType.label') }}:</label>
      <div class="col-sm-12">
        <select class="custom-select" id="us" name="usageType" formControlName="usageType"
          style="border-radius: 0.25rem;">
          <!-- <option [ngValue]="null">{{ t('editorFolderForm.partner.option.null_Please_select_folderUsageType') }}</option> -->
          <ng-template ngFor let-folderUsageType [ngForOf]="folderUsageTypes">
            <option [ngValue]="folderUsageType.type">{{ t('folderUsageType.' + folderUsageType.text) }} </option>
          </ng-template>
        </select>
      </div>
    </div>

    <!-- <div class="form-group form-row mb-1">
      <label for="suitableFor"
        class="col-sm-12 col-form-label">{{ t('editorWorkplacetypeForm.suitableFor.label') }}:</label>
      <div class="col-sm-12">
        <div class="input-group mb-1">
          <textarea name="suitableFor" id="suitableFor"
            placeholder="{{ t('editorWorkplacetypeForm.suitableFor.placeholder') }}" class="form-control"
            formControlName="suitableFor" rows="6" style="resize: none;"></textarea>
        </div>
      </div>
    </div>

    <div class="form-group form-row mb-1">
      <div class="col-sm-12">
        <div class="form-check mt-1">
          <input class="form-check-input k-checkbox" type="checkbox" kendoCheckBox formControlName="inFlexFactorCalcInd"
            id="inFlexFactorCalcInd" placeholder="{{ t('editorWorkplacetypeForm.inFlexFactorCalcInd.placeholder') }}" />
          <label class="form-check-label"
            for="inFlexFactorCalcInd">{{ t('editorWorkplacetypeForm.inFlexFactorCalcInd.label') }}</label>
        </div>
      </div>
    </div> -->

    <!-- <div class="form-group form-row mb-1">
      <label for="name" class="col-sm-12 col-form-label">{{ t('editorWorkplacetypeForm.imageUrl.label') }}:</label>
      <div class="col-sm-12">
        <div class="input-group mb-1">
          <app-file-upload formControlName="imageUrl"></app-file-upload>
        </div>
      </div>
    </div> -->


  </div>
</ng-container>