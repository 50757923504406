<ng-container *transloco="let t; read: 'spaceplan'">
  <div class="d-flex flex-column ml-5" *ngIf="options$ | async as options">
    <div *ngIf="!options.readOnly" class="pl-0 pt-3">{{ t('ADD_TAG_GROUP') }}</div>

    <div *ngIf="!options.readOnly" class="pl-0 pr-0 pt-3 pb-3">
      <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate [logValidationErrors]="form" appFormHandleEnterKey>
        <div class="input-group mb-3">
          <input type="text" class="form-control" placeholder="{{ t('editorTagsForm.tagGroupName.placeholder') }}"
            aria-label="Enter group" aria-describedby="basic-addon2" formControlName="tagGroupName" />
          <div class="input-group-append">
            <button class="btn btn-light" type="button" (click)="onAddTagGroup()"
              [disabled]="!form.valid || !form.get('tagGroupName').value"
              style="border: 1px solid rgba(33, 37, 41, 0.15);">
              <i class="k-icon k-i-plus"></i>
            </button>
          </div>
        </div>
      </form>
    </div>

    <div *ngFor="let tagGroup of options.tagGroups">
      <app-editor-tag-list [tagGroup]="tagGroup" [options]="options$ | async"></app-editor-tag-list>
    </div>
  </div>
</ng-container>