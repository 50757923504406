import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DataStoreFacade } from 'src/app/spaceplan/shared/facades/data-store.facade';
import { UserRole, WorkPlaceType } from 'src/app/core/services/api-clients';
import { EditorFacadeBase } from './editor-facade-base';
import { UserFacade } from './user.facade';
import { UIFacade } from './ui.facade';
import { TranslocoService } from '@ngneat/transloco';
import { bufferCount, filter, map } from 'rxjs/operators';

/**
 * t(spaceplan.WorkPlaceType)
 */
@Injectable({
  providedIn: 'root',
})
export class EditorWorkPlaceTypeFacade extends EditorFacadeBase<WorkPlaceType> {
  constructor(
    protected toastr: ToastrService,
    protected modalService: BsModalService,
    protected uiFacade: UIFacade,
    protected translocoService: TranslocoService,
    private dataStoreFacade: DataStoreFacade,
    private userFacade: UserFacade
  ) {
    super(toastr, modalService, uiFacade, translocoService);
    this.dataStoreFacade.spaceProgram$
      .pipe(
        bufferCount(2, 1),
        filter(([a, b]) => b?.spaceProgramID !== a?.spaceProgramID),
        map(([a, b]) => b)
      )
      .subscribe(() => {
        this.resetToDefault();
      });
  }
  protected className(): string {
    return this.translocoService.translate('spaceplan.WorkPlaceType');
  }
  protected getFromDataStore(id: string): Observable<WorkPlaceType> {
    return this.dataStoreFacade.getWorkPlaceType(id);
  }
  protected createDataStore(model: WorkPlaceType): Observable<WorkPlaceType> {
    return this.dataStoreFacade.createWorkPlaceType(model);
  }
  protected updateDataStore(model: WorkPlaceType): Observable<WorkPlaceType> {
    return this.dataStoreFacade.updateWorkPlaceType(model);
  }
  protected deleteDataStore(id: string): Observable<boolean> {
    return this.dataStoreFacade.deleteWorkPlaceType(id);
  }
  protected getModelId(model: WorkPlaceType): string {
    return model?.workPlaceTypeID;
  }
  protected setModelId(model: WorkPlaceType, id: string) {
    model.workPlaceTypeID = id;
  }
  protected userCanEdit(): Observable<boolean> {
    return this.dataStoreFacade.spaceProgram$.pipe(
      map(m => (m.roleNo === UserRole.Composer))
    );
  }
  public canDelete(id: string): Observable<boolean> {
    const state = this.dataStoreFacade.state;
    const canDelete =
      state.activityToWorkPlaceTypes.filter((f) => f.workPlaceTypeID === id)?.length === 0 &&
      state.generators.filter((f) => f.inputWorkPlaceTypeID === id)?.length === 0;
    return of(canDelete);
  }
  protected getNewModel(init: Partial<WorkPlaceType>): Observable<WorkPlaceType> {
    return of({
      ...init,
      workPlaceTypeID: null,
      inFlexFactorCalcInd: init.inFlexFactorCalcInd || false,
    });
  }
}
